// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../BuildingBlocks/Text';
import { StyledFooterLink } from './FooterLink.styles';

export default class FooterLink extends Component {
  static propTypes = {
    href: PropTypes.string,
    children: PropTypes.node.isRequired,
    target: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    href: undefined,
    target: undefined,
    className: undefined,
    onClick: () => { }
  };

  render() {
    const { children } = this.props;
    return (
      <StyledFooterLink {...this.props}>
        <Text.CopyParagraph color="secondary" noMargins>
          {children}
        </Text.CopyParagraph>
      </StyledFooterLink>
    );
  }
}
