import styled from 'styled-components';
import Grid from '../../Utility/Grid';
import { colors, media, mixins } from '../../css';

export const StyledFooter = styled.div`
  width: 100%;
  background-color: ${colors.colorGrey02};
  padding: ${mixins.pxToRem(35)} 0 0 0;
`;

export const StyledFooterBottomRow = styled(Grid.Row)`
  && {
    padding-top: ${mixins.pxToRem(15)};
    text-align: center;
    ${media.greaterThan('md')`
      padding-top: ${mixins.pxToRem(70)};
    `};
  }
`;

export const StyledLogoColumn = styled(Grid.Col)`
  && {
    ${media.between('md', 'lg')`
      padding-bottom: ${mixins.pxToRem(70)};
    `};
  }
`;
