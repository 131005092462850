import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class PrivateWashroom extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m14.59 4.23v-3h-10.09v21.51h1v-20.48h8.09v2a5.15 5.15 0 0 0 -4.85 5.11v.5h10.76v-.5a5.16 5.16 0 0 0 -4.91-5.14zm-4.82 4.64a4.16 4.16 0 0 1 4.13-3.66h.43a4.17 4.17 0 0 1 4.14 3.66z"
        />
        <path fill={iconColor} d="m10.42 12.43h1v1.66h-1z" />
        <path fill={iconColor} d="m10.42 15.27h1v1.66h-1z" />
        <path fill={iconColor} d="m10.42 18.27h1v1.66h-1z" />
        <path fill={iconColor} d="m16.42 12.43h1v1.66h-1z" />
        <path fill={iconColor} d="m16.42 15.27h1v1.66h-1z" />
        <path fill={iconColor} d="m16.42 18.27h1v1.66h-1z" />
        <path fill={iconColor} d="m13.42 13.93h1v1.66h-1z" />
        <path fill={iconColor} d="m13.42 16.77h1v1.66h-1z" />
        <path fill={iconColor} d="m13.42 19.77h1v1.66h-1z" />
        <path fill={iconColor} d="m13.42 10.93h1v1.66h-1z" />
      </svg>
    );
  }
}
