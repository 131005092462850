import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class DownloadIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg viewBox="0 0 50 50" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
          <g id="Icons_x2F_Download" transform="translate(-1.000000, 0.000000)">
            <g id="Icon_LL_Download" transform="translate(1.000000, 0.000000)">
              <polygon
                points="33.3,24.4 30.9,22 26.7,26.3 26.7,9.4 23.3,9.4 23.3,26.3 19.2,22.1 16.8,24.5 22.6,30.3 22.6,30.3 25,32.6 
				27.3,30.3 27.3,30.3"
                fill={iconColor}
              />
              <polygon
                id="Rectangle-path_4_"
                points="4.7,40.6 4.7,30.4 8,30.4 8,37.3 42,37.3 42,30.4 45.3,30.4 45.3,40.6"
                fill={iconColor}
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
