import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Utility/Grid';
import Search from '../../Search/Search';
import { locationsShape, patternsShape } from '../../Search/Search.shapes';
import { defaultResults } from '../../Search/Search.seedData';
import { StyledSearchSVG } from './NavSearchDesktop.styles';

export default class NavSearchDesktop extends Component {
  static propTypes = {
    clearValue: PropTypes.func,
    onFlyoutCloseHook: PropTypes.func,
    onFlyoutItemClickHook: PropTypes.func,
    onBlurHook: PropTypes.func,
    onChangeHook: PropTypes.func,
    onSubmitHook: PropTypes.func,
    flexRatio: PropTypes.string,
    onFocusHook: PropTypes.func,
    patterns: patternsShape,
    locations: locationsShape,
    searchName: PropTypes.string,
    searchIcon: PropTypes.node,
    searchValue: PropTypes.string,
    canSubmit: PropTypes.bool
  };

  static defaultProps = {
    clearValue: () => {},
    locations: [],
    onBlurHook: () => {},
    onChangeHook: () => {},
    onFlyoutItemClickHook: () => {},
    onFlyoutCloseHook: () => {},
    onFocusHook: () => {},
    onSubmitHook: () => {},
    patterns: defaultResults.locationsType,
    searchName: 'NavSearchBar',
    searchIcon: <StyledSearchSVG selectedIcon="search" />,
    searchValue: '',
    flexRatio: '',
    canSubmit: true
  };

  render() {
    const {
      clearValue,
      searchIcon,
      flexRatio,
      searchName,
      onBlurHook,
      onChangeHook,
      onFlyoutItemClickHook,
      onFlyoutCloseHook,
      onFocusHook,
      onSubmitHook,
      patterns,
      locations,
      canSubmit,
      searchValue
    } = this.props;

    return (
      <Grid.ScreenClassRender
        render={screenClass => (
          <Search
            clearValue={clearValue}
            height={60}
            icon={['xl'].includes(screenClass) ? searchIcon : null}
            locations={locations}
            name={searchName}
            flexRatio={flexRatio}
            onBlurHook={onBlurHook}
            onChangeHook={onChangeHook}
            onFlyoutItemClickHook={onFlyoutItemClickHook}
            onFlyoutCloseHook={onFlyoutCloseHook}
            onFocusHook={onFocusHook}
            patterns={patterns}
            value={searchValue}
            onSubmitHook={onSubmitHook}
            canSubmit={canSubmit}
            hasButton
          />
        )}
      />
    );
  }
}
