import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class PoweredParking extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m14.06 13.35-.71-.71-1.77 1.77-2.12-2.12 1.77-1.77-.71-.7-1.76 1.76-1.89-1.89-.71.71.51.51a4.41 4.41 0 0 0 0 5.59l-5.34 5.34.71.7 5.34-5.33a4.43 4.43 0 0 0 5.58 0l.51.51.71-.71-1.89-1.89zm-6.37 2.83a3.48 3.48 0 0 1 -1-2.48 3.4 3.4 0 0 1 .71-2.08l4.87 4.87a3.58 3.58 0 0 1 -4.58-.31z"
        />
        <path
          fill={iconColor}
          d="m17.33 7.5 5.34-5.34-.67-.71-5.38 5.34a4.49 4.49 0 0 0 -5.59 0l-.51-.51-.7.72 7.31 7.31.71-.7-.51-.52a4.48 4.48 0 0 0 0-5.59zm-1 .32a3.49 3.49 0 0 1 .32 4.56l-4.91-4.88a3.49 3.49 0 0 1 4.56.32z"
        />
      </svg>
    );
  }
}
