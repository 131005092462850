// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FooterLabel from '../FooterLabel';
import { StyledFooterColumn } from './FooterColumn.styles';

export default class FooterColumn extends Component {
  static propTypes = {
    heading: PropTypes.string,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    heading: undefined
  };

  render() {
    const { heading, children } = this.props;
    return (
      <StyledFooterColumn>
        <FooterLabel>{heading}</FooterLabel>
        {children}
      </StyledFooterColumn>
    );
  }
}
