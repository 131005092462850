import styled, { css, keyframes } from 'styled-components';
import { mixins, colors, media, zIndex } from '../../css';

const slideIn = keyframes`
0% {
  transform: translateY(-500px);
  opacity: 0;
}  
100% {
    transform: translateY(0);
    opacity: 1;
  }
  
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-500px);
    opacity: 0;
  }
`;

export const NavBorder = styled.div`
  width: 100%;
  position: relative;
  background-color: ${colors.colorWhite};
  &:after {
    content: '';
    width: 100vw;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ isInBookingFlow }) => (isInBookingFlow ? 'transparent' : colors.colorGrey05)};
  }
`;

export const NavWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 55px;
  z-index: ${zIndex.navigation.main};

  ${media.greaterThan('lg')`height: ${mixins.pxToRem(80)};`};

  &.nav-visible {
    ${NavBorder} {
      position: fixed;
      top: 0;
      left: 0;
      animation: ${css`
        ${slideIn} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `};
    }
  }

  &.nav-hidden {
    ${NavBorder} {
      position: fixed;
      top: 0;
      left: 0;
      animation: ${css`
        ${slideOut} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      `};
    }
  }
`;
