import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import Grid from '../Utility/Grid';
import VerticalSpacing from '../Utility/VerticalSpacing';
import PageBackgroundWrapper from '../BuildingBlocks/PageBackgroundWrapper/PageBackgroundWrapper';
import Text from '../BuildingBlocks/Text';

import PageSEO from '../PageSEO';

export default class NoPermission extends Component {
  static propTypes = {
    missingPermissions: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired
  };

  render() {
    const { missingPermissions, organizationName } = this.props;
    const message = missingPermissions;

    return (
      <PageBackgroundWrapper>
        <PageSEO title="Missing User Permission - LodgeLink" />
        <Grid.Container>
          <Grid.Row align="center">
            <Grid.Col md={8} push={{ md: 2 }}>
              <VerticalSpacing>
                <Text.HeadingFirst tagName="h2" color="quaternary" align="center" noMargins>
                  <SVGIcon selectedIcon="projects" /> <br /> Missing User Permission(s)
                </Text.HeadingFirst>
                <Text.CopyBody tagName="p" align="center">
                  You currently do not have the required permissions to view this page for{' '}
                  <Text.CopyBody tagName="strong" weight="bold">
                    {organizationName}
                  </Text.CopyBody>
                  . If you think this is a mistake please check with your organization administrators or contact
                  LodgeLink for additional support.
                </Text.CopyBody>
                <Text.CopyBody tagName="p" align="center">
                  Missing permission(s):{' '}
                  <Text.CopyBody tagName="strong" weight="bold">
                    {message}{' '}
                  </Text.CopyBody>
                </Text.CopyBody>
              </VerticalSpacing>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </PageBackgroundWrapper>
    );
  }
}
