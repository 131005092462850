import getConfig from 'next/config';
import LocalStorage from '../../../lib/storage/local';
import MemoryStorage from '../../../lib/storage/memory';

const {
  publicRuntimeConfig: {
    DEFAULT_PRICE_RANGE_MIN: ENV_DEFAULT_PRICE_RANGE_MIN,
    DEFAULT_PRICE_RANGE_MAX: ENV_DEFAULT_PRICE_RANGE_MAX,
    DEFAULT_DISTANCE: ENV_DEFAULT_DISTANCE
  }
} = getConfig();

export const DEFAULT_PRICE_RANGE_MIN = parseInt(ENV_DEFAULT_PRICE_RANGE_MIN) || 1;
export const DEFAULT_PRICE_RANGE_MAX = parseInt(ENV_DEFAULT_PRICE_RANGE_MAX) || 800;
export const DEFAULT_DISTANCE = parseInt(ENV_DEFAULT_DISTANCE) || 100;
export const DEFAULT_SAVED_AMENITIES = [];
export const DEFAULT_SAVED_LAYERS = [];
export const DEFAULT_SAVED_PROPERTY_TYPES = [1, 2]; // 2 is for hotels. 1 is for lodges

export const DEFAULT_MAP_FILTERS = {
  __typename: 'MAP_FILTERS',
  savedDistance: DEFAULT_DISTANCE,
  savedPriceRangeMin: DEFAULT_PRICE_RANGE_MIN,
  savedPriceRangeMax: DEFAULT_PRICE_RANGE_MAX,
  savedPropertyTypes: DEFAULT_SAVED_PROPERTY_TYPES,
  savedAmenities: DEFAULT_SAVED_AMENITIES,
  savedLayers: DEFAULT_SAVED_LAYERS
};

const createStore = () => {
  if (!process.browser) {
    return new MemoryStorage();
  }

  return new LocalStorage('LLV2_MAP_FILTERS');
};

const mapFilters = () => {
  const initial = {
    __typename: 'MAP_FILTERS',
    savedDistance: DEFAULT_DISTANCE,
    savedPriceRangeMin: DEFAULT_PRICE_RANGE_MIN,
    savedPriceRangeMax: DEFAULT_PRICE_RANGE_MAX,
    savedPropertyTypes: DEFAULT_SAVED_PROPERTY_TYPES,
    savedAmenities: DEFAULT_SAVED_AMENITIES,
    savedLayers: DEFAULT_SAVED_LAYERS
  };

  const store = createStore();
  store.ensureValues(initial);

  return {
    defaults: { mapFilters: store.read() },

    resolvers: {
      Mutation: {
        updateMapFilters: async (parent, args, { cache }) => {
          const { distance, priceRangeMin, priceRangeMax, propertyTypes, amenities } = args.mapFilters;

          const savedAmenities = amenities.filter(amenity => amenity.selected).map(amenity => amenity.layerId);

          const savedPropertyTypes = propertyTypes
            .filter(propertyType => propertyType.selected)
            .map(propertyType => propertyType.layerId);

          // let mapFilters = store.read();
          let mapFilters = store.set('savedPropertyTypes', savedPropertyTypes);
          mapFilters = store.set('savedAmenities', savedAmenities);
          mapFilters = store.set('savedDistance', distance);
          mapFilters = store.set('savedPriceRangeMax', priceRangeMax);
          mapFilters = store.set('savedPriceRangeMin', priceRangeMin);

          cache.writeData({
            data: {
              mapFilters
            }
          });

          return mapFilters;
        },
        updateMapLayers: async (parent, args, { cache }) => {
          const { layers } = args.mapLayers;
          const savedLayers = layers.filter(layer => layer.selected).map(layer => layer.layerId);
          const mapFilters = store.set('savedLayers', savedLayers);

          cache.writeData({
            data: {
              mapFilters
            }
          });

          return mapFilters;
        }
      },
      Query: {
        mapFilters: () => {
          const values = store.read() || initial;
          return values;
        }
      }
    },
    typeDefs: `
      type MAP_FILTERS {
        savedDistance: Int
        savedPriceRangeMin: Int
        savedPriceRangeMax: Int
        savedPropertyTypes: [String]
        savedAmenities: [String]
        savedLayers: [String]
      }
    `
  };
};

export default mapFilters;
