import PropTypes from 'prop-types';

/**
 * API will return two main objects when a query has started.
 * These are being referred to as 'suggests'.
 *
 * LOCATION SUGGESTS
 *
 * These are actual results based on the query sent to the API.
 * Results will only be returned after a user has typed more than
 * 3 characters into the search box. Before that, the API will return
 * Pattern Suggests to guide the user.
 *
 * --
 * PATTERN SUGGESTS
 *
 * Pattern suggests can always be return from the API when a user has input
 * a query into the search field. Pattern Suggests are suggestions to the user
 * for what they could search for based on what they've started to type.
 *
 * As Result Suggests start to return, Pattern Suggests will still be returned.
 * These will be shifted to the bottom of the pile.
 */
export const locationsShape = PropTypes.arrayOf(
  PropTypes.shape({
    locationType: PropTypes.string,
    locationText: PropTypes.string,
    locationValue: PropTypes.string
  })
);

export const patternsShape = PropTypes.arrayOf(
  PropTypes.shape({
    locationType: PropTypes.string,
    patternName: PropTypes.string,
    patternDescription: PropTypes.string
  })
);
