import styled from 'styled-components';
import { fonts, media, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledCopyParagraph = styled(Base)`
  text-align: ${props => props.align};
  text-transform: ${props => props.textTransform};
  font-family: ${props => (props.weight === 'bold' ? fonts.fontBold : fonts.fontRegular)};
  font-size: ${mixins.pxToRem(14)};
  line-height: ${mixins.pxToRem(24)};
  color: ${props => props.color};
  margin: ${props => (props.noMargins === false ? `${mixins.pxToRem(8)} 0` : 0)};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};

  a {
    color: inherit;
  }

  ${media.greaterThan('lg')`
    margin: ${props => (props.noMargins === false ? `${mixins.pxToRem(16)} 0` : 0)};
  `};
`;
