import React, { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { withToastManager } from 'react-toast-notifications';

const toastManagerPropType = PropTypes.shape({
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  ).isRequired
});

export const withToastsPropType = PropTypes.shape({
  dispatchToast: PropTypes.func.isRequired,
  toastManager: toastManagerPropType.isRequired
});

const MAX_TOASTS = 3;

/**
 * HOC for wrapping react-toast-notifications with common defaults.
 * If you're looking for the ToastComponents, those are setup in the Provider implementation
 * which currently lives in Layouts/DefaultLayout
 *
 * @param {object} defaultOverrides - set of options to override any of the default options.
 * This overrides at the HOC level. Individual toasts can have their own overrides by passing in
 * a secondary toastOverrides parameter in the same shape as defaultOverrides
 */

const withToasts = defaultOverrides => BaseComponent => {
  class ComponentWithToasts extends Component {
    static propTypes = {
      toastManager: toastManagerPropType.isRequired
    };

    state = { toastQueue: [] };

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
      this.clearQueue();
    }

    buildToastProps = toastOverrides => ({
      // these are our defaults that can be overridden at the HOC compose
      // level by passing in an override object - withToasts({ autoDismiss: false })
      autoDismiss: true,
      autoDismissTimeout: 5000,
      onDismiss: this.handleToastDismiss,
      placement: 'bottom-left',
      transitionDuration: 250,
      appearance: 'success',

      // pass in an overrides from the HOC compose level
      ...defaultOverrides,

      // pass in overrides from the toast.add level (page-level)
      ...toastOverrides
    });

    dispatchToast = (content, toastOverrides) => {
      const { toastManager } = this.props;
      if (toastManager.toasts.length < MAX_TOASTS) {
        toastManager.add(content, { ...this.buildToastProps(toastOverrides) });
      } else {
        this.pushToQueue({ content, toastOverrides });
      }
    };

    pushToQueue = toast => {
      this.asyncSetState(prev => ({
        toastQueue: [...prev.toastQueue, toast]
      }));
    };

    popFromQueue = () => {
      const { toastQueue } = this.state;
      const theToast = toastQueue.splice(0, 1)[0];
      this.asyncSetState({ toastQueue: [...toastQueue] }, () =>
        this.dispatchToast(theToast.content, theToast.toastOverrides)
      );
    };

    clearQueue = () => {
      this.asyncSetState({ toastQueue: [] });
    };

    handleToastDismiss = () => {
      const { toastQueue } = this.state;
      if (toastQueue.length > 0 && this.mounted) {
        this.popFromQueue();
      }
    };

    buildToasts = () => {
      const { toastManager } = this.props;
      return {
        dispatchToast: this.dispatchToast,
        clearQueue: this.clearQueue,
        toastManager
      };
    };

    asyncSetState(state, cb) {
      if (this.mounted) {
        this.setState(state, cb);
      }
    }

    render() {
      // exclude the non-wrapped toast props
      const blackListProps = ['toastManager'];
      const passThroughProps = omit(this.props, blackListProps);
      return <BaseComponent toasts={this.buildToasts()} {...passThroughProps} />;
    }
  }

  return withToastManager(ComponentWithToasts);
};

export default withToasts;
