import leftPad from 'left-pad';

import LocalStorage from '../../../lib/storage/local';
import MemoryStorage from '../../../lib/storage/memory';

const createStore = () => {
  if (!process.browser) {
    return new MemoryStorage();
  }
  return new LocalStorage('LLV2_SEARCH_CRITERIA');
};

const getToday = () => {
  const today = new Date();

  const year = today.getFullYear();
  const month = leftPad(today.getMonth() + 1, 2, 0);
  const day = leftPad(today.getDate(), 2, 0);

  return `${year}-${month}-${day}`;
};

const getTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date();

  tomorrow.setDate(today.getDate() + 1);

  const year = tomorrow.getFullYear();
  const month = leftPad(tomorrow.getMonth() + 1, 2, 0);
  const day = leftPad(tomorrow.getDate(), 2, 0);

  return `${year}-${month}-${day}`;
};

const isDateInPast = date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const compareDate = new Date(date);
  compareDate.setHours(0, 0, 0, 0);

  return compareDate < today;
};
const search = () => {
  const initial = {
    __typename: 'SEARCH_CRITERIA',
    roomCount: '1',
    startDate: getToday(),
    endDate: getTomorrow(),
    propertySearchInput: {
      __typename: 'PropertySearchInput',
      checkin: getToday(),
      checkout: getTomorrow(),
      numberRooms: '1',
      searchType: 'Address',
      searchValue: ''
    },
    lookupTerm: '',
    location: {
      __typename: 'SuggestedLocation',
      locationText: '',
      locationType: '',
      locationValue: ''
    }
  };

  const store = createStore();
  store.ensureValues(initial);

  // Sanitize the data and reset to initial values if anything doesnt pass checks.
  function sanitize(data) {
    const { startDate, endDate } = data;

    if (isDateInPast(startDate) || isDateInPast(endDate)) {
      store.write(initial);
      return initial;
    }

    return data;
  }

  const values = process.browser ? sanitize(store.read()) : initial;

  return {
    defaults: { searchCriteria: values },

    resolvers: {
      Mutation: {
        updateRoomCount: async (parent, args, { cache }) => {
          const { count } = args;
          let searchCriteria = store.set('roomCount', count.toString());
          // lets make sure that the count value is always saved as a string
          searchCriteria = store.set(['propertySearchInput', 'numberRooms'], count.toString());
          cache.writeData({ data: { searchCriteria } });
          return searchCriteria;
        },

        updateDateCriteria: async (parent, args, { cache }) => {
          const { startDate, endDate } = args;
          let searchCriteria = store.set('startDate', startDate);
          searchCriteria = store.set('endDate', endDate);
          searchCriteria = store.set(['propertySearchInput', 'checkin'], startDate);
          searchCriteria = store.set(['propertySearchInput', 'checkout'], endDate);
          cache.writeData({ data: { searchCriteria } });
          return searchCriteria;
        },

        resetSearchValues: async (parent, args, { cache }) => {
          let searchCriteria = store.set('lookupTerm', initial.lookupTerm);
          searchCriteria = store.set('propertySearchInput', initial.propertySearchInput);
          searchCriteria = store.set('location', initial.location);
          cache.writeData({ data: { searchCriteria } });
          return searchCriteria;
        },

        /**
         * this mutation gets updated on key stroke to update the search input
         * any change here should remove the location values because it indicates that a user typed something and overrode it.
         */
        updateAutoSuggestTerm: async (parent, args, { cache }) => {
          const { term } = args;
          let searchCriteria = store.set('lookupTerm', term);
          searchCriteria = store.set('location', initial.location);
          cache.writeData({ data: { searchCriteria } });
          return searchCriteria;
        },

        /**
         * this mutation gets executed when a user selects an autosuggestion value in the flyout          *
         * */
        updateSearchLocation: async (parent, args, { cache }) => {
          const { location } = args;
          let searchCriteria = store.set('location', location);
          searchCriteria = store.set('lookupTerm', location.locationValue);
          cache.writeData({ data: { searchCriteria } });
          return searchCriteria;
        },

        /**
         * this mutation gets executed when a user clicks on redo search; map page
         * */
        updateSearchLocationOnRedo: async (parent, args, { cache }) => {
          const {
            location: { locationValue, locationType }
          } = args;

          let searchCriteria = store.set(['location', 'locationValue'], locationValue);
          searchCriteria = store.set('lookupTerm', locationValue);
          searchCriteria = store.set(['location', 'locationType'], locationType);
          cache.writeData({ data: { searchCriteria } });
          return searchCriteria;
        },

        /**
         * this mutation is executed when the user submits (button click or hits enter on the search input box)
         * if there is a location value, use this, if there isnt then use the autoComplete value.
         * */
        commitSearchTerm: async (parent, args, { cache }) => {
          const criteria = store.read();

          const searchType = store.get('location.locationType', '');
          let searchValue = store.get('location.locationValue', '');
          // if (searchType === '') {
          //   searchType = 'Address';
          // }
          if (searchValue === '') {
            searchValue = criteria.lookupTerm;
          }

          let searchCriteria = store.set(['propertySearchInput', 'searchType'], searchType);
          searchCriteria = store.set(['propertySearchInput', 'searchValue'], searchValue);

          cache.writeData({ data: { searchCriteria } });
          return searchCriteria;
        }
      },
      Query: {
        searchCriteria: () => {
          const criteria = store.read();
          return sanitize(criteria);
        }
      }
    }
  };
};

export default search;
