// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../BuildingBlocks/Button';
import {
  StyledContainer,
  StyledFormControl,
  StyledFormControlClasses,
  StyledLabel,
  StyledLabelClasses,
  StyledInput,
  StyledInputClasses,
  StyledClearSearch,
  StyledCloseSVG,
  StyledHelper,
  StyledHelperClasses
} from './SearchField.styles';

export default class SearchField extends Component {
  static propTypes = {
    /**
     * Boolean prop to turn on autocomplete
     * Defaults to `false`
     */
    autocomplete: PropTypes.bool,

    /**
     * Override border-focused width
     */
    borderFocusedWidth: PropTypes.string,

    /**
     * Override default border width
     */
    borderWidth: PropTypes.string,

    /**
     * Method to clear the value controlled by parent container.
     * This must be sent down from the parent container.
     * Used by the clear search button
     */
    clearValue: PropTypes.func,

    /**
     * Toggle whether or not the label is shown
     */
    disableLabel: PropTypes.bool,

    /**
     * Display of the FormControl component override
     */
    display: PropTypes.string,

    /**
     * Focused boolean
     */
    focused: PropTypes.bool,

    /**
     * Font size override
     */
    fontSizeOverride: PropTypes.number,

    /* Flag to tell the styles whether there is an adjacent button or not */
    hasButton: PropTypes.bool,

    /**
     * Height override - only used for case checking at this level
     * Height should be passed to the Search component directly.
     */
    height: PropTypes.number,

    /**
     * Icon to be rendered next to SearchField
     */
    icon: PropTypes.node,

    /**
     * HTML ID attribute
     */
    id: PropTypes.string,

    /**
     * InnerText for InputLabel component
     * This prop acts as a placeholder for the input element
     */
    labelText: PropTypes.string,

    /**
     * HTML Name attribute
     */
    name: PropTypes.string,

    /**
     * noLeftPadding flag
     */
    noLeftPadding: PropTypes.bool,

    /**
     * onSubmitHook for hitting enter in search input
     */
    onSubmitHook: PropTypes.func,

    /**
     * HTML Placeholder attribute - shows when input is focused and empty
     */
    placeholder: PropTypes.string,

    /**
     * Button to be rendered next to the SearchField
     */
    submitButton: PropTypes.node,

    /**
     * Value controlled by parent component/container
     */
    value: PropTypes.string,

    /**
     * Width of the FormControl component override
     */
    width: PropTypes.string,

    noButton: PropTypes.bool,

    required: PropTypes.bool,

    showWarning: PropTypes.bool,

    warningLabel: PropTypes.string
  };

  static defaultProps = {
    autocomplete: false,
    borderWidth: undefined,
    borderFocusedWidth: '2px',
    clearValue: () => {},
    disableLabel: false,
    display: 'block',
    focused: false,
    fontSizeOverride: undefined,
    hasButton: false,
    height: 80,
    icon: undefined,
    id: undefined,
    labelText: undefined,
    name: undefined,
    noLeftPadding: false,
    noButton: false,
    onSubmitHook: () => {},
    placeholder: undefined,
    submitButton: undefined,
    value: '',
    width: '100%',
    required: true,
    showWarning: false,
    warningLabel: 'Please enter a search term.'
  };

  handleClearSearch = e => {
    const { clearValue } = this.props;
    e.preventDefault();
    clearValue();
  };

  render() {
    const {
      autocomplete,
      borderFocusedWidth,
      borderWidth,
      clearValue,
      disableLabel,
      display,
      focused,
      flexRatio,
      hasButton,
      height,
      icon,
      labelText,
      name,
      noLeftPadding,
      value,
      searchFieldRef,
      submitButton,
      onSubmitHook,
      width,
      noButton,
      required,
      showWarning,
      warningLabel,
      placeholder,
      ...props
    } = this.props;

    /** Define a few policies to cleanup some of our JSX output */
    const searchHasValue = value && value.length > 0;
    const focusedOrHasValue = required ? (value && value.length > 0) || focused : focused;

    const clearSearchButton = searchHasValue && (
      <StyledClearSearch id="icon-clear-search" role="button" tabIndex="0" onMouseDown={this.handleClearSearch}>
        <StyledCloseSVG selectedIcon="clear-search" />
      </StyledClearSearch>
    );

    return (
      <StyledContainer flexRatio={flexRatio}>
        <StyledFormControl flexRatio={flexRatio} classes={StyledFormControlClasses}>
          {icon}
          {!disableLabel ? (
            <StyledLabel height={height} hasIcon={!!icon} classes={StyledLabelClasses} htmlFor={name}>
              {labelText}
            </StyledLabel>
          ) : null}
          <StyledInput
            autoComplete={!autocomplete ? 'off' : undefined}
            borderFocusedWidth={borderFocusedWidth}
            borderWidth={borderWidth}
            classes={StyledInputClasses}
            disableUnderline
            endAdornment={clearSearchButton}
            hasButton={hasButton}
            hasIcon={!!icon}
            height={height}
            name={name}
            inputRef={searchFieldRef}
            type="search"
            value={value}
            noLeftPadding={noLeftPadding}
            placeholder={placeholder}
            {...props}
          />
          {showWarning && (
            <StyledHelper height={height} hasIcon={!!icon} classes={StyledHelperClasses} htmlFor={name}>
              {warningLabel}
            </StyledHelper>
          )}
        </StyledFormControl>
        {focusedOrHasValue && !noButton && (
          <Button type="submit" uppercase fullHeight onClick={onSubmitHook}>
            Search
          </Button>
        )}
      </StyledContainer>
    );
  }
}
