import styled from 'styled-components';
import { fonts, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledCopyMini = styled(Base)`
  text-align: ${props => props.align};
  text-transform: ${props => props.textTransform};
  font-family: ${props => (props.weight === 'bold' ? fonts.fontBold : fonts.fontRegular)};
  font-size: ${props => (props.fontSize ? mixins.pxToRem(props.fontSize) : mixins.pxToRem(10))};
  line-height: ${props => (props.noLineHeight ? 'normal' : mixins.pxToRem(16))};
  color: ${props => props.color};
  margin: ${props => (props.noMargins === false ? `0 0 ${mixins.pxToRem(12)} 0` : 0)};

  a {
    color: inherit;
  }
`;
