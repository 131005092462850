import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class TwoOccupant extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m8.52 13.14a3.94 3.94 0 1 0 -3.94-3.93 3.94 3.94 0 0 0 3.94 3.93zm0-6.87a2.94 2.94 0 1 1 -2.94 2.94 2.94 2.94 0 0 1 2.94-2.94z"
        />
        <path
          fill={iconColor}
          d="m17.75 12.78a2.76 2.76 0 1 0 -2.75-2.78 2.76 2.76 0 0 0 2.75 2.78zm0-4.52a1.76 1.76 0 1 1 -1.75 1.74 1.76 1.76 0 0 1 1.75-1.74z"
        />
        <path
          fill={iconColor}
          d="m22.67 16.7c-.42-3-2.39-3.26-4.92-3.26-1.75 0-3.29.13-4.17 1.27a10.51 10.51 0 0 0 -5.06-.85c-4.44 0-6.65.71-7.21 4.73v.13h1c.46-3.31 2-3.87 6.22-3.87 2.16 0 3.61.15 4.58.72h.09a4 4 0 0 1 1.57 3.1h1v-.14a5.49 5.49 0 0 0 -1.38-3.28c.51-.66 1.43-.87 3.39-.87 2.92 0 3.66.45 3.93 2.4h1z"
        />
      </svg>
    );
  }
}
