import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Star extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 22,
    backgroundColor: colors.colorGrey04,
    iconColor: colors.colorWhite
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
        <path
          stroke={iconColor}
          fill={backgroundColor}
          d="M10 15.729l6.18 3.727-1.635-7.03L20 7.7l-7.191-.617L10 .456 7.191 7.083 0 7.7l5.455 4.727-1.635 7.029L10 15.729z"
        />
      </svg>
    );
  }
}
