import getConfig from 'next/config';

const {
  publicRuntimeConfig: {
    GA_MEASUREMENT_ID,
    GA4_MEASUREMENT_ID
  }
} = getConfig();

// This script should be injected in the document head
export const GTM_HEAD_SCRIPT = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GA_MEASUREMENT_ID}');
`;

// This script should be injected just after the opening body tag
export const GTM_BODY_SCRIPT = `
<iframe src="https://www.googletagmanager.com/ns.html?id=${GA_MEASUREMENT_ID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

//This Script is for GA4 and should be injected in document head
export const GTM_GA4_HEAD_SCRIPT = 
`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GA4_MEASUREMENT_ID}');
`;

// This script is for GA4 and should be injected in document body
export const GTM_GA4_BODY_SCRIPT = `
<iframe src="https://www.googletagmanager.com/ns.html?id=${GA4_MEASUREMENT_ID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

export default {
  trackGA4: (event, payload, analyticsIds) => {
    if (process.browser && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event,
        ...payload,
        analyticIDs: analyticsIds,
      });
    }
  },
  trackEvent: (eventName, payload, analyticsIds) => {
    if (process.browser && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: eventName,
        analyticIDs: analyticsIds,
        ...payload
      });
    }
  },
  trackRoute: (routeName, analyticsIds) => {
    if (process.browser && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: 'pageView',
        page: {
          pageInfo: {
            pageName: routeName
          }
        },
        analyticIDs: analyticsIds
      });
    }
  }
};
