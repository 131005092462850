import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class TableViewToggle extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 22,
    height: 13,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Batch-6---Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="XL_Dashboard_04_Bookings_01_Cards_01"
            transform="translate(-1291.000000, -562.000000)"
            fill={iconColor}
          >
            <g id="Group-4" transform="translate(1284.000000, 550.000000)">
              <g id="Group-7" transform="translate(7.000000, 12.000000)">
                <polygon id="Fill-1" points="5 1 21.999 1 21.999 0 5 0" />
                <polygon id="Fill-2" points="0 1 3 1 3 0 0 0" />
                <polygon id="Fill-3" points="0 7 3 7 3 6 0 6" />
                <polygon id="Fill-4" points="0 13 3 13 3 12 0 12" />
                <polygon id="Fill-5" points="5 7 21.999 7 21.999 6 5 6" />
                <polygon id="Fill-6" points="5 13 21.999 13 21.999 12 5 12" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
