import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Pool extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m2.5 16.75a5.84 5.84 0 0 0 2.57-.54 4.75 4.75 0 0 1 2.18-.46 4.75 4.75 0 0 1 2.18.46 5.84 5.84 0 0 0 2.57.54 5.84 5.84 0 0 0 2.57-.54 4.79 4.79 0 0 1 2.18-.46 4.75 4.75 0 0 1 2.18.46 5.84 5.84 0 0 0 2.57.54.51.51 0 0 0 .5-.5.5.5 0 0 0 -.5-.5 4.89 4.89 0 0 1 -2.18-.46 5.7 5.7 0 0 0 -2.57-.54 5.7 5.7 0 0 0 -2.57.54 4.89 4.89 0 0 1 -2.18.46 4.92 4.92 0 0 1 -2.18-.46 5.7 5.7 0 0 0 -2.57-.54 5.7 5.7 0 0 0 -2.57.54 4.89 4.89 0 0 1 -2.18.46.5.5 0 0 0 -.5.5.5.5 0 0 0 .5.5z"
        />
        <path
          fill={iconColor}
          d="m21.5 18.75a4.89 4.89 0 0 1 -2.18-.46 5.7 5.7 0 0 0 -2.57-.54 5.7 5.7 0 0 0 -2.57.54 4.89 4.89 0 0 1 -2.18.46 4.92 4.92 0 0 1 -2.18-.46 5.7 5.7 0 0 0 -2.57-.54 5.7 5.7 0 0 0 -2.57.54 4.89 4.89 0 0 1 -2.18.46.5.5 0 0 0 -.5.5.5.5 0 0 0 .5.5 5.84 5.84 0 0 0 2.57-.54 4.75 4.75 0 0 1 2.18-.46 4.75 4.75 0 0 1 2.18.46 5.84 5.84 0 0 0 2.57.54 5.84 5.84 0 0 0 2.57-.54 4.79 4.79 0 0 1 2.18-.46 4.75 4.75 0 0 1 2.18.46 5.84 5.84 0 0 0 2.57.54.51.51 0 0 0 .5-.5.5.5 0 0 0 -.5-.5z"
        />
        <path
          fill={iconColor}
          d="m7 6v7.79h1v-1.5h3v1.5h1v-8a.51.51 0 0 0 -.15-.36l-1-1a.5.5 0 0 0 -.71.71l.86.86v3.25h-3v-3.5a.51.51 0 0 0 -.15-.36l-1-1a.5.5 0 1 0 -.71.71zm4 4.29v1h-3v-1z"
        />
      </svg>
    );
  }
}
