import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Crews extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    backgroundColor: colors.colorBlue,
    iconColor: colors.colorBlack
  };

  render() {
    const { height, width, iconColor, backgroundColor } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 119.1 91.3"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <polygon
          className="st0"
          points="119.1,44.6 111.3,36.8 72.4,75.7 57.4,60.7 49.6,68.5 72.4,91.3 80.2,83.5 80.1,83.5"
          fill={backgroundColor}
        />
        <circle className="st1" cx="50.5" cy="18.6" r="17.5" fill="transparent" stroke={iconColor} />
        <circle className="st1" cx="84.3" cy="35.3" r="9.6" fill="transparent" stroke={iconColor} />
        <path className="st1" d="M83.2,74c0-3.4-0.6-6.8-1.8-9.9" fill="transparent" stroke={iconColor} />
        <path
          className="st1"
          d="M73.9,52.6c-6.2-5.9-14.5-9.2-23.1-9.1C33,43.5,18.5,57.1,18.5,74"
          fill="transparent"
          stroke={iconColor}
        />
        <path
          className="st1"
          d="M100.3,64.1c0-8.3-7.2-15.1-16-15.1c-3.8,0-7.5,1.3-10.4,3.6c3.3,3.2,5.9,7.1,7.5,11.4"
          fill="transparent"
          stroke={iconColor}
        />
        <circle className="st1" cx="18" cy="35.4" r="9.6" fill="transparent" stroke={iconColor} />
        <path
          className="st1"
          d="M27.7,52.7c-3-2.5-6.7-3.8-10.6-3.8C10,48.9,3.7,53.5,1.7,60.2"
          fill="transparent"
          stroke={iconColor}
        />
        <path className="st1" d="M1.6,60.2c-0.3,1.2-0.5,2.5-0.5,3.8" fill="transparent" stroke={iconColor} />
      </svg>
    );
  }
}
