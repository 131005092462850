import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Australia extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    backgroundColor2: PropTypes.string,
    iconRed1: PropTypes.string,
    iconRed2: PropTypes.string,
    iconBlue: PropTypes.string
  };

  static defaultProps = {
    height: 20,
    width: 28,
    backgroundColor: colors.colorWhite,
    iconRed1: colors.australiaFlagRed1,
    iconRed2: colors.australiaFlagRed2,
    iconBlue: colors.australiaFlagBlue,
    backgroundColor2: colors.flagGrey
  };

  render() {
    const { height, width, backgroundColor, backgroundColor2, iconRed1, iconRed2, iconBlue } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 28 20"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect id="path-1" x="0" y="0" width="28" height="20" rx="2" />
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
            <stop stopColor={backgroundColor} offset="0%" />
            <stop stopColor={backgroundColor2} offset="100%" />
          </linearGradient>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
            <stop stopColor={iconRed1} offset="0%" />
            <stop stopColor={iconRed2} offset="100%" />
          </linearGradient>
        </defs>
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/UI/Flags/AUS">
            <g id="AU">
              <rect fill={iconBlue} x="0" y="0" width="28" height="20" />
              <path
                d="M4.33333333,4.15331164 L-0.901085803,-0.333333333 L0.666666667,-0.333333333 L0.860412731,-0.27124449 L5.44015502,3 L6.57106792,3 L12.3333333,-0.601415881 L12.3333333,0.333333333 C12.3333333,0.628978368 12.1628101,0.960008269 11.9158002,1.1364439 L7.66666667,4.1715393 L7.66666667,5.18002169 L11.9660302,8.86519043 C12.3427037,9.18805344 12.1509712,9.66666667 11.6666667,9.66666667 C11.4092185,9.66666667 11.0776031,9.56030345 10.8616414,9.40604508 L6.55984498,6.33333333 L5.42893208,6.33333333 L-0.333333333,9.93474921 L-0.333333333,8.49512737 L4.33333333,5.16179403 L4.33333333,4.15331164 Z"
                id="Rectangle-36"
                stroke={backgroundColor}
                strokeWidth="0.666666667"
                fill={iconRed1}
              />
              <path
                d="M0,3.33333333 L0,6 L4.66666667,6 L4.66666667,9.33948135 C4.66666667,9.70427573 4.97256724,10 5.33957926,10 L6.66042074,10 C7.0320601,10 7.33333333,9.69934988 7.33333333,9.33948135 L7.33333333,6 L11.9911963,6 C12.3642483,6 12.6666667,5.69409943 12.6666667,5.3270874 L12.6666667,4.00624593 C12.6666667,3.63460657 12.3646425,3.33333333 11.9911963,3.33333333 L7.33333333,3.33333333 L7.33333333,0 L4.66666667,0 L4.66666667,3.33333333 L0,3.33333333 Z"
                id="Rectangle-2"
                fill="url(#linearGradient-3)"
              />
              <polygon
                id="Rectangle-36"
                fill="url(#linearGradient-4)"
                points="0 4 5.33333333 4 5.33333333 3.33333333 5.33333333 0 6.66666667 0 6.66666667 3.33333333 6.66666667 4 12 4 12 5.33333333 6.66666667 5.33333333 6.66666667 6 6.66666667 9.33333333 5.33333333 9.33333333 5.33333333 6 5.33333333 5.33333333 0 5.33333333"
              />
              <polygon
                id="Star-2"
                fill={backgroundColor}
                points="6 16.3333333 4.8244295 16.9513673 5.04894348 15.6423503 4.09788697 14.7152993 5.41221475 14.5243163 6 13.3333333 6.58778525 14.5243163 7.90211303 14.7152993 6.95105652 15.6423503 7.1755705 16.9513673"
              />
              <polygon
                id="Star-2"
                fill={backgroundColor}
                points="20 17.3333333 19.057191 17.6094757 19.3333333 16.6666667 19.057191 15.7238576 20 16 20.942809 15.7238576 20.6666667 16.6666667 20.942809 17.6094757"
              />
              <polygon
                id="Star-2"
                fill={backgroundColor}
                points="20 4.66666667 19.057191 4.94280904 19.3333333 4 19.057191 3.05719096 20 3.33333333 20.942809 3.05719096 20.6666667 4 20.942809 4.94280904"
              />
              <polygon
                id="Star-2"
                fill={backgroundColor}
                points="24 8.66666667 23.057191 8.94280904 23.3333333 8 23.057191 7.05719096 24 7.33333333 24.942809 7.05719096 24.6666667 8 24.942809 8.94280904"
              />
              <polygon
                id="Star-2"
                fill={backgroundColor}
                points="16 10 15.057191 10.2761424 15.3333333 9.33333333 15.057191 8.39052429 16 8.66666667 16.942809 8.39052429 16.6666667 9.33333333 16.942809 10.2761424"
              />
              <polygon
                id="Star-2"
                fill={backgroundColor}
                points="22 11.6666667 21.5285955 11.8047379 21.6666667 11.3333333 21.5285955 10.8619288 22 11 22.4714045 10.8619288 22.3333333 11.3333333 22.4714045 11.8047379"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
