import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Directions extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 19,
    height: 18,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 19 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/Filters/Changes/Black" fill={iconColor}>
            <polygon
              id="Fill-1"
              points="13.3533 9.7069 16.5273 12.8809 0.7073 12.8809 0.7073 13.8259 16.5273 13.8259 13.3533 16.9999 14.0603 17.7069 18.4143 13.3539 14.0603 9.0009"
            />
            <polygon
              id="Fill-2"
              points="4.3533 0.0004 0.0003 4.3534 4.3533 8.7074 5.0603 7.9994 1.9013 4.8394 18.7073 4.8394 18.7073 3.8664 1.9013 3.8664 5.0603 0.7064"
            />
          </g>
        </g>
      </svg>
    );
  }
}
