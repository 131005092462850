import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class LinkedIn extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 37,
    backgroundColor: colors.linkedInBlue,
    iconColor: colors.colorWhite
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/Social/Twitter">
            <rect id="Rectangle-5-Copy" fill={backgroundColor} x="0" y="0" width="37" height="37" />
            <path
              fill={iconColor}
              d="M13.3998757,14.8018631 L13.3998757,27.6818341 L9.11310349,27.6818341 L9.11310349,14.8018631 L13.3998757,14.8018631 Z M13.6822133,10.8175685 C13.6822133,12.0542831 12.7513038,13.0438598 11.2565752,13.0438598 L11.2291804,13.0438598 C9.79001181,13.0438598 8.859616,12.0542831 8.859616,10.8175685 C8.859616,9.55469555 9.81834831,8.59281598 11.2848261,8.59281598 C12.7513038,8.59281598 13.6543904,9.55469555 13.6822133,10.8175685 Z M28.859616,20.2968524 L28.8594448,27.6818341 L24.5728438,27.6818341 L24.5728438,20.7910424 C24.5728438,19.0602233 23.9525229,17.8791597 22.4006932,17.8791597 C21.2163819,17.8791597 20.5109658,18.6753689 20.2011478,19.4443939 C20.0878018,19.7199982 20.059979,20.1039977 20.059979,20.4889376 L20.059979,27.682176 L15.7726932,27.682176 C15.7726932,27.682176 15.8291949,16.010795 15.7726932,14.8022051 L20.059979,14.8022051 L20.059979,16.6265449 C20.6289347,15.7495522 21.6479355,14.4994165 23.9239296,14.4994165 C26.7448232,14.4994165 28.859616,16.3406824 28.859616,20.2968524 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    );
  }
}
