import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Salon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m21.87 6.61-.28-.41-10 6.64 6.64-10-.41-.28c-.08-.06-2.07-1.34-4.87 2.23a25.71 25.71 0 0 0 -2.51 4.21c-.61 1.26-1.59 4.11-2.26 6.14l-1.55 1a1.92 1.92 0 0 0 -.56-1.23 2 2 0 0 0 -2.75 0 1.94 1.94 0 0 0 -.09 2.63 1.47 1.47 0 0 1 -.81-.19l-.56.83a2.49 2.49 0 0 0 1.41.37 5.5 5.5 0 0 0 1.73-.31 1.64 1.64 0 0 0 .62-.24l.13-.09 1.38-.91.48-.15-.12.36-1 1.44-.1.15a1.94 1.94 0 0 0 1.71 2.86 1.95 1.95 0 0 0 1.94-1.94 1.9 1.9 0 0 0 -1.8-1.92l.09-.14.91-1.36c2-.69 4.89-1.69 6.15-2.29a26.52 26.52 0 0 0 4.18-2.52c3.64-2.8 2.35-4.79 2.3-4.88zm-10.53 2.81a25 25 0 0 1 2.41-4c1.37-1.74 2.41-2.09 3-2.11l-7.16 10.76c.6-1.76 1.29-3.71 1.75-4.65zm-7.34 6.21a1 1 0 0 1 .67-.28 1 1 0 0 1 .67.28 1 1 0 0 1 0 1.34.95.95 0 1 1 -1.34-1.34zm5.06 4.12a.94.94 0 0 1 -.27.66 1 1 0 0 1 -1.34 0 .91.91 0 0 1 -.28-.66.93.93 0 0 1 .28-.67.94.94 0 0 1 1.34 0 1 1 0 0 1 .3.67zm9.93-9a24.91 24.91 0 0 1 -4 2.41c-.95.45-2.92 1.17-4.7 1.78l10.85-7.24c-.01.58-.35 1.61-2.14 3z"
        />
      </svg>
    );
  }
}
