import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Projects extends Component {
  static propTypes = {
    height: PropTypes.number,
    width: PropTypes.number
  };

  static defaultProps = {
    width: 105,
    height: 88
  };

  render() {
    const { height, width } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 105 88" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" fill="none" fillRule="evenodd">
          <g id="LL_Batch6_Projects" transform="translate(1 1)">
            <rect id="Rectangle" stroke="#333" strokeWidth="2.16" x="0.57" y="0.09" width="62.49" height="85.83" />
            <rect id="Rectangle" stroke="#333" strokeWidth="2.16" x="62.97" y="6.75" width="11.45" height="72.49" />
            <polygon
              id="Path"
              fill="#52B7B0"
              opacity="0.4"
              points="103.43 33.41 95.65 25.63 56.75 64.54 41.73 49.53 33.95 57.31 56.76 80.11 64.54 72.33 64.52 72.32"
            />
            <rect id="Rectangle" stroke="#333" strokeWidth="2.16" x="31.81" y="68.56" width="22.33" height="6.57" />
          </g>
        </g>
      </svg>
    );
  }
}
