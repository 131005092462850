import styled from 'styled-components';
// import { mixins, media } from '../../../css';

export const StyledIconContainer = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
  transform: rotate(${props => props.rotate}deg);
  padding-bottom: ${props => props.iconPadding}%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const StyledIconSizing = styled.figure`
  display: ${props => (props.useFlex ? `flex` : `inline-block`)};
  font-size: 0;
  ${props => (props.width ? `width: ${props.width};` : null)};
  ${props => (props.height ? `height: ${props.height};` : null)};
  ${props => (props.heightAuto ? `height: auto;` : null)};
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;
