import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ATM extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m23 4h-18v2h-2v2h-2v12h18v-2h2v-2h2zm-17 11v-10h16v10zm-2 2v-10h1v9h15v1zm-2 2v-10h1v9h15v1z"
        />
        <path fill={iconColor} d="m14 7a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 5a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z" />
        <path fill={iconColor} d="m9 7h-1v6h1z" />
        <path fill={iconColor} d="m20 7h-1v6h1z" />
      </svg>
    );
  }
}
