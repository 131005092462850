// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../SVGIcon';
import { StyledFooterSVGLink } from './FooterSVGLink.styles';

export default class FooterSVGLink extends Component {
  static propTypes = {
    iconType: PropTypes.string,
    href: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    iconType: '',
    href: '',
    className: undefined,
    onClick: () => {},
    backgroundColor: undefined,
    iconColor: undefined
  };

  render() {
    const { iconType, backgroundColor, iconColor } = this.props;
    return (
      <StyledFooterSVGLink {...this.props}>
        {}
        <SVGIcon selectedIcon={iconType} backgroundColor={backgroundColor} iconColor={iconColor} />
      </StyledFooterSVGLink>
    );
  }
}
