import styled from 'styled-components';
import { fonts, media, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledCopyBody = styled(Base)`
  text-align: ${props => props.align};
  font-family: ${props => (props.weight === 'bold' ? fonts.fontBold : fonts.fontRegular)};
  font-size: ${props => (props.fontSize ? mixins.pxToRem(props.fontSize) : mixins.pxToRem(14))};
  line-height: ${mixins.pxToRem(22)};
  color: ${props => props.color};
  margin: ${props => (props.noMargins === false ? `${mixins.pxToRem(8)} 0` : 0)};
  text-transform: ${props => props.textTransform};

  a {
    color: inherit;
  }

  ${media.greaterThan('lg')`
    font-size: ${props => (props.fontSize ? mixins.pxToRem(props.fontSize) : mixins.pxToRem(16))};
    line-height: ${mixins.pxToRem(28)};
    margin: ${props => (props.noMargins === false ? `${mixins.pxToRem(16)} 0` : 0)};
  `};
`;
