import styled from 'styled-components';
import { colors, mixins } from '../../../../css';

export const SubMenu = styled.div`
  display: block;
  position: absolute;
  top: 79px;
  right: 0;
  width: 30vw;
  max-height: calc(100vh - ${mixins.pxToRem(120)});
  min-height: ${mixins.pxToRem(80)};
  overflow-x: hidden;
  overflow-y: auto;
  max-width: ${mixins.pxToRem(300)};
  border: 1px solid ${colors.colorGrey05};
  border-top: none;
  transition: top 200ms ease-in;
  background-color: ${colors.colorWhite};
  border-top: 1px solid ${colors.colorGrey05};
  border-left: 1px solid ${colors.colorGrey05};
  a {
    padding: ${mixins.pxToRem(15)} ${mixins.pxToRem(20)};

    ${({ isTouchDevice }) =>
      !isTouchDevice
        ? `&:hover {
      background-color: ${colors.colorGrey05};
    }`
        : ''};
  }
`;

export const JoinOrg = styled.div``;
export const SettingsLink = styled.div``;

export const SignOutLink = styled.div`
  background-color: ${colors.colorWhite};
`;
