import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ArrowDoubleIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 16,
    width: 22,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={backgroundColor} fillRule="nonzero" width="50">
            <polygon points="46.9,19.8 25,42.7 3,19.8 0.2,22.6 25,48.4 49.8,22.6" fill={iconColor} />
          </g>
        </g>

        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={backgroundColor} fillRule="nonzero" width="50">
            <polygon points="46.9,1.6 25,24.5 3,1.6 0.2,4.4 25,30.2 49.8,4.4" fill={iconColor} />
          </g>
        </g>
      </svg>
    );
  }
}
