import getConfig from 'next/config';
import messages from './messages';

const {
  publicRuntimeConfig: { LODGELINK_CMS_URI, LODGELINK_AUS_CMS_URI }
} = getConfig();

/**
 * Configuration for the 3 nav regions in the footer.
 */
export const NAV_REGION_ONE = {
  heading: messages.navRegionOneHeading,
  links: [
    {
      href: `${LODGELINK_CMS_URI}/onboarding-journey/`,
      label: messages.linkOnboardingJourney,
      analytics: {
        // Click Id will show the href it is monitoring
        clickID: 'faqs'
      }
    },
    {
      href: `${LODGELINK_CMS_URI}/welcome-to-lodgelink/`,
      label: messages.linkTutorialVideos,
      analytics: {
        // Click Id will show the href it is monitoring
        clickID: 'faqs'
      }
    }
  ]
};

export const NAV_REGION_TWO = {
  heading: messages.navRegionTwoHeading,
  links: [
    {
      href: 'tel:+',
      label: messages.linkTollFree,
      analytics: {
        clickID: 'contact-lodgelink'
      }
    },
    {
      href: `${LODGELINK_CMS_URI}/contact-us/`,
      label: messages.linkContactLodgeLink,
      analytics: {
        clickID: 'contact-lodgelink'
      }
    }
  ]
};

export const NAV_REGION_THREE = {
  heading: messages.navRegionThreeHeading,
  links: [
    {
      href: `${LODGELINK_CMS_URI}/privacy-policy/`,
      label: messages.linkPrivacyPolicy,
      analytics: {
        clickID: 'privacy-policy'
      },
      subLabels: [
        {
          href: `${LODGELINK_CMS_URI}/privacy-policy/`,
          label: messages.linkPrivacyPolicyNorthAmerica
        },

        {
          href: `${LODGELINK_AUS_CMS_URI}/privacy-policy/`,
          label: messages.linkPrivacyPolicyAustralia
        }
      ]
    },
    {
      href: `${LODGELINK_CMS_URI}/terms-of-service/`,
      label: messages.linkTermsOfService,
      analytics: {
        clickID: 'terms-of-service'
      },
      subLabels: [
        {
          href: `${LODGELINK_CMS_URI}/terms-of-service/`,
          label: messages.linkPrivacyPolicyNorthAmerica
        },

        {
          href: `${LODGELINK_AUS_CMS_URI}/terms-of-service/`,
          label: messages.linkPrivacyPolicyAustralia
        }
      ]
    },
    {
      href: `https://partnerhub.lodgelink.com/login`,
      label: messages.linkPartnerHub,
      analytics: {
        clickID: 'partner-hub'
      }
    }
  ]
};

/**
 * Configuration for the social links in the footer.
 */
export const SOCIAL_LINKS = [
  {
    href: 'https://twitter.com/lodgelink',
    iconType: 'twitter',
    analytics: {
      clickID: 'twitter'
    }
  },
  {
    href: 'https://ca.linkedin.com/company/lodgelink',
    iconType: 'linkedin',
    analytics: {
      clickID: 'linkedin'
    }
  },
  {
    href: 'https://www.facebook.com/LodgeLink/',
    iconType: 'facebook',
    analytics: {
      clickID: 'facebook'
    }
  },
  {
    href: 'https://www.instagram.com/lodgelink/',
    iconType: 'instagram',
    analytics: {
      clickID: 'instagram'
    }
  }
];
