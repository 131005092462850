import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class LargeParking extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path fill={iconColor} d="m6.14 9.5-1.34 4h4.2v-4zm1.86 3h-1.81l.67-2h1.14z" />
        <path
          fill={iconColor}
          d="m23 4.5h-13v2.25h-4.86l-2 5.75h-2.14v6h2.28a2 2 0 0 0 1.72 1 2 2 0 0 0 1.72-1h5.56a2 2 0 0 0 1.72 1 2 2 0 0 0 1.72-1h1.6a2 2 0 0 0 1.72 1 2 2 0 0 0 1.72-1h2.24zm-18 14a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm0-3a2 2 0 0 0 -2 2h-1v-4h1.85l2-5.75h4.15v9.75h-3a2 2 0 0 0 -2-2zm9 3a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm5 0a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm3-1h-1a2 2 0 0 0 -4 0h-1a2 2 0 0 0 -4 0h-1v-12h11z"
        />
      </svg>
    );
  }
}
