import { defineMessages } from 'react-intl';

const messages = defineMessages({
  linkAboutUs: 'About Us',
  linkCareers: 'Careers',
  linkHelpAndSupport: 'Help & Support',
  linkResources: 'Resources',
  linkPartnerships: 'Partnerships',
  linkPrivacyPolicy: 'Privacy Policy ',
  linkPrivacyPolicyAustralia: '(Australia)',
  linkPrivacyPolicyNorthAmerica: '(North America)',
  linkRequestADemo: 'Request a Demo',
  linkTermsOfService: 'Terms of Service ',
  linkPartnerHub: 'PartnerHub',
  reservationsHeading: 'Reservations',

  socialHeading: 'Follow LodgeLink',

  navRegionOneHeading: 'Resources',
  linkOnboardingJourney: `Onboarding Journey`,
  linkTutorialVideos: `Tutorial Videos`,

  navRegionTwoHeading: 'Customer Service',
  linkContactLodgeLink: 'Contact us',
  linkTollFree: 'Toll Free: {phoneNumber}',

  navRegionThreeHeading: 'Copyright ©{year} LodgeLink. All rights reserved.'
});

export default messages;
