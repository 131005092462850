import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class CheckMark extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 12,
    width: 16,
    iconColor: colors.colorBlue
  };

  render() {
    const { height, width, iconColor } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="02-Organization" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="LL_Desktop_JoinOrg_01" transform="translate(-526.000000, -397.000000)" fill={iconColor}>
            <g id="Group" transform="translate(501.000000, 361.000000)">
              <g id="Icons/UI/Checkmark/Blue" transform="translate(21.000000, 30.000000)">
                <polygon id="Fill-1" points="19 6 9 16 5 11.999 4 12.999 7.999 17 9 18 10 17 20 7" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
