import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Chiropractor extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m12 11.37a4.3 4.3 0 1 1 4.3-4.29 4.3 4.3 0 0 1 -4.3 4.29zm0-7.59a3.3 3.3 0 1 0 3.3 3.3 3.3 3.3 0 0 0 -3.3-3.3z"
        />
        <path
          fill={iconColor}
          d="m5.07 21h-1v-4.11c.06-3.09 2.42-4.41 7.9-4.41 5.5 0 7.87 1.34 7.92 4.45v4h-1v-4c0-2.07-1.23-3.45-6.92-3.46-6 0-6.86 1.65-6.9 3.42z"
        />
        <path fill={iconColor} d="m11.5 15.3h1v5.92h-1z" />
        <circle fill={iconColor} cx="14.17" cy="15.86" r=".43" />
        <circle fill={iconColor} cx="14.17" cy="18.26" r=".43" />
        <circle fill={iconColor} cx="14.17" cy="20.66" r=".43" />
        <circle fill={iconColor} cx="9.87" cy="15.86" r=".43" />
        <circle fill={iconColor} cx="9.87" cy="18.26" r=".43" />
        <circle fill={iconColor} cx="9.87" cy="20.66" r=".43" />
      </svg>
    );
  }
}
