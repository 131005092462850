import React from "react"

/**
 * A React component that wraps its children with a container that prevents text from being translated.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children elements to be wrapped.
 * @param {boolean} props.inTextEdit - Determines the type of container used.
 * @returns {JSX.Element} A span or div element with the class "notranslate".
 */

export default function IgnoreTranslation({ children, inTextEdit }) {
    if(inTextEdit){
        return(
            <span className="notranslate">
                {children}
            </span>
        )
    }

    return (
        <div className="notranslate">
            {children}
        </div>
    )
}