// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubMenu, JoinOrg, SettingsLink, SignOutLink } from './UserSubMenu.styles.js';
import OrgSelect from '../../common/OrgSelect';
import LinkList from '../../common/LinkList/index.js';
import { IsTouchEnabledConsumer } from '../../common/Context';

class UserCard extends Component {
  static propTypes = {
    organizations: PropTypes.arrayOf(PropTypes.object),
    organizationSettings: PropTypes.node,
    joinOrganization: PropTypes.node,
    userCurrentOrganization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    organizationChange: PropTypes.func,
    subMenuLinks: PropTypes.arrayOf(PropTypes.node),
    signOut: PropTypes.node,
    className: PropTypes.string,
    hoverCheck: PropTypes.func
  };

  static defaultProps = {
    /** These comments will not be pulled into the Storybook info panels */
    organizations: [],
    joinOrganization: null,
    organizationSettings: null,
    userCurrentOrganization: '',
    organizationChange: () => {},
    subMenuLinks: [],
    signOut: null,
    className: '',
    hoverCheck: () => {}
  };

  render() {
    const {
      subMenuLinks,
      organizations,
      organizationSettings,
      userCurrentOrganization,
      organizationChange,
      joinOrganization,
      signOut,
      className,
      hoverCheck
    } = this.props;

    return (
      <IsTouchEnabledConsumer>
        {({ isTouchDevice }) => (
          <SubMenu className={className} isTouchDevice={isTouchDevice}>
            <LinkList links={subMenuLinks} type="stacked" />

            <OrgSelect
              organizations={organizations}
              userCurrentOrganization={userCurrentOrganization}
              iconRotation={0}
              organizationChange={organizationChange}
              showIcon
              hoverCheck={hoverCheck}
              isTouchDevice={isTouchDevice}
            />

            <JoinOrg>{joinOrganization}</JoinOrg>
            {userCurrentOrganization && organizations.length > 0 ? (
              <SettingsLink>{organizationSettings}</SettingsLink>
            ) : null}
            <SignOutLink>{signOut}</SignOutLink>
          </SubMenu>
        )}
      </IsTouchEnabledConsumer>
    );
  }
}

export default UserCard;
