import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class AfterHourMeal extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path d="m12 6a1 1 0 0 0 -1 1h1s.67 0 1 0a1 1 0 0 0 -1-1z" fill="none" />
        <path d="m11.83 8a1 1 0 0 0 .17 0 1 1 0 0 0 .17 0z" fill="none" />
        <path
          fill={iconColor}
          d="m22 15c0-3.87-3.44-7.1-8-7.84a.88.88 0 0 0 0-.16 2 2 0 0 0 -4 0 .88.88 0 0 0 0 .16c-4.58.74-8 4-8 7.84h-1v1l1 1 1 1h18l2-2v-1zm-10-9a1 1 0 0 1 1 1c-.32 0-.66 0-1 0s-.67 0-1 0a1 1 0 0 1 1-1zm-1.68 2.09a9.51 9.51 0 0 1 1.51-.09h.34a9.51 9.51 0 0 1 1.51.12c4.16.58 7.32 3.45 7.32 6.88h-18c0-3.43 3.16-6.3 7.32-6.91zm10.27 8.91h-17.18l-.7-.71-.3-.29h19.18z"
        />
      </svg>
    );
  }
}
