import React from 'react';
import { flowRight as compose } from 'lodash';
import Text from '../BuildingBlocks/Text';
import withAuth, { ORG_PERMISSIONS } from '../../hocs/withAuth';
import { Banner, envLookup } from './EnvBanner.styles';

function EnvBanner(props) {
  const { auth } = props;
  const {
    me: {
      activeOrganization: { permissions: userPermissions }
    }
  } = auth;

  const productTeam = userPermissions.includes(ORG_PERMISSIONS.productTeam);

  const env = envLookup[window.location.hostname.split('.')[0]];
  if (!productTeam || !env) return null;

  return (
    <Banner env={env}>
      <Text.CopyParagraph textTransform="uppercase" align="center" color="secondary" noMargins>
        {env}
      </Text.CopyParagraph>
    </Banner>
  );
}

export default compose(
  withAuth({
    requireAuthentication: true
  })
)(EnvBanner);
