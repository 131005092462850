import React from 'react';
import { processNodes } from 'react-html-parser';
import Image from '../../BuildingBlocks/Image';
import Text from '../../BuildingBlocks/Text';

export default {
  a: (node, key, { transform }) => {
    if (node && node.attribs && node.attribs.href) {
      const { href, ...otherAttribs } = node.attribs;

      return (
        <a key={key} href={href} {...otherAttribs}>
          {processNodes(node.children, transform)}
        </a>
      );
    }
    return undefined;
  },
  figure: (node, key, { transform }) => (
    <div className="rich-text-figure-container" key={key}>
      {processNodes(node.children, transform)}
    </div>
  ),
  img: (node, key) => {
    if (node && node.attribs && node.attribs.src) {
      const { src, ...otherAttribs } = node.attribs;
      return <Image key={key} src={src} {...otherAttribs} />; // eslint-disable-line
    }
    return undefined;
  },
  h1: {
    element: Text.HeadingFirst,
    props: { tagName: 'h1', color: 'primary' }
  },
  h2: {
    element: Text.HeadingSecond,
    props: { tagName: 'h2', color: 'primary' }
  },
  h3: {
    element: Text.HeadingThird,
    props: { tagName: 'h3', color: 'primary' }
  },
  h4: {
    element: Text.HeadingFourth,
    props: { tagName: 'h4', color: 'primary' }
  },
  h5: {
    element: Text.HeadingFifth,
    props: { tagName: 'h5', color: 'primary' }
  },
  h6: {
    element: Text.HeadingSixth,
    props: { tagName: 'h6', color: 'primary' }
  },
  p: {
    element: Text.CopyBody,
    props: { tagName: 'p', color: 'primary' }
  },
  span: {
    element: Text.CopyBody,
    props: { tagName: 'p', color: 'primary' }
  },
  strong: {
    element: Text.CopyBody,
    props: { tagName: 'strong', color: 'primary', weight: 'bold' }
  },
  ul: {
    element: 'ul',
    props: { className: 'rich-text-ul' }
  },
  ol: {
    element: 'ol',
    props: { className: 'rich-text-ol' }
  },
  li: {
    element: Text.CopyBody,
    props: { tagName: 'li', className: 'rich-text-li', color: 'primary', noMargins: true }
  }
};
