import PropTypes from 'prop-types';
import { CommonIDPropType } from './common';
import TaxFeeType from '../enums/TaxFeeType';
import TaxFeeCategory from '../enums/TaxFeeCategory';
import TaxFeeAppliedTo from '../enums/TaxFeeAppliedTo';
import TaxFeeCalculatedOn from '../enums/TaxFeeCalculatedOn';
import PropertyPropType from './Property';

/**
 * TaxFeeTemplate
 */
export const TaxFeePropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string,
  amount: PropTypes.number,
  taxFeeType: TaxFeeType,
  category: TaxFeeCategory,
  appliedTo: TaxFeeAppliedTo,
  calculatedOn: TaxFeeCalculatedOn,
  maxDays: PropTypes.number,
  description: PropTypes.string
});

export const TaxesFeesInTemplatePropType = PropTypes.shape({
  order: PropTypes.number,
  taxFee: TaxFeePropType
});

const TaxFeeTemplatePropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string,
  description: PropTypes.string,
  taxesFees: PropTypes.arrayOf(TaxesFeesInTemplatePropType),
  properties: PropTypes.arrayOf(PropertyPropType)
});

export default TaxFeeTemplatePropType;
