import PropTypes from 'prop-types';
import { CommonIDPropType, LocationTypePropType, GeoPropType } from './common';

const LodgeLinkLocationPropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string,
  type: LocationTypePropType,
  geo: GeoPropType,
  region: PropTypes.string,
  country: PropTypes.string,
  regionAbbreviation: PropTypes.string,
  countryAbbreviation: PropTypes.string
});

export default LodgeLinkLocationPropType;
