import styled from 'styled-components';
import { fonts, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledHeadingSixth = styled(Base)`
  font-family: ${fonts.fontBold};
  font-size: ${mixins.pxToRem(10)};
  line-height: ${mixins.pxToRem(14)};
  letter-spacing: 0.5px;
  text-transform: ${props => props.textTransform};
  margin: ${props => (props.noMargins === false ? `${mixins.pxToRem(20)} 0 ${mixins.pxToRem(12)}` : 0)};
  text-align: ${props => props.align};
  color: ${props => props.color};

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`;
