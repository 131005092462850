// exclude-from-index
import PropTypes from 'prop-types';
import React from 'react';

import { StyledContainer } from './Container.styles';

function Container({ nogutter, ...props }) {
  return <StyledContainer {...props} nogutter={nogutter ? 1 : 0} />;
}

Container.propTypes = {
  /** Remove the additional gutter that is applied to container. */
  nogutter: PropTypes.bool
};

Container.defaultProps = {
  nogutter: false
};

export default Container;
