import PropTypes from 'prop-types';
import { AddressPropType, CommonIDPropType, OrganizationPermissionsPropType } from './common';

import CreditApplicationPropType from './CreditApplication';
import SupplierPropType from './Supplier';

import AnnualAccommodationExpense from '../enums/AnnualAccommodationExpense';
import CompanySize from '../enums/CompanySize';
import Industry from '../enums/Industry';
import MembershipType from '../enums/MembershipType';
import RoleName from '../enums/RoleName';

const OrganizationPropType = PropTypes.shape({
  id: CommonIDPropType,
  domainSuffix: PropTypes.string,
  name: PropTypes.string,
  primaryBusinessAddress: AddressPropType,
  billingAddress: AddressPropType,
  stub: PropTypes.bool,
  masterServiceAgreed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  masterServiceAgreementVersion: PropTypes.string,
  creditApplications: PropTypes.arrayOf(CreditApplicationPropType),
  emailAddress: PropTypes.string,
  numberOfAdmins: PropTypes.number,
  supplier: SupplierPropType,
  industry: PropTypes.oneOf(Object.values(Industry)),
  annualAccommodationExpense: PropTypes.oneOf(Object.values(AnnualAccommodationExpense)),
  companySize: PropTypes.oneOf(Object.values(CompanySize)),
  membershipType: PropTypes.oneOf(Object.values(MembershipType)),
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(RoleName))),
  permissions: PropTypes.arrayOf(OrganizationPermissionsPropType)
});

export default OrganizationPropType;
