import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Twitter extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 37,
    backgroundColor: colors.twitterBlue,
    iconColor: colors.colorWhite
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/Social/Twitter">
            <rect id="Rectangle-5-Copy" fill={backgroundColor} x="0" y="0" width="37" height="37" />
            <path
              fill={iconColor}
              d="M18.7111412,14.2562505 L18.7544945,14.9482956 L18.0319394,14.8635554 C15.4018386,14.5387179 13.1041133,13.4370951 11.1532144,11.5869336 L10.1994416,10.6689146 L9.9537728,11.3468363 C9.43353309,12.8580369 9.76590846,14.4539777 10.8497412,15.5273538 C11.4277853,16.1205353 11.2977254,16.2052756 10.3005993,15.8521913 C9.9537728,15.7392044 9.65029964,15.6544641 9.62139743,15.6968342 C9.52023971,15.7956978 9.86706618,17.0809245 10.1416371,17.5893658 C10.5173658,18.2955343 11.2832743,18.9875794 12.1214383,19.3971571 L12.8295423,19.7219946 L11.9913783,19.736118 C11.1821166,19.736118 11.1532144,19.7502414 11.239921,20.0468321 C11.528943,20.9648512 12.6705802,21.9393637 13.9422772,22.3630648 L14.8382456,22.6596556 L14.0578861,23.1116034 C12.9017978,23.7612784 11.5433941,24.128486 10.1849904,24.1567328 C9.53469081,24.1708561 9,24.2273496 9,24.2697197 C9,24.4109534 10.7630346,25.2018621 11.7890629,25.5125763 C14.8671478,26.4305953 18.5232769,26.0351409 21.2689865,24.4674469 C23.2198854,23.3517007 25.1707843,21.1343316 26.0812038,18.9875794 C26.5725413,17.8435865 27.0638788,15.7533277 27.0638788,14.7505685 C27.0638788,14.1008935 27.1072321,14.0161532 27.9164939,13.2393679 C28.3933803,12.7874201 28.8413645,12.2931021 28.9280711,12.1518684 C29.0725821,11.8835244 29.058131,11.8835244 28.3211247,12.1236217 C27.092781,12.5473228 26.9193678,12.4908293 27.5263141,11.8552777 C27.9742983,11.4033298 28.5089891,10.5841744 28.5089891,10.3440771 C28.5089891,10.301707 28.2922225,10.3723238 28.0465538,10.4994342 C27.7864339,10.6406678 27.2083898,10.8525184 26.7748567,10.9796287 L25.9944972,11.219726 L25.2863931,10.7536548 C24.8962133,10.4994342 24.3470714,10.2169668 24.0580494,10.1322265 C23.3210431,9.93449936 22.1938571,9.9627461 21.5291063,10.18872 C19.7227184,10.8242717 18.5810813,12.4625826 18.7111412,14.2562505 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    );
  }
}
