import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Washing extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path fill={iconColor} d="m2.55 1.5v21h18.9v-21zm17.9 1v2.9h-16.9v-2.9zm-16.9 19v-15.1h16.9v15.1z" />
        <path
          fill={iconColor}
          d="m12 8.5a5.46 5.46 0 1 0 5.45 5.5 5.47 5.47 0 0 0 -5.45-5.5zm0 9.92a4.46 4.46 0 1 1 4.45-4.42 4.47 4.47 0 0 1 -4.45 4.42z"
        />
        <path d="m12 16.35v1a3.46 3.46 0 0 0 3.46-3.46h-1a2.46 2.46 0 0 1 -2.46 2.46z" />
        <path fill={iconColor} d="m4.95 3.4h2v1h-2z" />
        <path fill={iconColor} d="m8.95 3.4h2v1h-2z" />
      </svg>
    );
  }
}
