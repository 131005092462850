// exclude-from-index
import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../css';

function Base({ children, tagName, className }) {
  const Element = tagName;

  return <Element className={className}>{children}</Element>;
}
export const TEXT_ALIGN = ['inherit', 'left', 'center', 'right', 'justify'];
export const TEXT_TRANSFORM = ['none', 'capitalize', 'uppercase', 'lowercase', 'initial', 'inherit'];
export const TAG_NAMES = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span', 'label', 'p', 'strong', 'li'];
export const FONT_COLOR = ['primary', 'secondary', 'tertiary', 'quaternary', 'error', 'alert', 'pending', 'label'];
export const HEADING_COLORS = {
  primary: colors.colorGrey,
  secondary: colors.colorWhite,
  tertiary: colors.colorGrey03,
  quaternary: colors.colorBlue,
  error: colors.colorRed,
  alert: colors.colorCobalt,
  pending: colors.colorOrange,
  label: colors.colorGrey08,
  black: colors.colorBlack
};

export const FONT_WEIGHT = ['normal', 'bold'];

Base.propTypes = {
  tagName: PropTypes.oneOf(TAG_NAMES),
  className: PropTypes.string,
  children: PropTypes.node
};

Base.defaultProps = {
  tagName: 'div',
  className: '',
  children: null
};

export default Base;
