import PropTypes from 'prop-types';
import {
  CommonIDPropType,
  AddressPropType,
  AffiliationPropType,
  TagPropType,
  GeoPropType,
  CurrencyPropType
} from './common';
import LodgeLinkLocationPropType from './LodgeLinkLocation';
import AmenityPropType from './Amenity';
import RoomPropType from './Room';
import PropertyStatus from '../enums/PropertyStatus';
import AvailabilityType from '../enums/AvailabilityType';
import TaxFeeTemplatePropType from './TaxFee';

/**
 * Property
 * Sub-proptypes - used almost exclusively for the Property type
 */
export const PropertyTypePropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string
});

export const PropertyImagePropType = PropTypes.shape({
  id: CommonIDPropType,
  href: PropTypes.string,
  priorityOrder: PropTypes.number,
  retinaHref: PropTypes.string,
  card: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string
});

const PropertyPropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string,
  slug: PropTypes.string,
  location: LodgeLinkLocationPropType,
  topAmenities: PropTypes.arrayOf(AmenityPropType),
  allAmenities: PropTypes.arrayOf(AmenityPropType),
  type: PropertyTypePropType,
  cardImage: PropertyImagePropType,
  rooms: PropTypes.arrayOf(RoomPropType),
  address: AddressPropType,
  availability: PropTypes.oneOf(Object.values(AvailabilityType)),
  tourLink: PropTypes.string,
  similar: PropTypes.arrayOf(PropTypes.shape({})),
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  affiliations: PropTypes.arrayOf(PropTypes.oneOf(Object.values(AffiliationPropType))),
  tags: PropTypes.arrayOf(TagPropType),
  geo: GeoPropType,
  startingPrice: CurrencyPropType,
  distance: PropTypes.number,
  currency: PropTypes.string,
  published: PropTypes.bool,
  lastUpdated: PropTypes.string,
  standBy: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(PropertyStatus)),
  statusDescription: PropTypes.string,
  taxFeeTemplate: TaxFeeTemplatePropType
});

export default PropertyPropType;
