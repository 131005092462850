import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { colors, media } from '../../css';

const openAnim = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }`;

const spreadDiv = ({
  flyoutMaxHeight,
  disableFlyoutAnimation,
  onFlyoutItemClickHook,
  closeFlyout,
  borderWidth,
  overflowVisible,
  positionFixed,
  offsetTop,
  offsetLeft,
  noShadow,
  ...rest
}) => <div {...rest} />;

export const StyledFlyout = styled(spreadDiv)`
  box-sizing: border-box;
  position: ${({ positionFixed }) => (positionFixed ? 'fixed' : 'absolute')};
  ${({ offsetTop }) => offsetTop && `top: ${offsetTop};`};
  ${({ offsetLeft }) => offsetLeft && `left: ${offsetLeft}`};
  ${({ noShadow }) => !noShadow && `box-shadow: 0px 0px 22.5px 0px rgba(0, 0, 0, 0.05);`};
  background-color: #ffffff;

  width: 100%;
  height: 100vh;
  ${media.greaterThan('lg')`height: auto;`};

  max-height: ${({ flyoutMaxHeight }) => flyoutMaxHeight};
  border: 1px solid #e1e1e1;
  ${({ borderWidth }) => !!borderWidth && `border-width: ${borderWidth}`};
  border-top: 0;
  padding: 0 0 20px 0;
  z-index: 2;
  transform-origin: top;
  ${({ disableFlyoutAnimation }) =>
    !disableFlyoutAnimation &&
    css`
      animation: ${openAnim} 150ms ease-out;
    `};
  overflow: ${({ overflowVisible }) => (overflowVisible ? 'visible' : 'auto')};
`;

export const FlyoutItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 1;
  align-items: center;
  min-height: 46px;
  width: 100%;
  margin: 10px 0;
  outline: none;

  &:hover,
  &:focus {
    ${({ noHover }) =>
      !noHover &&
      `
      background-color: ${colors.colorGrey05};
    `};
  }

  ${({ type }) =>
    type === 'locations' &&
    `
      cursor: pointer;

     
  `};

  ${({ type }) =>
    type === 'patterns' &&
    `
      user-select: none;
      pointer-events: none;
  `};
`;

export const ItemContainer = styled.div`
  color: ${({ type }) => (type === 'patterns' ? colors.colorGrey03 : colors.colorGrey)};
`;

export const LeftBlock = styled.div`
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
