const AnnualAccommodationExpense = {
  UNDER_ONEHUNDREDTHOUSAND: 'UNDER_ONEHUNDREDTHOUSAND',
  ONEHUNDREDTHOUSAND_TO_TWOHUNDREDFIFTYTHOUSAND: 'ONEHUNDREDTHOUSAND_TO_TWOHUNDREDFIFTYTHOUSAND',
  TWOHUNDREDFIFTYTHOUSAND_TO_FIVEHUNDREDTHOUSAND: 'TWOHUNDREDFIFTYTHOUSAND_TO_FIVEHUNDREDTHOUSAND',
  FIVEHUNDREDTHOUSAND_TO_SEVENHUNDREDFIFTYTHOUSAND: 'FIVEHUNDREDTHOUSAND_TO_SEVENHUNDREDFIFTYTHOUSAND',
  SEVENHUNDREDFIFTYTHOUSAND_TO_ONEMILLION: 'SEVENHUNDREDFIFTYTHOUSAND_TO_ONEMILLION',
  ONEMILLION: 'ONEMILLION'
};

export const AnnualAccommodationExpenseOrder = [
  AnnualAccommodationExpense.UNDER_ONEHUNDREDTHOUSAND,
  AnnualAccommodationExpense.ONEHUNDREDTHOUSAND_TO_TWOHUNDREDFIFTYTHOUSAND,
  AnnualAccommodationExpense.TWOHUNDREDFIFTYTHOUSAND_TO_FIVEHUNDREDTHOUSAND,
  AnnualAccommodationExpense.FIVEHUNDREDTHOUSAND_TO_SEVENHUNDREDFIFTYTHOUSAND,
  AnnualAccommodationExpense.SEVENHUNDREDFIFTYTHOUSAND_TO_ONEMILLION,
  AnnualAccommodationExpense.ONEMILLION
];

export default AnnualAccommodationExpense;
