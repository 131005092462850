import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class AirportIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 22,
    height: undefined,
    iconColor: colors.colorGrey06
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.7 20.97" height={height} width={width}>
        <g id="Layer_2" data-name="Layer 2">
          <path
            fill={iconColor}
            d="M20.5.12c-.34-.25-.92-.09-1.4.28s-6,4.94-6,4.94L1.74,3.81A.63.63,0,0,0,1.35,4L.28,5a.48.48,0,0,0,.15.77L8.88,9.57,4.5,15s-1.77-.29-2.66-.42C.51,14.41.1,14.21,0,14.76c-.06.38,0,.37.64.72L3.73,17h0l1.19,3.24c.27.72.26.73.65.72.55,0,.4-.45.36-1.79,0-.59-.1-2.13-.15-3.12L11.1,11.8l5.3,8.52a.48.48,0,0,0,.77.15l1.08-1.15c.15-.08.15-.23.15-.38L15.33,7.57s4.7-5.75,5-6.06S20.84.36,20.5.12Z"
            id="Layer_1-2"
            data-name="Layer 1"
          />
        </g>
      </svg>
    );
  }
}
