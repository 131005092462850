const BookingPermission = {
  ADD_GUEST: 'ADD_GUEST',
  EDIT_GUEST_CHANGE_ROOM: 'EDIT_GUEST_CHANGE_ROOM',
  EDIT_GUEST_INFORMATION: 'EDIT_GUEST_INFORMATION',
  EDIT_ROOM_ASSIGNMENTS: 'EDIT_ROOM_ASSIGNMENTS',
  FINALIZE_BOOKING: 'FINALIZE_BOOKING',
  REMOVE_GUEST: 'REMOVE_GUEST',
  SELECT_BOOKING_DATES: 'SELECT_BOOKING_DATES',
  SET_CREW_SCHEDULE: 'SET_CREW_SCHEDULE',
  SET_GUEST_SCHEDULE: 'SET_GUEST_SCHEDULE',
  SUBMIT_PAYMENT: 'SUBMIT_PAYMENT',
  SET_GUEST_UNKNOWN: 'SET_GUEST_UNKNOWN',
  CANCEL_BOOKING: 'CANCEL_BOOKING',
  SPECIAL_REQUEST: 'SPECIAL_REQUEST',
  ADMIN_NO_DATE_RESTRICTIONS: 'ADMIN_NO_DATE_RESTRICTIONS',
  // REMOVE SOON
  EDIT_GUEST_CHANGE_SHARE: 'EDIT_GUEST_CHANGE_SHARE'
};

export default BookingPermission;
