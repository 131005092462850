import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Fridge extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path fill={iconColor} d="m4.5 1.5v21h15v-21zm1 1h13v5h-13zm13 19h-13v-13h13z" />
        <path fill={iconColor} d="m7.5 4h1v2h-1z" />
        <path fill={iconColor} d="m7.5 10h1v4h-1z" />
      </svg>
    );
  }
}
