import HeadingHero from './HeadingHero/HeadingHero';
import HeadingFirst from './HeadingFirst/HeadingFirst';
import HeadingSecond from './HeadingSecond/HeadingSecond';
import HeadingThird from './HeadingThird/HeadingThird';
import HeadingFourth from './HeadingFourth/HeadingFourth';
import HeadingFifth from './HeadingFifth/HeadingFifth';
import HeadingSixth from './HeadingSixth/HeadingSixth';
import HeadingSeven from './HeadingSeven/HeadingSeven';
import CopyBody from './CopyBody/CopyBody';
import CopyParagraph from './CopyParagraph/CopyParagraph';
import CopyMini from './CopyMini/CopyMini';

const Text = {
  HeadingHero,
  HeadingFirst,
  HeadingSecond,
  HeadingThird,
  HeadingFourth,
  HeadingFifth,
  HeadingSixth,
  HeadingSeven,
  CopyBody,
  CopyParagraph,
  CopyMini
};

export default Text;
