import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Restaurant extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m10.93 6.75h-1.66v-4.1h-1v4.1h-1.77v-4.1h-1v8.07l2.77.7v10.52h1v-10.52l2.66-.7v-8.07h-1zm-2.16 3.77-2.27-.58v-2.19h4.43v2.25z"
        />
        <path
          fill={iconColor}
          d="m18.49 3.44c0-1.06-.45-1.2-1.19-1.29h-.17-.34-.41l-3.16 11.63 4.27 2.57v5.59h1zm-4.1 9.88 2.73-10.19h.06.3s0 .13 0 .26v11.79z"
        />
      </svg>
    );
  }
}
