import styled from 'styled-components';
import { colors } from '../css';

export const envLookup = {
  localhost: 'localhost',
  'dev-app': 'dev',
  'test-app': 'test',
  'staging-app': 'staging',
  'sandbox-app': 'sandbox',
  app: 'production'
};

export const Banner = styled.div`
  padding: 5px 15px;
  margin: 20px;
  display: flex !important;
  align-items: center !important;
  border-radius: 12px;
  background-color: ${({ env }) => {
    switch (env) {
      case 'production':
        return colors.colorRed;
      case 'staging':
        return colors.colorOrange;
      case 'test':
        return colors.colorCobalt;
      case 'dev':
      case 'localhost':
      case 'sandbox':
      default:
        return colors.colorGreen;
    }
  }};
`;
