/**
 * Use camelCase for variable names to avoid upsetting eslint
 */

//   Primary
export const colorBurgundy = '#694459';
export const colorBurgundyRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(105, 68, 89, ${alpha} )`;
};

export const colorBlack = '#000000';
export const colorBlackRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(0, 0, 0, ${alpha} )`;
};

export const colorGreen = '#00B361';
export const colorGreenRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(0, 179, 97, ${alpha} )`;
};

export const colorBlue = '#53B7B0';
export const colorBlueRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(83, 183, 176, ${alpha} )`;
};

export const colorSand = '#EFEBE2';
export const colorSandRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(239, 235, 226, ${alpha} )`;
};

export const colorWhite = '#FFFFFF';
export const colorWhiteRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(255, 255, 255, ${alpha} )`;
};

//  Secondary
export const colorBlue02 = '#429C95';
export const colorBlue02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(66, 156, 149, ${alpha} )`;
};

export const colorBlue03 = '#5CCBCB';
export const colorBlue03Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(92, 203, 203, ${alpha} )`;
};

export const colorBlue04 = '#97DADB';
export const colorBlue04Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(151, 218, 219, ${alpha} )`;
};

export const colorBlue05 = '#E4FDFD';
export const colorBlue05Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(228, 253, 253, ${alpha})`;
};

export const colorBlue06 = '#3185ff';
export const colorBlue07 = '#ACE5E0';

export const colorBurgundy02 = '#43303B';
export const colorBurgundy02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(67, 48, 59, ${alpha} )`;
};

export const colorSand02 = '#58544A';
export const colorSand02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(88, 84, 74, ${alpha} )`;
};

export const colorSand03 = '#8B8678';
export const colorSand03Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(139, 134, 120, ${alpha} )`;
};

export const colorSand04 = '#FDFBF7';
export const colorSand04Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(253, 251, 247 , ${alpha} )`;
};

//  Greys
export const colorGrey = '#2D2429';
export const colorGreyRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(45, 36, 41 , ${alpha} )`;
};

export const colorGrey02 = '#333333';
export const colorGrey02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(51, 51, 51 , ${alpha} )`;
};

export const colorGrey03 = '#8D8D8D';
export const colorGrey03Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(141, 141, 141 , ${alpha} )`;
};

export const colorGrey04 = '#D8D8D8';
export const colorGrey04Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(216, 216, 216 , ${alpha} )`;
};

export const colorGrey05 = '#F6F5F6';
export const colorGrey05Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(246, 245, 246 , ${alpha} )`;
};

export const colorGrey06 = '#062334';
export const colorGrey06Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(6, 35, 52, ${alpha})`;
};

export const colorGrey07 = '#CACACA';
export const colorGrey07Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(202, 202, 202, ${alpha})`;
};

export const colorGrey08 = '#828282';
export const colorGrey08Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(130, 130, 130, ${alpha})`;
};

export const colorGrey09 = '#F4F3F5';
export const colorGrey09Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(244, 243, 245, ${alpha})`;
};

//  Alerts
export const colorCobalt = '#3185FF';
export const colorCobaltRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(49, 133, 255, ${alpha} )`;
};

export const colorOrange = '#FB6400';
export const colorOrangeRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(251, 100, 0, ${alpha} )`;
};

// Crew Swap Orange
export const colorOrange02 = '#FFF4E5';

export const colorOrange03 = '#ed881c';

export const colorRed = '#f44336';
export const colorRedRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(244, 67, 54, ${alpha})`;
};

/*  SVG Icon Colors */

//  LodgeLink SVG Color Logo
export const logoColor1 = '#572A31';
export const logoColor2 = '#009D4F';
export const logoColor3 = '#57B6B2';
export const logoColor4 = '#93D500';
export const logoColor5 = '#F16522';

//  LodgeLink SVG White Logo
export const logoWhite = '#C6C6C6';

//  Canada Flag Icon
export const canadaFlagRed = '#FF3131';
export const canadaFlagBackground = '#F5F5F5';

// USA Flag Icon
export const usaFlagBlue = '#46467F';
export const usaFlagRed = '#D02F44';

// Australia Flag Icon
export const australiaFlagRed1 = '#FF2E3B';
export const australiaFlagRed2 = '#FC0D1B';
export const australiaFlagBlue = '#0A17A7';

export const flagGrey = '#F0F0F0';

//  Facebook Icon
export const facebookBlue = '#3B5998';

//  LinkedIn Blue
export const linkedInBlue = '#0077B5';

//  Twitter Blue
export const twitterBlue = '#55ACEE';

//  YouTube Red
export const youTubeRed = '#CE1312';

//  Grey Icons
export const iconGrey = '#020303';

//  Icon Grid Green
export const iconGreen = '#52B7B0';

//  Warning Alert Yellow
export const alertYellow = '#FFD880';

export const alertYellow02 = '#fff4e5';
