import PropTypes from 'prop-types';
import { CommonIDPropType, CurrencyPropType, RoomTypePropType } from './common';

const RoomPropType = PropTypes.shape({
  id: CommonIDPropType,
  roomId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  rate: CurrencyPropType,
  availableRooms: PropTypes.number,
  guestsPerRoom: PropTypes.number,
  available: PropTypes.bool,
  bathrooms: PropTypes.number,
  roomType: RoomTypePropType,
  strikeoutRate: CurrencyPropType,
  nightlyRates: PropTypes.arrayOf(
    PropTypes.shape({ date: PropTypes.string.isRequired, price: PropTypes.string.isRequired })
  )
});

export default RoomPropType;
