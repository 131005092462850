import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class BookingChange extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 22,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 18"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Atoms" fill="none" fillRule="evenodd">
          <g id="Icons/Filters/BookingChange/Black" transform="translate(-4 -3)" fill={iconColor}>
            <path
              d="M15,3 L15,5 L9.001,5 L9.001,3 L8,3 L8,5 L4,5 L4,8 L4,9 L4,21 L20,21 L20,13.707 L23.354,10.354 L22.646,9.646 L20,12.293 L20,5 L16,5 L16,3 L15,3 Z M16,7 L16,6 L19.001,6 L19.001,8 L5,8 L5,6 L8,6 L8,7 L9.001,7 L9.001,6 L15,6 L15,7 L16,7 Z M5,9 L19.001,9 L19.001,13.293 L15,17.293 L12.354,14.647 L11.647,15.353 L15,18.707 L19.001,14.707 L19.001,20 L5,20 L5,9 Z"
              id="Fill-1"
            />
          </g>
        </g>
      </svg>
    );
  }
}
