import styled from 'styled-components';
import { mixins } from '../../../../css';

/**
 * Inline Styles for the link list;
 */
export const InlineList = styled.ul`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

export const InlineListItem = styled.li`
  padding-right: ${mixins.pxToRem(30)};
  text-align: center;
  height: 100%;
  &:last-of-type {
    padding-right: 0;
  }

  > a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
`;

/**
 * Stacked List styles for link List
 */
export const StackedList = styled.ul`
  display: block;
  width: 100%;
  text-align: left;
`;

export const StackedListItem = styled.li`
  display: block;
  width: 100%;
  text-align: left;
`;
