import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class SharedWashroom extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m11.69 4.23v-3h-10.1v21.51h1v-20.48h8.1v2a5.16 5.16 0 0 0 -4.86 5.11v.5h10.76v-.5a5.16 5.16 0 0 0 -4.9-5.14zm-4.83 4.64a4.17 4.17 0 0 1 4.14-3.66h.43a4.16 4.16 0 0 1 4.13 3.66z"
        />
        <path fill={iconColor} d="m7.52 12.43h1v1.66h-1z" />
        <path fill={iconColor} d="m7.52 15.27h1v1.66h-1z" />
        <path fill={iconColor} d="m7.52 18.27h1v1.66h-1z" />
        <path fill={iconColor} d="m13.52 12.43h1v1.66h-1z" />
        <path fill={iconColor} d="m10.52 13.93h1v1.66h-1z" />
        <path fill={iconColor} d="m10.52 16.77h1v1.66h-1z" />
        <path fill={iconColor} d="m10.52 19.77h1v1.66h-1z" />
        <path fill={iconColor} d="m10.52 10.93h1v1.66h-1z" />
        <circle cx="17.9" cy="18.24" fill="none" r="4.5" stroke="#000" strokeMiterlimit="10" />
        <path
          fill={iconColor}
          d="m15.48 19.74v-.58l1.14-1a1 1 0 0 0 .17-.21.4.4 0 0 0 .07-.24.32.32 0 0 0 -.1-.25.34.34 0 0 0 -.26-.1.38.38 0 0 0 -.29.12.62.62 0 0 0 -.13.32l-.66-.09a1.25 1.25 0 0 1 .12-.4 1.16 1.16 0 0 1 .25-.29 1 1 0 0 1 .34-.19 1.29 1.29 0 0 1 .4-.06 1.33 1.33 0 0 1 .4.05 1 1 0 0 1 .33.17.73.73 0 0 1 .24.28.8.8 0 0 1 .09.39.79.79 0 0 1 0 .28.9.9 0 0 1 -.12.24 1 1 0 0 1 -.16.2l-.19.18-.68.6h1.19v.61z"
        />
        <path fill={iconColor} d="m19.51 18.75v.86h-.51v-.86h-.9v-.52h.9v-.87h.54v.87h.88v.52z" />
      </svg>
    );
  }
}
