import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class CloseModal extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string,

    onClick: PropTypes.func
  };

  static defaultProps = {
    height: undefined,
    width: 16,
    backgroundColor: 'none',
    iconColor: colors.colorGrey03,

    onClick: undefined
  };

  render() {
    const { height, width, backgroundColor, iconColor, onClick } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <g fill={backgroundColor} fillRule="evenodd">
          <g transform="translate(-546 -27)" fill={iconColor}>
            <g transform="translate(544 25)">
              <polygon points="16.84391 2 9.99972513 8.84363511 3.15663975 2 2 3.15609002 8.84363511 9.99972513 2 16.84391 3.15663975 18 9.99972513 11.1563649 16.84391 18 18 16.84391 11.1563649 9.99972513 18 3.15609002" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
