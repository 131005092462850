import { defineMessages } from 'react-intl';

const messages = defineMessages({
  linkAccountSettings: 'Account Settings',
  linkBookings: 'Organization Bookings',
  linkActiveBookings: 'Active Bookings',
  linkPastBookings: 'Past Bookings',
  attendingBookings: "Bookings I'm Attending",
  linkGuestSchedule: 'Bookings by Guest',
  linkCrewList: 'Crew List',
  linkCrewManagement: 'Crew Management',
  linkBilling: 'Payment IDs',
  linkCostTrackingCodes: 'Tracking Codes',
  linkDashboard: 'My Dashboard',
  linkHome: 'Home',
  linkJoin: 'JOIN NOW',
  linkMap: 'Map',
  linkOrganizationSettings: 'Organization Settings',
  linkReporting: 'Reporting',
  linkReportingV2: 'Reporting V2',
  linkSignIn: 'Sign In',
  linkSignOut: 'Sign Out',
  linkSuppliers: 'Suppliers',
  linkWhyLodgeLink: 'Why LodgeLink'
});

export default messages;
