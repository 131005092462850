// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledIconList } from './FooterIconList.styles';
import FooterSVGLink from '../FooterSVGLink/FooterSVGLink';

export default class FooterIconList extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: []
  };

  /* eslint-disable react/no-array-index-key */
  renderChildren() {
    const { children } = this.props;
    return React.Children.toArray(children).map(child => (child.type === FooterSVGLink ? child : null));
  }

  render() {
    return <StyledIconList>{this.renderChildren()}</StyledIconList>;
  }
}
