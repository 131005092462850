import styled, { css } from 'styled-components';

export const StyledImageContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  padding-top: calc((${props => props.height} / ${props => props.width}) * 100%);
  overflow: hidden;
  position: relative;

  ${props => {
    const { displayImage, src } = props;

    if (displayImage === 'crop') {
      return css`
        background-image: url(${src});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      `;
    }
    return null;
  }};
`;

export const StyledImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  ${props => {
    const { displayImage } = props;

    if (displayImage === 'crop') {
      return css`
        display: none;
      `;
    }
    return null;
  }};
`;
