import styled from 'styled-components';
import { media, fonts, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledHeadingHero = styled(Base)`
  font-family: ${fonts.fontBold};
  font-size: ${mixins.pxToRem(36)};
  line-height: ${mixins.pxToRem(45)};
  letter-spacing: 1px;
  text-align: ${props => props.align};
  text-transform: ${props => props.textTransform};
  color: ${props => props.color};
  margin: ${props => (props.noMargins === false ? `0 0 ${mixins.pxToRem(30)} 0` : 0)};

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

  ${media.greaterThan('md')`
    font-size: ${mixins.pxToRem(50)};
    line-height: ${mixins.pxToRem(60)};
    margin: ${props => (props.noMargins === false ? `0 0 ${mixins.pxToRem(40)} 0` : 0)};
  `};

  ${media.greaterThan('lg')`
    font-size: ${mixins.pxToRem(60)};
    line-height: ${mixins.pxToRem(80)};
    letter-spacing: 3px;
    margin: ${props => (props.noMargins === false ? `0 0 ${mixins.pxToRem(40)} 0` : 0)};
  `};
`;
