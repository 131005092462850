// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InlineList, InlineListItem, StackedList, StackedListItem } from './LinkList.styles';

class LinkList extends Component {
  static propTypes = {
    /**
     * collection of link components passed to be rendered in li's
     */
    links: PropTypes.arrayOf(PropTypes.node),
    /**
     * css flag to determine what display styles to put on the li.  stacked/inline
     */

    type: PropTypes.string
  };

  static defaultProps = {
    links: [],
    type: 'stacked'
  };

  constructor(props) {
    super(props);

    this.stackedRender = this.stackedRender.bind(this);
    this.inlineRender = this.inlineRender.bind(this);
  }

  /**
   * return the structure and styles needed for the mobile version of the nav list.
   * Stacking one on the other is how we know it works, and not side by side.
   *
   */
  stackedRender() {
    const { links } = this.props;

    return (
      <StackedList>
        {Object.keys(links).map(k => (
          <StackedListItem key={`listItem${k}`}>{links[k]}</StackedListItem>
        ))}
      </StackedList>
    );
  }

  /**
   * return the structure and styles needed for the desktop version of the nav list.
   * we know this is working when li's are side by side and not stacked.
   */
  inlineRender() {
    const { links } = this.props;
    return (
      <InlineList>
        {Object.keys(links).map(k => (
          <InlineListItem key={`listItem${k}`}>{links[k]}</InlineListItem>
        ))}
      </InlineList>
    );
  }

  render() {
    const { type, links } = this.props;
    const listType = type === 'stacked' ? this.stackedRender() : this.inlineRender();
    return links.length > 0 ? listType : null;
  }
}

export default LinkList;
