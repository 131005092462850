import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Money extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 24,
    width: 34,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 34 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Page-1" fill="none" fillRule="evenodd">
          <g id="LL_WhyLodgeLink_LG" transform="translate(-541 -1772)" fill={iconColor}>
            <g id="Feature_01" transform="translate(57 1144)">
              <g id="Group-4" transform="translate(483 279)">
                <g id="Icons/Filters/ATM/Black" transform="translate(0 343)">
                  <path
                    d="M7.5,6 L7.5,9 L4.5,9 L4.5,12 L1.5,12 L1.5,29.9985 L28.5,29.9985 L28.5,26.9985 L31.5,26.9985 L31.5,24 L34.5,24 L34.5,6 L7.5,6 Z M9.033,7.5 L32.967,7.5 L32.967,22.5 L31.5,22.5 L30,22.5 L9.033,22.5 L9.033,7.5 Z M6.0345,10.5 L7.5,10.5 L7.5,24 L29.9835,24 L29.967,25.5 L28.5,25.5 L27,25.5 L6.0345,25.5 L6.0345,10.5 Z M3.0345,13.4985 L4.5,13.4985 L4.5,26.9985 L27,26.9985 L26.9655,28.4985 L3.0345,28.4985 L3.0345,13.4985 Z"
                    id="Fill-1"
                  />
                  <g id="Group-3" transform="translate(12 10.5)">
                    <polygon id="Fill-1" points="0 9 1.5 9 1.5 0 0 0" />
                    <polygon id="Fill-2" points="16.5 9 18 9 18 0 16.5 0" />
                  </g>
                  <path
                    d="M21,18 C19.3455,18 18,16.6545 18,15 C18,13.3455 19.3455,12 21,12 C22.6545,12 24,13.3455 24,15 C24,16.6545 22.6545,18 21,18 M21,10.5 C18.5145,10.5 16.5,12.5145 16.5,15 C16.5,17.4855 18.5145,19.5 21,19.5 C23.4855,19.5 25.5,17.4855 25.5,15 C25.5,12.5145 23.4855,10.5 21,10.5"
                    id="Fill-1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
