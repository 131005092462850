import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Security extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m7.57 14.74v-1.06l2.08-1.88a1.85 1.85 0 0 0 .32-.38.83.83 0 0 0 .12-.44.62.62 0 0 0 -.18-.46.67.67 0 0 0 -.49-.18.65.65 0 0 0 -.51.23.91.91 0 0 0 -.24.58l-1.21-.15a1.84 1.84 0 0 1 .22-.72 2 2 0 0 1 .45-.54 1.79 1.79 0 0 1 .62-.34 2.25 2.25 0 0 1 .74-.12 2.46 2.46 0 0 1 .72.1 1.7 1.7 0 0 1 .62.3 1.43 1.43 0 0 1 .43.51 1.53 1.53 0 0 1 .16.72 1.68 1.68 0 0 1 -.07.52 1.72 1.72 0 0 1 -.21.43 2.6 2.6 0 0 1 -.3.36l-.35.34-1.24 1.1h2.18v1.1z"
        />
        <path fill={iconColor} d="m15.81 13.7v1h-1.18v-1h-2.51v-1.06l2.17-3.24h1.51v3.27h.74v1zm-1.18-3-1.27 2h1.29z" />
        <path fill={iconColor} d="m12 22a10 10 0 1 0 -8-4h-2v1h4v-4h-1v2.64a9 9 0 1 1 7 3.36z" />
      </svg>
    );
  }
}
