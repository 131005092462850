import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ConvenienceStore extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path fill={iconColor} d="m17 14.5h1v2h-1z" />
        <path
          fill={iconColor}
          d="m22.64 12-2.07-8h-17.14l-2.08 8h1.65v8h18v-8zm-1.29-1h-2.89l-1.55-6h2.88zm-14.44 0 1.55-6h3v6zm5.59-6h3.37l1.56 6h-4.93zm-9.86 6 1.56-6h3.23l-1.56 6zm12.36 1v5h-11v-5zm-11 6h11v1h-11zm16 1h-4v-7h4z"
        />
      </svg>
    );
  }
}
