import styled, { css } from 'styled-components';
import { media, mixins } from '../../css';

// px sizes for sm/lg options on sm/lg breakpoints
const largeDefault = mixins.pxToRem(58);
const largeGreaterThanLarge = mixins.pxToRem(88);
const smallDefault = mixins.pxToRem(30);
const smallGreaterThanLarge = mixins.pxToRem(50);
const extraSmallDefault = mixins.pxToRem(16);
const extraSmallGreaterThanLarge = mixins.pxToRem(20);

export const VerticalSpacingWrapper = styled.div`
  ${props => {
    if (props.type === 'padding') {
      switch (props.size) {
        case 'lg':
          return css`
            padding-top: ${largeDefault};
            padding-bottom: ${largeDefault};
            ${media.greaterThan('lg')`
            padding-top: ${largeGreaterThanLarge};
            padding-bottom: ${largeGreaterThanLarge};
          `};
          `;

        case 'sm':
          return css`
            padding-top: ${smallDefault};
            padding-bottom: ${smallDefault};
            ${media.greaterThan('lg')`
            padding-top: ${smallGreaterThanLarge};
            padding-bottom: ${smallGreaterThanLarge};
          `};
          `;

        case 'xs':
          return css`
            padding-top: ${extraSmallDefault};
            padding-bottom: ${extraSmallDefault};
            ${media.greaterThan('lg')`
            padding-top: ${extraSmallGreaterThanLarge};
            padding-bottom: ${extraSmallGreaterThanLarge};
          `};
          `;

        default:
          return css`
            padding-top: ${largeDefault};
            padding-bottom: ${largeDefault};
            ${media.greaterThan('lg')`
              padding-top: ${largeGreaterThanLarge};
              padding-bottom: ${largeGreaterThanLarge};
            `};
          `;
      }
    } else if (props.type === 'margin') {
      switch (props.size) {
        case 'lg':
          return css`
            margin-top: ${largeDefault};
            margin-bottom: ${largeDefault};
            ${media.greaterThan('lg')`
          margin-top: ${largeGreaterThanLarge};
          margin-bottom: ${largeGreaterThanLarge};
          `};
          `;

        case 'sm':
          return css`
            margin-top: ${smallDefault};
            margin-bottom: ${smallDefault};
            ${media.greaterThan('lg')`
          margin-top: ${smallGreaterThanLarge};
          margin-bottom: ${smallGreaterThanLarge};
          `};
          `;

        case 'xs':
          return css`
            margin-top: ${extraSmallDefault};
            margin-bottom: ${extraSmallDefault};
            ${media.greaterThan('lg')`
              margin-top: ${extraSmallGreaterThanLarge};
              margin-bottom: ${extraSmallGreaterThanLarge};
            `};
          `;

        default:
          return css`
            margin-top: ${largeDefault};
            margin-bottom: ${largeDefault};
            ${media.greaterThan('lg')`
          margin-top: ${largeGreaterThanLarge};
          margin-bottom: ${largeGreaterThanLarge};
          `};
          `;
      }
    } else {
      return css``;
    }
  }};
`;
