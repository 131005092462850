import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class PetFriendly extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 120 120"
      >
        <path
          fill={iconColor}
          d="M94.21,87.9c0.18,13.61-14.19,22.34-25.9,16.29c-2.65-1.37-5.09-3.16-8.27-3.38c-3.6-0.24-6.96,0.25-9.83,2.5
	c-3.29,2.58-7.03,4.19-11.14,4.2c-5.97,0.01-11.11-2.28-14.74-7.2c-4.69-6.36-4.72-13.57-0.79-20.31
	c3.56-6.11,7.27-12.14,11.19-18.02c5.03-7.54,12.22-11.58,21.32-11.83c9.97-0.28,17.68,3.99,23.4,12.09
	c3.95,5.59,8.03,11.1,12.02,16.66C93.23,81.35,94.13,84.44,94.21,87.9z M59.19,96.04c3.18-0.08,6.47,0.95,9.37,2.84
	c2.66,1.74,5.38,2.58,8.57,2.44c9.71-0.41,15.86-11.57,10.31-19.95c-4.19-6.32-8.8-12.37-13.42-18.38
	c-8.76-11.39-27.07-11.08-35.47,1.66c-1.98,3-3.87,6.05-5.69,9.14c-2.26,3.83-4.72,7.59-6.53,11.63c-2.18,4.86-0.13,11.35,4,14.44
	c5.03,3.77,11.45,3.94,16.23,0.26C50.19,97.32,54.25,95.98,59.19,96.04z"
        />
        <path
          fill={iconColor}
          d="M62.12,27.8c0.2,6.14-3.57,13.13-10.39,15.47c-6.5,2.23-13.74-0.29-17.66-7.31c-3.86-6.93-3.93-14.06,1.08-20.69
	c5.32-7.03,14.43-7.58,20.95-1.88C60.24,17,62.13,21.78,62.12,27.8z M57.4,27.68c0-3.38-0.78-5.84-2.15-8.13
	c-3.42-5.68-10.95-6.97-15.33-2.66c-5.3,5.21-5,14.62,0.57,19.65c2.66,2.41,5.88,3.4,9.68,2.26C54.08,37.64,57.84,32.26,57.4,27.68z
	"
        />
        <path
          fill={iconColor}
          d="M98.32,33.15c0.16,7.13-4.21,14.21-11.17,16.75c-4.74,1.73-10.39,0.88-14.77-3.56c-8.24-8.36-5.27-24.19,5.43-28.89
	c7.38-3.24,15.18,0.25,18.65,7.18C97.81,27.32,98.52,30.13,98.32,33.15z M93.89,33.12c-0.36-1.71-0.51-3.49-1.1-5.12
	c-2.42-6.7-9.55-9.12-15.16-4.95c-5.68,4.23-7.12,13.57-2.72,19.09c2.12,2.65,4.96,4.13,8.55,3.77
	C89.4,45.32,93.99,38.65,93.89,33.12z"
        />
        <path
          fill={iconColor}
          d="M6.08,47.13c-0.18-6.88,4.49-12.34,11.19-12.85c5.77-0.44,10.17,2.29,13.29,6.85c3.39,4.96,3.93,10.38,0.86,15.76
	c-2.16,3.79-5.67,5.71-9.97,5.77C12.54,62.79,6.03,54.81,6.08,47.13z M28.55,50.09c0.17-4.09-1.83-7.18-4.92-9.45
	c-3.73-2.74-8.42-2.17-11.15,1.06c-1.63,1.94-1.92,4.28-1.59,6.72c0.65,4.9,4.35,8.68,8.76,9.43
	C24.86,58.75,28.29,55.54,28.55,50.09z"
        />

        <path
          fill={iconColor}
          d="M102.38,75.44c-7.35-0.08-12.9-6.42-11.98-13.34c1.03-7.78,5.07-12.57,12.55-14.33c7.25-1.71,14,3.04,14.85,10.38
	C118.81,67.03,111.47,75.41,102.38,75.44z M105.79,52.22c-0.47,0-0.95-0.07-1.4,0.01c-4.74,0.84-8.4,4.43-9.36,9.14
	c-0.79,3.86,1.64,7.93,5,8.97c5.58,1.73,12.15-2.95,13.02-9C113.8,56.11,110.81,52.18,105.79,52.22z"
        />
      </svg>
    );
  }
}
