import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Notifications extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 20,
    height: undefined,
    iconColor: colors.iconGrey,
    backgroundColor: colors.colorBlue03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" fill="none" fillRule="evenodd">
          <g id="Navigation/Header/LG/Authenticated" transform="translate(-819 -31)">
            <g id="Icons/UI/Notification/On" transform="translate(817 29)">
              <path
                d="M10.5000036,2 L3.99100589,2 C3.92000591,2 2.00000654,2.018 2.00000654,3.991 L2.00000654,20.009 C2.00000654,20.009 1.98100655,22 3.99100589,22 L20.0090007,22 C20.0800006,22 22,21.982 22,20.009 L22,13.4999998 C22,13.2238576 21.7761424,13 21.5000002,13 L21.5000002,13 C21.2238579,13 21.0000003,13.2238576 21.0000003,13.4999998 L21.0000003,20.009 C21.0000003,20.814 20.4600005,20.992 20.0090007,21 L3.99100589,21 C3.18600615,21 3.00800621,20.46 3.00000621,20.009 L3.00000621,3.991 C3.00000621,3.187 3.53900604,3.008 3.99100589,3 L10.5000036,3 C10.776146,3 11.0000036,2.77614237 11.0000036,2.5 L11.0000036,2.5 C11.0000036,2.22385763 10.776146,2 10.5000036,2 Z"
                id="Fill-3"
                fill={iconColor}
              />
              <path
                d="M17,12 C14.2388889,12 12,9.76111111 12,7 C12,4.23888889 14.2388889,2 17,2 C19.7611111,2 22,4.23888889 22,7 C22,9.76111111 19.7611111,12 17,12 Z"
                id="Fill-1"
                fill={backgroundColor}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
