import React from 'react';
import PropTypes from 'prop-types';
import { getDataFromTree } from 'react-apollo';
import Head from 'next/head';
import initApollo from '../../apollo';
import { getAnalyticCookiesFromContext } from '../../lib/analytics';
import { getDefaultLocale } from '../../locales';
import { withActiveLocalePropType } from '../withActiveLocale';

/**
 * Settings for the API URL are currently located inside the apollo/index.js file
 * This file exists to provide the apolloClient as a prop so we can wrap our Application
 * inside the ApolloProvider. The ApolloProvider has to be wrapped under the Next <Container> or
 * we run into issues using SSR.
 */
const withApollo = App =>
  class withApollo extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      apolloClient: PropTypes.shape({
        analyticIDs: PropTypes.shape({}).isRequired,
        apolloState: PropTypes.shape({}).isRequired,
        locale: withActiveLocalePropType.isRequired
      }).isRequired
    };

    constructor(props) {
      super(props);

      this.displayName = `withApollo(${App.displayName})`;

      const {
        apolloClient: { analyticIDs, apolloState, locale }
      } = this.props;

      // Create an apollo client for use client side.
      // We're basically restoring the state of the server side client here.
      this.apolloClient = initApollo(apolloState, {
        analyticIDs,
        locale
      });
    }

    static async getInitialProps(ctx) {
      const {
        Component,
        router,
        ctx: { res }
      } = ctx;

      // When redirecting, the response is finished there is no point in continuing to render
      if (res && res.finished) {
        return {};
      }

      // Run the "getInitialProps" function on the App component
      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      // Create an apollo client for use server side
      const analyticIDs = getAnalyticCookiesFromContext(ctx);
      const locale = appProps.locale || getDefaultLocale(); // Uses locale suggested by getInitialProps in the App component
      const apollo = initApollo({}, { locale, analyticIDs });

      // This should only happen for SSR

      // Extract the cache from all of the queries that we just ran
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloClient: {
          analyticIDs,
          apolloState,
          locale
        }
      };
    }

    render() {
      const { apolloClient, ...appProps } = this.props;

      return <App {...appProps} apolloClient={this.apolloClient} />;
    }
  };

export default withApollo;
