import PropTypes from 'prop-types';
import React from 'react';

import { trackEvent, trackRoute, trackGA4 } from '../../lib/analytics';

const analytics = {
  trackEvent,
  trackRoute,
  trackGA4
};

/**
 * Export a shape for use with this HOC.
 */
export const withAnalyticsPropType = PropTypes.shape({
  trackEvent: PropTypes.func.isRequired,
  trackRoute: PropTypes.func.isRequired,
  trackGA4: PropTypes.func.isRequired,
});

/**
 * A HOC that provides analytics tracking props to pages.
 */
const withAnalytics = () => BaseComponent =>
  class ComponentWithAnalytics extends React.Component {
    static displayName = `withAnalytics(${BaseComponent.displayName || BaseComponent.name})`;

    render() {
      return <BaseComponent {...this.props} analytics={analytics} />;
    }
  };

export default withAnalytics;
