import { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';

import withRouting, { withRoutingPropType } from '../../hocs/withRouting';

/**
 * When this component is rendered on a page it will redirect the user to the
 * route defined by the "route" prop.
 *
 * Use the "params" prop to pass through any required params.
 *
 * The "locale" param will be automatically populated for you.
 */
class Redirect extends Component {
  static propTypes = {
    router: withRoutingPropType.isRequired,
    method: PropTypes.oneOf(['push', 'replace']),
    params: PropTypes.shape({}),
    route: PropTypes.string.isRequired
  };

  static defaultProps = {
    method: 'push',
    params: {}
  };

  constructor(props) {
    super(props);

    if (process.browser) {
      const {
        method,
        params,
        route,
        router: { pushRouteAndScroll, replaceRouteAndScroll }
      } = props;

      // Assemble route information
      const routeName = route;
      const routeParams = {
        ...params
      };

      // Use one of the two routing methods
      if (method === 'push') {
        pushRouteAndScroll(routeName, routeParams);
      } else if (method === 'replace') {
        replaceRouteAndScroll(routeName, routeParams);
      }
    }
  }

  render() {
    return null;
  }
}

export default compose(withRouting)(Redirect);
