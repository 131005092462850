import { Component } from 'react';
import { injectIntl } from 'react-intl';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import withRouting, { withRoutingPropType } from '../../hocs/withRouting';
import withAnalytics, { withAnalyticsPropType } from '../../hocs/withAnalytics';
import withDebouncedProps from '../../hocs/withDebouncedProps';

import messages from './messages';
import {
  QUERY_SEARCH_TERM_INPUT,
  QUERY_SEARCH_CRITERIA,
  MUTATION_UPDATE_ROOM_COUNT,
  MUTATION_UPDATE_AUTO_SUGGEST_TERM,
  MUTATION_UPDATE_DATE_CRITERIA,
  MUTATION_COMMIT_SEARCH_TERM,
  MUTATION_UPDATE_SEARCH_LOCATION,
  MUTATION_RESET_SEARCH_VALUES
} from './gql';

class PropertySearch extends Component {
  static propTypes = {
    router: withRoutingPropType.isRequired,
    children: PropTypes.func.isRequired,
    updateLookupTermMutation: PropTypes.func.isRequired,
    updateRoomCount: PropTypes.func.isRequired,
    updateDateCriteria: PropTypes.func.isRequired,
    inputTerm: PropTypes.string,
    analytics: withAnalyticsPropType.isRequired,
    analyticLocation: PropTypes.string,
    commitSearchTerm: PropTypes.func.isRequired,
    resetSearchValues: PropTypes.func.isRequired
  };

  static defaultProps = {
    analyticLocation: null,
    inputTerm: ''
  };

  constructor() {
    super();

    this.onDatesChanged = this.onDatesChanged.bind(this);
    this.onFlyoutClick = this.onFlyoutClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRoomsChange = this.onRoomsChange.bind(this);
    this.onHandleSearch = this.onHandleSearch.bind(this);
    this.clearTerm = this.clearTerm.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
  }

  /**
   * This is the onChange handler for changing dates which will update dates in apollo link state
   *
   * @memberof PropertySearch
   */
  onDatesChanged = ([startDate, endDate]) => {
    const { updateDateCriteria } = this.props;

    if (!startDate || !endDate) {
      return;
    }
    // eslint-disable-next-line
    const val = updateDateCriteria({
      variables: { startDate, endDate }
    });

    // eslint-disable-next-line
    return val;
  };

  /**
   * This is the onChange handler for updating apollo link state with the room count.
   *
   * @memberof PropertySearch
   */
  onRoomsChange = ({ target: { value } }) => {
    const { updateRoomCount } = this.props;
    return updateRoomCount({
      variables: { count: value }
    });
  };

  /**
   * This is the handler for clearing the search term in apollo link state.
   *
   * @memberof PropertySearch
   */
  clearTerm = () => {
    const { resetSearchValues } = this.props;
    return resetSearchValues({
      variables: { term: '' }
    });
  };

  /**
   * This is the click handler for when someone clicks on an autosuggest term in the search component.
   *
   * @memberof PropertySearch
   */
  onFlyoutClick = async value => {
    const { updateSearchLocation, analyticLocation, analytics } = this.props;
    const graphData = await updateSearchLocation({
      variables: { location: value }
    });

    analytics.trackEvent('search-suggestions', {
      clickedAutosuggest: graphData.data.updateSearchLocation.location.locationValue,
      searchBarName: analyticLocation
    });

    return graphData;
  };

  /**
   * Change handler for when you type and change a search term.
   *
   * @memberof PropertySearch
   */
  onSearchChange = async ({ target: { value } }) => {
    const { updateLookupTermMutation, refetch } = this.props;

    await updateLookupTermMutation({
      variables: { term: value }
    });

    const response = await refetch({ input: value });

    return response;
  };

  /**
   * Handler for when the user clicks on the search button in the component.
   *
   * @memberof PropertySearch
   */
  onHandleSearch = async e => {
    const { commitSearchTerm, updateLookupTermMutation, lookupTerm, router, analytics, analyticLocation } = this.props;

    if (e && e.target && e.target.type === 'search' && e.target.value !== lookupTerm) {
      await updateLookupTermMutation({ variables: { term: e.target.value } });
    }
    // data returned from the graphQL mutation of final input. Used for analytics currently.
    const { data } = await commitSearchTerm();

    // Get the url for map page
    const href = router.getRouteUrl('Map');

    // Navigate to map page
    router.pushRouteAndScroll(href);

    // Track search completion
    analytics.trackEvent('search', {
      destinationUrl: href,
      searchBarName: analyticLocation,
      typedSearch: data.commitSearchTerm.propertySearchInput.searchValue
    });
  };


  canSubmit = () => {
    const store = localStorage.getItem('LLV2_SEARCH_CRITERIA')

    const parsedObject = JSON.parse(store);

    const { lookupTerm, roomCount, propertySearchInput } = parsedObject;

    const checkInDate = new Date(propertySearchInput.checkin);
    const checkOutDate = new Date(propertySearchInput.checkout);

    if (lookupTerm && roomCount && (checkInDate < checkOutDate)) {
      return true
    }
    return false
  }

  buildProps = props => {
    const { patterns, locations, lookupTerm, endDate, startDate, roomCount, intl } = props;
    return {
      patterns,
      locations,
      clearValue: this.clearTerm,
      onDatesChangeHook: this.onDatesChanged,
      onFlyoutItemClickHook: this.onFlyoutClick,
      onSearchChangeHook: this.onSearchChange,
      onRoomsChangeHook: this.onRoomsChange,
      onHandleSearch: this.onHandleSearch,
      onSubmitHook: this.onHandleSearch,
      searchValue: lookupTerm,
      roomsValue: roomCount,
      datesStartDate: startDate,
      datesEndDate: endDate,
      flyoutMaxHeight: '30vh',
      searchLabelText: intl.formatMessage(messages.searchLabelText),
      placeholder: intl.formatMessage(messages.placeholder),
      roomsLabelText: intl.formatMessage(messages.roomsLabelText),
      datesStartDateLabel: intl.formatMessage(messages.startDateLabelText),
      datesEndDateLabel: intl.formatMessage(messages.endDateLabelText),
      canSubmit: this.canSubmit(props)
    };
  };

  render() {
    const { children, ...otherProps } = this.props;
    return children(this.buildProps(otherProps));
  }
}

const PropertySearchWithMutations = compose(
  injectIntl,
  withRouting,
  withApollo,
  // Link State Mutations
  graphql(MUTATION_UPDATE_AUTO_SUGGEST_TERM, { name: 'updateLookupTermMutation' }),
  graphql(MUTATION_UPDATE_ROOM_COUNT, { name: 'updateRoomCount' }),
  graphql(MUTATION_UPDATE_DATE_CRITERIA, { name: 'updateDateCriteria' }),
  graphql(MUTATION_COMMIT_SEARCH_TERM, { name: 'commitSearchTerm' }),
  graphql(MUTATION_UPDATE_SEARCH_LOCATION, { name: 'updateSearchLocation' }),
  graphql(MUTATION_RESET_SEARCH_VALUES, { name: 'resetSearchValues' }),

  // Link State query for extracting the search criteria from local state
  graphql(QUERY_SEARCH_CRITERIA, {
    props: ({ data }) => {
      const { searchCriteria } = data;
      const props = {
        lookupTerm: get(searchCriteria, 'lookupTerm'),
        roomCount: get(searchCriteria, 'roomCount'),
        startDate: get(searchCriteria, 'startDate'),
        endDate: get(searchCriteria, 'endDate')
      };
      return props;
    }
  }),

  // because we don't want to high the graph ql endpoint without debounce we use a HOC
  // to make sure that the props that are passed in as vars wont be too chirpy
  withDebouncedProps(['lookupTerm'], 250, { leading: true }),

  // GraphQL Server Query
  // This is pass a the search term to the GraphQL server and will
  // get back a list of matched patterns and a list of matched locations.
  graphql(QUERY_SEARCH_TERM_INPUT, {
    options: props => {
      const { lookupTerm = '' } = props;
      return {
        fetchPolicy: 'cache-first',
        ssr: false, // Authentication only works client side
        variables: { input: lookupTerm }
      };
    },
    props: ({ data, ownProps }) => {
      const { suggestedPattern } = data;
      const patterns = get(suggestedPattern, 'suggestedPatterns', []);
      const locations = get(suggestedPattern, 'suggestedLocations', []);

      return {
        ...ownProps,
        patterns,
        locations,
        refetch: data.refetch
      };
    }
  }),
  withAnalytics()
)(PropertySearch);

export default PropertySearchWithMutations;
