import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class MapLayersIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 22,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" fill="none" fillRule="evenodd">
          <g id="Icons/Map/Layers/Black" transform="translate(-1 -5)" fill={iconColor}>
            <g id="Group-3" transform="translate(1 5)">
              <path
                d="M10.9998,-0.0003 L-0.0002,4.9997 L10.9998,9.9997 L21.9998,4.9997 L10.9998,-0.0003 Z M2.4178,4.9997 L10.9998,1.0987 L19.5828,4.9997 L10.9998,8.9017 L2.4178,4.9997 Z"
                id="Fill-1"
              />
              <polygon
                id="Fill-2"
                points="16.3917 8.5497 19.5827 9.9997 10.9997 13.9007 2.4177 9.9997 5.6087 8.5497 4.3997 7.9997 -0.0003 9.9997 10.9997 14.9997 21.9997 9.9997 17.5997 7.9997"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
