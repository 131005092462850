import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Bar extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m12.7 15.5 7.39-8.82h-4.33l.33-.68a2.21 2.21 0 0 0 .37 0h.15a1.76 1.76 0 0 0 1.22-.63 1.78 1.78 0 0 0 -.19-2.5l.65-1.29-.89-.45-.66 1.32a1.25 1.25 0 0 0 -.43 0 1.76 1.76 0 0 0 -1.22.63 1.74 1.74 0 0 0 -.41 1.31 1.72 1.72 0 0 0 .53 1.12l-.57 1.15h-10.73l7.79 8.85v6.3h-3.87v1h8.75v-1h-3.88zm3.16-11.76a.77.77 0 0 1 .53-.28h.07a.79.79 0 0 1 .79.73.79.79 0 0 1 -.72.85.8.8 0 0 1 -.86-.72.79.79 0 0 1 .19-.58zm-.34 6.84-3.34 4-3.5-4zm2.48-2.9-1.59 1.9h-2l.93-1.9zm-3.8 0-.94 1.9h-5.46l-1.68-1.9z"
        />
      </svg>
    );
  }
}
