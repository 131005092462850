import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Close extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" fill="none" fillRule="evenodd">
          <g id="Navigation/Flyout/SM/Unauthenticated" transform="translate(-26 -28)" fill={iconColor}>
            <g id="Icons/UI/Close/Black" transform="translate(26 28)">
              <polygon
                id="Fill-1"
                points="18.75 0 10 8.75 1.25 0 0 1.25 8.75 10 0 18.75 1.25 20 10 11.25 18.75 20 20 18.75 11.25 10 20 1.25"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
