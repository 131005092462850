// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../SVGIcon';
import { colors } from '../../../css';
import {
  DesktopWrapper,
  LinkListContainer,
  AuthenticatedContainer,
  SearchContainer,
  SignInContainer,
  HomeLinkContainer,
  NotificationsContainer,
  NotificationsFlyout
} from './DesktopNav.styles';
import UserSubMenu from './UserSubMenu';
import UserCard from '../common/UserCard';
import { EnvBanner } from '../../../EnvBanner';
import LinkList from '../common/LinkList';
import NavLink from '../common/NavLink';
import Logo from '../common/Logo';

class DesktopNav extends Component {
  static propTypes = {
    /**
     * Passed Component of the link that takes a user to the homepage.
     */
    logo: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * The Passed component housing the search feature.
     */
    search: PropTypes.node,

    /**
     * An array of objects used to create functional nav links.
     */
    mainLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string,
        label: PropTypes.string,
        className: PropTypes.string,
        target: PropTypes.string,
        onClick: PropTypes.func
      })
    ),

    /**
     * Flagging whether user is logged in or not to determine wether to render user in nav with submenu or the passed joinLink.
     */
    authenticated: PropTypes.bool,

    /**
     * Bool telling us if we are on a touch device or not.
     */
    isTouchDevice: PropTypes.bool,

    /**
     * Passed JSON that will render a link in place of user info if authentication i false.
     */
    joinLink: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Authenticated Users First Name.
     */

    userFirstName: PropTypes.string,

    /**
     * Authenticated Users Last Name.
     */

    userLastName: PropTypes.string,

    /**
     * Authenticated Users Currently active organization id.
     */

    userCurrentOrganization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Authenticated Users Profile Pic (optional), defaults to users initials from First/Last Name.
     */

    userProfilePicture: PropTypes.string,

    /**
     * List of Organizations the Authenticated user is associated to that when clicked sets current active organization
     */
    // eslint-disable-next-line react/forbid-prop-types
    organizations: PropTypes.arrayOf(PropTypes.object),

    /**
     * JSON representation of a link to the Current Organizations settings page
     */

    organizationSettings: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Change handler to send back selected org so all places can be updated by parent/ancestor
     */
    organizationChange: PropTypes.func,

    /**
     * Array of notification components provided by the navigation.
     */
    notifications: PropTypes.arrayOf(PropTypes.node),

    /**
     * String message telling the user they do not have notifications.
     */
    noNotificationsMsg: PropTypes.string,
    /**
     * JSON representation of a link to the Join Organization page.
     */
    joinOrganization: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Array of JSON representing links available to the authenticated user.
     */
    subMenuLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string,
        label: PropTypes.string,
        className: PropTypes.string,
        target: PropTypes.string,
        onClick: PropTypes.func
      })
    ),

    /**
     * JSON representing a link to sign out the current authenticated user.
     */
    signOut: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * JSON object representing a link to sign in a user.
     */
    signIn: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * String value of the class name to determine the state of the nav.
     */
    mainMenuVisible: PropTypes.bool,
    /**
     * Boolean to tell the navs to not render notifications.
     */
    suppressNotifications: PropTypes.bool,
    logoSelectedIcon: PropTypes.string
  };

  static defaultProps = {
    authenticated: false,
    isTouchDevice: false,
    joinOrganization: null,
    joinLink: null,
    logo: null,
    mainLinks: [],
    mainMenuVisible: true,
    notifications: [],
    noNotificationsMsg: '',
    organizations: [],
    organizationSettings: null,
    organizationChange: () => {},
    userCurrentOrganization: '',
    userFirstName: '',
    userLastName: '',
    userProfilePicture: null,
    search: null,
    signOut: null,
    signIn: null,
    suppressNotifications: false,
    subMenuLinks: [],
    logoSelectedIcon: ''
  };

  static getCurrentOrgName(userCurrentOrganization, organizations) {
    const currentOrg =
      organizations.length > 0
        ? organizations.find(organization => organization.id === userCurrentOrganization)
        : undefined;
    const orgName = currentOrg ? currentOrg.name : undefined;

    return orgName;
  }

  static createNavLinks(links, cbFunc) {
    // if triggered again with no links, return null right away.
    if (links === null || links === undefined) {
      return null;
    }
    const extraCallback = typeof cbFunc === 'function' ? cbFunc : () => {};

    // handles if there is an array of links to make.
    if (Array.isArray(links)) {
      const reactLinks = [];

      links.forEach(linkObj => {
        const interceptedOnClick =
          linkObj.onClick && typeof linkObj.onClick === 'function'
            ? e => {
                linkObj.onClick(e, extraCallback);
              }
            : extraCallback;

        reactLinks.push(
          <NavLink
            key={linkObj.id}
            href={linkObj.href || '#'}
            onClick={interceptedOnClick}
            className={linkObj.className || null}
            target={linkObj.target || null}
          >
            {linkObj.label}
          </NavLink>
        );
      });

      return reactLinks;
    }

    // handles single link
    if (!Array.isArray(links) && typeof links === 'object') {
      const interceptedOnClick =
        links.onClick && typeof links.onClick === 'function'
          ? e => {
              links.onClick(e, extraCallback);
            }
          : extraCallback;
      return (
        <NavLink
          href={links.href || '#'}
          onClick={interceptedOnClick}
          target={links.target || null}
          className={links.className || null}
        >
          {links.label}
        </NavLink>
      );
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
    this.notificationRef = React.createRef();

    this.state = {
      menuOpen: false,
      notificationsOpen: false,
      currentOrgName: DesktopNav.getCurrentOrgName(props.userCurrentOrganization, props.organizations)
    };

    // utilitys
    this.closest = (el, fn) => {
      if (el === null || (el !== null && el.nodeName.toLowerCase() === 'body')) {
        return false;
      }

      return el && fn(el) ? el : this.closest(el.parentNode, fn);
    };

    this.handleMenuMouseEnter = this.handleMenuMouseEnter.bind(this);
    this.handleMenuMouseLeave = this.handleMenuMouseLeave.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuOffClick = this.handleMenuOffClick.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.handleNotificationOffClick = this.handleNotificationOffClick.bind(this);
    this.notificationsMouseEnter = this.notificationsMouseEnter.bind(this);
    this.notificationsMouseLeave = this.notificationsMouseLeave.bind(this);
    this.hoverCheck = this.hoverCheck.bind(this);

    this.renderNotificationsContent = this.renderNotificationsContent.bind(this);
    this.renderUserLoggedInOut = this.renderUserLoggedInOut.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { menuOpen, currentOrgName } = prevState;
    const { mainMenuVisible, userCurrentOrganization, organizations } = nextProps;
    const newOrgName = DesktopNav.getCurrentOrgName(userCurrentOrganization, organizations);
    const stateReturn = {};
    // force state of the menu being open to close when nav is hidden
    // also call focus on something else to close search flyout
    if (!mainMenuVisible && menuOpen) {
      stateReturn.menuOpen = false;
    }

    // update the current name based on props change.
    if (currentOrgName !== newOrgName && newOrgName !== undefined) {
      stateReturn.currentOrgName = newOrgName;
    }

    return stateReturn;
  }

  componentWillUnmount() {
    // remove event handlers incase they are active when unmounting.
    document.removeEventListener('touchstart', this.handleMenuOffClick, false);
    document.removeEventListener('touchstart', this.handleNotificationOffClick, false);
  }

  handleMenuMouseEnter() {
    this.setState({ menuOpen: true });
  }

  handleMenuMouseLeave() {
    this.setState({ menuOpen: false });
  }

  hoverCheck() {
    // creating function here as this.handleMouseMove wasn't able to remove via remove event listener
    const handleMouseMove = e => {
      const curX = e.pageX || e.clientX + document.body.scrollLeft;
      const curY = e.pageY || e.clientY + document.body.scrollTop;
      const hoverElement = document.elementFromPoint(curX, curY);

      const foundParent = this.closest(hoverElement, el => {
        const hasClassList = el.classList;
        if (hasClassList && hasClassList.contains(this.menuRef.current.state.generatedClassName)) {
          return true;
        }
        return false;
      });

      if (!foundParent) {
        this.handleMenuMouseLeave();
        window.removeEventListener('mousemove', handleMouseMove, true);
      } else {
        window.removeEventListener('mousemove', handleMouseMove, true);
      }
    };
    // check to see if we are still in the menu after closing material select. delayed with timeout cause of closing effect on material.
    setTimeout(() => {
      window.addEventListener('mousemove', handleMouseMove, true);
    }, 500);
  }

  // menu open/close ability on touch devices
  handleMenuClick(e) {
    const { menuOpen } = this.state;
    e.preventDefault();

    if (!menuOpen) {
      document.addEventListener('touchstart', this.handleMenuOffClick, false);
    } else {
      document.removeEventListener('touchstart', this.handleMenuOffClick, false);
    }
    this.setState({ menuOpen: !menuOpen });
  }

  // when clicking on anything other than the menu, we close it
  handleMenuOffClick(e) {
    const clickTarget = e.target;
    let isMuiModal = false;

    const foundParent = this.closest(clickTarget, el => {
      if (el.classList.contains(this.menuRef.current.state.generatedClassName)) {
        return true;
      }
      return false;
    });

    // check to make sure it is not the mui modal for the select element
    if (!foundParent) {
      isMuiModal = this.closest(clickTarget, el => {
        const elementClass = el.getAttribute('class');

        if (elementClass && elementClass.indexOf('MuiModal') > -1) {
          return true;
        }

        return false;
      });
    }

    if (!foundParent && !isMuiModal) {
      document.removeEventListener('touchstart', this.handleMenuOffClick, false);
      this.setState({ menuOpen: false });
    }
  }

  notificationsMouseEnter() {
    const { menuOpen } = this.state;

    const newState = { notificationsOpen: true };

    if (menuOpen) newState.menuOpen = false;

    this.setState(newState);
  }

  notificationsMouseLeave() {
    this.setState({ notificationsOpen: false });
  }

  // notifications menu touch device handler
  handleNotificationClick(e) {
    const { notificationsOpen } = this.state;
    e.preventDefault();
    e.stopPropagation();

    if (!notificationsOpen) {
      document.addEventListener('touchstart', this.handleNotificationOffClick, false);
    } else {
      document.removeEventListener('touchstart', this.handleNotificationOffClick, false);
    }

    this.setState({ notificationsOpen: !notificationsOpen });
  }

  // notifications menu click handler when clicking off the menu
  handleNotificationOffClick(e) {
    const clickTarget = e.target;

    const foundParent = this.closest(clickTarget, el =>
      el.classList.contains(this.notificationRef.current.state.generatedClassName)
    );

    if (!foundParent) {
      document.removeEventListener('touchstart', this.handleNotificationOffClick, false);
      this.setState({ notificationsOpen: false });
    }
  }

  renderUserLoggedInOut() {
    const {
      authenticated,
      joinLink,
      isTouchDevice,
      userFirstName,
      userLastName,
      userCurrentOrganization,
      userProfilePicture,
      organizationChange,
      subMenuLinks,
      organizations,
      organizationSettings,
      mainMenuVisible,
      joinOrganization,
      signOut
    } = this.props;
    const { menuOpen, currentOrgName } = this.state;

    return (
      (authenticated && (
        <AuthenticatedContainer
          authenticated={authenticated}
          onMouseEnter={!isTouchDevice ? this.handleMenuMouseEnter : () => {}}
          onMouseLeave={!isTouchDevice ? this.handleMenuMouseLeave : () => {}}
          ref={this.menuRef}
        >
          <UserCard
            userFirstName={userFirstName}
            userLastName={userLastName}
            userCurrentOrganization={currentOrgName}
            userProfilePicture={userProfilePicture}
            cursor="pointer"
            onClick={
              !isTouchDevice
                ? () => {}
                : e => {
                    this.handleMenuClick(e);
                  }
            }
          />
          {menuOpen && mainMenuVisible ? (
            <UserSubMenu
              subMenuLinks={DesktopNav.createNavLinks(subMenuLinks)}
              userCurrentOrganization={userCurrentOrganization}
              organizationSettings={DesktopNav.createNavLinks(organizationSettings)}
              organizations={organizations}
              organizationChange={organizationChange}
              joinOrganization={DesktopNav.createNavLinks(joinOrganization)}
              signOut={DesktopNav.createNavLinks(signOut)}
              hoverCheck={this.hoverCheck}
            />
          ) : null}
        </AuthenticatedContainer>
      )) || (
        <AuthenticatedContainer authenticated={authenticated}>
          {DesktopNav.createNavLinks(joinLink)}
        </AuthenticatedContainer>
      )
    );
  }

  renderNotificationsContent() {
    const { authenticated, notifications, noNotificationsMsg, isTouchDevice } = this.props;
    const { notificationsOpen } = this.state;

    return (
      (authenticated && (
        <NotificationsContainer
          ref={this.notificationRef}
          onMouseEnter={!isTouchDevice ? this.notificationsMouseEnter : () => {}}
          onMouseLeave={!isTouchDevice ? this.notificationsMouseLeave : () => {}}
        >
          <NavLink
            onClick={
              !isTouchDevice
                ? e => {
                    e.preventDefault();
                  }
                : e => {
                    this.handleNotificationClick(e);
                  }
            }
          >
            <SVGIcon selectedIcon="notifications" height="20px" width="20px" backgroundColor={colors.colorCobalt} />
          </NavLink>
          {notificationsOpen && (
            <NotificationsFlyout>{notifications.length > 0 ? notifications : noNotificationsMsg}</NotificationsFlyout>
          )}
        </NotificationsContainer>
      )) ||
      null
    );
  }

  render() {
    const { authenticated, logo, search, suppressNotifications, mainLinks, signIn, logoSelectedIcon } = this.props;
    return (
      <DesktopWrapper>
        <HomeLinkContainer>
          <Logo
            href={(logo && logo.href) || '#'}
            onClick={(logo && logo.onClick) || null}
            className="lodgelink-logo"
            selectedIcon={logoSelectedIcon}
          />
        </HomeLinkContainer>
        <SearchContainer>{search}</SearchContainer>
        <LinkListContainer>
          {authenticated && <EnvBanner />}
          <LinkList links={DesktopNav.createNavLinks(mainLinks)} type="inline" />
          {!authenticated && <SignInContainer>{DesktopNav.createNavLinks(signIn)}</SignInContainer>}
        </LinkListContainer>
        {!suppressNotifications && this.renderNotificationsContent()}
        {this.renderUserLoggedInOut()}
      </DesktopWrapper>
    );
  }
}

export default DesktopNav;
