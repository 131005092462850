import gql from 'graphql-tag';

export const QUERY_SEARCH_TERM_INPUT = gql`
  query QUERY_SEARCH_TERM_INPUT($input: String!) {
    suggestedPattern(inputString: $input) {
      suggestedPatterns {
        locationType
        patternName
        patternDescription
      }
      suggestedLocations {
        locationText
        locationValue
        locationType
      }
    }
  }
`;

export const QUERY_SEARCH_CRITERIA = gql`
  query QUERY_SEARCH_CRITERIA {
    searchCriteria @client {
      lookupTerm
      roomCount
      startDate
      endDate
    }
  }
`;

export const MUTATION_UPDATE_AUTO_SUGGEST_TERM = gql`
  mutation UpdateAutoSuggestTerm($term: String!) {
    updateAutoSuggestTerm(term: $term) @client
  }
`;

export const MUTATION_UPDATE_SEARCH_LOCATION = gql`
  mutation UpdateSearchLocation($location: Object) {
    updateSearchLocation(location: $location) @client
  }
`;

export const MUTATION_UPDATE_TERM = gql`
  mutation UpdateTerm($term: String!) {
    updateSearchTerm(term: $term) @client
  }
`;

export const MUTATION_COMMIT_SEARCH_TERM = gql`
  mutation CommitSearchTerm {
    commitSearchTerm @client
  }
`;

export const MUTATION_UPDATE_ROOM_COUNT = gql`
  mutation UpdateRoomCount($count: String!) {
    updateRoomCount(count: $count) @client
  }
`;

export const MUTATION_UPDATE_DATE_CRITERIA = gql`
  mutation UpdateDateCriteria($endDate: String!, $startDate: String!) {
    updateDateCriteria(startDate: $startDate, endDate: $endDate) @client
  }
`;

export const MUTATION_RESET_SEARCH_VALUES = gql`
  mutation ResetSearchValues {
    resetSearchValues @client
  }
`;
