import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../css';

const spreadSrc = ({ src, ...rest }) => <div {...rest} />;
export const StyledAvatarWrapper = styled(spreadSrc)`
  border-radius: 50%;
  overflow: hidden;
  width: 63px;
  height: 63px;

  ${({ src }) =>
    !src
      ? `display: flex;
        justify-content: center;
        background-color: ${colors.colorWhite};
        border: 1px solid ${colors.colorBlue};`
      : ``};
`;

export const StyledInitials = styled.p`
  align-self: center;
  font-family: ${fonts.fontBold};
  color: ${colors.colorBlue};
  font-size: 1.4em;
  text-transform: uppercase;
`;
