import React, { Component } from 'react';
import PropType from 'prop-types';
import { StyledIconSizing, StyledIconContainer } from './SVGIcon.styles';

/** Icon imports */
import Airport from './icons/Airport';
import Amex from './icons/Amex';
import ArrowIcon from './icons/ArrowIcon';
import ArrowDouble from './icons/ArrowDouble';
import Australia from './icons/Australia';
import BookingChange from './icons/BookingChange';
import Canada from './icons/Canada';
import CardViewToggle from './icons/CardViewToggle';
import Checkmark from './icons/Checkmark';
import ClearSearch from './icons/ClearSearch';
import Close from './icons/Close';
import CloseModal from './icons/CloseModal';
import ClosePanel from './icons/ClosePanel';
import Construction from './icons/Construction';
import Copy from './icons/Copy';
import Crew from './icons/Crew';
import Crews from './icons/Crews';
import CrewSupervisor from './icons/CrewSupervisor';
import Directions from './icons/Directions';
import Download from './icons/Download';
import Facebook from './icons/Facebook';
import Facitilies from './icons/Facilities';
import Favourite from './icons/Favourite';
import FuelStation from './icons/FuelStation';
import Government from './icons/Government';
import Hamburger from './icons/Hamburger';
import HotelIcon from './icons/HotelIcon';
import Info from './icons/Info';
import Information from './icons/Information';
import LinkedIn from './icons/LinkedIn';
import LodgeLinkLogoColor from './icons/LodgeLinkLogoColor';
import LodegLinkLogoWhite from './icons/LodgeLinkLogoWhite';
import LodegLinkLogoDemo from './icons/LodgeLinkLogoDemo';
import MapGrid from './icons/MapGrid';
import MapLayers from './icons/MapLayers';
import Mastercard from './icons/Mastercard';
import Money from './icons/Money';
import NightShiftIcon from './icons/NightShift';
import Notifications from './icons/Notifications';
import OrganizationSettings from './icons/OrganizationSettings';
import OilGas from './icons/OilGas';
import OpenPanel from './icons/OpenPanel';
import Paperwork from './icons/Paperwork';
import Padlock from './icons/Padlock';
import Phone from './icons/Phone';
import Pencil from './icons/Pencil';
import Pin from './icons/Pin';
import Projects from './icons/Projects';
import Radio from './icons/Radio';
import Reporting from './icons/Reporting';
import DndIcon from './icons/DndIcon';
import Schools from './icons/Schools';
import SearchArrow from './icons/SearchArrow';
import SearchIcon from './icons/SearchIcon';
import Settings from './icons/Settings';
import Share from './icons/Share';
import SharedRoom from './icons/SharedRoom';
import Star from './icons/Star';
import TableViewToggle from './icons/TableViewToggle';
import Transportation from './icons/Transportation';
import Twitter from './icons/Twitter';
import USA from './icons/USA';
import Utilities from './icons/Utilities';
import UWIIcon from './icons/UWIIcon';
import Visa from './icons/Visa';
import Warning from './icons/Warning';
import ColoredWarning from './icons/ColoredWarning';
import YouTube from './icons/YouTube';
import Instagram from './icons/Instagram';
import ZoomInIcon from './icons/ZoomIn';
import ZoomOutIcon from './icons/ZoomOut';

/** Amenity Icon imports */
import Bar from './amenity-icons/Bar';
import Washing from './amenity-icons/Washing';
import RoomService from './amenity-icons/RoomService';
import Fridge from './amenity-icons/Fridge';
import Tv from './amenity-icons/Tv';
import Internet from './amenity-icons/Internet';
import Air from './amenity-icons/Air';
import Cafe from './amenity-icons/Cafe';
import Parking from './amenity-icons/Parking';
import BagLunch from './amenity-icons/BagLunch';
import VendingMachine from './amenity-icons/VendingMachine';
import Medic from './amenity-icons/Medic';
import PoweredParking from './amenity-icons/PoweredParking';
import ConvenienceStore from './amenity-icons/ConvenienceStore';
import GamesRoom from './amenity-icons/GamesRoom';
import TwoOccupant from './amenity-icons/TwoOccupant';
import AfterHourMeal from './amenity-icons/AfterHourMeal';
import LargeParking from './amenity-icons/LargeParking';
import BagStorage from './amenity-icons/BagStorage';
import PrivateWashroom from './amenity-icons/PrivateWashroom';
import Restaurant from './amenity-icons/Restaurant';
import Security from './amenity-icons/Security';
import Salon from './amenity-icons/Salon';
import Fitness from './amenity-icons/Fitness';
import Chiropractor from './amenity-icons/Chiropractor';
import Massage from './amenity-icons/Massage';
import Breakfast from './amenity-icons/Breakfast';
import MeetingRoom from './amenity-icons/MeetingRoom';
import ArcticHalls from './amenity-icons/ArcticHalls';
import ATM from './amenity-icons/ATM';
import Dinner from './amenity-icons/Dinner';
import Pool from './amenity-icons/Pool';
import SharedWashroom from './amenity-icons/SharedWashroom';
import Kitchenette from './amenity-icons/Kitchenette';
import RotateLeft from './icons/RotateLeft';
import RotateRight from './icons/RotateRight';
import PetFriendly from './amenity-icons/PetFriendly';

/** Add SVG Icons here */
const iconIndex = props => ({
  amex: <Amex {...props} />,
  arrow: <ArrowIcon {...props} />,
  arrowDouble: <ArrowDouble {...props} />,
  australia: <Australia {...props} />,
  'booking-change': <BookingChange {...props} />,
  canada: <Canada {...props} />,
  cardViewToggle: <CardViewToggle {...props} />,
  checkmark: <Checkmark {...props} />,
  copy: <Copy {...props} />,
  close: <Close {...props} />,
  closeModal: <CloseModal {...props} />,
  closePanel: <ClosePanel {...props} />,
  'clear-search': <ClearSearch {...props} />,
  construction: <Construction {...props} />,
  crew: <Crew {...props} />,
  crews: <Crews {...props} />,
  crewSupervisor: <CrewSupervisor {...props} />,
  directions: <Directions {...props} />,

  download: <Download {...props} />,
  facebook: <Facebook {...props} />,
  favourite: <Favourite {...props} />,
  facilities: <Facitilies {...props} />,
  government: <Government {...props} />,
  hamburger: <Hamburger {...props} />,
  hotel: <HotelIcon {...props} />,
  info: <Info {...props} />,
  information: <Information {...props} />,
  linkedin: <LinkedIn {...props} />,
  lodgelinkcolor: <LodgeLinkLogoColor {...props} />,
  lodgelinkwhite: <LodegLinkLogoWhite {...props} />,
  lodgelinkdemo: <LodegLinkLogoDemo {...props} />,
  dndIcon: <DndIcon />,
  'map-grid': <MapGrid {...props} />,
  mastercard: <Mastercard {...props} />,
  money: <Money {...props} />,
  nightShift: <NightShiftIcon {...props} />,
  notifications: <Notifications {...props} />,
  'organization-settings': <OrganizationSettings {...props} />,
  oilgas: <OilGas {...props} />,
  openPanel: <OpenPanel {...props} />,
  paperwork: <Paperwork {...props} />,
  padlock: <Padlock {...props} />,
  pencil: <Pencil {...props} />,
  pin: <Pin {...props} />,
  phone: <Phone {...props} />,
  projects: <Projects {...props} />,
  'map-layers': <MapLayers {...props} />,
  radio: <Radio {...props} />,
  reporting: <Reporting {...props} />,
  rotateLeft: <RotateLeft {...props} />,
  rotateRight: <RotateRight {...props} />,
  airport: <Airport {...props} />,
  fuel: <FuelStation {...props} />,
  search: <SearchIcon {...props} />,
  searchArrow: <SearchArrow {...props} />,
  share: <Share {...props} />,
  settings: <Settings {...props} />,
  schools: <Schools {...props} />,
  sharedRoom: <SharedRoom {...props} />,
  star: <Star {...props} />,
  tableViewToggle: <TableViewToggle {...props} />,
  transportation: <Transportation {...props} />,
  twitter: <Twitter {...props} />,
  usa: <USA {...props} />,
  utilities: <Utilities {...props} />,
  uwi: <UWIIcon {...props} />,
  visa: <Visa {...props} />,
  youtube: <YouTube {...props} />,
  warning: <Warning {...props} />,
  coloredWarning: <ColoredWarning {...props} />,
  instagram: <Instagram {...props} />,
  zoomIn: <ZoomInIcon {...props} />,
  zoomOut: <ZoomOutIcon {...props} />
});

/** Add SVG Icons here */
const amenityIconIndex = props => ({
  bar: <Bar {...props} />,
  washing: <Washing {...props} />,
  roomservice: <RoomService {...props} />,
  fridge: <Fridge {...props} />,
  tv: <Tv {...props} />,
  internet: <Internet {...props} />,
  air: <Air {...props} />,
  cafe: <Cafe {...props} />,
  parking: <Parking {...props} />,
  baglunch: <BagLunch {...props} />,
  vendingmachine: <VendingMachine {...props} />,
  medic: <Medic {...props} />,
  poweredparking: <PoweredParking {...props} />,
  conveniencestore: <ConvenienceStore {...props} />,
  gamesroom: <GamesRoom {...props} />,
  twooccupant: <TwoOccupant {...props} />,
  afterhourmeal: <AfterHourMeal {...props} />,
  largeparking: <LargeParking {...props} />,
  bagstorage: <BagStorage {...props} />,
  privatewashroom: <PrivateWashroom {...props} />,
  restaurant: <Restaurant {...props} />,
  '24hsecurity': <Security {...props} />,
  salon: <Salon {...props} />,
  fitness: <Fitness {...props} />,
  chiropractor: <Chiropractor {...props} />,
  massage: <Massage {...props} />,
  breakfast: <Breakfast {...props} />,
  meetingroom: <MeetingRoom {...props} />,
  arctichalls: <ArcticHalls {...props} />,
  atm: <ATM {...props} />,
  dinner: <Dinner {...props} />,
  pool: <Pool {...props} />,
  sharedwashroom: <SharedWashroom {...props} />,
  kitchenette: <Kitchenette {...props} />,
  petfriendly: <PetFriendly {...props} />
});

export const iconIndexKeys = Object.keys(iconIndex(null));
export const amenityIconIndexKeys = Object.keys(amenityIconIndex(null));

export default class SVGIcon extends Component {
  static propTypes = {
    /**
     * The Selected SVG Icon
     */
    selectedIcon: PropType.string.isRequired,

    /**
     * Selected background color, all SVGs have a default color related to their branding
     */
    backgroundColor: PropType.string,

    /**
     * The height of the Icon if different from the default - including unit (px, % etc.)
     */
    height: PropType.string,

    /**
     * The width of the Icon if different from the default - including unit (px, % etc.)
     */
    width: PropType.string,

    /**
     * The Icons color, most default to white
     */
    iconColor: PropType.string,

    /**
     * Rotation of the Icon, passed as a number between 0-360
     */
    rotate: PropType.number,

    /**
     * Option to take in a click function on the SVG
     */
    onClick: PropType.func,

    /**
     * Add ability to override in .styles.js file
     */
    className: PropType.string,

    style: PropType.objectOf({})
  };

  static defaultProps = {
    backgroundColor: undefined,
    height: undefined,
    width: undefined,
    iconColor: undefined,
    rotate: 0,
    onClick: undefined,
    className: null,
    style: null
  };

  render() {
    const { selectedIcon, height, width, rotate, className, onClick, style, useFlex, ...props } = this.props;
    const icon = iconIndex(props)[selectedIcon] || amenityIconIndex(props)[selectedIcon];

    let iconWidth;
    let iconHeight;
    let iconPadding;

    if (icon) {
      iconPadding =
        (icon.props.width && icon.props.height) !== undefined ? (icon.props.height / icon.props.width) * 100 : 100;
      iconWidth = icon.props.width !== undefined ? `${icon.props.width}px` : '';
      iconHeight = icon.props.height !== undefined ? `${icon.props.height}px` : '';
    }

    // overrides the icon padding based on the passed props for height and width if both are passed.
    if (height && width) iconPadding = (height.replace('px', '') / width.replace('px', '')) * 100;

    // overrides the height to auto if a percent is passed to the width
    const heightAuto = width ? width.indexOf('%') > -1 : false;

    return (
      <StyledIconSizing
        width={!width ? `${iconWidth}` : width}
        height={!height ? `${iconHeight}` : height}
        className={className}
        heightAuto={heightAuto}
        onClick={onClick}
        useFlex={useFlex}
      >
        <StyledIconContainer style={{ ...style }} rotate={rotate} iconPadding={iconPadding}>
          {icon || null}
        </StyledIconContainer>
      </StyledIconSizing>
    );
  }
}
