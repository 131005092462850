/**
 * Helper class for fetching feature flags in local storage and return boolean value if featureIdentifier is found
 */

export default class featureFlagLocalStorageHelper {
  static checkFeatureFlagsInLocalStorage(featureIdentifier, permission) {
    // check if localStorage is not undefined
    if (typeof localStorage === 'undefined') {
      return false;
    }

    const checkLocalStorage = JSON.parse(localStorage.getItem('organizationFeatureFlag')) || [];
    const userHasPermission = checkLocalStorage.length
      ? !!permission && checkLocalStorage[0].includes(permission)
      : false;
    const isFeatureFlagged =
      (checkLocalStorage.find(
        feature =>
          feature.featureIdentifier === featureIdentifier && (feature.featureFlagActiveOrg && feature.featureFlagActive)
      ) &&
        userHasPermission) ||
      false;
    return isFeatureFlagged;
  }
}
