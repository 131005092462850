const ROUTE_NAMES = {
  Home: 'Home',
  Suppliers: 'Suppliers',
  WhyLodgeLink: 'WhyLodgeLink',
  HelpAndSupport: 'HelpAndSupport',
  ContactLodgeLink: 'ContactLodgeLink',
  AboutUs: 'AboutUs',
  Partnerships: 'Partnerships',
  PrivacyPolicy: 'PrivacyPolicy',
  TermsOfService: 'TermsOfService',
  Map: 'Map',
  PropertyDetails: 'PropertyDetails',
  InitiateBooking: 'InitiateBooking',
  BookingPayment: 'BookingPayment',
  BookingSummary: 'BookingSummary',
  BookingConfirmation: 'BookingConfirmation',
  SignUp: 'SignUp',
  CreateProfile: 'CreateProfile',
  ProfileCreated: 'ProfileCreated',
  OnboardingOptions: 'OnboardingOptions',
  OnboardingOptionsPublic: 'OnboardingOptionsPublic',
  CreateOrganization: 'CreateOrganization',
  InitiateCreditApplication: 'InitiateCreditApplication',
  EditCreditApplication: 'EditCreditApplication',
  ReviewCreditApplication: 'ReviewCreditApplication',
  SignIn: 'SignIn',
  ForgotPassword: 'ForgotPassword',
  ResetPassword: 'ResetPassword',
  ChangePassword: 'ChangePassword',
  AccountSettings: 'AccountSettings',
  Token: 'Token',
  ResolveActiveOrganization: 'ResolveActiveOrganization',
  Dashboard: 'Dashboard',
  CrewList: 'CrewList',
  PaymentCodes: 'PaymentCodes',
  CreatePaymentCode: 'CreatePaymentCode',
  EditPaymentCode: 'EditPaymentCode',
  ManagePaymentCodeAccess: 'ManagePaymentCodeAccess',
  CostTrackingCodes: 'CostTrackingCodes',
  CreateCostTrackingCode: 'CreateCostTrackingCode',
  EditCostTrackingCode: 'EditCostTrackingCode',
  Organization: 'Organization',
  // Paginated bookings routes
  OrgBookings: 'OrgBookings',
  AttendedBookings: 'AttendedBookings',
  ContractorBookings: 'ContractorBookings',
  // Un paginated Bookings routes
  OrganizationBookings: 'OrganizationBookings',
  BookingsImAttending: 'BookingsImAttending',
  BookingDetails: 'BookingDetails',
  OrganizationInvite: 'OrganizationInvite',
  OrganizationSettings: 'OrganizationSettings',
  Reporting: 'Reporting',
  RoomNights: 'RoomNights',
  Spend: 'Spend',
  CrewManagement: 'CrewManagement'
};

export default ROUTE_NAMES;
