export default {
  LocationTypes: {
    LOCATION: 'Location',
    PROPERTY: 'Property',
    UWI: 'UWI',
    LSD: 'LSD',
    NTS: 'NTS',
    LATLNG: 'LatLng',
    ZIPORPOSTALCODE: 'ZipOrPostalCode'
  },
  ResultsTypes: {
    PATTERNS: 'PATTERNS',
    LOCATIONS: 'LOCATIONS'
  }
};
