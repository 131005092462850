import get from 'lodash.get';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { intlShape, injectIntl } from 'react-intl';

import withAnalytics, { withAnalyticsPropType } from '../../../hocs/withAnalytics';
import withAuth, { withAuthPropType, ORG_PERMISSIONS } from '../../../hocs/withAuth';
import withRouting, { withRoutingPropType } from '../../../hocs/withRouting';
import { checkForEditedBooking } from '../../../helpers/navigationHelpers';
import {
  JOIN_LINK,
  LOGO_LINK,
  MAIN_LINKS,
  ORGANIZATION_SETTINGS_LINK,
  SIGN_IN_LINK,
  SUB_MENU_LINKS,
  SUB_MENU_LINKS_PAGINATED
} from './config';
import messages from './messages';
import lodgeLinkMessages from '../../../constants/messages/lodgeLink';
import ORGANIZATION_STATUS from '../../../constants/enums/OrganizationStatus';
import Redirect from '../../../components/Redirect';
import featureFlagLocalStorageHelper from '../../../helpers/featureFlagLocalStorageHelper';

import FeatureFlagEnum from '../../../constants/enums/FeatureFlagIdentifier';
import ROUTE_NAMES from '../../../constants/enums/RouteNames';

/**
 * The `MainNavContainer` is responsible for sourcing everything that needs to be displayed
 * in the navigation and handling it's authenticated user functionality.
 *
 * @class MainNavContainer
 * @extends {Component}
 */
class MainNavContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subLinks: SUB_MENU_LINKS
    };

    // Lots of the "things" being passed to the nav will never change after the component
    // is first mounted. To keep things performing well (not running on every render call)
    // this work is done in the constructor once and then returned in the render call.
    this.joinLink = this.buildLink(JOIN_LINK);
    this.logo = this.buildLink(LOGO_LINK);
    this.mainLinks = this.buildLinks(MAIN_LINKS);
    this.signIn = this.buildLink(SIGN_IN_LINK);
    this.signOut = this.buildSignOut();

    // Binding event handlers
    this.contactClick = this.contactClick.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
  }

  componentDidMount() {
    this.prefetchRoutes();
    const paginated = featureFlagLocalStorageHelper.checkFeatureFlagsInLocalStorage(
      FeatureFlagEnum.FLAG_PAG_BOOKINGS,
      ORG_PERMISSIONS.ADM_FLAG_PAG_BOOKINGS
    );

    const crewManagementFeatureFlag = featureFlagLocalStorageHelper.checkFeatureFlagsInLocalStorage(
      FeatureFlagEnum.FLAG_CREW_MGMT,
      ORG_PERMISSIONS.ADM_FLAG_CREW_MGMT
    );
    this.setState({
      subLinks: paginated ? SUB_MENU_LINKS_PAGINATED : SUB_MENU_LINKS,
      crewManagementFeatureFlag
    });
  }

  /**
   * Get a list of organizations the user belongs to.
   *
   * @returns {Object[]} Array of objects containing organization id and names.
   * @memberof MainNavContainer
   */
  getOrganizations() {
    const { auth } = this.props;

    const isLodgeLinkAdmin = get(auth, 'me.admin', false);
    const organizations = get(auth, 'me.organizations', [])
      // filter only active organizations, show all for LLAdmin
      .filter(({ status }) => isLodgeLinkAdmin || status === ORGANIZATION_STATUS.Active)
      // Convert to object shape required by the organization selector
      .map(organization => ({
        id: organization.id,
        name: organization.name,
        slug: organization.slug
      }));

    return organizations;
  }

  /**
   * Check to see if the user currently has all of the permissions that have been passed
   * in on their active organization.
   *
   * @param {string[]} requiredPermissions Set of required permissions from the OrganizationPermission enum.
   * @returns {boolean} True if the user has all permissions false if not.
   * @memberof MainNavContainer
   */
  hasRequiredPermissions(requiredPermissions = []) {
    const { auth } = this.props;

    const activeOrganizationPermissions = get(auth, 'me.activeOrganization.permissions', []);

    return requiredPermissions.every(permission => activeOrganizationPermissions.includes(permission));
  }

  /**
   * This function is called when a user clicks on the phone icon
   * in the mobile nav.
   *
   * @memberof MainNavContainer
   */
  contactClick() {
    const { analytics, intl } = this.props;

    analytics.trackEvent('global-nav-click', {
      clickID: 'contact',
      destinationUrl: intl.formatMessage(lodgeLinkMessages.phoneNumber)
    });
  }

  /**
   * Because the Link component is not being used we need to implement our own pre-fetching.
   *
   * This function pre-fetches some of the routes the user is likely to hit next.
   *
   * @returns {Promise} Resolves when all routes have been pre-fetched.
   * @memberof MainNavContainer
   */
  prefetchRoutes() {
    const {
      router: { prefetchRoute }
    } = this.props;
    // Build a list of links to prefetch
    const hrefToPrefetch = [
      this.joinLink.href,
      this.logo.href,
      this.signIn.href,
      ...this.mainLinks.map(link => link.href)
    ];

    // Prefetch all of the links
    return Promise.all(hrefToPrefetch.map(href => prefetchRoute(href)));
  }

  /**
   * Builds a link object from the passed in configuration object.
   *
   * @param {Object} linkConfig Link configuration object.
   * @returns {Object} Link object.
   * @memberof MainNavContainer
   */
  buildLink(linkConfig) {
    const { analytics, intl, auth, router } = this.props;

    // Checks to see if the user has the required permissions to display this link
    if (!this.hasRequiredPermissions(linkConfig.permissions)) {
      return;
    }

    // Auto appending of active organization slug
    if (linkConfig.paramsAppendOrganizationSlug) {
      linkConfig.params.organizationSlug = get(auth, 'me.activeOrganization.slug', 'placeholder');
    }

    // Localize link label
    const label = intl.formatMessage(linkConfig.label);

    // Generate link href
    const href = router.getRouteUrl(linkConfig.route, linkConfig.params);

    // Generate a unique id for the link
    const id = `${href}|${label}`;

    // Generate a click handler for the link
    const onClick = (event, routeChanged) => {
      // Prevent a hard page reload when link is clicked
      event.preventDefault();

      // if we are in the middle of editing a booking block navigation
      checkForEditedBooking(() => {
        // Route to the the page
        router.pushRouteAndScroll(href).then(() => {
          // Closes menu after navigating to the new route
          if (typeof routeChanged === 'function') {
            routeChanged();
          }
        });
      }, intl);

      // Track click of nav item
      analytics.trackEvent(linkConfig.analytics.eventName, {
        clickID: linkConfig.analytics.clickID,
        destinationUrl: href
      });
    };

    /* eslint-disable-next-line consistent-return */
    return {
      id,
      href,
      label,
      onClick
    };
  }

  /**
   * Build an array of link objects from an array of link configuration objects.
   *
   * @param {Object[]} linkConfigs Array of link configuration objects.
   * @returns {Object[]} Array of link objects.
   * @memberof MainNavContainer
   */
  buildLinks(linkConfigs) {
    const { crewManagementFeatureFlag } = this.state;
    return linkConfigs
      .map(linkConfig => {
        if (crewManagementFeatureFlag && linkConfig.route === ROUTE_NAMES.CrewList) {
          linkConfig.route = ROUTE_NAMES.CrewManagement;
          linkConfig.label = messages.linkCrewManagement;
        }
        return this.buildLink(linkConfig); // Filters out the "undefined" permission trimmed items
      })
      .filter(Boolean);
  }

  /**
   * Build a link for the Sign Out button.
   *
   * @returns {Object}
   * @memberof MainNavContainer
   */
  buildSignOut() {
    const { analytics, auth, intl } = this.props;

    // Localize link label
    const label = intl.formatMessage(messages.linkSignOut);

    // Generate link href
    const href = '#';

    // Generate a unique id for the link
    const id = `${href}|${label}`;

    // Generate a click handler for sign out
    const onClick = event => {
      event.preventDefault();

      // Signs out the user using HOC
      auth.logout();

      // Track click of sign out
      analytics.trackEvent('sign-out');
    };

    return {
      id,
      href,
      label,
      onClick
    };
  }

  /**
   * This function is called when the user selects an organization from their
   * list of organizations.
   *
   * @param {Object} organization Organization object containing id and name.
   * @memberof MainNavContainer
   */
  organizationChange(organization) {
    const { analytics, auth } = this.props;

    // Call mutation to change organization
    // There is nothing we can really do on the frontend if this fails so there is no error handling
    auth.changeOrganization({
      organization
    });

    // Track changing of organization
    analytics.trackEvent('profile-org-change', {
      clickID: 'organization-change',
      organizationID: organization.id,
      organizationName: organization.name
    });
  }

  /**
   * Generates the mainNav object that is passed out of this container.
   *
   * @returns {Object}
   * @memberof MainNavContainer
   */
  buildMainNav() {
    const { auth, intl } = this.props;
    const { subLinks } = this.state;

    // Pull related configuration variables from the runtime config
    const {
      publicRuntimeConfig: { DEMO_SITE }
    } = getConfig();

    const authenticated = get(auth, 'authenticated', false);
    const mainLinksWithoutMap = this.mainLinks.filter(({ label }) => label !== 'Map');
    return {
      authenticated,
      contactNumber: intl.formatMessage(lodgeLinkMessages.phoneNumber),
      joinLink: this.joinLink,
      logo: this.logo,
      mainLinks: authenticated ? this.mainLinks : mainLinksWithoutMap,
      onContactClick: this.contactClick,
      organizations: this.getOrganizations(),
      organizationChange: this.organizationChange,
      organizationSettings: this.buildLink(ORGANIZATION_SETTINGS_LINK),
      signIn: this.signIn,
      signOut: this.signOut,
      subMenuLinks: this.buildLinks(subLinks),
      userCurrentOrganization: get(auth, 'me.activeOrganization.id'),
      userFirstName: get(auth, 'me.firstName'),
      userLastName: get(auth, 'me.lastName'),
      isDemoSite: DEMO_SITE
    };
  }

  render() {
    const { children, auth } = this.props;
    const { authenticated, associatedWithAnOrganization } = auth ?? {};

    if (authenticated === true && associatedWithAnOrganization === false) {
      return <Redirect route="ProfileCreated" method="replace" />;
    }

    const renderProps = {
      mainNav: this.buildMainNav()
    };

    return children(renderProps);
  }
}

MainNavContainer.propTypes = {
  analytics: withAnalyticsPropType.isRequired,
  auth: withAuthPropType.isRequired,
  children: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  router: withRoutingPropType.isRequired
};

export default compose(
  injectIntl,
  withRouting,
  withAuth(),
  withAnalytics()
)(MainNavContainer);
