import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../BuildingBlocks/Button';
import SearchField from '../../Search/SearchField/SearchField';
import { SearchContainer } from '../../Search/Search.styles';
import { ButtonContainer, FlyoutContainer, StyledFlyout } from './NavSearchMobile.styles';

import { locationsShape, patternsShape } from '../../Search/Search.shapes';
import { defaultResults } from '../../Search/Search.seedData';

export default class NavSearchMobile extends Component {
  static propTypes = {
    /**
     * Boolean prop to turn on autocomplete
     * Defaults to `false`
     */
    autocomplete: PropTypes.bool,

    /** Override default focused border width */
    borderFocusedWidth: PropTypes.string,

    /** Override default border width */
    borderWidth: PropTypes.string,

    /**
     * Method to clear the value controlled by parent container.
     * This must be sent down from the parent container.
     * Used by the clear search button
     */
    clearValue: PropTypes.func,

    /**
     * Height override for Search component
     */
    height: PropTypes.number,

    /**
     * Represents the id HTML attribute to be passed to the SearchField.
     */
    id: PropTypes.string,

    /**
     * Method to hook into closeFlyout method for extra functionality
     */
    onFlyoutCloseHook: PropTypes.func,

    /**
     * Override for max-height of the Flyout component
     */
    flyoutMaxHeight: PropTypes.string,

    /** position left setting for CSS */
    flyoutOffsetLeft: PropTypes.string,

    /** position top setting for CSS */
    flyoutOffsetTop: PropTypes.string,

    /**
     * Accepts JSX/Components to display on the SearchField. Styling is the responsibility
     * of the prop-passer.
     */
    icon: PropTypes.node,

    /**
     * Label that displays when the input is blurred & empty and shrinks/animates
     * on focus.
     */
    labelText: PropTypes.string,

    /**
     * Represents the name HTML attribute to be passed to SearchField.
     * Additionally passed to the label as a for attribute.
     */
    name: PropTypes.string,

    /**
     * Callback for the onBlur handlers inside the SearchField component.
     */
    onBlurHook: PropTypes.func,

    /**
     * onChangeHook handler passed down to the input itself to callback to the parent
     * when a user changes the input. Use this to ensure your parent container's value
     * is kept in sync and passed down the tree to the input.
     */
    onChangeHook: PropTypes.func,

    onSubmitHook: PropTypes.func,

    /**
     * onFlyoutItemClickHook handler for clicking on a FlyoutItem, or, locations item.
     */
    onFlyoutItemClickHook: PropTypes.func,

    /**
     * Callback for the onFocus handlers inside the SearchField component.
     */
    onFocusHook: PropTypes.func,

    /**
     * Array of objects representing patterns or pattern suggets returned from the API.
     * These are passed down directly to the Flyout component.
     */
    patterns: patternsShape,

    /**
     * Placeholder string that displays while the input is focused AND
     * has no value.
     */
    placeholder: PropTypes.string,

    /**
     * Array of objects representing locations or result suggests returned from the API.
     * These are passed down directly to the Flyout component.
     */
    locations: locationsShape,

    /**
     * Localization label to be passed to Flyout component
     */
    searchFormatsLabel: PropTypes.string,

    /**
     * Ref to be passed down to SearchField -> StyledInput -> Input (@mui/core)
     */
    searchFieldRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     * String passed down the tree to the input itself to control the value of
     * the input. Ensure that you provide an onChangeHook handler to update the value
     * in your parent container.
     */
    searchValue: PropTypes.string,

    /** JSX Button to render at bottom of screen */
    submitButtonText: PropTypes.string,
    canSubmit: PropTypes.bool
  };

  static defaultProps = {
    autocomplete: false,
    borderFocusedWidth: '0',
    borderWidth: '0',
    clearValue: () => {},
    height: 20,
    icon: null,
    id: 'globalnav-mobile-search-input',
    flyoutMaxHeight: '100vh',
    flyoutOffsetLeft: undefined,
    flyoutOffsetTop: undefined,
    labelText: 'Enter a location',
    name: 'globalnav-mobile-search-input',
    onBlurHook: () => {},
    onChangeHook: () => {},
    onFlyoutItemClickHook: () => {},
    onFlyoutCloseHook: () => {},
    onFocusHook: () => {},
    onSubmitHook: () => {},
    patterns: defaultResults.locationsType,
    placeholder: 'Enter a location ',
    locations: [],
    searchFormatsLabel: 'Search Formats',
    searchFieldRef: null,
    searchValue: '',
    submitButtonText: 'Search',
    canSubmit: true
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      expandFlyout: false
    };

    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  componentWillUnmount() {
    /* eslint-disable no-undef */

    // Enable page scrolling when nav is unmounted
    document.body.classList.remove('no-scrolling');

    /* eslint-disable no-undef */
  }

  /**
   * Method that returns a boolean and runs up
   * the tree to see if we've changed focus
   * out of an entire container.
   *
   * This is used to ensure that if we change focus from the SearchField
   * to the Flyout component that we don't 'lose focus' and close
   * our Flyout pre-emptively.
   */
  focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
    /** If our target is null, back out quick */
    if (relatedTarget === null) return false;

    /** Start with our parentNode */
    let node = relatedTarget.parentNode;

    /**
     * While we still have a parentNode (not null), keep checking
     * If we find that our parentNode matches our actual target,
     * return true.
     *
     * If we go up the tree and never have a match, return false.
     */
    while (node !== null) {
      if (node === currentTarget) return true;
      node = node.parentNode;
    }

    return false;
  };

  /**
   * Internal onChangeHook handler
   */
  onChange = e => {
    const {
      target: { value }
    } = e;
    const { onChangeHook } = this.props;
    const { expandFlyout } = this.state;

    onChangeHook && onChangeHook(e);

    if (!expandFlyout && (value && value.length > 0)) {
      this.setState({ expandFlyout: true, focused: true });
    }
  };

  /**
   * Toggles our focused state to 'true' so we can tell the Flyout it's time to shine
   */
  onFocus = () => {
    const { onFocusHook } = this.props;

    /** Call onFocus override passed as a prop - we still handle our focused state internally */
    onFocusHook && onFocusHook();
    this.setState({ focused: true, expandFlyout: true });
    document.body.classList.add('no-scrolling');
  };

  /**
   * Toggles our focused state to 'false' so we can tell the Flyout to go away
   */
  onBlur = e => {
    const { onBlurHook } = this.props;

    /**
     * Check and make sure we've blurred our entire container (clicked out) / hit escape
     */
    if (!this.focusInCurrentTarget(e)) {
      this.setState({ focused: false });
      /** Call onBlur override passed as a prop - we still handle our focused state internally */
      onBlurHook && onBlurHook(e);
    }
  };

  /** Force close the Flyout, return focus to the input */
  closeFlyoutAndFocusInput = () => {
    const { onFlyoutCloseHook } = this.props;

    this.setState({ expandFlyout: false });
    document.body.classList.remove('no-scrolling');
    onFlyoutCloseHook && onFlyoutCloseHook();
  };

  closeFlyout = () => {
    const { onFlyoutCloseHook } = this.props;

    this.setState({ expandFlyout: false });
    document.body.classList.remove('no-scrolling');
    onFlyoutCloseHook && onFlyoutCloseHook();
  };

  handleSearchKeyDown = e => {
    const { key } = e;
    if (key === 'Escape') {
      /**
       * Preventing default here stops the ESC key from clearing the field
       * For some reason, this is the default behaviour for input[type=search]
       * Google's search fields don't do this and it seems annoying from a UX perspective
       */
      e.preventDefault();
    }
  };

  handleSearchKeyPress = e => {
    const { key } = e;
    const { onSubmitHook } = this.props;

    if (key === 'Enter') {
      this.closeFlyout();
      onSubmitHook && onSubmitHook();
    }
  };

  // We always want the flyout to close on click.
  handleSubmitClick() {
    const { onSubmitHook } = this.props;
    this.closeFlyout();
    onSubmitHook();
  }

  render() {
    const { focused, expandFlyout } = this.state;
    const {
      autocomplete,
      borderFocusedWidth,
      borderWidth,
      height,
      flexRatio,
      flyoutMaxHeight,
      flyoutOffsetTop,
      flyoutOffsetLeft,
      onFlyoutItemClickHook,
      onFlyoutCloseHook,
      onChangeHook,
      onBlurHook,
      onFocusHook,
      onSubmitHook,
      patterns,
      locations,
      searchFormatsLabel,
      searchFieldRef,
      searchValue,
      submitButtonText,
      canSubmit,
      ...props
    } = this.props;

    return (
      <>
        <SearchContainer height={height}>
          <SearchField
            autocomplete={autocomplete}
            borderWidth={borderWidth}
            borderFocusedWidth={borderFocusedWidth}
            value={searchValue}
            focused={focused}
            onKeyDown={this.handleSearchKeyDown}
            onKeyPress={this.handleSearchKeyPress}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            height={height}
            searchFieldRef={searchFieldRef}
            fontSizeOverride={14}
            onSubmitHook={onSubmitHook}
            {...props}
            submitButton={null}
            disableLabel
            noButton
            noLeftPadding
          />
        </SearchContainer>
        {expandFlyout && (
          <>
            <FlyoutContainer>
              <StyledFlyout
                id="globalnav-mobile-search-flyout"
                borderWidth="0"
                patterns={patterns}
                locations={locations}
                onFlyoutItemClickHook={onFlyoutItemClickHook}
                closeFlyout={this.closeFlyout}
                closeFlyoutAndFocusInput={this.closeFlyoutAndFocusInput}
                flyoutMaxHeight={flyoutMaxHeight}
                noShadow
                positionFixed
                offsetTop={flyoutOffsetTop}
                offsetLeft={flyoutOffsetLeft}
                searchFormatsLabel={searchFormatsLabel}
                disableFlyoutAnimation
              />
            </FlyoutContainer>
            <ButtonContainer className="flyout-search-btn">
              <Button
                theme="blue"
                fullWidth
                disabled={searchValue.length === 0 ? true : undefined}
                onClick={this.handleSubmitClick}
              >
                {submitButtonText}
              </Button>
            </ButtonContainer>
          </>
        )}
      </>
    );
  }
}
