// exclude-from-index
import React from 'react';
import PropTypes from 'prop-types';
import { StyledHeadingHero } from './HeadingHero.styles';
import { TAG_NAMES, FONT_COLOR, TEXT_ALIGN, HEADING_COLORS, TEXT_TRANSFORM } from '../Base/Base';

function HeadingHero({ tagName, color, children, align, noMargins, className, textTransform }) {
  return (
    <StyledHeadingHero
      tagName={tagName}
      color={HEADING_COLORS[color]}
      align={align}
      noMargins={noMargins}
      className={className}
      textTransform={textTransform}
    >
      {children}
    </StyledHeadingHero>
  );
}

HeadingHero.propTypes = {
  /** Font Color: primary, secondary, tertiary, quaternary */
  color: PropTypes.oneOf(FONT_COLOR),
  /** Element tag name: h1, h2, h3, h4, h5, h6, div, span, label, p, strong, li */
  tagName: PropTypes.oneOf(TAG_NAMES),
  /** Copy alignment: inherit, left, center, right, justify */
  align: PropTypes.oneOf(TEXT_ALIGN),
  /** Text Transform: none, capitalize, uppercase, lowercase, initial, inherit */
  textTransform: PropTypes.oneOf(TEXT_TRANSFORM),
  /** Copy/elements you want inside */
  children: PropTypes.node,
  /** Remove default margins */
  noMargins: PropTypes.bool,
  /** Allows component to be overwritten by StyledComponents */
  className: PropTypes.string
};

HeadingHero.defaultProps = {
  color: 'primary',
  tagName: 'div',
  align: 'left',
  textTransform: 'none',
  children: null,
  noMargins: false,
  className: ''
};

export default HeadingHero;
