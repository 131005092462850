import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class VendingMachine extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m19.5 1.25h-15v20h3v1.5h1v-1.5h7v1.49h1v-1.5h3zm-4.68 1v3.41h-3v-1.57h-1v1.57h-2.07v-1.57h-1v1.57h-2.22v-3.41zm0 4.41v4.16h-3v-1.58h-1v1.58h-2.07v-1.58h-1v1.58h-2.22v-4.16zm-9.29 10.59h9.29v2.94h-9.29zm9.29-1h-3v-1.58h-1v1.58h-2.07v-1.58h-1v1.58h-2.22v-4.43h9.29zm3.68 4h-2.68v-18h2.68z"
        />
        <path fill={iconColor} d="m16.52 4.5h1.25v1h-1.25z" />
        <path fill={iconColor} d="m16.52 6.5h1.25v1h-1.25z" />
      </svg>
    );
  }
}
