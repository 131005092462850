import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Info extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string,
    backgroundColor: PropTypes.string
  };

  static defaultProps = {
    height: 22,
    width: 22,
    iconColor: colors.colorBlue,
    backgroundColor: colors.colorWhite
  };

  render() {
    const { height, width, iconColor, backgroundColor } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="01--Invite-Employees" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="1.3-Info-Tip-1440" transform="translate(-1098.000000, -574.000000)">
            <g id="Email-dropdown" transform="translate(326.000000, 469.000000)">
              <g id="Info" transform="translate(750.000000, 105.000000)">
                <polygon
                  id="Triangle"
                  stroke={iconColor}
                  fill={backgroundColor}
                  points="33 0, 23 19, 43 19"
                  strokeLinejoin="round"
                />
                <rect id="Rectangle" fill={iconColor} x="32.65" y="5.5" width="1" height="7.5" rx="0.5" />
                <circle id="Oval-3" fill={iconColor} cx="33.25" cy="15.5" r="1" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
