// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../../css';
import {
  CardContainer,
  ProfilePic,
  ProfileInfo,
  AutoAvatar,
  TruncatedOrg,
  TruncatedName,
  StyledCarrot
} from './UserCard.styles';
import IgnoreTranslation from '../../../../Localize/ignoreTranslation';

class UserCard extends Component {
  static propTypes = {
    /**
     * String containing the text making up the users first name
     */
    userFirstName: PropTypes.string,
    /**
     * String containing the text making up the users last name
     */
    userLastName: PropTypes.string,
    /**
     * String containing matching ID for a organization being passed through in the organizations. Both this ID and organization need to be passed, otherwise something is wrong.
     */
    userCurrentOrganization: PropTypes.string,
    /**
     * [Optional] Web reference to an image to use in place of initials in the avatar spot.
     */
    userProfilePicture: PropTypes.string,
    /**
     * Accepts valid CSS cursor values to determing mouse icon on hover of the card.
     */
    cursor: PropTypes.string,
    /**
     * Bool that enables or disables the showing of the organization above the users name.
     */
    showOrg: PropTypes.bool,
    /**
     * Bool determining wether to show the carrot beside the org/user to indicate interactions.
     */
    showCarrot: PropTypes.bool
  };

  static defaultProps = {
    /** These comments will not be pulled into the Storybook info panels */
    userFirstName: undefined,
    userLastName: undefined,
    userProfilePicture: undefined,
    userCurrentOrganization: '',
    showOrg: true,
    showCarrot: true,
    cursor: 'initial'
  };

  render() {
    const {
      userProfilePicture,
      userFirstName,
      userLastName,
      userCurrentOrganization,
      cursor,
      showOrg,
      showCarrot
    } = this.props;

    let initials = '??';

    if (userFirstName && userLastName && userFirstName.length > 0 && userLastName.length > 0) {
      initials = `${userFirstName.slice(0, 1)}${userLastName.slice(0, 1)}`;
    } else if (userFirstName && !userLastName && userFirstName.length > 0) {
      initials = `${userFirstName.slice(0, 1)}`;
    } else if (userLastName && !userFirstName && userLastName.length > 0) {
      initials = `${userLastName.slice(0, 1)}`;
    }

    return (
      <CardContainer cursor={cursor}>
        <ProfilePic>
          <IgnoreTranslation inTextEdit>
            <AutoAvatar src={userProfilePicture} initials={initials} alt={`${userFirstName} ${userLastName}`} />
          </IgnoreTranslation>
        </ProfilePic>
        <ProfileInfo>
          <IgnoreTranslation inTextEdit>
            {showOrg && userCurrentOrganization ? <TruncatedOrg>{userCurrentOrganization}</TruncatedOrg> : null}
            <TruncatedName>{`${userFirstName && userFirstName.length > 0 ? userFirstName : ''} ${
              userLastName && userLastName.length > 0 ? userLastName.slice(0, 1) : ''
            } `}</TruncatedName>
            {showCarrot && (
              <StyledCarrot selectedIcon="arrow" rotate={0} width="16px" height="16px" iconColor={colors.colorGrey03} />
            )}
          </IgnoreTranslation>
        </ProfileInfo>
      </CardContainer>
    );
  }
}

export default UserCard;
