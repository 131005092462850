import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../BuildingBlocks/Text';

export default class FooterLabel extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: undefined
  };

  render() {
    const { children } = this.props;
    return (
      <Text.CopyParagraph noMargins weight="bold" color="quaternary" textTransform="uppercase">
        {children}
      </Text.CopyParagraph>
    );
  }
}
