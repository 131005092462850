import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Air extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m23 11.5h-2.79l2.14-2.15-.7-.7-2.86 2.85h-5.58l3.95-3.95h4.03v-1h-3.03l1.97-1.97-.7-.71-1.98 1.97v-3.03h-1v4.03l-3.95 3.95v-5.58l2.85-2.86-.7-.7-2.15 2.14v-2.79h-1v2.79l-2.15-2.14-.7.7 2.85 2.86v5.58l-3.95-3.95v-4.03h-1v3.03l-1.97-1.97-.71.71 1.97 1.97h-3.03v1h4.03l3.95 3.95h-5.58l-2.86-2.85-.7.7 2.14 2.15h-2.79v1h2.79l-2.14 2.15.7.7 2.86-2.85h5.58l-3.95 3.95h-4.03v1h3.03l-1.97 1.98.71.7 1.97-1.97v3.03h1v-4.03l3.95-3.95v5.58l-2.85 2.86.7.7 2.15-2.14v2.79h1v-2.79l2.15 2.14.7-.7-2.85-2.86v-5.58l3.95 3.95v4.03h1v-3.03l1.98 1.97.7-.7-1.97-1.98h3.03v-1h-4.03l-3.95-3.95h5.58l2.86 2.85.7-.7-2.14-2.15h2.79z"
        />
      </svg>
    );
  }
}
