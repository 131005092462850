export const navigation = {
  main: 97,
  flyout: 99,
  underlay: {
    over: 98,
    under: -1
  },
  notifications: 99
};
export const searchSuggestions = 100;
// MUI Modals are set to 1300 - we don't use this but it's nice to have as a reference
export const modal = 1300;

export const toasts = 1400;
