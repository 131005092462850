import { ORG_PERMISSIONS } from '../../../hocs/withAuth';
import messages from './messages';

export const JOIN_LINK = {
  route: 'OnboardingOptionsPublic',
  params: {},
  label: messages.linkJoin,
  analytics: {
    eventName: 'global-nav-click',
    clickID: 'join'
  }
};

export const LOGO_LINK = {
  route: 'Dashboard',
  params: {},
  label: messages.linkDashboard,
  analytics: {
    eventName: 'global-nav-click',
    clickID: 'logo'
  }
};

export const MAIN_LINKS = [
  {
    route: 'Map',
    params: {},
    label: messages.linkMap,
    analytics: {
      eventName: 'global-nav-click',
      clickID: 'map'
    }
  }
];

export const ORGANIZATION_SETTINGS_LINK = {
  route: 'OrganizationManage',
  params: {},
  paramsAppendOrganizationSlug: true,
  permissions: [ORG_PERMISSIONS.EDIT_ORGANIZATION],
  label: messages.linkOrganizationSettings,
  analytics: {
    eventName: 'account-click',
    clickID: 'organization-settings'
  }
};

export const SIGN_IN_LINK = {
  route: 'SignIn',
  params: {},
  label: messages.linkSignIn,
  analytics: {
    eventName: 'global-nav-click',
    clickID: 'sign-in'
  }
};

export const SUB_MENU_LINKS = [
  {
    route: 'Dashboard',
    params: {},
    permissions: [],
    label: messages.linkDashboard,
    analytics: {
      eventName: 'account-click',
      clickID: 'my-dashboard'
    }
  },
  {
    route: 'OrganizationBookings',
    params: { bookingStatusType: 'ActiveBookings' },
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_ACTIVE_BOOKINGS],
    label: messages.linkActiveBookings,
    analytics: {
      eventName: 'account-click',
      clickID: 'bookings'
    }
  },
  {
    route: 'OrganizationBookings',
    params: { bookingStatusType: 'PastBookings' },
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_PAST_BOOKINGS],
    label: messages.linkPastBookings,
    analytics: {
      eventName: 'account-click',
      clickID: 'bookings'
    }
  },
  {
    route: 'BookingsImAttending',
    params: {},
    permissions: [ORG_PERMISSIONS.VIEW_BOOKINGS_ATTENDING],
    label: messages.attendingBookings,
    analytics: {
      eventName: 'account-click',
      clickID: 'bookings'
    }
  },
  {
    route: 'GuestSchedule',
    params: {},
    permissions: [ORG_PERMISSIONS.VIEW_GUEST_BOOKINGS],
    paramsAppendOrganizationSlug: true,
    label: messages.linkGuestSchedule,
    analytics: {
      eventName: 'account-click',
      clickID: 'guest-schedule'
    }
  },
  {
    route: 'CrewList',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.EDIT_CREW],
    label: messages.linkCrewList,
    analytics: {
      eventName: 'account-click',
      clickID: 'crew-list'
    }
  },
  {
    route: 'PaymentCodes',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_PAYMENT_CODES],
    label: messages.linkBilling,
    analytics: {
      eventName: 'account-click',
      clickID: 'billing'
    }
  },
  {
    route: 'CostTrackingCodes',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_COST_TRACKING_CODES],
    label: messages.linkCostTrackingCodes,
    analytics: {
      eventName: 'account-click',
      clickID: 'tracking-codes'
    }
  },
  {
    route: 'AccountSettings',
    params: {},
    permissions: [],
    label: messages.linkAccountSettings,
    analytics: {
      eventName: 'account-click',
      clickID: 'account-settings'
    }
  },
  {
    route: 'ReportingV2',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_REPORTS],
    label: messages.linkReporting,
    analytics: {
      eventName: 'account-click',
      clickID: 'reporting'
    }
  }
];

export const SUB_MENU_LINKS_PAGINATED = [
  {
    route: 'Dashboard',
    params: {},
    permissions: [],
    label: messages.linkDashboard,
    analytics: {
      eventName: 'account-click',
      clickID: 'my-dashboard'
    }
  },
  {
    route: 'OrgBookings',
    params: { bookingStatusType: 'ActiveBookings' },
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_ACTIVE_BOOKINGS],
    label: messages.linkActiveBookings,
    analytics: {
      eventName: 'account-click',
      clickID: 'bookings'
    }
  },
  {
    route: 'OrgBookings',
    params: { bookingStatusType: 'PastBookings' },
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_PAST_BOOKINGS],
    label: messages.linkPastBookings,
    analytics: {
      eventName: 'account-click',
      clickID: 'bookings'
    }
  },
  {
    route: 'AttendedBookings',
    params: { bookingStatusType: 'AttendedBookings' },
    permissions: [ORG_PERMISSIONS.VIEW_BOOKINGS_ATTENDING],
    label: messages.attendingBookings,
    analytics: {
      eventName: 'account-click',
      clickID: 'bookings'
    }
  },
  {
    route: 'GuestSchedule',
    params: {},
    permissions: [ORG_PERMISSIONS.VIEW_GUEST_BOOKINGS],
    paramsAppendOrganizationSlug: true,
    label: messages.linkGuestSchedule,
    analytics: {
      eventName: 'account-click',
      clickID: 'guest-schedule'
    }
  },
  {
    route: 'CrewList',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.EDIT_CREW],
    label: messages.linkCrewList,
    analytics: {
      eventName: 'account-click',
      clickID: 'crew-list'
    }
  },
  {
    route: 'PaymentCodes',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_PAYMENT_CODES],
    label: messages.linkBilling,
    analytics: {
      eventName: 'account-click',
      clickID: 'billing'
    }
  },
  {
    route: 'CostTrackingCodes',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_COST_TRACKING_CODES],
    label: messages.linkCostTrackingCodes,
    analytics: {
      eventName: 'account-click',
      clickID: 'tracking-codes'
    }
  },
  {
    route: 'AccountSettings',
    params: {},
    permissions: [],
    label: messages.linkAccountSettings,
    analytics: {
      eventName: 'account-click',
      clickID: 'account-settings'
    }
  },
  {
    route: 'ReportingV2',
    params: {},
    paramsAppendOrganizationSlug: true,
    permissions: [ORG_PERMISSIONS.VIEW_REPORTS],
    label: messages.linkReporting,
    analytics: {
      eventName: 'account-click',
      clickID: 'reporting'
    }
  }
];
