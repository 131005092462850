const RoleName = {
  OrganizationAdmin: 'OrganizationAdmin',
  BookingManager: 'BookingManager',
  CustomerAdmin: 'CustomerAdmin',
  CustomerManager: 'CustomerManager',
  CrewMember: 'CrewMember',
  CrewBooker: 'CrewBooker',
  CrewSelfBooker: 'CrewSelfBooker'
};

export default RoleName;
