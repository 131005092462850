import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class UWIIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 22,
    height: 15,
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 22 15" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icon/Lodge/UWI/Grey" transform="translate(-5.000000, -9.000000)" fill={iconColor} fillRule="nonzero">
            <g id="Group-6" transform="translate(5.000000, 9.000000)">
              <path
                d="M7.8701,11.27 C7.7301,11.917 7.5031,12.48 7.1901,12.96 C6.8761,13.44 6.4601,13.817 5.9401,14.09 C5.4201,14.363 4.7801,14.5 4.0201,14.5 C3.2601,14.5 2.6231,14.363 2.1101,14.09 C1.5971,13.817 1.1831,13.44 0.8701,12.96 C0.5571,12.48 0.3331,11.917 0.2001,11.27 C0.0661,10.624 0.0001,9.934 0.0001,9.2 L0.0001,0 L1.4001,0 L1.4001,9.02 C1.4001,9.54 1.4361,10.054 1.5101,10.56 C1.5831,11.067 1.7171,11.517 1.9101,11.91 C2.1031,12.304 2.3701,12.62 2.7101,12.86 C3.0501,13.1 3.4931,13.22 4.0401,13.22 C4.5731,13.22 5.0131,13.1 5.3601,12.86 C5.7061,12.62 5.9761,12.304 6.1701,11.91 C6.3631,11.517 6.4961,11.07 6.5701,10.57 C6.6431,10.07 6.6801,9.554 6.6801,9.02 L6.6801,0 L8.0801,0 L8.0801,9.2 C8.0801,9.934 8.0101,10.624 7.8701,11.27"
                id="Fill-1"
              />
              <polygon id="Fill-3" points="10.16 14.16 11.56 14.16 11.56 0 10.16 0" />
              <path
                d="M20.5996,7.1001 C20.5996,6.0971 20.5026,5.2241 20.3096,4.4811 C20.1166,3.7391 19.8496,3.1271 19.5096,2.6451 C19.1696,2.1631 18.7696,1.8021 18.3096,1.5611 C17.8496,1.3211 17.3526,1.2001 16.8196,1.2001 L15.1796,1.2001 L15.1796,12.9601 L16.8196,12.9601 C17.3526,12.9601 17.8496,12.8401 18.3096,12.5991 C18.7696,12.3581 19.1696,11.9971 19.5096,11.5151 C19.8496,11.0341 20.1166,10.4251 20.3096,9.6891 C20.5026,8.9531 20.5996,8.0901 20.5996,7.1001 M21.9796,7.1001 C21.9796,8.2601 21.8596,9.2801 21.6196,10.1601 C21.3796,11.0401 21.0326,11.7771 20.5796,12.3701 C20.1266,12.9631 19.5826,13.4101 18.9496,13.7101 C18.3166,14.0101 17.6126,14.1601 16.8396,14.1601 L13.7996,14.1601 L13.7996,0.0001 L16.8596,0.0001 C17.6326,0.0001 18.3366,0.1501 18.9696,0.4501 C19.6026,0.7501 20.1426,1.2001 20.5896,1.8001 C21.0366,2.4001 21.3796,3.1431 21.6196,4.0301 C21.8596,4.9171 21.9796,5.9401 21.9796,7.1001"
                id="Fill-5"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
