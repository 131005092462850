import styled from 'styled-components';
import { media, mixins } from '../../../css';
import Text from '../../../BuildingBlocks/Text';

export const StyledPhoneNum = styled(Text.HeadingThird)`
  && {
    padding: ${mixins.pxToRem(15)} 0 ${mixins.pxToRem(15)} 0;
    display: inline-block;
    ${media.greaterThan('md')`
      padding-bottom: ${mixins.pxToRem(35)};
    `};
  }
`;

export const StyledPhoneNumberLink = styled.a`
  && {
    &:hover {
      text-decoration: none;
    }
  }
`;
