import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Crew extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/Filters/Crew/Black" transform="translate(0.000000, -4.000000)" fill={iconColor}>
            <g id="Group-19" transform="translate(0.000000, 4.000000)">
              <path
                d="M12.001,0 C9.516,0 7.501,2.015 7.501,4.5 C7.501,6.985 9.516,9 12.001,9 C14.486,9 16.501,6.985 16.501,4.5 C16.501,2.015 14.486,0 12.001,0 M12.001,1 C13.931,1 15.501,2.57 15.501,4.5 C15.501,6.43 13.931,8 12.001,8 C10.071,8 8.501,6.43 8.501,4.5 C8.501,2.57 10.071,1 12.001,1"
                id="Fill-1"
              />
              <path
                d="M20.001,6 C18.62,6 17.501,7.119 17.501,8.5 C17.501,9.881 18.62,11 20.001,11 C21.382,11 22.501,9.881 22.501,8.5 C22.501,7.119 21.382,6 20.001,6 M20.001,7 C20.828,7 21.501,7.673 21.501,8.5 C21.501,9.327 20.828,10 20.001,10 C19.174,10 18.501,9.327 18.501,8.5 C18.501,7.673 19.174,7 20.001,7"
                id="Fill-3"
              />
              <path
                d="M17.7481,12.1377 C16.2941,10.8217 14.2591,9.9997 12.0011,9.9997 C7.5831,9.9997 4.0011,13.1337 4.0011,16.9997 L5.0011,16.9997 C5.0011,13.6917 8.1421,10.9997 12.0011,10.9997 C13.9381,10.9997 15.6931,11.6787 16.9621,12.7727 C17.1941,12.5337 17.4511,12.3147 17.7481,12.1377"
                id="Fill-5"
              />
              <path
                d="M19.001,17 L20.001,17 C20.001,15.462 19.428,14.045 18.467,12.891 C18.155,13.045 17.883,13.244 17.656,13.479 C18.498,14.469 19.001,15.684 19.001,17"
                id="Fill-7"
              />
              <path
                d="M19.9825,11.5332 C19.1575,11.5332 18.3875,11.7572 17.7485,12.1372 C18.0075,12.3732 18.2475,12.6252 18.4665,12.8902 C18.9155,12.6692 19.4305,12.5332 19.9825,12.5332 C21.6075,12.5332 22.9675,13.6392 22.9675,15.0002 L23.9625,15.0002 C23.9625,13.0852 22.1605,11.5332 19.9825,11.5332"
                id="Fill-9"
              />
              <path
                d="M17.7481,12.1377 C17.4511,12.3147 17.1941,12.5337 16.9621,12.7727 C17.2161,12.9907 17.4441,13.2297 17.6561,13.4787 C17.8831,13.2437 18.1551,13.0447 18.4671,12.8907 C18.2471,12.6247 18.0081,12.3727 17.7481,12.1377"
                id="Fill-11"
              />
              <path
                d="M3.9288,6 C2.5478,6 1.4288,7.119 1.4288,8.5 C1.4288,9.881 2.5478,11 3.9288,11 C5.3098,11 6.4288,9.881 6.4288,8.5 C6.4288,7.119 5.3098,6 3.9288,6 M3.9288,7 C4.7558,7 5.4288,7.673 5.4288,8.5 C5.4288,9.327 4.7558,10 3.9288,10 C3.1018,10 2.4288,9.327 2.4288,8.5 C2.4288,7.673 3.1018,7 3.9288,7"
                id="Fill-13"
              />
              <path
                d="M3.9815,11.5332 C4.8055,11.5332 5.5755,11.7572 6.2145,12.1372 C5.9555,12.3732 5.7155,12.6252 5.4965,12.8902 C5.0475,12.6692 4.5335,12.5332 3.9815,12.5332 C2.3555,12.5332 0.9955,13.6392 0.9955,15.0002 L0.0005,15.0002 C0.0005,13.0852 1.8025,11.5332 3.9815,11.5332"
                id="Fill-15"
              />
              <path
                d="M6.2149,12.1377 C6.5119,12.3147 6.7689,12.5337 7.0009,12.7727 C6.7469,12.9907 6.5189,13.2297 6.3069,13.4787 C6.0799,13.2437 5.8079,13.0447 5.4959,12.8907 C5.7159,12.6247 5.9549,12.3727 6.2149,12.1377"
                id="Fill-17"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
