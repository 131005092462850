import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Internet extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 29,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <g fill={iconColor}>
          <path d="m12 4.5a15.39 15.39 0 0 0 -11 4.5l.71.71a14.49 14.49 0 0 1 20.5 0l.79-.71a15.39 15.39 0 0 0 -11-4.5z" />
          <path d="m4.29 12.29.71.71a9.92 9.92 0 0 1 14 0l.7-.7a10.89 10.89 0 0 0 -15.42 0z" />
          <path d="m7.76 15.76.7.7a5 5 0 0 1 7.08 0l.7-.7a6 6 0 0 0 -8.48 0z" />
          <circle cx="12" cy="19" r="1" />
        </g>
      </svg>
    );
  }
}
