import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Share extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 22,
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path id="a" d="M0 0h22.036v20H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill={iconColor}>
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M12.036 0v5c-13.317 0-12 15-12 15 5.549-6.658 12-7 12-7v5l10-9-10-9zm1 2.245L20.54 9l-7.505 6.755V11.945l-1.053.056c-.253.014-5.655.36-10.926 5.435.172-2.352.839-5.89 3.134-8.395C6.04 7.023 8.68 6 12.036 6h1V2.245z"
            fill={iconColor}
            mask="url(#b)"
          />
        </g>
      </svg>
    );
  }
}
