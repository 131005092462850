import React, { Component } from 'react';

export default class Reporting extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 261.8 349"
        style={{ enableBackground: 'new 0 0 261.8 349' }}
        xmlSpace="preserve"
        width="261.8"
        height={349}
      >
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n\t.st0{display:none;}\n\t.st1{display:inline;}\n\t.st2{display:inline;stroke:#FFFFFF;stroke-width:0.2;stroke-miterlimit:10;}\n\t.st3{display:inline;fill:none;stroke:#E0E0E0;stroke-miterlimit:10;}\n\t.st4{display:inline;fill:none;stroke:#000000;stroke-width:1.4;stroke-miterlimit:10;}\n\t.st5{fill:#51B7B0;stroke:#4FB7B0;stroke-width:2.232;stroke-miterlimit:10;}\n\t.st6{fill:none;stroke:#53B7B0;stroke-width:10.8;stroke-miterlimit:10;}\n\t.st7{display:inline;stroke:#222020;stroke-miterlimit:10;}\n\t.st8{display:inline;fill:none;stroke:#222020;stroke-miterlimit:10;}\n\t.st9{stroke:#222020;stroke-miterlimit:10;}\n'
          }}
        />
        <g className="st0">
          <rect x="-110.1" y="108.9" className="st1" width="824.6" height="414.1" />
        </g>
        <g className="st0">
          <circle className="st1" cx={24} cy={317} r={32} />
          <polyline
            className="st1"
            points="42.7,336.2 42.7,296.1 8.7,296.1 8.7,341.4 37.5,341.4 37.5,341.4 42.7,336.2 	"
          />
          <polygon className="st1" points="35.8,339.7 7,339.7 7,294.4 41,294.4 41,334.5 	" />
          <path
            className="st1"
            d="M16.5,302.1c-3.1,0-5.7,2.5-5.7,5.7c0,3.1,2.5,5.7,5.7,5.7s5.7-2.5,5.7-5.7h-5.7
        		C16.5,307.8,16.5,302.1,16.5,302.1z"
          />
          <path className="st2" d="M17.1,301.5v5.7h5.7C22.8,304,20.3,301.5,17.1,301.5z" />
          <path
            className="st1"
            d="M30.9,302.1c-3.1,0-5.7,2.5-5.7,5.7c0,3.1,2.5,5.7,5.7,5.7s5.7-2.5,5.7-5.7h-5.7
        		C30.9,307.8,30.9,302.1,30.9,302.1z"
          />
          <path className="st2" d="M31.5,301.5v5.7h5.7C37.2,304,34.6,301.5,31.5,301.5z" />
          <polygon className="st1" points="17.6,319.2 14.4,323.1 13,321.8 12.1,322.8 14.5,325 18.6,320.1 	" />
          <polygon className="st1" points="17.6,326.8 14.4,330.7 13,329.4 12.1,330.3 14.5,332.6 18.6,327.6 	" />
          <line className="st3" x1="21.1" y1="320.9" x2="35.9" y2="320.9" />
          <line className="st3" x1="21.1" y1="323.4" x2="35.9" y2="323.4" />
          <line className="st3" x1="21.1" y1="328.5" x2="35.9" y2="328.5" />
          <line className="st3" x1="21.1" y1="330.9" x2="35.9" y2="330.9" />
          <polygon className="st1" points="35.8,339.7 35.8,334.5 41,334.5 	" />
        </g>
        <g className="st0">
          <circle className="st1" cx={24} cy={317} r={32} />
          <polyline
            className="st1"
            points="42.7,336.2 42.7,296.1 8.7,296.1 8.7,341.4 37.5,341.4 37.5,341.4 42.7,336.2 	"
          />
          <polygon className="st1" points="35.8,339.7 7,339.7 7,294.4 41,294.4 41,334.5 	" />
          <path
            className="st1"
            d="M16.5,302.1c-3.1,0-5.7,2.5-5.7,5.7c0,3.1,2.5,5.7,5.7,5.7s5.7-2.5,5.7-5.7h-5.7
        		C16.5,307.8,16.5,302.1,16.5,302.1z"
          />
          <path className="st2" d="M17.1,301.5v5.7h5.7C22.8,304,20.3,301.5,17.1,301.5z" />
          <path
            className="st1"
            d="M30.9,302.1c-3.1,0-5.7,2.5-5.7,5.7c0,3.1,2.5,5.7,5.7,5.7s5.7-2.5,5.7-5.7h-5.7
        		C30.9,307.8,30.9,302.1,30.9,302.1z"
          />
          <path className="st2" d="M31.5,301.5v5.7h5.7C37.2,304,34.6,301.5,31.5,301.5z" />
          <polygon className="st1" points="17.6,319.2 14.4,323.1 13,321.8 12.1,322.8 14.5,325 18.6,320.1 	" />
          <polygon className="st1" points="17.6,326.8 14.4,330.7 13,329.4 12.1,330.3 14.5,332.6 18.6,327.6 	" />
          <line className="st3" x1="21.1" y1="320.9" x2="35.9" y2="320.9" />
          <line className="st3" x1="21.1" y1="323.4" x2="35.9" y2="323.4" />
          <line className="st3" x1="21.1" y1="328.5" x2="35.9" y2="328.5" />
          <line className="st3" x1="21.1" y1="330.9" x2="35.9" y2="330.9" />
          <polygon className="st1" points="35.8,339.7 35.8,334.5 41,334.5 	" />
        </g>
        <g className="st0">
          <polygon className="st1" points="29,349 -11.6,349 -11.6,285 36.4,285 36.4,341.7 	" />
          <path className="st1" d="M1.8,295.9c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8C1.8,303.9,1.8,295.9,1.8,295.9z" />
          <path className="st1" d="M2.8,295.1v7.8h7.8C10.5,298.7,7,295.2,2.8,295.1z" />
          <path className="st1" d="M22.1,295.9c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8V295.9z" />
          <path className="st1" d="M23.1,295.1v7.8h7.8C30.8,298.7,27.3,295.2,23.1,295.1z" />
          <polygon className="st1" points="3.3,320.1 -1.2,325.6 -3.1,323.7 -4.4,325.1 -1.1,328.3 4.7,321.3 	" />
          <polygon className="st1" points="3.3,330.8 -1.2,336.3 -3.1,334.4 -4.4,335.8 -1.1,339 4.7,332 	" />
          <rect x="8.2" y="321.7" className="st1" width="20.9" height="1.4" />
          <rect x="8.2" y="325.2" className="st1" width="20.9" height="1.4" />
          <rect x="8.2" y="332.4" className="st1" width="20.9" height="1.4" />
          <rect x="8.2" y="335.9" className="st1" width="20.9" height="1.4" />
          <polygon className="st1" points="29,349 29,341.7 36.4,341.7 	" />
        </g>
        <g className="st0">
          <g className="st1">
            <path
              d="M12,297.4v6.5v1.4h1.4h6.5c-0.6,3-3.3,5.2-6.5,5.2c-3.7,0-6.6-3-6.6-6.6C6.8,300.7,9,298.1,12,297.4 M13.4,295.9
          			c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8V295.9L13.4,295.9z"
            />
          </g>
          <g className="st1">
            <path d="M15.7,296.5c2.5,0.5,4.5,2.5,5.1,5.1h-5.1L15.7,296.5 M14.3,295v8h8C22.3,298.6,18.7,295,14.3,295L14.3,295z" />
          </g>
          <g className="st1">
            <path
              d="M32.3,297.4v6.5v1.4h1.4h6.5c-0.6,3-3.3,5.2-6.5,5.2c-3.7,0-6.6-3-6.6-6.6C27.1,300.7,29.3,298.1,32.3,297.4 M33.7,295.9
          			c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8V295.9L33.7,295.9z"
            />
          </g>
          <g className="st1">
            <path d="M36,296.5c2.5,0.5,4.5,2.5,5.1,5.1H36V296.5 M34.6,295v8h8C42.6,298.6,39,295,34.6,295L34.6,295z" />
          </g>
          <g className="st1">
            <polygon points="14.9,320.1 10.4,325.6 8.5,323.7 7.2,325.1 10.5,328.3 16.3,321.3 		" />
          </g>
          <g className="st1">
            <polygon points="14.9,330.8 10.4,336.3 8.5,334.4 7.2,335.8 10.5,339 16.3,332 		" />
          </g>
          <line className="st4" x1="19.9" y1="322.4" x2="40.8" y2="322.4" />
          <line className="st4" x1="19.9" y1="325.9" x2="40.8" y2="325.9" />
          <line className="st4" x1="19.9" y1="333.1" x2="40.8" y2="333.1" />
          <line className="st4" x1="19.9" y1="336.6" x2="40.8" y2="336.6" />
          <g className="st1">
            <path d="M46.6,286.4v54.7l-6.5,6.5H1.4v-61.2H46.6 M48,285H0v64h40.7l7.3-7.3V285L48,285z" />
          </g>
          <polyline className="st4" points="40.2,348.5 40.2,341.2 47.5,341.2 	" />
        </g>
        <g className="st0">
          <g className="st1">
            <path
              d="M12,297.4v6.5v1.4h1.4h6.5c-0.6,3-3.3,5.2-6.5,5.2c-3.7,0-6.6-3-6.6-6.6C6.8,300.7,9,298.1,12,297.4 M13.4,295.9
          			c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8V295.9L13.4,295.9z"
            />
          </g>
          <g className="st1">
            <path d="M15.7,296.5c2.5,0.5,4.5,2.5,5.1,5.1h-5.1L15.7,296.5 M14.3,295v8h8C22.3,298.6,18.7,295,14.3,295L14.3,295z" />
          </g>
          <g className="st1">
            <path
              d="M32.3,297.4v6.5v1.4h1.4h6.5c-0.6,3-3.3,5.2-6.5,5.2c-3.7,0-6.6-3-6.6-6.6C27.1,300.7,29.3,298.1,32.3,297.4 M33.7,295.9
          			c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8V295.9L33.7,295.9z"
            />
          </g>
          <g className="st1">
            <path d="M36,296.5c2.5,0.5,4.5,2.5,5.1,5.1H36V296.5 M34.6,295v8h8C42.6,298.6,39,295,34.6,295L34.6,295z" />
          </g>
          <g className="st1">
            <polygon points="14.9,320.1 10.4,325.6 8.5,323.7 7.2,325.1 10.5,328.3 16.3,321.3 		" />
          </g>
          <g className="st1">
            <polygon points="14.9,330.8 10.4,336.3 8.5,334.4 7.2,335.8 10.5,339 16.3,332 		" />
          </g>
          <line className="st4" x1="19.9" y1="322.4" x2="40.8" y2="322.4" />
          <line className="st4" x1="19.9" y1="325.9" x2="40.8" y2="325.9" />
          <line className="st4" x1="19.9" y1="333.1" x2="40.8" y2="333.1" />
          <line className="st4" x1="19.9" y1="336.6" x2="40.8" y2="336.6" />
          <g className="st1">
            <path
              d="M43.2,286.4c1.9,0,3.4,1.5,3.4,3.4v54.5c0,1.9-1.5,3.4-3.4,3.4H4.8c-1.9,0-3.4-1.5-3.4-3.4v-54.5c0-1.9,1.5-3.4,3.4-3.4
          			H43.2 M43.2,285H4.8c-2.6,0-4.8,2.1-4.8,4.8v54.5c0,2.6,2.1,4.8,4.8,4.8h38.5c2.6,0,4.8-2.1,4.8-4.8v-54.5
          			C48,287.1,45.9,285,43.2,285L43.2,285z"
            />
          </g>
        </g>
        <g>
          <path
            className="st5"
            d="M127.8,146.6c26.3,0,47.6-21.3,47.6-47.6h-47.6V51.4c-26.3,0-47.6,21.3-47.6,47.6S101.6,146.6,127.8,146.6z
        		 M119.6,60.6v38.4v8.3h8.3h38.4c-3.8,17.7-19.5,31.1-38.4,31.1c-21.7,0-39.3-17.6-39.3-39.3C88.5,80.2,101.9,64.4,119.6,60.6z"
          />
          <path
            className="st5"
            d="M136.2,44.1v47.6h47.6C183.8,65.5,162.5,44.1,136.2,44.1z M144.5,53.4c15,3.3,26.9,15.1,30.1,30.1h-30.1V53.4z
        		"
          />
          <polygon className="st5" points="56.8,216.1 45.5,205.2 38.1,213.2 57.7,232.3 91.9,190.7 83.6,183.5 	" />
          <polygon className="st5" points="56.8,285 45.5,274 38.1,282.1 57.6,301.2 91.9,259.6 83.6,252.4 	" />
          <rect x="111.9" y="203.7" className="st5" width="123.7" height="8.3" />
          <rect x="111.9" y="276.7" className="st5" width="123.7" height="8.3" />
          <path className="st6" d="M254.1,341.4H7.6V7.6h246.5V341.4z" />
        </g>
        <g className="st0">
          <g className="st1">
            <path
              d="M12.4,296.9v7v1h1h7c-0.5,3.4-3.4,6-7,6c-3.9,0-7-3.2-7-7C6.4,300.4,9,297.4,12.4,296.9 M13.4,295.9c-4.4,0-8,3.6-8,8
          			s3.6,8,8,8s8-3.6,8-8h-8V295.9L13.4,295.9z"
            />
          </g>
          <g className="st1">
            <path d="M15.3,296.1c3.1,0.4,5.5,2.9,6,6h-6L15.3,296.1 M14.3,295v8h8C22.3,298.6,18.7,295,14.3,295L14.3,295z" />
          </g>
          <g className="st1">
            <path
              d="M32.7,296.9v7v1h1h7c-0.5,3.4-3.4,6-7,6c-3.9,0-7-3.2-7-7C26.7,300.4,29.3,297.4,32.7,296.9 M33.7,295.9c-4.4,0-8,3.6-8,8
          			s3.6,8,8,8s8-3.6,8-8h-8V295.9L33.7,295.9z"
            />
          </g>
          <g className="st1">
            <path d="M35.6,296.1c3.1,0.4,5.5,2.9,6,6h-6V296.1 M34.6,295v8h8C42.6,298.6,39,295,34.6,295L34.6,295z" />
          </g>
          <polygon className="st7" points="14.9,320.1 10.4,325.6 8.5,323.7 7.2,325.1 10.5,328.3 16.3,321.3 	" />
          <polygon className="st7" points="14.9,330.8 10.4,336.3 8.5,334.4 7.2,335.8 10.5,339 16.3,332 	" />
          <line className="st8" x1="19.9" y1="322.4" x2="40.8" y2="322.4" />
          <line className="st8" x1="19.9" y1="325.9" x2="40.8" y2="325.9" />
          <line className="st8" x1="19.9" y1="333.1" x2="40.8" y2="333.1" />
          <line className="st8" x1="19.9" y1="336.6" x2="40.8" y2="336.6" />
          <g className="st1">
            <polygon className="st9" points="40.5,348.8 0.2,348.8 0.2,285.3 47.8,285.3 47.8,341.5 		" />
            <polyline className="st9" points="40.4,348.6 40.4,341.4 47.7,341.4 		" />
          </g>
        </g>
        <g className="st0">
          <g className="st1">
            <g>
              <g>
                <path
                  d="M13.6,295.6c-7.2,0.4-12.6,9-6.4,14.6c5.5,5,14.4,0.8,14.7-6.3c0-0.2-0.2-0.2-0.4-0.2c-1.2,0-2.5,0-3.7,0
              					c-0.8,0-4,0.1-4.1,0c0,0,0.2-3.3,0.2-3.9c0-1.3,0-2.7,0-4c0-0.3-1.4-0.1-1.4,0.4c0,2.7,0,5.3,0,8c0,0.2,0.3,0.2,0.4,0.2
              					c1.6,0,3.3,0,4.9,0c0.5,0,2.2-0.3,2.7,0c0.3,0.2-0.4,2.3-0.7,3.1c-2.6,5.7-10.7,5.1-13.1-0.3c-2.1-4.7,0.8-10.5,6.3-10.7
              					C13.4,296.3,14.5,295.6,13.6,295.6z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M13.4,295.2c0,2.7,0,5.3,0,8c0,0.3,0.2,0.4,0.5,0.4c2.7,0,5.3,0,8,0c0.3,0,1-0.1,1-0.5c-0.1-4.7-3.8-8.3-8.5-8.4
              					c-0.6,0-1.5,0.9-0.5,1c4.2,0.1,7.4,3.4,7.5,7.6c0.3-0.2,0.7-0.4,1-0.5c-2.7,0-5.3,0-8,0c0.2,0.1,0.3,0.3,0.5,0.4
              					c0-2.7,0-5.3,0-8C14.9,294.4,13.4,294.6,13.4,295.2z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M33.5,295.5c-7.5,0.4-12.2,9.6-5.8,14.9c5.6,4.7,14,0.5,14.4-6.6c0-0.3-0.2-0.4-0.5-0.4c-1.2,0-2.5,0-3.7,0
              					c-1,0-2,0-2.9,0c-0.4,0-1.4-0.2-1.1,0.2c0.4,0.4,0.2-0.9,0.2-1.1c0-0.9,0-1.8,0-2.6c0-1.3,0-2.7,0-4c0-0.7-1.5-0.4-1.5,0.2
              					c0,2.7,0,5.3,0,8c0,0.2,0.2,0.4,0.5,0.4c1.6,0,3.3,0,4.9,0c0.4,0,2.5-0.3,2.8,0c0.3,0.2-1.4,3.7-1.7,4.1
              					c-3.4,4.7-10.5,3.3-12.5-1.9c-1.9-4.8,1.4-10,6.6-10.3C34.1,296.4,34.5,295.5,33.5,295.5z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M33.6,295.2c0,2.7,0,5.3,0,8c0,0.4,0.4,0.4,0.7,0.4c2.7,0,5.3,0,8,0c0.3,0,0.8-0.2,0.8-0.6c-0.1-4.7-3.8-8.3-8.5-8.4
              					c-0.7,0-1.5,1-0.5,1c4.2,0.1,7.4,3.4,7.5,7.6c0.3-0.2,0.5-0.4,0.8-0.6c-2.7,0-5.3,0-8,0c0.2,0.1,0.4,0.3,0.7,0.4
              					c0-2.7,0-5.3,0-8C35,294.3,33.6,294.6,33.6,295.2z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M14.1,320.2c-1.5,1.8-3,3.7-4.5,5.5c0.5-0.1,0.9-0.3,1.4-0.4c-0.6-0.6-1.3-1.2-1.9-1.8c-0.2-0.2-1.1,0.1-1.3,0.3
              					c-0.4,0.5-0.8,0.9-1.2,1.4c-0.1,0.1-0.1,0.1,0,0.2c1.1,1.1,2.2,2.1,3.3,3.2c0.3,0.3,1.2-0.1,1.4-0.4c1.9-2.3,3.8-4.7,5.7-7v-0.1
              					c-0.5-0.4-0.9-0.8-1.4-1.2c-0.3-0.2-1.6,0.3-1.4,0.5c0.5,0.5,1.3,0.9,1.2,1.3c-0.2,0.7-1.4,1.7-1.9,2.3c-1.2,1.5-2.4,3-3.7,4.4
              					c0.5-0.1,0.9-0.3,1.4-0.4c-0.8-0.8-1.6-1.6-2.5-2.4c-0.1-0.1-0.8-0.8-0.8-0.7c0-0.3,1-1.1,1.2-1.3c-0.4,0.1-0.9,0.2-1.3,0.3
              					c0.6,0.6,1.3,1.2,1.9,1.8c0.3,0.3,1.2-0.1,1.4-0.4c1.5-1.8,3-3.7,4.5-5.5C15.5,319.8,14.3,319.8,14.1,320.2z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M14.2,330.5c-1.5,1.8-3,3.6-4.5,5.5c0.4,0,0.8-0.1,1.3-0.1c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.3-1-0.1-1.3,0.1
              					c-0.4,0.5-0.8,0.9-1.2,1.4c-0.1,0.1-0.2,0.3-0.1,0.4c1.1,1.1,2.2,2.1,3.3,3.2c0.3,0.3,1,0.2,1.3-0.1c1.9-2.3,3.8-4.7,5.7-7
              					c0.1-0.1,0.2-0.3,0.1-0.4c-0.5-0.4-0.9-0.8-1.4-1.2c-0.4-0.4-1.8,0.2-1.3,0.6c0.3,0.3,0.8,0.8,1.2,1c0.3,0.1,0.5-0.4,0.1,0
              					c-0.7,0.7-1.3,1.6-1.9,2.3c-1.2,1.5-2.4,3-3.7,4.4c0.4,0,0.8-0.1,1.3-0.1c-0.8-0.8-1.6-1.6-2.5-2.4c-0.4-0.4-0.7-1-0.8-0.4
              					c0.1-0.4,0.9-1,1.2-1.3c-0.4,0-0.8,0.1-1.3,0.1c0.6,0.6,1.3,1.2,1.9,1.8c0.3,0.3,1,0.2,1.3-0.1c1.5-1.8,3-3.6,4.5-5.5
              					C15.9,330.2,14.5,330.1,14.2,330.5z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M19.4,322.8c6.9,0,13.8,0,20.8,0c0.5,0,1.5-0.8,0.6-0.8c-6.9,0-13.8,0-20.8,0C19.4,322,18.4,322.8,19.4,322.8
              					L19.4,322.8z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M19.4,326.2c6.9,0,13.8,0,20.8,0c0.5,0,1.6-0.7,0.6-0.7c-6.9,0-13.8,0-20.8,0C19.5,325.5,18.4,326.2,19.4,326.2
              					L19.4,326.2z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M19.4,333.5c6.9,0,13.8,0,20.8,0c0.6,0,1.5-0.9,0.5-0.9c-6.9,0-13.8,0-20.8,0C19.4,332.6,18.4,333.5,19.4,333.5
              					L19.4,333.5z"
                />
              </g>
            </g>
            <g>
              <g>
                <path d="M19.4,337c6.9,0,13.8,0,20.8,0c0.6,0,1.5-1,0.5-1c-6.9,0-13.8,0-20.8,0C19.3,336,18.5,337,19.4,337L19.4,337z" />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    d="M40.5,348.2c-7.2,0-14.4,0-21.5,0c-4.5,0-9,0-13.6,0c-1.3,0-2.6,0.1-3.9,0c-1.2-0.1-0.7,0-0.6,0.1
                						c-0.7-1.3,0-4.9,0-6.4c0-4.3,0-8.6,0-12.8c0-10,0-20.1,0-30.1c0-3.5,0-6.9,0-10.4c0-0.8,0.2-1.9,0-2.7c-0.2-0.9-1.4,0.1-0.6,0
                						c7.4-0.8,15.3,0,22.8,0c4.9,0,9.9,0,14.8,0c2.5,0,6.2-0.7,8.7,0c1.2,0.3-0.1-0.6,0.2,0c0.3,0.7,0,2,0,2.7c0,3.8,0,7.6,0,11.4
                						c0,10.9,0,21.8,0,32.7c0,2.4,0.8,6.3,0,8.5c-0.7,2.1-5.2,5.3-7.1,7.2c-0.6,0.6,0.8,0.6,1.2,0.3c2.5-2.5,6.8-5.2,7.4-8.7
                						c0.9-5.6,0-12.2,0-17.8c0-12.3,0-24.5,0-36.8c0-0.2-0.2-0.3-0.4-0.3c-15.8,0-31.6,0-47.4,0c-0.2,0-1,0.1-1,0.5
                						c0,21.1,0,42.1,0,63.2c0,0.2,0.2,0.3,0.4,0.3c13.4,0,26.7,0,40.1,0C40.5,349,41.5,348.2,40.5,348.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M40.9,348.3c0-2.4,0-4.8,0-7.2c-0.4,0.2-0.8,0.3-1.2,0.5c2.4,0,4.8,0,7.2,0c0.3,0,1.6-0.6,1-0.6c-2.4,0-4.8,0-7.2,0
                						c-0.2,0-1.2,0.1-1.2,0.5c0,2.4,0,4.8,0,7.2C39.4,348.9,40.9,348.7,40.9,348.3L40.9,348.3z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g className="st0">
          <circle className="st1" cx={24} cy={317} r={32} />
          <polygon className="st1" points="0.8,285.8 47.5,285.8 47.5,341.1 39.5,348.5 0.8,348.5 	" />
          <polygon className="st1" points="40.3,341.2 40.4,345.6 3.4,345.6 3.4,288.4 44.6,288.4 44.6,341.2 	" />
          <path className="st1" d="M46.6,286.4v54.7l-6.5,6.5H1.4v-61.2H46.6 M48,285H0v64h40.7l7.3-7.3V285L48,285z" />
          <polygon className="st1" points="40.9,348.5 39.5,348.5 39.5,340.5 47.5,340.5 47.5,341.9 40.9,341.9 	" />
          <path
            className="st1"
            d="M12,297.4v6.5v1.4h1.4h6.5c-0.6,3-3.3,5.2-6.5,5.2c-3.7,0-6.6-3-6.6-6.6C6.8,300.7,9,298.1,12,297.4
        		 M13.4,295.9c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8V295.9L13.4,295.9z"
          />
          <path
            className="st1"
            d="M15.7,296.5c2.5,0.5,4.5,2.5,5.1,5.1h-5.1L15.7,296.5 M14.3,295v8h8C22.3,298.6,18.7,295,14.3,295L14.3,295z"
          />
          <path
            className="st1"
            d="M32.3,297.4v6.5v1.4h1.4h6.5c-0.6,3-3.3,5.2-6.5,5.2c-3.7,0-6.6-3-6.6-6.6C27.1,300.7,29.3,298.1,32.3,297.4
        		 M33.7,295.9c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8h-8V295.9L33.7,295.9z"
          />
          <path
            className="st1"
            d="M36,296.5c2.5,0.5,4.5,2.5,5.1,5.1H36V296.5 M34.6,295v8h8C42.6,298.6,39,295,34.6,295L34.6,295z"
          />
          <polygon className="st1" points="14.9,320.1 10.4,325.6 8.5,323.7 7.2,325.1 10.5,328.3 16.3,321.3 	" />
          <polygon className="st1" points="14.9,330.8 10.4,336.3 8.5,334.4 7.2,335.8 10.5,339 16.3,332 	" />
          <rect x="19.9" y="321.7" className="st1" width="20.9" height="1.4" />
          <rect x="19.9" y="325.2" className="st1" width="20.9" height="1.4" />
          <rect x="19.9" y="332.4" className="st1" width="20.9" height="1.4" />
          <rect x="19.9" y="335.9" className="st1" width="20.9" height="1.4" />
        </g>
      </svg>
    );
  }
}
