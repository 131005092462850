import { baseFontSize } from './fonts';

export const pxToRem = (px, base = baseFontSize) => `${parseFloat(px) / parseFloat(base)}rem`; //eslint-disable-line

// function to switch computer RGB color values to hex values (used in tests)
const trimValue = value => value.replace(/^\s+|\s+$/gm, '');
export const rgbToHex = rgb => {
  const values = rgb.substring(rgb.indexOf('(')).split(',');
  if (values.length === 3) {
    const r = parseInt(trimValue(values[0].substring(1)), 10).toString(16);
    const g = parseInt(trimValue(values[1]), 10).toString(16);
    const b = parseInt(trimValue(values[2]), 10).toString(16);

    return `#${r}${g}${b}`;
  }

  // return false if the rgb value arrives blank or incorrect
  return false;
};
