import styled from 'styled-components';
import { fonts, media, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledHeadingFifth = styled(Base)`
  font-family: ${fonts.fontBold};
  font-size: ${mixins.pxToRem(14)};
  line-height: ${mixins.pxToRem(18)};

  text-transform: uppercase;
  margin: ${props => (props.noMargins === false ? `${mixins.pxToRem(20)} 0 ${mixins.pxToRem(12)}` : 0)};
  text-align: ${props => props.align};
  text-transform: ${props => props.textTransform};
  color: ${props => props.color};

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

  ${media.greaterThan('lg')`
    letter-spacing: -0.25px;
  `};
`;
