import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';

import { TOAST_PLACEMENT_ENUM } from '../ToastComponent';
import * as Styled from './ToastContainer.styles';

class ToastContainer extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    placement: PropTypes.oneOf(TOAST_PLACEMENT_ENUM).isRequired
  };

  static defaultProps = {
    children: null
  };

  render() {
    const { placement, children } = this.props;
    return (
      <Styled.ToastContainer placement={placement}>
        <TransitionGroup component={null}>{children}</TransitionGroup>
      </Styled.ToastContainer>
    );
  }
}

export default ToastContainer;
