const BOOKING_ROUTES = {
  INIT: 'InitiateBooking',
  // ASSIGNMENT: 'RoomAssignments',
  ROOMSCHEDULE: 'RoomSchedules',
  COST_TRACKING_CODES: 'AssignCostTrackingCodes',
  PAYMENT: 'BookingPayment',
  SUMMARY: 'BookingSummary',
  CONFIRM: 'BookingConfirmation',
  DETAILS: 'BookingDetails',
  // Edit
  EDITASSIGNMENT: 'EditRoomAssignments',
  EDIT_COST_TRACKING_CODES: 'EditCostTrackingCodes',
  EDITROOMSCHEDULE: 'EditRoomSchedules',
  EDITPAYMENT: 'EditBookingPayment',
  EDITSUMMARY: 'EditBookingSummary'
};

export const BOOKING_PAGE_ROUTES = {
  ROOMSCHEDULE: '/BookingEdit/RoomSchedules',
  PAYMENT: '/BookingEdit/Payment',
  COST_TRACKING_CODES: '/BookingEdit/CostTrackingCodes'
};

export default BOOKING_ROUTES;
