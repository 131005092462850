import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class YouTube extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 37,
    backgroundColor: colors.youTubeRed,
    iconColor: colors.colorWhite
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/Social/Twitter">
            <rect id="Rectangle-5-Copy" fill={backgroundColor} x="0" y="0" width="37" height="37" />
            <path
              fill={iconColor}
              d="M27.6636815,14.9228841 C27.6636815,14.9228841 27.4792834,13.595395 26.9143064,13.0108182 C26.1973335,12.2432225 25.3937583,12.2396103 25.0255512,12.1950596 C22.387421,12 18.4302257,12 18.4302257,12 L18.4219778,12 C18.4219778,12 14.4647825,12 11.8266523,12.1950596 C11.457856,12.2396103 10.65487,12.2432225 9.93730799,13.0108182 C9.37233102,13.595395 9.18852204,14.9228841 9.18852204,14.9228841 C9.18852204,14.9228841 9,16.482157 9,18.0408279 L9,19.502571 C9,21.0618439 9.18852204,22.6205147 9.18852204,22.6205147 C9.18852204,22.6205147 9.37233102,23.9480038 9.93730799,24.5325807 C10.65487,25.3001764 11.596891,25.276095 12.0163526,25.3561657 C13.5245288,25.5042665 18.4261018,25.5500213 18.4261018,25.5500213 C18.4261018,25.5500213 22.387421,25.5440009 25.0255512,25.3489413 C25.3937583,25.3037886 26.1973335,25.3001764 26.9143064,24.5325807 C27.4792834,23.9480038 27.6636815,22.6205147 27.6636815,22.6205147 C27.6636815,22.6205147 27.8522035,21.0618439 27.8522035,19.502571 L27.8522035,18.0408279 C27.8522035,16.482157 27.6636815,14.9228841 27.6636815,14.9228841 L27.6636815,14.9228841 Z"
              id="Youtube"
            />
            <polygon fill={backgroundColor} id="Path" points="16.0005891 21.4129045 16 16 21.0936297 18.7157838" />
          </g>
        </g>
      </svg>
    );
  }
}
