import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class MeetingRoom extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m8.43 16.09a3.18 3.18 0 1 0 -3.18-3.18 3.18 3.18 0 0 0 3.18 3.18zm0-5.36a2.18 2.18 0 1 1 -2.18 2.18 2.18 2.18 0 0 1 2.18-2.18z"
        />
        <path
          fill={iconColor}
          d="m22.75 4.47h-21.48v14.73h1.5a4 4 0 0 0 -.08.62v1.45h1v-1.42c0-1.18.61-2.27 4.73-2.27 4.34 0 4.72 1.19 4.74 2.29v1.42h1v-1.43a3.32 3.32 0 0 0 -.07-.65h8.65zm-1 13.74h-8c-.94-1.4-3.12-1.63-5.27-1.63-2.86 0-4.53.51-5.27 1.65h-.88v-12.76h19.42z"
        />
      </svg>
    );
  }
}
