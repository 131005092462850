import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class SearchArrow extends Component {
  static propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 16,
    height: 11,
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 16 11" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" fill="none" fillRule="evenodd">
          <g id="Icons/UI/Arrow/Right/Grey" transform="translate(-4 -7)" fill={iconColor} fillRule="nonzero">
            <polygon
              id="Rectangle-6"
              points="14.4 7 13.6 7.7865 17.8344 11.9452857 13.2568 11.9452857 4 11.9452857 4 13.0562857 13.2568 13.0562857 17.8344 13.0562857 13.6 17.2150714 14.4 18 19.2 13.2857143 20 12.5 19.2 11.7142857"
            />
          </g>
        </g>
      </svg>
    );
  }
}
