import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Dinner extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path fill={iconColor} d="m12 3a9 9 0 1 0 9 9 9 9 0 0 0 -9-9zm0 17a8 8 0 1 1 8-8 8 8 0 0 1 -8 8z" />
        <path
          fill={iconColor}
          d="m12 6.86a5.14 5.14 0 1 0 5.14 5.14 5.14 5.14 0 0 0 -5.14-5.14zm0 9.28a4.14 4.14 0 1 1 4.14-4.14 4.15 4.15 0 0 1 -4.14 4.14z"
        />
        <path fill={iconColor} d="m1 7h1v10h-1z" />
        <path fill={iconColor} d="m22 7h1v10h-1z" />
      </svg>
    );
  }
}
