const CompanySize = {
  ONE_TO_FOUR: 'ONE_TO_FOUR',
  FIVE_TO_NINE: 'FIVE_TO_NINE',
  TEN_TO_NINETEEN: 'TEN_TO_NINETEEN',
  TWENTY_TO_FOURTYNINE: 'TWENTY_TO_FOURTYNINE',
  FIFTY_TO_NINETYNINE: 'FIFTY_TO_NINETYNINE',
  ONEHUNDRED_TO_TWOFOURTYNINE: 'ONEHUNDRED_TO_TWOFOURTYNINE',
  TWOHUNDREDFIFTY_TO_FOURNINETYNINE: 'TWOHUNDREDFIFTY_TO_FOURNINETYNINE',
  FIVEHUNDRED_TO_NINENINETYNINE: 'FIVEHUNDRED_TO_NINENINETYNINE',
  OVER_ONETHOUSAND: 'OVER_ONETHOUSAND'
};

export const CompanySizeOrder = [
  CompanySize.ONE_TO_FOUR,
  CompanySize.FIVE_TO_NINE,
  CompanySize.TEN_TO_NINETEEN,
  CompanySize.TWENTY_TO_FOURTYNINE,
  CompanySize.FIFTY_TO_NINETYNINE,
  CompanySize.ONEHUNDRED_TO_TWOFOURTYNINE,
  CompanySize.TWOHUNDREDFIFTY_TO_FOURNINETYNINE,
  CompanySize.FIVEHUNDRED_TO_NINENINETYNINE,
  CompanySize.OVER_ONETHOUSAND
];

export default CompanySize;
