import PropTypes from 'prop-types';
import { CurrencyPropType } from './common';
import CreditApplicationStatus from '../enums/CreditApplicationStatus';

const CreditApplicationPropType = PropTypes.shape({
  creditApplicationId: PropTypes.string,
  legalCompanyName: PropTypes.string,
  tradeName: PropTypes.string,
  dunsNum: PropTypes.string,
  parentCompany: PropTypes.string,
  lineOfBusiness: PropTypes.string,

  // primaryBusinessAddress: AddressPropType,   ** Because of formik the propTypes these are the intuitive values. See flattend values
  // billingAddress: AddressPropType,           ** See above comments

  primaryBusinessAddress: PropTypes.string,
  city: PropTypes.string,
  stateProv: PropTypes.string,
  country: PropTypes.string,
  zipPostalCode: PropTypes.string,

  billingAddress: PropTypes.string,
  billingCity: PropTypes.string,
  billingStateProv: PropTypes.string,
  billingCountry: PropTypes.string,
  billingZipPostalCode: PropTypes.string,

  phone: PropTypes.string,
  email: PropTypes.string,
  apName: PropTypes.string,
  apPhoneNumber: PropTypes.string,
  signatureFullName: PropTypes.string,
  signatureTitle: PropTypes.string,
  authorizedOrganizationBehalf: PropTypes.bool,
  state: PropTypes.oneOf(Object.values(CreditApplicationStatus)),
  active: PropTypes.bool,
  creditLimit: CurrencyPropType,
  submittedDate: PropTypes.string
});

export default CreditApplicationPropType;
