export default {
  default: 'pin',
  property: 'hotel',
  brand: 'hotel',
  city: 'pin',
  motel: 'hotel',
  location: 'pin',
  uwi: 'uwi',
  lsd: 'map-grid',
  nts: 'map-grid',
  latlng: 'map-grid',
  ziporpostalcode: 'pin',
  money: 'money',
  dates: 'booking-change'
};
