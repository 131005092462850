import styled from 'styled-components';
import { colors, mixins } from '../../../css';

export const StyledCopywright = styled.div`
  padding: 0;
  text-align: center;
  border-top: 1px solid #fff;
  overflow: hidden;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
`;

export const StyledBottomItem = styled.span`
  display: inline-block;
  padding: 0 ${mixins.pxToRem(6)} ${mixins.pxToRem(35)} 0;
  color: ${colors.colorWhite};

  &:before {
    content: '•';
    margin-right: 1.5rem;
  }
`;

export const StyledLinkRow = styled.div`
  display: inline-block;
`;

export const StyledCopyLine = styled.div`
  padding-right: ${mixins.pxToRem(18)};
  padding-bottom: ${mixins.pxToRem(30)};
  display: inline-block;
`;

export const StyledChildSpan = styled.span`
  position: relative;
  left: ${mixins.pxToRem(-4)};
`;
