import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Tv extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m22.47 16.92v-12.08h-20.94v12.08h8v1.24h-3.63v1h12.19v-1h-3.65v-1.24zm-11.91 1.24v-1.22h2.88v1.22zm-8-12.32h18.91v10.08h-18.94z"
        />
        <path fill={iconColor} d="m3.53 12.36h1v-4.5h2.5v-1h-3.5z" />
        <path fill={iconColor} d="m8.03 6.86h1v1h-1z" />
        <path fill={iconColor} d="m19.53 13.86h-2.5v1h3.5v-2.37h-1z" />
      </svg>
    );
  }
}
