import PropTypes from 'prop-types';

import RoomPropType from '../Room';
import RoleName from '../../enums/RoleName';
import MembershipType from '../../enums/MembershipType';
import OrganizationPermission from '../../enums/OrganizationPermission';
import BookingPermission from '../../enums/BookingPermission';
import BookingGuestDayStatus from '../../enums/BookingGuestDayStatus';
import BookingGuestDayState from '../../enums/BookingGuestDayState';

/**
 * We're type-matching the API but there is no "ID" type.
 * This is a safe one to use for IDs when building out your shapes.
 * */
export const CommonIDPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

/**
 * Address
 * PropType and GenericShape
 */
export const AddressPropType = PropTypes.shape({
  address: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string
});

export const AddressDefaultShape = {
  address: '',
  city: '',
  region: '',
  postalCode: '',
  country: ''
};

/**
 * Currency
 * PropType
 */
export const CurrencyPropType = PropTypes.shape({
  symbol: PropTypes.string,
  position: PropTypes.oneOf(['Before', 'After']),
  value: PropTypes.number,
  formatted: PropTypes.string,
  name: PropTypes.string
});

export const RoleNamePropType = PropTypes.oneOf(Object.values(RoleName));
export const MembershipTypePropType = PropTypes.oneOf(Object.values(MembershipType));

/**
 * LocationType
 * PropType
 */
export const LocationTypePropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string
});

/**
 * RoomType
 * PropType
 */
export const RoomTypePropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string
});

/**
 * Geo
 * PropType
 */
export const GeoPropType = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number
});

/**
 * Affiliation
 * PropType
 */
export const AffiliationPropType = PropTypes.shape({
  id: CommonIDPropType,
  affiliationId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string
});

/**
 * Tag
 * PropType
 */
export const TagPropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string,
  description: PropTypes.string
});

// export const OrganizationPermissionsPropType = PropTypes.oneOf(Object.values(OrganizationPermission));
export const OrganizationPermissionsPropType = PropTypes.string;
export const BookingPermissionsPropType = PropTypes.oneOf(Object.values(BookingPermission));

/**
 * Common Booking Types
 *
 * These are only used inside of the Booking type so didn't really warrant their own
 * files but wanted to keep as typedefs
 */

export const BookingRoomDateBreakdownPropType = PropTypes.shape({
  totalRooms: PropTypes.number,
  bookedRooms: PropTypes.number,
  bookedRate: CurrencyPropType,
  standByRooms: PropTypes.number,
  standByRate: CurrencyPropType
});

export const BookingGuestDay = PropTypes.shape({
  date: PropTypes.string,
  inboundRate: CurrencyPropType,
  markup: CurrencyPropType,
  status: PropTypes.oneOf([...Object.values(BookingGuestDayStatus), 'initialcheckin', 'finalcheckout', 'inactive']),
  state: PropTypes.oneOf(Object.values(BookingGuestDayState))
});

export const BookingGuestPropType = PropTypes.shape({
  id: CommonIDPropType,
  identityId: CommonIDPropType,
  assigned: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  supervisor: PropTypes.bool,
  phone: PropTypes.string,
  emailAddress: PropTypes.string,
  room: RoomPropType,
  guestDays: PropTypes.arrayOf(BookingGuestDay),
  nightShift: PropTypes.bool,
  company: PropTypes.string,
  position: PropTypes.string,
  complete: PropTypes.bool,
  comments: PropTypes.string,
  roomIndex: PropTypes.number
});

export const BookingCancellationStatusPropType = PropTypes.shape({
  cancellationMessage: PropTypes.string,
  cancelledBy: PropTypes.string
});

export const BookingRoomDatePropType = PropTypes.shape({
  date: PropTypes.string,
  breakdown: BookingRoomDateBreakdownPropType
});

export const BookingFeePropType = PropTypes.shape({
  currency: CurrencyPropType,
  name: PropTypes.string,
  percentage: PropTypes.number
});

export const BookingRoomSummaryPropType = PropTypes.shape({
  id: CommonIDPropType,
  numberOfNights: PropTypes.number,
  numberOfRooms: PropTypes.number,
  roomName: PropTypes.string,
  dates: PropTypes.arrayOf(BookingRoomDatePropType)
});

export const FeatureFlagPropType = PropTypes.shape({
  id: CommonIDPropType,
  active: PropTypes.bool,
  description: PropTypes.string,
});
