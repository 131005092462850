import Constants from './Flyout/Flyout.constants';

const { LOCATION, PROPERTY, LATLNG, UWI, NTS, LSD, ZIPORPOSTALCODE } = Constants.LocationTypes;

export const defaultResults = {
  patternsType: [
    {
      id: 'mffmqg3j9qgq3g3q8g3qu',
      patternName: 'Search by Location',
      patternDescription: 'e.g. Fort McMurray, Edmonton, Saskatchewan',
      locationType: LOCATION
    },
    {
      id: 'fdsfsr2314534t5346534',
      patternName: 'Search by Property or Brand',
      patternDescription: 'e.g. Ancho Lodge, Super 8, Quality Inn',
      locationType: PROPERTY
    },
    {
      id: 'f34f324f3544f35343g',
      patternName: 'Try a Coordinate',
      patternDescription: 'e.g. 55.666419, -110.785335',
      locationType: LATLNG
    },
    {
      id: 'f534ef534g3g4355a32432g',
      patternName: 'Try a UWI',
      patternDescription: 'e.g. 102 1-13-77-6 W4 01',
      locationType: UWI
    },
    {
      id: 'vz2xc5v4ad5fbd5gzreerg',
      patternName: 'Search by LSD',
      patternDescription: 'e.g. 11-36-89-10 W4',
      locationType: LSD
    },
    {
      id: 'gfds4gd5gera66a6ha6',
      patternName: 'Search by NTS',
      patternDescription: 'e.g. A 8-C / 094 A 07',
      locationType: NTS
    },
    {
      id: 'hget5he6hfsb77b7ib7',
      patternName: 'Search by Zip OR Postal Code',
      patternDescription: 'e.g. 20500, K1A 0A9',
      locationsType: ZIPORPOSTALCODE
    }
  ],
  locationsType: [
    {
      id: 'e7f14076-35f6-436b-bf97-fdfdfddfdf32',
      locationText: 'Days Inn Fort McMurray',
      locationType: PROPERTY
    },
    {
      id: 'fi2929gig9g2f2f',
      locationText: 'Fort McMurray',
      locationType: LOCATION
    },
    {
      id: 'ganjkgi420000z3',
      locationText: 'Fort St. John',
      locationType: LOCATION
    },
    {
      id: 'fgai30a0fda0',
      locationText: 'Fort Nelson',
      locationType: LOCATION
    },
    {
      id: 'z335465g9g2f2f',
      locationText: 'Fort McMurray Village',
      locationType: LOCATION
    },
    {
      id: 'fzi22243g2f2f',
      locationText: 'Super 8 Fort McMurray',
      locationType: PROPERTY
    },
    {
      id: 'fi2929fds54456gig9g2f2f',
      locationText: 'Super 8 Fort St. John',
      locationType: PROPERTY
    },
    {
      id: 'e7f14076-35f6-436b-bf97-833bfd680fb6',
      locationText: 'Super 8 St. Albert',
      locationType: PROPERTY
    },
    {
      id: '520e1e68-5992-4554-b8e7-c6bc561cd7bd',
      locationText: 'Edmonton',
      locationType: LOCATION
    },
    {
      id: 'b0a422f8-c0bd-44ca-9392-d990a8a8e0af',
      locationText: 'Edmonton LOCATION Centre',
      locationType: PROPERTY
    },
    { id: 'b9f8b526-2b5e-478d-b884-b1ecc52322c6', locationText: 'Seattle', locationType: LOCATION },
    {
      id: '03755dbc-88d7-46ae-94b4-efaa01d1c458',
      locationText: 'Waterton Village',
      locationType: LOCATION
    },
    {
      id: '05c92909-4805-4357-8e79-e24b187c2c0d',
      locationText: 'Prince George',
      locationType: LOCATION
    },
    {
      id: '81eeab05-a7ed-4a86-9aa0-c4ed43aa561d',
      locationText: 'Holiday Inn Prince George',
      locationType: PROPERTY
    },
    {
      id: '7d49d84c-300f-436a-9b69-ae4087df4b3f',
      locationText: 'Super 8 Prince George',
      locationType: PROPERTY
    },
    {
      id: 'a940b184-88f8-4a7f-821d-303cbe534a60',
      locationText: 'Suncor CNRL',
      locationType: LOCATION
    },
    {
      id: 'eec33994-2b14-4f89-b450-2a1c4dd02f34',
      locationText: 'Suncor Firebag',
      locationType: PROPERTY
    },
    {
      id: '939d378a-5232-46c7-96fc-d6767a16ac49',
      locationText: 'Ancho Lodge Chetwynd',
      locationType: PROPERTY
    },
    { id: 'b0b46652-84f6-468c-b8a4-236898940e99', locationText: 'Yanggan', locationType: LOCATION },
    {
      id: 'eff982fd-beb1-4abb-8d2d-642b8057de30',
      locationText: 'Super 8 Matsudo',
      locationType: LOCATION
    },
    {
      id: '7d687055-b095-4fc8-9d96-0e6bef0ef352',
      locationText: 'Huurch',
      locationType: PROPERTY
    },
    {
      id: '6e58f388-9ef8-499f-b297-724a36b8b667',
      locationText: 'Rzepiennik Strzyżewski',
      locationType: PROPERTY
    },
    {
      id: '47e1bab0-0410-45ab-9027-f4869d69cfa0',
      locationText: 'Nagai',
      locationType: PROPERTY
    },
    { id: 'c9353d7d-24d9-4bd6-bb6a-08de303bc360', locationText: 'Tong’an', locationType: LOCATION },
    {
      id: '954b6243-b9c9-4646-b739-7e61468ab45e',
      locationText: 'Super 8 Tiegai',
      locationType: PROPERTY
    },
    {
      id: 'c1f58fb3-3efa-4e3f-8806-3e2d6af1a383',
      locationText: 'Lordegān',
      locationType: PROPERTY
    },
    {
      id: 'aae35ca5-60cc-410f-85ed-ab23a2827793',
      locationText: 'Nuits-Saint-Georges',
      locationType: PROPERTY
    },
    {
      id: '9027492c-e230-434d-86f8-ead8cbd78ecf',
      locationText: 'Salinópolis',
      locationType: PROPERTY
    },
    {
      id: 'c0479d14-b8e7-44c8-b44c-2cd3c978b910',
      locationText: 'Palalang',
      locationType: LOCATION
    },
    {
      id: 'dd8e7210-b690-40fa-b2d5-1dadfb6300bf',
      locationText: 'Marinilla',
      locationType: PROPERTY
    },
    {
      id: 'd5e4aa6c-37c5-4830-b22a-90411c66faba',
      locationText: 'Kaga Bandoro',
      locationType: PROPERTY
    },
    {
      id: '77bcac3a-7432-45ee-990e-45b64227620d',
      locationText: 'Macatuba',
      locationType: PROPERTY
    },
    {
      id: '50b547b9-0de3-4c80-b3f6-d8f02694c325',
      locationText: 'Leeds',
      locationType: PROPERTY
    },
    { id: 'b2e69b19-a757-4e6c-a7f4-fd139a3ee0a9', locationText: 'Tanniu', locationType: LOCATION },
    {
      id: 'c6a8d497-c56e-4eba-b74b-a668134189e2',
      locationText: 'Adolfo Ruiz Cortines',
      locationType: PROPERTY
    },
    {
      id: '13941b41-595e-4f37-8b5b-36742ac76fb4',
      locationText: 'Köln',
      locationType: PROPERTY
    },
    {
      id: '5bdf6662-e2c3-46bd-b4c8-9023d3047323',
      locationText: 'Clermont-Ferrand',
      locationType: LOCATION
    },
    { id: '632a00a1-cd1d-419a-a6c6-0ee99a56f749', locationText: 'Zábřeh', locationType: LOCATION },
    {
      id: 'c01e8f97-55d7-45f4-9d4a-dfd817571112',
      locationText: 'Nakhabino',
      locationType: LOCATION
    },
    { id: '412c8ce3-fbc3-4525-9450-8062b86cbdf8', locationText: 'Majzar', locationType: LOCATION },
    { id: 'f41942cc-2c8e-49b2-a431-96783cfffade', locationText: 'Kétou', locationType: LOCATION },
    {
      id: '2ca7ca8f-b89e-4472-b8fe-c375c2e86ee5',
      locationText: 'Lokavec',
      locationType: PROPERTY
    },
    {
      id: '381c79c4-440c-4905-bf08-f14637d99b45',
      locationText: 'Shaoguan',
      locationType: LOCATION
    },
    {
      id: 'cbd1bdf6-7130-4dad-a07e-424acea4cc7b',
      locationText: 'Uthai Thani',
      locationType: PROPERTY
    },
    {
      id: 'b9c75628-fdda-4ff0-834d-52f5ed8598d1',
      locationText: 'Sula',
      locationType: PROPERTY
    },
    {
      id: '6839cd2d-e494-4ac6-802b-4cba0864b94f',
      locationText: 'Sandaogou',
      locationType: PROPERTY
    },
    {
      id: '81541e0a-b69b-4a89-8512-93e65656c348',
      locationText: 'Trambalan',
      locationType: PROPERTY
    },
    {
      id: 'ef4e34e8-48ba-4c62-89b5-175b7039875c',
      locationText: 'Junik',
      locationType: PROPERTY
    },
    { id: '83409978-612a-4c83-a9b3-fa26a633e9d5', locationText: 'Pancas', locationType: LOCATION },
    {
      id: '75f2c58d-84dd-4834-b109-d6732f001f4f',
      locationText: 'Samburat',
      locationType: LOCATION
    },
    {
      id: '1b523e21-2c06-49ae-b9cc-fcb229f14d5e',
      locationText: 'Suslonger',
      locationType: PROPERTY
    },
    {
      id: 'a7642b4a-4fed-4217-a0b2-bfe7cde6e920',
      locationText: 'Super 8 Micheng',
      locationType: PROPERTY
    },
    {
      id: '710c3770-7d38-4e19-975f-065de6bed306',
      locationText: 'Kilingi-Nõmme',
      locationType: PROPERTY
    },
    {
      id: '66476b58-d2c4-4b09-9568-cf48153f09fb',
      locationText: 'Cantuk Kidul',
      locationType: PROPERTY
    },
    {
      id: '4e8ac7d6-f198-4c3e-928c-7063573a70af',
      locationText: 'Lipsko',
      locationType: PROPERTY
    },
    {
      id: '28973269-a3bc-4261-bbf6-29393a3dd972',
      locationText: 'Warungpeuteuy',
      locationType: LOCATION
    },
    {
      id: '3a85667d-28b9-4016-ada6-1aa4998aa865',
      locationText: 'Dinititi',
      locationType: LOCATION
    },
    {
      id: '63a2ff4a-5884-4303-bbb7-8c9189f75c67',
      locationText: 'San Francisco',
      locationType: LOCATION
    },
    {
      id: '3c1f0b74-72f6-428d-866d-3c647b08e501',
      locationText: 'Reguengos de Monsaraz',
      locationType: PROPERTY
    },
    { id: 'e8b951cd-e9ac-4aa3-8984-7b9dda4d9233', locationText: 'Xianghu', locationType: LOCATION },
    {
      id: '9e1a74e3-dc27-4029-bb44-3c724f48d6f7',
      locationText: 'Ādīs Zemen',
      locationType: PROPERTY
    },
    { id: '8729e841-e765-4778-ae43-b9d9c5d406b7', locationText: 'Rouen', locationType: LOCATION },
    {
      id: '306e6f37-a4b7-4826-92a4-8246b51cb79c',
      locationText: 'Granja do Tedo',
      locationType: PROPERTY
    },
    { id: 'fc39e9a7-00f7-45a5-a04a-f32486f86dc1', locationText: 'Tomice', locationType: LOCATION },
    {
      id: '11161b35-fbc3-4026-bc28-d46a5d7707a6',
      locationText: 'Ljubešćica',
      locationType: LOCATION
    },
    {
      id: '12a454a1-4a40-4406-84c8-e46bf5ed97db',
      locationText: 'Super 8 Osieczany',
      locationType: LOCATION
    },
    { id: '8bef93f8-6565-4e5f-b5fb-6c55e7d03fa2', locationText: 'Jiebu', locationType: LOCATION },
    {
      id: 'abdd03b8-e755-4b94-8605-204aecc1bb3a',
      locationText: 'Sundsvall',
      locationType: LOCATION
    },
    {
      id: 'f30b11e8-0534-4034-8cb0-a3892e17e5ef',
      locationText: 'San Miguel',
      locationType: LOCATION
    },
    {
      id: '54f3220c-693f-4e47-b921-af8bc060f579',
      locationText: 'Vasyshcheve',
      locationType: PROPERTY
    },
    { id: 'e55128d2-6fec-4512-99f5-2f105f364ab1', locationText: 'Kladovo', locationType: LOCATION },
    {
      id: 'b18d9258-3ca0-4c46-ace9-1ef5a8c16f34',
      locationText: 'Coelho Neto',
      locationType: PROPERTY
    },
    {
      id: '08d2068f-c939-4462-86d2-cbbdfc5e2c31',
      locationText: 'Dayanggezhuang',
      locationType: PROPERTY
    },
    { id: '176c3e97-68fd-4e6a-b0f3-62ee4bf1fbec', locationText: 'Ngori', locationType: LOCATION },
    {
      id: '11e54e4e-d094-47d7-981c-03b334d7b9ee',
      locationText: 'Berezovo',
      locationType: PROPERTY
    },
    {
      id: 'c3460775-ac36-4551-83b4-53e53f18f46c',
      locationText: 'Kota Bharu',
      locationType: PROPERTY
    },
    {
      id: '9e933ead-2841-42e6-807e-ca9353ee8198',
      locationText: 'Moss',
      locationType: PROPERTY
    },
    { id: '6705e39c-1213-4cbe-bd0d-ca068f85439d', locationText: 'Mendes', locationType: LOCATION },
    {
      id: '9a85a3ce-079f-4363-9ed5-ce5832d6bbee',
      locationText: 'Ljungskile',
      locationType: LOCATION
    },
    {
      id: 'cc1649bb-87b2-47af-bce6-be3509fd2000',
      locationText: 'Asaka',
      locationType: PROPERTY
    },
    {
      id: 'd8e85d21-a452-4151-9c19-3a2091346234',
      locationText: 'Liudu',
      locationType: PROPERTY
    },
    {
      id: 'f948a63a-9c1d-4ede-962f-044ae8187ed9',
      locationText: 'Pires do Rio',
      locationType: PROPERTY
    },
    {
      id: '42df22d2-d38f-486d-9caf-e4e5690cee85',
      locationText: 'Shaxi',
      locationType: PROPERTY
    },
    {
      id: '728f374f-de93-436c-8a48-960ae2b806bf',
      locationText: 'Aqtaū',
      locationType: PROPERTY
    },
    {
      id: '87b432f9-dcea-4d45-bf35-000790dd5c9d',
      locationText: 'Kuala Terengganu',
      locationType: LOCATION
    },
    {
      id: 'f75ac296-7a61-4438-905c-552a672eb92b',
      locationText: 'Gaoqiao',
      locationType: PROPERTY
    },
    {
      id: '4e61181a-40a6-4b6c-9d97-81823d920ade',
      locationText: 'Thawi Watthana',
      locationType: PROPERTY
    },
    {
      id: '3e377b20-0e85-4225-9f79-3d2e91ecc1e8',
      locationText: 'Illela',
      locationType: PROPERTY
    },
    { id: '7c497a26-3bf7-4e96-8875-16ee63553ed9', locationText: 'Leńcze', locationType: LOCATION },
    {
      id: 'ee213edb-b663-4d9e-b748-8da0960f7736',
      locationText: 'Wolbrom',
      locationType: PROPERTY
    },
    {
      id: '2a1d992c-f963-4438-99ee-56988f547c74',
      locationText: 'Tirhanimîne',
      locationType: PROPERTY
    },
    { id: '12d11e4f-e766-49c3-9e26-f0fe6cfa637d', locationText: 'Oehala', locationType: LOCATION },
    {
      id: '36af9fba-af81-40a5-9b0e-2c31a2bf2430',
      locationText: 'Super 8 Daxindian',
      locationType: LOCATION
    },
    {
      id: 'db8f690d-4ef2-4b90-8341-2f2ec590fd71',
      locationText: 'Pokotylivka',
      locationType: LOCATION
    },
    {
      id: '836f99f4-07fa-41f8-9f15-f336525bad07',
      locationText: 'Arísvi',
      locationType: PROPERTY
    },
    { id: '2f436ae5-9b8f-4fc8-8919-c2949d320cb2', locationText: 'Bungkal', locationType: LOCATION },
    {
      id: 'b1c24f50-eb37-4e9e-bcc9-794b9a07bb98',
      locationText: 'Buôn Ma Thuột',
      locationType: PROPERTY
    },
    {
      id: '319e809c-3039-44f1-929d-c6876364b814',
      locationText: 'Eusébio',
      locationType: PROPERTY
    },
    {
      id: 'd3554ddf-5167-483a-9bfc-e30e9358005c',
      locationText: 'Ayia Napa',
      locationType: LOCATION
    },
    {
      id: '02605cf2-340d-471c-ab5b-b080d1a07eaf',
      locationText: 'Araguaiana',
      locationType: PROPERTY
    },
    { id: '93b3694a-634e-4b17-abff-c032ae797ecc', locationText: 'Kari', locationType: LOCATION },
    {
      id: '830d0553-e4a9-418b-a756-a8058bdb8e66',
      locationText: 'Namur',
      locationType: PROPERTY
    },
    {
      id: '822daf7b-ecbe-45fe-b12a-2bd0476e07c6',
      locationText: 'El Salitre',
      locationType: LOCATION
    },
    {
      id: '546c9098-4e36-4cb4-8306-c95ff495d336',
      locationText: 'Borås',
      locationType: PROPERTY
    },
    {
      id: 'cd47caa6-305a-41c4-8ec5-4d3d99914556',
      locationText: 'Muruni',
      locationType: PROPERTY
    },
    {
      id: 'ff175d69-bf60-4e75-84e0-20b18329dd29',
      locationText: 'Huangwan',
      locationType: LOCATION
    },
    {
      id: '61dedb2f-540a-44d0-8e35-e2e568461e86',
      locationText: 'Hamilton',
      locationType: PROPERTY
    }
  ]
};
