import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ArcticHalls extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m9.39 12.45v.05h.7v-.05-7.02-3.12h-.7v.95l-.72-.71-.49.49 1.21 1.21v1.18 1.17l-1.6-1.6v-1.71h-.7v1.01l-.66-.66-.5.49.68.68h-1.01v.7h1.71l1.54 1.54h-2.18l-1.21-1.21-.5.49.72.72h-.93v.7h.95l-.71.71.49.5 1.21-1.21h2.24l-1.58 1.58h-1.71v.7h1.02l-.66.66.49.5.68-.68v1.01h.7v-1.71l1.52-1.52v2.29l-1.21 1.21.49.49.72-.72z"
        />
        <path
          fill={iconColor}
          d="m14.75 15.91v-7.63h-1v7.63a2.43 2.43 0 1 0 1 0zm-.5 3.8a1.43 1.43 0 1 1 1.43-1.42 1.43 1.43 0 0 1 -1.43 1.42z"
        />
        <path
          fill={iconColor}
          d="m17.25 14v-10a3 3 0 0 0 -6 0v10a5 5 0 1 0 6 0zm-3 8a4 4 0 0 1 -2.2-7.34l.25-.17-.05-10.49a2 2 0 0 1 4 0v10.1.39l.26.17a4 4 0 0 1 -2.26 7.34z"
        />
      </svg>
    );
  }
}
