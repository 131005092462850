import styled from 'styled-components';
import { mixins } from '../../../css';

export const StyledLinkList = styled.ul`
  padding: ${mixins.pxToRem(15)} 0 0 0;
`;

export const StyledLinkListItem = styled.li`
  padding: ${mixins.pxToRem(10)} 0;
`;
