// exclude-from-index
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../SVGIcon/SVGIcon';
import Text from '../../BuildingBlocks/Text';
import { colors } from '../../css';
import Constants from './Flyout.constants';
import { locationsShape, patternsShape } from '../Search.shapes';
import iconMap from './Flyout.iconMap';
import { StyledFlyout, FlyoutItem, LeftBlock, ItemContainer } from './Flyout.styles';
import IgnoreTranslation from '../../Localize/ignoreTranslation';

/**
 * Flyout component used by the Search component
 */
export default class Flyout extends PureComponent {
  static propTypes = {
    /** Override Border Width */
    borderWidth: PropTypes.string,

    /** Boolean to turn off the flyout collapse animation */
    disableFlyoutAnimation: PropTypes.bool,

    /** an array of Locations (results) */
    locations: locationsShape,

    /** Disable box shadow */
    noShadow: PropTypes.bool,

    /** an array of Patterns (suggestions for searching) */
    patterns: patternsShape,

    /** position top setting for CSS */
    offsetTop: PropTypes.string,

    /** Hook called when a Flyout Item is clicked */
    onFlyoutItemClickHook: PropTypes.func,

    /** Set the overflow to visible instead of auto */
    overflowVisible: PropTypes.bool,

    /** Position Relative override */
    positionFixed: PropTypes.bool,

    /** Label for Search Formats to allow localization */
    searchFormatsLabel: PropTypes.string
  };

  static defaultProps = {
    borderWidth: undefined,
    disableFlyoutAnimation: false,
    locations: [],
    noShadow: false,
    patterns: [],
    offsetTop: undefined,
    onFlyoutItemClickHook: null,
    overflowVisible: false,
    positionFixed: false,
    searchFormatsLabel: 'Search Formats'
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.locations) {
      return {
        sortedLocations: Flyout.sortLocations(nextProps.locations)
      };
    }

    return null;
  }

  /**
   * Sort location data prioritizing Cities/Location types first,
   * and then sort alphabetically.
   */
  static sortLocations(locations) {
    const typeIsLocation = type => type === Constants.LocationTypes.LOCATION;
    const citiesOnly = locations.filter(loc => typeIsLocation(loc.locationType));
    const notCities = locations.filter(loc => !typeIsLocation(loc.locationType));

    if (!locations.length) return locations;
    return [
      ...citiesOnly.sort((a, b) => a.locationText.localeCompare(b.locationText)),
      ...notCities.sort((a, b) => a.locationText.localeCompare(b.locationText))
    ];
  }

  constructor(props) {
    super(props);
    this.locationsRefs = [];
    this.state = {
      highlighted: -1,
      sortedLocations: []
    };
  }

  /**
   * Add document.eventListeners on componentMount
   * keydown - for accessibility purposes, allows navigation of th custom dropdown component
   */
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDownEvent);
  }

  /**
   * Cleanup for eventListeners when the component is unmounted
   */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDownEvent);
  }

  /**
   * Logic to handle different keydown events based on which key is pressed
   * This mimics native accessibility functionality:
   *
   * * Tab to focus on the element
   * * Spacebar to open the dropdown
   * * Arrow up/down to navigate
   * * Spacebar (while expanded) selects an option
   * * Escape (while expanded) collapses the dropdown
   *
   * @param {KeyboardEvent} e - KeyboardEvent passed in by document
   *
   */
  handleKeyDownEvent = e => {
    const { highlighted, sortedLocations } = this.state;
    const { closeFlyoutAndFocusInput, closeFlyout } = this.props;
    const { key } = e;

    /** If we're actively selecting an item, disable the TAB key */
    if (key === 'Tab') {
      closeFlyout();
    }

    /** If we hit the Escape key and we're expanded, close up and clean our state */
    if (key === 'Escape') {
      /**
       * Preventing default here stops the ESC key from clearing the field
       * For some reason, this is the default behaviour for input[type=search]
       * Google's search fields don't do this and it seems annoying from a UX perspective
       */
      e.preventDefault();

      this.setState({ highlighted: -1 });
      closeFlyoutAndFocusInput();
    }

    /** Capture arrow up and down and focus on each option as required. */
    if ((key === 'ArrowDown' || key === 'ArrowUp') && this.locationsRefs.length > 0) {
      e.preventDefault();
      let newValue;

      /**
       * If we hit the down arrow, change our highlighted value by +1
       * Otherwise, change it by -1
       */
      if (key === 'ArrowDown') {
        newValue = highlighted === sortedLocations.length - 1 ? 0 : highlighted + 1;
      } else if (highlighted === -1) {
        newValue = sortedLocations.length - 1;
      } else {
        newValue = highlighted === 0 ? sortedLocations.length - 1 : highlighted - 1;
      }

      /**
       * We're using an array to store references to the option nodes so we can
       * access them dynamically. Calling .focus() on the ref.
       */
      this.locationsRefs[newValue].focus();

      /**
       * Update our state with the new value of highlighted so we can compare it next time
       * one of the up/down arrow keys are hit.
       */
      this.setState({ highlighted: newValue });
    }

    /**
     * If we hit enter/spacebar, and we're expanded, set the currently focused element
     * as the selected one in our state.
     */
    if ((key === 'Enter' || key === ' ') && highlighted !== -1) {
      e.preventDefault();

      /** Don't reset 'focused' here since we're re-focusing on the DropdownHead in our callback */
      this.setState({ highlighted: -1 }, () => this.handleClickItem(sortedLocations[highlighted]));
      closeFlyoutAndFocusInput();
    }

    return false;
  };

  handleClickItem = value => {
    const { onFlyoutItemClickHook } = this.props;

    onFlyoutItemClickHook && onFlyoutItemClickHook(value);
  };

  /**
   * Take our location type and spit out an icon
   * This may be unnecessary abstraction but we're
   * shooting in the dark a bit at this stage.
   */
  mapLocationTypeToIcon = locationType => {
    if (typeof locationType !== 'string') return iconMap.default;
    return iconMap[locationType.toLowerCase()] || iconMap.default;
  };

  render() {
    const { patterns, closeFlyoutAndFocusInput, searchFormatsLabel, ...props } = this.props;
    const { sortedLocations } = this.state;

    /**
     * Render all our locations back from the API
     * If we're not in a loading state (default prop),
     * show the items. Otherwise, display a loading message
     */
    const renderItems = (item, type, frenchFlag) => {
      const { locationType, patternName, patternDescription, locationText } = item;
      const iconName = this.mapLocationTypeToIcon(locationType);
      const headerTextProps =
        type === Constants.ResultsTypes.PATTERNS ? { color: 'tertiary', weight: 'bold' } : { color: 'primary' };
      const key = patternName ? patternName.replace(/ /g, '-') : locationText.replace(/ /g, '-');

      return (
        <FlyoutItem
          key={key}
          role="option"
          ref={node => {
            if (type !== Constants.ResultsTypes.PATTERNS && node) {
              this.locationsRefs.push(node);
            }
          }}
          tabIndex="0"
          type={type}
          noHover={type === Constants.ResultsTypes.PATTERNS}
          onMouseDown={() => this.handleClickItem(item)}
        >
          <LeftBlock>
            <SVGIcon
              selectedIcon={iconName}
              iconColor={type === Constants.ResultsTypes.PATTERNS ? colors.colorGrey03 : colors.colorGrey}
            />
          </LeftBlock>
          <ItemContainer type={type}>
            {frenchFlag ? (
              <IgnoreTranslation inTextEdit>
                <Text.CopyParagraph {...headerTextProps} noMargins>
                  {patternName || locationText}
                </Text.CopyParagraph>
              </IgnoreTranslation>
            ) : (
              <Text.CopyParagraph {...headerTextProps} noMargins>
                {patternName || locationText}
              </Text.CopyParagraph>
            )}
            {patternDescription && (
              <Text.CopyParagraph color="tertiary" noMargins>
                {patternDescription}
              </Text.CopyParagraph>
            )}
          </ItemContainer>
        </FlyoutItem>
      );
    };

    /** Generate our Patterns, Locations, and then our full list to render */
    const locationsLoaded = sortedLocations.length > 0;
    const itemsToRender = [
      ...sortedLocations.map(item => renderItems(item, Constants.ResultsTypes.LOCATIONS, true)),
      ...patterns.map(item => renderItems(item, Constants.ResultsTypes.PATTERNS))
    ];
    const loadingState = (
      <FlyoutItem key="nav-search-flyout-header" id="globalnav-search-flyout-header" noHover>
        <LeftBlock />
        <Text.CopyParagraph color="tertiary" noMargins>
          Loading...
        </Text.CopyParagraph>
      </FlyoutItem>
    );

    return (
      <StyledFlyout {...props}>
        {itemsToRender.length > 0 ? (
          <>
            {!locationsLoaded && (
              <FlyoutItem key="nav-search-flyout-header" id="globalnav-search-flyout-header" noHover>
                <LeftBlock />
                <Text.CopyParagraph color="tertiary" noMargins>
                  {searchFormatsLabel}
                </Text.CopyParagraph>
              </FlyoutItem>
            )}
            {itemsToRender}
          </>
        ) : (
          loadingState
        )}
      </StyledFlyout>
    );
  }
}
