import React from 'react';
import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors, fonts, mixins } from '../../css';

/**
 * Define properties for different button sizes.
 * The styles are written in SCSS format using template literals tagged with the
 * `css` helper from styled-components
 */
export const btnSizes = {
  sm: css`
    margin: 0;
    padding: ${({ pill }) =>
      pill ? `${mixins.pxToRem(8)} ${mixins.pxToRem(20)}` : `${mixins.pxToRem(20)} ${mixins.pxToRem(50)}`};
  `,

  lg: css`
    margin: 0;
    padding: ${({ pill }) => (pill ? `${mixins.pxToRem(16)}` : `${mixins.pxToRem(32)}`)};
  `
};
export const sizeNames = Object.keys(btnSizes);
export const [DEFAULT_SIZE] = sizeNames;

/**
 * Define properties for different button themes.
 * The styles are written in SCSS format using template literals tagged with the
 * `css` helper from styled-components
 */
export const btnThemes = {
  blue: css`
    background-color: ${colors.colorBlue};
    color: ${colors.colorWhite};
    border: 1px solid ${colors.colorBlue};
    &:hover {
      cursor: pointer;
      background-color: ${colors.colorBlue02};
      border-color: ${colors.colorBlue02};
      color: ${colors.colorWhite};
    }
  `,
  burgundy: css`
    background-color: ${colors.colorBurgundy};
    color: ${colors.colorWhite};
    border: 1px solid ${colors.colorBurgundy};
    &:hover {
      cursor: pointer;
      background-color: ${colors.colorBurgundy02};
      border-color: ${colors.colorBurgundy02};
      color: ${colors.colorWhite};
    }
  `,
  white: css`
    background-color: ${colors.colorWhite};
    color: ${colors.colorBlue};
    border: 1px solid ${colors.colorWhite};
    &:hover {
      cursor: pointer;
      background-color: ${colors.colorGrey05};
      border-color: ${colors.colorGrey05};
    }
  `,
  ghost: css`
    background-color: ${colors.colorWhite};
    color: ${colors.colorBlue};
    border: 1px solid ${colors.colorBlue};
    &:hover {
      cursor: pointer;
      background-color: ${colors.colorGrey05};
    }
  `,
  grey: css`
    background-color: ${colors.colorGrey04};
    color: ${colors.colorGrey03};
    border: 1px solid ${colors.colorGrey04};
    &:hover {
      cursor: pointer;
      background-color: ${colors.colorGrey03};
      border-color: ${colors.colorGrey03};
      color: ${colors.colorGrey02};
    }
  `,
  disabled: css`
    background-color: ${colors.colorGrey04};
    color: ${colors.colorGrey03};
    border: 1px solid ${colors.colorGrey04};
    &:hover {
      background-color: ${colors.colorGrey04};
      border-color: ${colors.colorGrey04};
      color: ${colors.colorGrey03};
      cursor: auto;
    }
  `
};
export const themeNames = Object.keys(btnThemes);
export const [DEFAULT_THEME] = themeNames;

const spreadButton = ({
  size,
  theme,
  block,
  uppercase,
  disabled,
  pill,
  fullHeight,
  fullWidth,
  type,
  children,
  loading,
  ...rest
}) => (
  /* eslint-disable react/button-has-type */
  <button disabled={disabled} type={type} aria-label={rest.name} {...rest}>
    {children}
  </button>
);
export const StyledButton = styled(spreadButton)`
    /* This reverts the default browser borders for button themes that don't explicitly specify one */
    border: none;

    /* Properties controlled by size prop */
    ${({ size }) => btnSizes[size]}
      
    /* Properties controlled by theme prop */
    ${({ theme }) => btnThemes[theme]}

    /** If the button is disabled, use that theme instead */
    ${({ disabled }) => disabled && btnThemes.disabled}

    /* height:100% if fullHeight flag is passed */
    ${({ fullHeight }) => fullHeight && `height: 100%; padding-top: 0; padding-bottom: 0;`}

    /* height:100% if fullHeight flag is passed */
    ${({ fullWidth }) => fullWidth && `width: 100%; padding-left: 0; padding-right: 0;`}
    
    /* Global properties */
    /* Typopgraphy */
    font-family: ${({ pill }) => (pill ? fonts.fontRegular : fonts.fontBold)};
    font-size: ${mixins.pxToRem(14)};
    letter-spacing: 1px;
    line-height: ${mixins.pxToRem(14)};
    text-decoration: none;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    user-select: none;

    /* Element Positioning */
    display: ${({ block }) => (block ? 'block' : 'inline-block')};
    position: relative;
    box-sizing: border-box;

    /* Element Style */
    transition: background-color 125ms;
    transition-property: background-color, border, color;

    /* Pill flag */
    ${({ pill }) => pill && `border-radius: ${mixins.pxToRem(50)};`}
     

    /* Global Hover Rules */
    &:hover {
      text-decoration: none;
    }
`;

export const NeutralAnchor = styled.a`
  text-decoration: none;
  font-size: ${mixins.pxToRem(14)};
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  ${({ fullHeight }) => fullHeight && 'height: 100%'};
`;

export const StyledSpinner = styled(CircularProgress)`
  && {
    color: white;
    margin: 0;
  }
`;

export const SpinnerWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ pill }) => pill && `border-radius: ${mixins.pxToRem(50)};`};
`;
