import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ArrowIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 16,
    width: 22,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 22 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={backgroundColor} fillRule="nonzero" width="22">
            <polygon
              points="18.9419526 5 10.5 13.7978212 2.05754905 5 1 6.09979249 10.5 16 20 6.09979249"
              fill={iconColor}
            />
          </g>
        </g>
      </svg>
    );
  }
}
