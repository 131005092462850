import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Construction extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 120,
    iconColor: colors.colorGrey02,
    backgroundColor: colors.iconGreen
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
        <polygon
          fill={backgroundColor}
          opacity={0.4}
          points="86.39 74.58 90.33 78.51 87.14 90.54 75.12 93.79 71.12 89.81 75.72 85.23 79.77 85.7 82.25 82.66 81.83 79.13 86.39 74.58"
        />
        <polyline
          fill={backgroundColor}
          opacity={0.4}
          points="77.33 48.11 73.65 44.46 31.31 86.88 34.99 90.53 65.91 59.55 71.12 61.6 77.33 61.05 81.88 59.09 85.18 56.51 77.33 48.11"
        />
        <polygon
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          points="81.5 69.73 69.49 72.98 66.3 85.01 75.12 93.79 87.14 90.54 90.33 78.51 81.5 69.73"
        />
        <path
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          d="M85.18,56.51A17.66,17.66,0,0,1,65.1,60L34.25,90.79a6,6,0,0,1-8.24.45,5.82,5.82,0,0,1-.23-8.45l31-31A17.59,17.59,0,0,1,55,43.21a17.93,17.93,0,0,1,17.24-17A17.64,17.64,0,0,1,80.44,28L69,39.41Z"
        />
        <circle
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          cx="78.31"
          cy="81.76"
          r="3.94"
        />
        <path
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          d="M73.51,42.7l8.07-7.42S91.7,43.55,82,52.44"
        />
      </svg>
    );
  }
}
