import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ZoomInIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg viewBox="0 0 50 50" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
          <g id="Icons_x2F_Search" transform="translate(-1.000000, 0.000000)">
            <g id="Icon_LL_Search" transform="translate(1.000000, 0.000000)">
              <path
                id="Circle"
                d="M21.5,40.8c-7.8,0-14.8-4.7-17.8-11.9s-1.3-15.5,4.2-21s13.8-7.2,21-4.2s11.9,10,11.9,17.8
				C40.7,32.1,32.1,40.7,21.5,40.8z M21.5,5.5c-8.8,0-15.9,7.1-15.9,15.9s7.1,15.9,15.9,15.9s15.9-7.1,15.9-15.9S30.3,5.5,21.5,5.5z
				"
                fill={iconColor}
              />

              <rect
                id="Handle"
                x="39.7"
                y="30.9"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.1071 41.3068)"
                width="3.3"
                height="20.9"
                fill={iconColor}
              />
              <rect id="Minus" x="11" y="19.8" width="20.9" height="3.3" fill={iconColor} />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
