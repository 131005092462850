import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Utility/Grid';
import FooterColumn from './FooterColumn/FooterColumn';
import { StyledFooter, StyledLogoColumn } from './Footer.styles';
import FooterCopyright from './FooterCopyright/FooterCopyright';
import FooterIconList from './FooterIconList/FooterIconList';
import FooterLink from './FooterLink/FooterLink';
import FooterLinkList from './FooterLinkList/FooterLinkList';
import FooterLocaleSelect from './FooterLocaleSelect/FooterLocaleSelect';
import FooterPhoneNumber from './FooterPhoneNumber/FooterPhoneNumber';
import FooterSVGLink from './FooterSVGLink/FooterSVGLink';
import FooterLabel from './FooterLabel';

/**
 * The Global Footer object. Contains components utilized only within the Footer
 * and contained within it's base folder. Mainly static content
 */
class Wrapper extends Component {
  static propTypes = {
    columns: PropTypes.node,
    footerSelect: PropTypes.node,
    copyright: PropTypes.node
  };

  static defaultProps = {
    columns: [],
    footerSelect: undefined,
    copyright: undefined
  };

  /* eslint-disable react/no-array-index-key */
  render() {
    const { columns, copyright, footerSelect } = this.props;
    return (
      <StyledFooter>
        <Grid.Container fluid={false}>
          <Grid.Row>
            <StyledLogoColumn sm={12} md={12} lg={12 / columns.length}>
              {columns[0].type === FooterColumn ? columns[0] : null}
            </StyledLogoColumn>
            <Grid.Col sm={12} md={12 / (columns.length - 1)} lg={12 / columns.length}>
              {columns[1].type === FooterColumn ? columns[1] : null}
            </Grid.Col>
            <Grid.Col sm={12} md={12 / (columns.length - 1)} lg={12 / columns.length}>
              {columns[2].type === FooterColumn ? columns[2] : null}
            </Grid.Col>
            <Grid.Col sm={12} md={12 / (columns.length - 1)} lg={12 / columns.length}>
              {columns[3].type === FooterColumn ? columns[3] : null}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row align="center">
            <Grid.Col sm={12} md={12} lg={12}>
              {copyright}
            </Grid.Col>
            <Grid.Col sm={12} md={12} lg={12}>
              {footerSelect}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </StyledFooter>
    );
  }
}

export default {
  Wrapper,
  FooterColumn,
  FooterCopyright,
  FooterIconList,
  FooterLink,
  FooterLinkList,
  FooterLocaleSelect,
  FooterPhoneNumber,
  FooterSVGLink,
  FooterLabel
};
