import React from 'react';
import styled from 'styled-components';
import { mixins, colors, fonts, media } from '../../../../css';

const spreadTouch = ({ isTouchDevice, children, ...rest }) => <a {...rest}>{children}</a>;

// eslint-disable-next-line
export const StyledLink = styled(spreadTouch)`
  cursor: pointer;
  font-size: ${mixins.pxToRem(14)};
  color: ${colors.colorGrey};
  font-family: ${fonts.fontRegular};
  text-decoration: none;
  display: flex;

  ${media.greaterThan('lg')`flex-flow: row nowrap; align-items: center; justify-items: center; height: 100%;`};

  ${({ isTouchDevice }) =>
    !isTouchDevice
      ? `&:hover {
    color: ${colors.colorBlue};
    text-decoration: underline;
  }`
      : ''};
`;
