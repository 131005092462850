import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SearchContainer } from './Search.styles';
import SearchField from './SearchField';
import Flyout from './Flyout';

import { locationsShape, patternsShape } from './Search.shapes';

/**
 * The search component is a combination of the `SearchField`, `Button` and `Flyout` components.
 * The component takes in a `locations` object. Please refer to the `Search.shape` for the proper interface
 */
class Search extends PureComponent {
  static propTypes = {
    /**
     * Boolean prop to turn on autocomplete
     * Defaults to `false`
     */
    autocomplete: PropTypes.bool,

    /** Override the default focused border width */
    borderFocusedWidth: PropTypes.string,

    /** Override default border width */
    borderWidth: PropTypes.string,

    /**
     * Accepts JSX/Components to display a Button to the right of the SearchField inside
     * of the FormControl component. Attach click handlers to this component directly.
     */
    button: PropTypes.node,

    /**
     * Method to clear the value controlled by parent container.
     * This must be sent down from the parent container.
     * Used by the clear search button
     */
    clearValue: PropTypes.func,

    /** Flag to disable the flyout */
    disableFlyout: PropTypes.bool,

    /* Flag to tell the SearchField whether or not it has an adjacent button - this is a huge edge case prop mainly for NavSearch */
    hasButton: PropTypes.bool,

    /**
     * Height override for Search component
     */
    height: PropTypes.number,

    /**
     * Represents the id HTML attribute to be passed to the SearchField.
     */
    id: PropTypes.string,

    /**
     * Method to hook into closeFlyout method for extra functionality
     */
    onFlyoutCloseHook: PropTypes.func,

    /**
     * Override for max-height of the Flyout component
     */
    flyoutMaxHeight: PropTypes.string,

    /**
     * Accepts JSX/Components to display on the SearchField. Styling is the responsibility
     * of the prop-passer.
     */
    icon: PropTypes.node,

    /**
     * Label that displays when the input is blurred & empty and shrinks/animates
     * on focus.
     */
    labelText: PropTypes.string,

    /**
     * Represents the name HTML attribute to be passed to SearchField.
     * Additionally passed to the label as a for attribute.
     */
    name: PropTypes.string,

    /**
     * Callback for the onBlur handlers inside the SearchField component.
     */
    onBlurHook: PropTypes.func,

    /**
     * Calback for the onClick handlers inside the SearchField component.
     */
    onClickHook: PropTypes.func,

    /**
     * onChangeHook handler passed down to the input itself to callback to the parent
     * when a user changes the input. Use this to ensure your parent container's value
     * is kept in sync and passed down the tree to the input.
     */
    onChangeHook: PropTypes.func,

    /**
     * onFlyoutItemClickHook handler for clicking on a FlyoutItem, or, locations item.
     */
    onFlyoutItemClickHook: PropTypes.func,

    /**
     * Callback for the onFocus handlers inside the SearchField component.
     */
    onFocusHook: PropTypes.func,

    /**
     * Array of objects representing patterns or pattern suggets returned from the API.
     * These are passed down directly to the Flyout component.
     */
    patterns: patternsShape,

    /**
     * Placeholder string that displays while the input is focused AND
     * has no value.
     */
    placeholder: PropTypes.string,

    /**
     * Array of objects representing locations or result suggests returned from the API.
     * These are passed down directly to the Flyout component.
     */
    locations: locationsShape,

    /**
     * Localization label to be passed to Flyout component
     */
    searchFormatsLabel: PropTypes.string,

    /**
     * String passed down the tree to the input itself to control the value of
     * the input. Ensure that you provide an onChangeHook handler to update the value
     * in your parent container.
     */
    value: PropTypes.string,

    noButton: PropTypes.bool,
    /** combines the flyout hook item selection with the close and focus function. if false, selecting the flyout item will not close the flyout. */
    closeFlyoutOnSelection: PropTypes.bool,

    required: PropTypes.bool,

    /** an override for disallowing submission */
    canSubmit: PropTypes.bool
  };

  static defaultProps = {
    autocomplete: false,
    borderFocusedWidth: undefined,
    borderWidth: undefined,
    button: null,
    clearValue: () => {},
    disableFlyout: false,
    hasButton: false,
    height: 80,
    icon: null,
    id: 'globalnav-search-input',
    flyoutMaxHeight: '70vh',
    labelText: 'Search Accommodations',
    name: 'globalnav-search-input',
    noButton: false,
    onBlurHook: () => {},
    onClickHook: () => {},
    onChangeHook: () => {},
    onFlyoutItemClickHook: () => {},
    onFlyoutCloseHook: () => {},
    onFocusHook: () => {},
    closeFlyoutOnSelection: true,
    patterns: [],
    placeholder: 'Search...',
    locations: [],
    searchFormatsLabel: 'Search Formats',
    value: '',
    required: true,
    canSubmit: true
  };

  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = {
      searchValue: value,
      searchName: 'HomepageSearch',
      focused: false,
      expandFlyout: false,
      showWarning: false
    };
    this.searchTimer;
    this.searchFieldRef = React.createRef();

    this.closeFlyoutOnItemClick = this.closeFlyoutOnItemClick.bind(this);
    this.emitFlyoutItemClickHook = this.emitFlyoutItemClickHook.bind(this);
  }

  componentDidUpdate() {
    const { value } = this.props;
    const { searchValue, focused } = this.state;

    // check that passed in prop is new and this search isn't currently focused
    // (you aren't currently typing in it)
    if (!focused && value !== searchValue) {
      this.setState({ searchValue: searchValue }); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  }

  /**
   * Method that returns a boolean and runs up
   * the tree to see if we've changed focus
   * out of an entire container.
   *
   * This is used to ensure that if we change focus from the SearchField
   * to the Flyout component that we don't 'lose focus' and close
   * our Flyout pre-emptively.
   */
  focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
    /** If our target is null, back out quick */
    if (relatedTarget === null) return false;

    /** Start with our parentNode */
    let node = relatedTarget.parentNode;

    /**
     * While we still have a parentNode (not null), keep checking
     * If we find that our parentNode matches our actual target,
     * return true.
     *
     * If we go up the tree and never have a match, return false.
     */
    while (node !== null) {
      if (node === currentTarget) return true;
      node = node.parentNode;
    }

    return false;
  };

  /**
   * Internal onChangeHook handler
   */
  onChange = e => {
    const {
      target: { value, name }
    } = e;
    const { onChangeHook } = this.props;
    const { expandFlyout } = this.state;
    const context = this;
    clearTimeout(this.searchTimer);
    if (onChangeHook) {
      this.searchTimer = setTimeout(() => {
        context.emitChangeHook();
      }, 250);
    }

    if (!expandFlyout && (value && value.length > 0)) {
      this.setState({ expandFlyout: true, focused: true, searchValue: value, searchName: name, showWarning: false });
    } else if (value && value.length > 0) {
      this.setState({ searchValue: value, searchName: name, showWarning: false });
    } else {
      this.setState({ searchValue: value, searchName: name });
    }
  };

  // eslint says this function is not being used but looks like it's being used on line 262
  // eslint-disable-next-line
  emitChangeHook = () => {
    const { onChangeHook } = this.props;
    const { searchName, searchValue } = this.state;
    onChangeHook && onChangeHook({ target: { value: searchValue, name: searchName } });
  };

  /**
   * Toggles our focused state to 'true' so we can tell the Flyout it's time to shine
   */
  onFocus = () => {
    const { onFocusHook } = this.props;

    /** Call onFocus override passed as a prop - we still handle our focused state internally */
    onFocusHook && onFocusHook();
    this.setState({ focused: true, expandFlyout: true });
  };

  /**
   * Toggles our focused state to 'false' so we can tell the Flyout to go away
   */
  onBlur = e => {
    const { onBlurHook } = this.props;

    /**
     * Check and make sure we've blurred our entire container (clicked out) / hit escape
     */
    if (!this.focusInCurrentTarget(e)) {
      this.setState({ focused: false, expandFlyout: false, showWarning: false });
      /** Call onBlur override passed as a prop - we still handle our focused state internally */
      onBlurHook && onBlurHook(e);
    }
  };

  /**
   * Fires onClick hook
   */

  onClick = e => {
    const { onClickHook } = this.props;
    onClickHook && onClickHook(e);
  };

  /** Force close the Flyout, return focus to the input DOESN"T SEEM TO WORK */
  closeFlyoutAndFocusInput = () => {
    const { onFlyoutCloseHook } = this.props;
    this.setState({ expandFlyout: false, focused: true });
    this.searchFieldRef.current.focus();
    onFlyoutCloseHook && onFlyoutCloseHook();
  };

  closeFlyout = () => {
    this.setState({ expandFlyout: false });
  };

  handleSearchKeyDown = e => {
    const { key } = e;
    if (key === 'Escape') {
      /**
       * Preventing default here stops the ESC key from clearing the field
       * For some reason, this is the default behaviour for input[type=search]
       * Google's search fields don't do this and it seems annoying from a UX perspective
       */
      e.preventDefault();
    }
  };

  handleSearchKeyPress = e => {
    const { key } = e;
    const { onSubmitHook, value, required, canSubmit } = this.props;
    if (key === 'Enter') {
      if (!required || (required && value && value.length > 0)) {
        // if not required, or required & has value: submit
        this.closeFlyout();
        e.preventDefault();
        if (canSubmit) {
          this.showWarning(false);
          onSubmitHook && onSubmitHook(e);
        }
      } else {
        // else do nothing
        onSubmitHook && onSubmitHook(e);
        e.preventDefault();
      }
    }
  };

  handleSubmit = e => {
    const { onSubmitHook, canSubmit } = this.props;
    e.preventDefault();
    if (canSubmit) {
      this.closeFlyout();
      this.showWarning(false);
      onSubmitHook && onSubmitHook(e);
    } else {
      // else do nothing
      this.showWarning(true);
    }
  };

  handleClearSearch = () => {
    const { clearValue } = this.props;
    this.setState({ searchValue: '' });
    clearValue();
  };

  closeFlyoutOnItemClick(item) {
    this.emitFlyoutItemClickHook(item);
    this.closeFlyoutAndFocusInput();
  }

  emitFlyoutItemClickHook(item) {
    const { onFlyoutItemClickHook } = this.props;
    const { locationText } = item;

    this.setState({ searchValue: locationText });
    onFlyoutItemClickHook(item);
  }

  showWarning(showWarning) {
    this.setState({ showWarning });
  }

  render() {
    const { focused, expandFlyout, searchValue, showWarning } = this.state;
    const {
      autocomplete,
      closeFlyoutOnSelection,
      disableFlyout,
      height,
      hasButton,
      flyoutMaxHeight,
      onFlyoutItemClickHook,
      onFlyoutCloseHook,
      onChangeHook,
      onClickHook,
      onBlurHook,
      onFocusHook,
      patterns,
      locations,
      searchFormatsLabel,
      onSubmitHook,
      value,
      noButton,
      canSubmit,
      clearValue,
      placeholder,
      ...props
    } = this.props;

    return (
      <SearchContainer height={height} onBlur={this.onBlur}>
        <SearchField
          autocomplete={autocomplete}
          onKeyDown={this.handleSearchKeyDown}
          onKeyPress={this.handleSearchKeyPress}
          value={searchValue}
          focused={focused}
          onSubmitHook={this.handleSubmit}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onClick={this.onClick}
          hasButton={hasButton}
          height={height}
          searchFieldRef={this.searchFieldRef}
          noButton={noButton}
          clearValue={this.handleClearSearch}
          showWarning={showWarning}
          placeholder={placeholder}
          {...props}
        />
        {!disableFlyout && expandFlyout && (
          <Flyout
            id="globalnav-search-flyout"
            patterns={patterns}
            locations={locations}
            onFlyoutItemClickHook={closeFlyoutOnSelection ? this.closeFlyoutOnItemClick : this.emitFlyoutItemClickHook}
            closeFlyout={this.closeFlyout}
            closeFlyoutAndFocusInput={this.closeFlyoutAndFocusInput}
            flyoutMaxHeight={flyoutMaxHeight}
            searchFormatsLabel={searchFormatsLabel}
          />
        )}
      </SearchContainer>
    );
  }
}

export default Search;
