import styled from 'styled-components';
import AvatarImage from '../../../../BuildingBlocks/AvatarImage';
import SVGIcon from '../../../../SVGIcon/SVGIcon';
import { colors, fonts, media, mixins } from '../../../../css';

export const CardContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')};
  min-width: ${mixins.pxToRem(160)};
`;

export const ProfilePic = styled.div`
  width: 25%;
  max-width: ${mixins.pxToRem(40)};
  height: 100%;
  ${media.greaterThan('lg')`height: 50%;`};
`;

export const ProfileInfo = styled.div`
  width: 75%;
  flex-grow: 1;
  padding: 0 ${mixins.pxToRem(10)};
`;

export const AutoAvatar = styled(AvatarImage)`
  width: 100%;
  height: 100%;
  > p {
    line-height: 1.2;
    font-size: 0.8em;
    color: ${colors.colorGrey};
  }
`;

export const TruncatedOrg = styled.p`
  font-family: ${fonts.fontRegular};
  font-size: ${mixins.pxToRem(14)};
  color: ${colors.colorBlue};
  width: 85%;
  ${media.greaterThan('lg')`width: 95%;`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TruncatedName = styled.p`
  font-family: ${fonts.fontBold};
  font-size: ${mixins.pxToRem(14)};
  display: inline-block;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.greaterThan('lg')`
    width: 75%;
  `};
`;

export const StyledCarrot = styled(SVGIcon)`
  cursor: pointer !important;
  margin-left: ${mixins.pxToRem(5)};
  vertical-align: top;
  transform: rotate(-90deg) translateY(-50%);
  position: absolute;
  right: 25px;
  top: 50%;
  ${media.greaterThan('lg')`
    position: relative;
    right:auto; 
    top:auto; transform: initial;
  `};
`;
