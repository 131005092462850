import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Paperwork extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 21,
    width: 16,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 21"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Batch-6---Desktop" fill="none" fillRule="evenodd">
          <g id="XL_03_Organizations_02_Edit_02_STUB" transform="translate(-712 -1721)">
            <g id="gain-access" transform="translate(405 1647)" fill={iconColor}>
              <g id="01-copy" transform="translate(230 72)">
                <g id="Icons/Filters/Paperwork/Black" transform="translate(73)">
                  <g id="Group-7" transform="translate(4 2)">
                    <path
                      d="M7,17 L12,17 L12,15 L7,15 L7,17 Z M7.791,16.209 L11.209,16.209 L11.209,15.791 L7.791,15.791 L7.791,16.209 Z"
                      id="Fill-1"
                    />
                    <polygon id="Fill-2" points="2 4 12 4 12 3 2 3" />
                    <polygon id="Fill-3" points="2 7 12 7 12 5.999 2 5.999" />
                    <polygon id="Fill-4" points="2 10 12 10 12 9 2 9" />
                    <polygon id="Fill-5" points="2 13 12 13 12 12 2 12" />
                    <path
                      d="M0,0.0002 L0,20.0002 L14,20.0002 L14,19.0002 L15,19.0002 L16,19.0002 L16,1.0002 L15,1.0002 L14,1.0002 L14,0.0002 L0,0.0002 Z M1,1.0002 L13,1.0002 L13,2.0002 L13,18.0002 L13,19.0002 L1,19.0002 L1,1.0002 Z M14,18.0002 L15,18.0002 L15,2.0002 L14,2.0002 L14,18.0002 Z"
                      id="Fill-6"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
