import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { flowRight as compose } from 'lodash';
import { intlShape, injectIntl } from 'react-intl';

import withActiveLocale, { withActiveLocalePropType } from '../../../hocs/withActiveLocale';
import withAnalytics, { withAnalyticsPropType } from '../../../hocs/withAnalytics';
import withRouting, { withRoutingPropType } from '../../../hocs/withRouting';

import { getLocales } from '../../../locales';
import routes from '../../../routes';

import { NAV_REGION_ONE, NAV_REGION_TWO, NAV_REGION_THREE, SOCIAL_LINKS } from './config';
import messages from './messages';
import lodgeLinkMessages from '../../../constants/messages/lodgeLink';

/**
 * The `FooterContainer` container is responsible for sourcing everything that needs to be displayed
 * in the footer and handling it's locale switching functionality.
 *
 * @class FooterContainer
 * @extends {Component}
 */
class FooterContainer extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    activeLocale: withActiveLocalePropType.isRequired,
    router: withRoutingPropType.isRequired,
    analytics: withAnalyticsPropType.isRequired
  };

  /**
   * Get a list of all supported locales.
   *
   * The returned locale objects are structured to work directly with the
   * locales prop on the "FooterContainer.FooterContainerLocaleSelect" component.
   *
   * @static
   * @returns {Array} An array of locale objects.
   */
  static getLocaleList() {
    return getLocales().map(({ id, label, locale }) => {
      // Decide which country flag icon to use
      let icon;
      if (locale.toUpperCase().endsWith('CA')) {
        icon = 'canada';
      } else if (locale.toUpperCase().endsWith('US')) {
        icon = 'usa';
      }
      // else if (locale.toUpperCase().endsWith('AU')) {
      //   icon = 'australia';
      // }

      return {
        value: id,
        title: label,
        icon
      };
    });
  }

  /**
   * Creates an instance of FooterContainer.
   *
   * @param {Object} props
   * @memberof FooterContainer
   */
  constructor(props) {
    super(props);
    // The state is only set once when the component is created and never updated.
    // Once the footer has been rendered it never needs to update again.
    this.state = this.buildState();
  }

  buildSocialLinks(socialLinks) {
    const { analytics } = this.props;

    return socialLinks.map(linkConfig => ({
      href: linkConfig.href,
      iconType: linkConfig.iconType,
      onClick: () => {
        analytics.trackEvent('click-footer', {
          clickID: linkConfig.analytics.clickID,
          destinationUrl: linkConfig.href
        });
      },
      rel: 'noreferrer',
      target: '_blank',
      backgroundColor: 'transparent',
      iconColor: '#ffffff'
    }));
  }

  /**
   * Builds the object that will eventually be sent through to the render function
   * of this component.
   *
   * All of the data and handlers the footer needs must be returned here.
   *
   * @returns {object}
   * @memberof FooterContainer
   */
  buildState() {
    const { activeLocale, intl } = this.props;

    return {
      navRegionOne: this.buildNavRegion(NAV_REGION_ONE),
      navRegionTwo: this.buildNavRegion(NAV_REGION_TWO),
      navRegionThree: this.buildNavRegion(NAV_REGION_THREE),
      locale: {
        selected: activeLocale.id,
        change: this.changeLocale.bind(this),
        list: FooterContainer.getLocaleList()
      },
      social: {
        heading: intl.formatMessage(messages.socialHeading),
        links: this.buildSocialLinks(SOCIAL_LINKS)
      }
    };
  }

  /**
   * Generates a nav region object based on a configuration.
   *
   * @param {object} navRegionConfiguration Configuration for the nav region.
   * @returns {object} Nav region object.
   * @memberof FooterContainer
   */
  buildNavRegion(navRegionConfiguration) {
    const { analytics, intl, router } = this.props;
    // Localize the heading for the nav region
    // Sending through the current year for copyright message
    const heading = intl.formatMessage(navRegionConfiguration.heading, {
      year: new Date().getFullYear()
    });

    // Generate and localizejs links for the nav region
    const links = navRegionConfiguration.links.map(linkConfig => {
      // This is for the north american/ australian sub links
      let getSubLabels = null;
      if (linkConfig?.subLabels) {
        getSubLabels = linkConfig?.subLabels.map(subLabel => (
          <a key={subLabel?.href} href={subLabel?.href}>{`${intl.formatMessage(subLabel?.label)} `}</a>
        ));
      }
      const type = typeof linkConfig.href === 'string' ? 'external' : 'internal';

      // Tel Link
      if (type === 'external' && linkConfig.href.includes('tel')) {
        const label = intl.formatMessage(linkConfig.label, {
          phoneNumber: intl.formatMessage(lodgeLinkMessages.phoneNumber)
        });

        return {
          href: linkConfig.href + intl.formatMessage(lodgeLinkMessages.phoneNumber),
          label,
          onClick: () => {
            analytics.trackEvent('click-footer', {
              clickID: linkConfig.analytics.clickID,
              destinationUrl: linkConfig.href
            });
          }
        };
      }
      const label = intl.formatMessage(linkConfig.label);

      // External links
      if (type === 'external') {
        return {
          href: linkConfig.href,
          label,
          subLabels: getSubLabels,
          onClick: () => {
            analytics.trackEvent('click-footer', {
              clickID: linkConfig.analytics.clickID,
              destinationUrl: linkConfig.href
            });
          },
          rel: 'noreferrer',
          target: '_blank'
        };
      }

      // Internal links
      const href = router.getRouteUrl(linkConfig.route, linkConfig.params);
      return {
        href,
        label,
        subLabels: getSubLabels,
        onClick: event => {
          event.preventDefault();

          router.pushRouteAndScroll(href);

          analytics.trackEvent('click-footer', {
            clickID: linkConfig.analytics.clickID,
            destinationUrl: href
          });
        }
      };
    });

    // Send out the nav region
    return {
      heading,
      links
    };
  }

  /**
   * Redirects the user to the same page they are currently on but in an
   * alternate locale.
   *
   * @param {string} locale One of the supported locale to change to.
   * @memberof FooterContainer
   */
  changeLocale(locale) {
    const { router } = this.props;

    // Attempt to match up the current URL to one of our defined routes
    const currentUrl = router.asPath;
    const routeMatch = routes.match(currentUrl);

    // We are going to be generating a new url that will take the user to the new locale
    let nextUrl;

    // A route match was found
    if (routeMatch.route) {
      // Create a new params object replacing the locale with the requested locale
      const paramsWithReplacedLocale = {
        ...routeMatch.params,
        locale
      };

      // This generates a url to the current route but with a replaced locale value
      nextUrl = routeMatch.route.getAs(paramsWithReplacedLocale);
    }
    // No matching route was found so this is likely some kind of 404 page the user is on
    else {
      // Lookup the home page route
      const homeRoute = routes.findByName('home');

      // Generate a URL to the homepage with the requested locale
      nextUrl = homeRoute.getAs({
        locale
      });
    }

    // Navigates to our new URL if it's different than the current URL
    if (nextUrl !== currentUrl) {
      // The translated messages for a locale are only updated on a full page reload.
      // This is why why window.location is used instead of pushRoute
      window.location.href = nextUrl;
    }
  }

  render() {
    const { children } = this.props;

    return <>{children(this.state)}</>;
  }
}

/**
 * Providing this component with Intl localizations, the ability to access the current
 * route and to see the active locale.
 */
export default compose(
  withRouting,
  injectIntl,
  withActiveLocale,
  withAnalytics()
)(FooterContainer);
