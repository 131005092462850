import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class BagLunch extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m20.78 13.18a3 3 0 0 0 -1.41-.64s.07-.06.1-.1c.76-.85.89-1.88.31-2.39a1.17 1.17 0 0 0 -.78-.27 2.35 2.35 0 0 0 -1.52.85 2.73 2.73 0 0 0 -.37.54l-.62-1.28-.9.43 1 2.15a4.83 4.83 0 0 0 -1.38 0l-1.84-6.75 2.63-.09-4-2.94h-7.68l-2.4 14.5 6.32 3.56 4.62-1.87a3.86 3.86 0 0 0 .75 1.09 5 5 0 0 0 3.63 1.39 5.06 5.06 0 0 0 3.65-1.36 4.18 4.18 0 0 0 1.16-3c0-1.22-.13-2.88-1.27-3.82zm-2.6-1.89a1.32 1.32 0 0 1 .93-.5c.05.05.06.49-.38 1a1.75 1.75 0 0 1 -.67.47.39.39 0 0 1 -.26 0v-.09a1.42 1.42 0 0 1 .38-.88zm-10.51-6.38-1.59-1.24 5.49-.07 1.43 1.13zm-2.67.09 2.82 14.32-4.82-2.69zm7.49 12a4.37 4.37 0 0 0 .1 1l-3.74 1.4-2.85-14.55 1.34 1.08 5-.17 1.91 7.07a2.18 2.18 0 0 0 -.46.28c-1.16.94-1.32 2.64-1.33 3.83zm7.72 2.36a4.1 4.1 0 0 1 -2.92 1 4.06 4.06 0 0 1 -2.92-1.09 3.13 3.13 0 0 1 -.91-2.27c0-1 .12-2.39 1-3.07a2.49 2.49 0 0 1 1.54-.51 5.73 5.73 0 0 1 1.17.13h.09.09a3.42 3.42 0 0 1 2.8.39c.81.68.91 2 .91 3a3.18 3.18 0 0 1 -.88 2.36z"
        />
      </svg>
    );
  }
}
