// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledPhoneNumberLink, StyledPhoneNum } from './FooterPhoneNumber.styles';

export default class FooterPhoneNum extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    target: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    href: undefined,
    target: undefined,
    className: undefined,
    onClick: () => {}
  };

  render() {
    const { children, target, href, className, onClick } = this.props;
    return (
      <StyledPhoneNum noMargins weight="bold" color="secondary">
        <StyledPhoneNumberLink href={`tel:+${href}`} target={target} className={className} onClick={onClick}>
          {children}
        </StyledPhoneNumberLink>
      </StyledPhoneNum>
    );
  }
}
