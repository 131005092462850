// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../BuildingBlocks/Text';
import {
  StyledCopyLine,
  StyledCopywright,
  StyledBottomItem,
  StyledLinkRow,
  StyledChildSpan
} from './FooterCopyright.styles';

export default class FooterCopyright extends Component {
  static propTypes = {
    copyright: PropTypes.string,
    children: PropTypes.node
  };

  static defaultProps = {
    copyright: '',
    children: []
  };

  /* eslint-disable react/no-array-index-key */
  render() {
    const { copyright, children } = this.props;
    return (
      <StyledCopywright>
        <StyledCopyLine>
          <Text.CopyParagraph noMargins color="secondary">
            {copyright}
          </Text.CopyParagraph>
        </StyledCopyLine>
        <StyledLinkRow>
          {React.Children.toArray(children).map((child, index) => (
            <StyledBottomItem key={index}>
              <StyledChildSpan>{child}</StyledChildSpan>
            </StyledBottomItem>
          ))}
        </StyledLinkRow>
      </StyledCopywright>
    );
  }
}
