import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class FuelStationIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 22,
    height: undefined,
    iconColor: colors.colorGrey06
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.25 18.34" height={height} width={width}>
        <g id="Layer_2" data-name="Layer 2">
          <path
            fill={iconColor}
            d="M16.48,1.49h-2.7V6.74h.66v5c0,.14-.14.68-.68.68H12.19V0H1.68V16.4H0v1.94H13.87V16.4H12.19V13.8h1.57a2.09,2.09,0,0,0,2.06-2V6.74h.65V4h1.77V1.49ZM10.2,5H3.67V2H10.2Z"
            id="Layer_1-2"
            data-name="Layer 1"
          />
        </g>
      </svg>
    );
  }
}
