import React, { Component, Fragment } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

import Grid from '../Utility/Grid';

export default class Adaptive extends Component {
  /* eslint-disable react/no-unused-prop-types */
  static propTypes = {
    /** Rendered before the component has mounted (server side rendering) */
    initial: PropTypes.node /** Component rendered in small breakpoint */,
    sm: PropTypes.node.isRequired /** Component rendered in medium breakpoint */,
    md: PropTypes.node /** Component rendered in large breakpoint */,
    lg: PropTypes.node /** Component rendered in extralarge breakpoint */,
    xl: PropTypes.node
  };
  /* eslint-enable react/no-unused-prop-types */

  static defaultProps = { initial: undefined, md: undefined, lg: undefined, xl: undefined };

  render() {
    const isMounted = typeof window !== 'undefined';
    const { initial, sm, md, lg, xl } = this.props;

    const breakpoints = { initial, sm, md: md || sm, lg: lg || md || sm, xl: xl || lg || md || sm };

    /* eslint-disable */
    return (
      <Fragment>
        {initial && isMounted === false ? (
          initial
        ) : (
          <Grid.ScreenClassRender render={screenClass => breakpoints[screenClass] || sm} />
        )}
      </Fragment>
    );
    /* eslint-enable */
  }
}
