import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class MapPin extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 22,
    width: 17,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg viewBox="0 0 16 22" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
          <path
            d="M8,11 C6.346,11 5,9.654 5,8 C5,6.346 6.346,5 8,5 C9.654,5 11,6.346 11,8 C11,9.654 9.654,11 8,11 M8,4 C5.791,4 4,5.791 4,8 C4,10.209 5.791,12 8,12 C10.209,12 12,10.209 12,8 C12,5.791 10.209,4 8,4"
            id="Fill-1"
            fill={iconColor}
          />

          <path
            d="M8,20.7227 C4.266,17.5867 1,11.7267 1,7.9997 C1,4.1397 4.141,0.9997 8,0.9997 C11.859,0.9997 15,4.1397 15,7.9997 C15,11.7267 11.734,17.5867 8,20.7227 M8,-0.0003 C3.582,-0.0003 0,3.5817 0,7.9997 C0,12.4177 4,18.9997 8,21.9997 C12,18.9997 16,12.4177 16,7.9997 C16,3.5817 12.418,-0.0003 8,-0.0003"
            id="Fill-3"
            fill={iconColor}
          />
        </g>
      </svg>
    );
  }
}
