import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class NightShiftIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.colorBlack
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={height} width={width}>
        <path
          fill={iconColor}
          d="M17.82,21.8A11.1,11.1,0,1,1,15.2.68l1.15.19-.94.7A8.64,8.64,0,0,0,22.13,17l1.15-.2-.65,1A11,11,0,0,1,17.82,21.8ZM9.35,2.37a10.1,10.1,0,0,0,8.07,18.51,9.92,9.92,0,0,0,3.7-2.77A9.64,9.64,0,0,1,13.91,1.54,10.08,10.08,0,0,0,9.35,2.37Z"
        />
      </svg>
    );
  }
}
