import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Hamburger extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-17 -20)" fill={iconColor} id="Atoms" fillRule="evenodd">
          <g id="Icons/UI/Hamburger" transform="translate(16 17)">
            <g id="Group-4" transform="translate(1.667 3.333)">
              <polygon
                fill={iconColor}
                points="0 -0.000166666667 0 1.17816667 0.785 1.17816667 7.7775 1.17816667 16.6666667 1.17816667 16.6666667 -0.000166666667"
              />
              <polygon
                fill={iconColor}
                points="7.7775 6.32158333 0.785 6.32158333 0 6.32158333 0 7.49991667 0.785 7.49991667 7.7775 7.49991667 16.6666667 7.49991667 16.6666667 6.32158333"
              />
              <polygon
                fill={iconColor}
                points="7.7775 12.1550833 0.785 12.1550833 0 12.1550833 0 13.3334167 0.785 13.3334167 7.7775 13.3334167 16.6666667 13.3334167 16.6666667 12.1550833"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
