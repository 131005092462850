import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Fitness extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m23 11.5h-1.5v-3.5h-3v-2.5h-4v6h-5v-6h-4v2.5h-3v3.5h-1.5v1h1.5v3.5h3v2.5h4v-6h5v6h4v-2.5h3v-3.5h1.5zm-19.5 3.5v-6h2v6zm5 2.5h-2v-11h2zm9 0h-2v-11h2zm3-2.5h-2v-6h2z"
        />
      </svg>
    );
  }
}
