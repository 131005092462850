import { setConfiguration } from 'react-grid-system';

import breakpoints from './breakpoints';

/**
 * Grid configuration.
 *
 * The `containerWidths` setting is not part of the library. It is used to add additional gutters on the
 * Container component.
 *
 * https://github.com/JSxMachina/react-grid-system#configuration
 */
const configuration = {
  breakpoints: Object.keys(breakpoints).map(breakpointName => breakpoints[breakpointName]),
  containerGutters: [32, 32, 38, 38, 40], // [xs, sm, md, lg, xl]
  containerWidths: [640, 1024, 1200, 1440, 1440], // [xs, sm, md, lg, xl]
  defaultScreenClass: 'sm',
  gridColumns: 12,
  gutterWidth: 36
};

/**
 * Set grid configuration.
 */
setConfiguration(configuration);

export default configuration;
