import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import { StyledAvatarWrapper, StyledInitials } from './AvatarImage.styles';

class AvatarImage extends Component {
  static propTypes = {
    /** Image src url */
    src: PropTypes.string,
    /** Alt copy provided by SEO */
    alt: PropTypes.string.isRequired,
    /** Title copy provided by SEO - defaults to alt copy if no title is provided */
    title: PropTypes.string,
    /** Classname */
    className: PropTypes.string,
    /** User intial to display if image is not available */
    initials: PropTypes.string
  };

  static defaultProps = {
    title: '',
    className: '',
    src: null,
    initials: ''
  };

  render() {
    const { src, alt, title, className, initials } = this.props;

    return (
      <StyledAvatarWrapper className={className} src={src}>
        {src ? <Image src={src} alt={alt} title={title} ratio="1:1" /> : null}
        {!src ? <StyledInitials>{initials}</StyledInitials> : null}
      </StyledAvatarWrapper>
    );
  }
}

export default AvatarImage;
