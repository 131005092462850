import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Favourite extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 22,
    backgroundColor: colors.colorGrey04,
    iconColor: colors.colorWhite
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 22 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="Cards/Listing/Full/XL"
            transform="translate(-342.000000, -20.000000)"
            fill={backgroundColor}
            stroke={iconColor}
          >
            <g id="Icons/UI/Favouriate/Off" transform="translate(337.000000, 14.000000)">
              <path d="M11.2618959,7 C9.90828281,7 8.55448166,7.52036453 7.53226587,8.57227832 C5.48802237,10.6759225 5.49046741,14.0436235 7.53226587,16.1487346 L15.5256736,24.4032416 C15.770554,24.6555396 16.1790642,24.6667243 16.4378625,24.4279947 C16.4465142,24.419927 16.4549778,24.411676 16.4632533,24.4032416 C19.1311707,21.6576678 21.7987119,18.9042096 24.4668174,16.1586358 C26.5110609,14.0549916 26.5110609,10.6860071 24.4668174,8.58217955 C22.4221977,6.47835197 19.0518009,6.47835197 17.0073693,8.58217955 L15.9994476,9.62382539 L14.9915259,8.57227832 C13.9693101,7.52036453 12.6155089,7 11.2618959,7 Z" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
