// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../SVGIcon';
import {
  StyledLocaleSelect,
  StyledMenuItem,
  StyledMenuText,
  StyledArrowSVG,
  StyledFormControl
} from './FooterLocaleSelect.styles';

export default class FooterLocaleSelect extends Component {
  static propTypes = {
    locales: PropTypes.arrayOf(PropTypes.shape),
    selected: PropTypes.string.isRequired,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    locales: [],
    onSelect: () => {}
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      selected: props.selected
    };
  }

  handleChange(event) {
    const { selected } = this.state;
    const { onSelect, locales } = this.props;
    if (event.target.value !== selected) {
      this.setState({ selected: event.target.value });
      const [returnValue] = locales.filter(locale => (locale.value === event.target.value ? locale : null));
      onSelect(returnValue);
    }
  }

  renderMenuList() {
    const { locales } = this.props;
    return [locales.map((locale, index) => this.renderMenuItem(locale, index))];
  }

  /* eslint-disable class-methods-use-this */
  renderMenuItem(locale, index) {
    return (
      <StyledMenuItem key={index} value={locale.value}>
        <SVGIcon selectedIcon={locale.icon} />
        <StyledMenuText weight="bold" noMargins>
          {locale.title}
        </StyledMenuText>
      </StyledMenuItem>
    );
  }

  render() {
    const { selected } = this.state;
    return (
      <div>
        <StyledFormControl>
          <StyledLocaleSelect
            onChange={this.handleChange}
            IconComponent={<StyledArrowSVG selectedIcon="arrow" />}
            value={selected}
          >
            {this.renderMenuList()}
          </StyledLocaleSelect>
        </StyledFormControl>
      </div>
    );
  }
}
