import PropTypes from 'prop-types';
import { CommonIDPropType } from './common';

const AmenityPropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string,
  iconCode: PropTypes.string,
  description: PropTypes.string,
  code: PropTypes.string,
  order: PropTypes.number,
  filterable: PropTypes.bool
});

export default AmenityPropType;
