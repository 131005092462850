import merge from 'lodash.merge';
import { withClientState } from 'apollo-link-state';

import search from './search';
import mapFilters from './mapFilters';

const typeDefs = ``;

const createStateLink = context => {
  const { cache: clientCache } = context;

  const combined = merge(search(), mapFilters());

  const stateLink = withClientState({
    typeDefs,
    ...combined,
    cache: clientCache
  });
  return stateLink;
};

export default createStateLink;
