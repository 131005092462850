import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Phone extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <polygon id="path-1" points="0 0 17.5 0 17.5 17.5 0 17.5" />
        </defs>
        <g id="Symbols" fill={backgroundColor} fillRule="evenodd">
          <g id="Navigation/Flyout/SM/Unauthenticated" transform="translate(-249 -29)">
            <g id="Icons/UI/Phone" transform="translate(248 28)">
              <g id="Group-3" transform="translate(1.25 1.25)">
                <path
                  d="M3.59838264,0.00556148309 C2.80920785,-0.0528152094 1.95932732,0.355821638 1.41297554,0.881211871 C0.441683498,1.87361564 -0.104668276,3.39140965 0.0167432297,4.26706004 C0.745212262,9.28745559 7.66566807,16.4094121 12.825657,17.4601925 C13.6755376,17.6353226 15.3145929,17.2266858 16.4072965,16.3510354 C17.014354,15.8256452 17.4392942,15.0667482 17.5,14.3078512 C17.5,13.3738241 13.6755376,10.8052496 12.5221283,11.1555098 C11.9757765,11.3306398 11.7329535,12.0895368 11.4901305,12.5565504 C11.0044845,13.3154474 10.3974269,13.1403173 9.79036941,12.6149271 C8.15131409,11.3306398 6.02661274,9.17070221 4.81249769,7.47777813 C4.32685167,6.8940112 4.14473441,6.25186758 4.99461495,5.90160743 C5.48026097,5.66810066 6.26943576,5.49297058 6.51225877,4.96758035 C6.99790479,3.91679988 4.56967468,0.00556148309 3.59838264,0.00556148309"
                  fill={iconColor}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
