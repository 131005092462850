// GUID https://gist.github.com/bugventure/f71337e3927c34132b9a
const GUID_PATTERN = '([a-f\\d]{8}-[a-f\\d]{4}-4[a-f\\d]{3}-[89ab][a-f\\d]{3}-[a-f\\d]{12})';

/**
 * Define all routes below.
 *
 * Each route must include the following:
 * {
 *   name: '',
 *   pattern: '',
 *   page: '/index'
 * }
 */
module.exports = [
  // Root page of app currently redirecting to Dashboard
  {
    name: 'Home',
    pattern: '',
    page: '/Home'
  },
  // Properties
  {
    name: 'Map',
    pattern: '/accommodations',
    page: '/Map'
  },
  {
    name: 'PropertyDetails',
    pattern: '/accommodations/:slug',
    page: '/PropertyDetails',
    robots: '/accommodations/*'
  },
  // Booking Flow
  {
    name: 'InitiateBooking',
    pattern: '/booking',
    page: '/Booking/Initiate'
  },
  {
    name: 'RoomAssignments',
    pattern: '/:organizationSlug/booking/:bookingNumber/room-assignments',
    page: '/BookingEdit/RoomAssignments'
  },
  {
    name: 'AssignCostTrackingCodes',
    pattern: '/:organizationSlug/booking/assignments/:bookingNumber/cost-tracking-codes',
    page: '/BookingEdit/CostTrackingCodes'
  },
  {
    name: 'RoomSchedules',
    pattern: '/:organizationSlug/booking/:bookingNumber/room-schedules',
    page: '/BookingEdit/RoomSchedules'
  },
  {
    name: 'BookingPayment',
    pattern: '/:organizationSlug/booking/:bookingNumber/payment',
    page: '/BookingEdit/Payment'
  },
  {
    name: 'BookingSummary',
    pattern: '/:organizationSlug/booking/:bookingNumber/summary',
    page: '/BookingEdit/Summary'
  },
  {
    name: 'BookingConfirmation',
    pattern: '/:organizationSlug/booking/:bookingNumber/confirmation',
    page: '/Booking/Confirmation'
  },
  {
    name: 'BookingError',
    pattern: '/:organizationSlug/booking/:bookingNumber/error',
    page: '/Booking/Error'
  },
  // Edit Booking Flow
  {
    name: 'EditRoomAssignments',
    pattern: '/:organizationSlug/edit-booking/assignments/:bookingNumber/room-assignments',
    page: '/BookingEdit/RoomAssignments'
  },
  {
    name: 'EditCostTrackingCodes',
    pattern: '/:organizationSlug/edit-booking/assignments/:bookingNumber/cost-tracking-codes',
    page: '/BookingEdit/CostTrackingCodes'
  },
  {
    name: 'EditRoomSchedules',
    pattern: '/:organizationSlug/edit-booking/schedules/:bookingNumber/room-schedules',
    page: '/BookingEdit/RoomSchedules'
  },
  {
    name: 'EditBookingPayment',
    pattern: '/:organizationSlug/edit-booking/payment/:bookingNumber/payment',
    page: '/BookingEdit/Payment'
  },
  {
    name: 'EditBookingSummary',
    pattern: '/:organizationSlug/edit-booking/:type/:bookingNumber/summary',
    page: '/BookingEdit/Summary'
  },
  // Sign Up
  {
    name: 'SignUp',
    pattern: '/sign-up/verify-email',
    page: '/SignUp/VerifyEmail'
  },
  {
    name: 'CreateProfile',
    pattern: '/sign-up/create-profile',
    page: '/SignUp/CreateProfile'
  },
  {
    name: 'ProfileCreated',
    pattern: '/sign-up/profile-created',
    page: '/SignUp/ProfileCreated'
  },
  {
    name: 'OnboardingOptionsPublic',
    pattern: '/onboarding/onboarding-options-public',
    page: '/Onboarding/OnboardingOptionsPublic'
  },
  {
    name: 'OnboardingOptions',
    pattern: '/onboarding/onboarding-options',
    page: '/Onboarding/OnboardingOptions'
  },
  {
    name: 'OnboardingStatusPublic',
    pattern: '/onboarding/onboarding-status-public',
    page: '/Onboarding/OnboardingStatusPublic'
  },
  {
    name: 'OnboardingStatus',
    pattern: '/onboarding/onboarding-status',
    page: '/Onboarding/OnboardingStatus'
  },
  {
    name: 'CreateOrganization',
    pattern: '/sign-up/create-organization',
    page: '/SignUp/CreateOrganization'
  },
  // Credit Application
  {
    name: 'InitiateCreditApplication',
    pattern: '/:organizationSlug/credit-application',
    page: '/CreditApplication/InitiateCreditApplication'
  },
  {
    name: 'EditCreditApplication',
    pattern: `/:organizationSlug/credit-application/:creditApplicationId${GUID_PATTERN}`,
    page: '/CreditApplication/EditCreditApplication'
  },
  {
    name: 'ReviewCreditApplication',
    pattern: `/:organizationSlug/credit-application/:creditApplicationId${GUID_PATTERN}/review`,
    page: '/CreditApplication/ReviewCreditApplication'
  },

  // Account
  {
    name: 'SignIn',
    pattern: '/sign-in',
    page: '/SignIn',
    includeInSitemap: true
  },
  {
    name: 'ForgotPassword',
    pattern: '/forgot-password',
    page: '/ForgotPassword',
    includeInSitemap: true
  },
  {
    name: 'ResetPassword',
    pattern: '/reset-password',
    page: '/ResetPassword'
  },
  {
    name: 'ChangePassword',
    pattern: '/change-password',
    page: '/ChangePassword'
  },
  {
    name: 'AccountSettings',
    pattern: '/account-settings',
    page: '/AccountSettings'
  },
  // Token &  Util Routes
  {
    name: 'TokenWithTokenId',
    pattern: `/t/:tokenId${GUID_PATTERN}`,
    page: '/Token'
  },
  {
    name: 'TokenWithLink',
    pattern: `/t/:claimCodeLink`,
    page: '/Token'
  },
  {
    name: 'ResolveActiveOrganization',
    pattern: `/resolve-organization/:organizationSlug`,
    page: '/ResolveOrganization'
  },
  // Dashboard
  {
    name: 'Dashboard',
    pattern: '/dashboard',
    page: '/Dashboard'
  },
  {
    name: 'CrewManagement',
    pattern: '/:organizationSlug/crew-management',
    page: '/Crew/CrewManagement'
  },
  {
    name: 'CrewList',
    pattern: '/:organizationSlug/crew-list',
    page: '/Crew/CrewList'
  },
  {
    name: 'CustomCategories',
    pattern: '/:organizationSlug/custom-categories',
    page: '/Crew/CustomCategories'
  },
  {
    name: 'CrewListUpload',
    pattern: '/:organizationSlug/crew-list-upload',
    page: '/Crew/CrewListUpload'
  },

  {
    name: 'UploadDownload',
    pattern: '/:organizationSlug/upload-download',
    page: '/Crew/UploadDownload'
  },
  {
    name: 'PaymentCodes',
    pattern: '/:organizationSlug/payment-codes',
    page: '/PaymentCodes/List'
  },
  {
    name: 'CreatePaymentCode',
    pattern: '/:organizationSlug/payment-codes/create',
    page: '/PaymentCodes/Create'
  },
  {
    name: 'EditPaymentCode',
    pattern: '/:organizationSlug/payment-codes/:paymentCodeId/edit/',
    page: '/PaymentCodes/Edit'
  },
  {
    name: 'ManagePaymentCodeAccess',
    pattern: '/:organizationSlug/payment-codes/:paymentCodeId/manage/',
    page: '/PaymentCodes/Manage'
  },
  {
    name: 'CostTrackingCodes',
    pattern: '/:organizationSlug/cost-tracking-codes',
    page: '/CostTrackingCodes/List'
  },
  {
    name: 'CreateCostTrackingCode',
    pattern: '/:organizationSlug/cost-tracking-codes/create',
    page: '/CostTrackingCodes/Create'
  },
  {
    name: 'EditCostTrackingCode',
    pattern: '/:organizationSlug/cost-tracking-codes/:costTrackingCodeId/edit/',
    page: '/CostTrackingCodes/Edit'
  },

  // Organization
  {
    name: 'OrganizationManage',
    pattern: '/:organizationSlug/manage',
    page: '/Organization/Manage'
  },
  {
    name: 'OrganizationBookings',
    pattern: '/:organizationSlug/bookings/organization-bookings',
    page: '/Bookings/OrganizationBookings'
  },
  {
    name: 'OrgBookings',
    pattern: '/:organizationSlug/bookings/org-bookings',
    page: '/Bookings/OrganizationBookingsPaginated'
  },
  {
    name: 'BookingsImAttending',
    pattern: '/bookings/bookings-im-attending',
    page: '/Bookings/BookingsImAttending'
  },
  {
    name: 'AttendedBookings',
    pattern: 'bookings/attended-bookings',
    page: '/Bookings/BookingsImAttendingPaginated'
  },
  {
    name: 'SubContractorBookings',
    pattern: '/bookings/subcontractor-bookings',
    page: '/Bookings/SubContractorBookings'
  },
  {
    name: 'ContractorBookings',
    pattern: '/bookings/contractor-bookings',
    page: '/Bookings/SubContractorBookingsPaginated'
  },
  {
    name: 'GuestSchedule',
    pattern: '/:organizationSlug/bookings/guest-schedule',
    page: '/Bookings/GuestSchedule'
  },
  {
    name: 'BookingDetails',
    pattern: '/:organizationSlug/bookings/:bookingNumber/details',
    page: '/BookingDetails'
  },
  {
    name: 'BookingDetailsExternal',
    pattern: '/bd/:token',
    page: '/BookingDetailsExternal'
  },
  {
    name: 'EmailsTemplatesExternal',
    pattern: '/et/:token',
    page: '/EmailTemplatesExternal'
  },
  {
    name: 'ConfirmBookingExternal',
    pattern: `/confirm-booking/:tokenId`,
    page: '/EmailTemplatesExternal/ConfirmBooking'
  },
  {
    name: 'RejectBookingExternal',
    pattern: `/reject-booking/:tokenId`,
    page: '/EmailTemplatesExternal/RejectBooking'
  },
  {
    name: 'GuestBookingDetails',
    pattern: '/guest-booking-details/:bookingNumber',
    page: '/GuestBookingDetails'
  },
  {
    name: 'OrganizationInvite',
    pattern: '/:organizationSlug/invite',
    page: '/Organization/Invite'
  },
  {
    name: 'OrganizationEditDetails',
    pattern: '/:organizationSlug/edit-details',
    page: '/Organization/EditDetails'
  },
  {
    name: 'ReportingV2',
    pattern: '/:organizationSlug/reportingV2',
    page: '/ReportingV2'
  },
  {
    name: 'FinanceAndProcurement',
    pattern: '/:organizationSlug/reportingV2/finance-and-procurement',
    page: '/ReportingV2/FinanceAndProcurement'
  },
  {
    name: 'LifetimeStats',
    pattern: '/:organizationSlug/reportingV2/lifetime',
    page: '/ReportingV2/LifetimeStats'
  },
  {
    name: 'UpcomingRoomNights',
    pattern: '/:organizationSlug/reportingV2/upcoming-room-nights',
    page: '/ReportingV2/UpcomingRoomNights'
  }
];
