import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Facitilies extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 120,
    iconColor: colors.colorGrey02,
    backgroundColor: colors.iconGreen
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
        <rect fill={backgroundColor} opacity={0.4} x="34.92" y="48.01" width="13.19" height="47.73" />
        <rect
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x="48.44"
          y="26.21"
          width="43.51"
          height="69.47"
        />
        <rect
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x="26.62"
          y="42.05"
          width="21.76"
          height="53.62"
        />
        <line
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="34.92"
          y1="60.66"
          x2="48.38"
          y2="60.66"
        />
        <line
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="54.92"
          y1="60.66"
          x2="68.38"
          y2="60.66"
        />
        <line
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="34.92"
          y1="77.17"
          x2="48.38"
          y2="77.17"
        />
        <line
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="73.49"
          y1="60.66"
          x2="84.74"
          y2="60.66"
        />
        <line
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="54.92"
          y1="44.55"
          x2="68.38"
          y2="44.55"
        />
        <line
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="73.49"
          y1="44.55"
          x2="84.74"
          y2="44.55"
        />
        <polyline
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          points="79.17 94.92 79.17 77.39 61.65 77.39 61.65 94.92"
        />
      </svg>
    );
  }
}
