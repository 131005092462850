import React from 'react';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import debounce from 'lodash.debounce';


/**
 * This is a higher order component that will debounce prop changes.
 */
const withDebouncedProps = (propNames, rate = 200, options = { leading: true }) => BaseComponent =>
  class ComponentWithDebouncedProps extends React.Component {
    static displayName = `withDebouncedProps(${BaseComponent.displayName || BaseComponent.name})`;

    debouncedUpdate = debounce(
      nextProps => {
        this.setState(pick(nextProps, propNames));
      },
      rate,
      options
    );

    constructor(props) {
      super(props);
      this.state = pick(props, propNames);
    }

    componentWillUnmount() {
      this.debouncedUpdate.cancel();
    }

    // TODO:  Rewrite this to not use componentWillReceiveProps?
    // eslint-disable-next-line camelcase
    getDerivedStateFromProps(nextProps) {
      this.debouncedUpdate(nextProps);
    }

    render() {
      return <BaseComponent {...omit(this.props, propNames)} {...this.state} />;
    }
  };

export default withDebouncedProps;
