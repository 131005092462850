const BookingGuestDayState = {
  Unconfirmed: 'Unconfirmed',
  Unreconciled: 'Unreconciled',
  ReconciledCheckpoint: 'ReconciledCheckpoint',
  Reconciled: 'Reconciled',
  ReconciledNoShow: 'ReconciledNoShow',
  ReconciledCancelled: 'ReconciledCancelled',
  ChargeReady: 'ChargeReady',
  ChargeSent: 'ChargeSent',
  ChargeApproved: 'ChargeApproved',
  ChargeDisputed: 'ChargeDisputed',
  InvoiceReady: 'InvoiceReady',
  InvoiceSent: 'InvoiceSent',
  InvoicePaid: 'InvoicePaid',
  InvoiceDisputed: 'InvoiceDisputed',
  BookingRejected: 'BookingRejected',
  Draft: 'Draft',
  Removed: 'Removed'
};

export const ReconciledPastBookingGuestDayState = [
  'ReconciledCheckpoint',
  'Reconciled',
  'ReconciledNoShow',
  'ReconciledCancelled',
  'ReconciledLoss',
  'ChargeReady',
  'ChargeSent',
  'ChargeApproved',
  'ChargeDisputed',
  'InvoiceReady',
  'InvoiceSent',
  'InvoicePaid',
  'InvoiceDisputed',
  'BookingRejected',
  'ReconciledException'
];

export default BookingGuestDayState;
