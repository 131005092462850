import React from 'react';
import { processNodes } from 'react-html-parser';

/**
 * Generates a key for a node.
 *
 * At the moment this will return a unique string every time it's called. Whenever the
 * html prop is changed on RichTextParser this will cause every element to be re-created
 * even if they have not changed.
 *
 * If this ends up becoming a performance issue the key returned by this function should
 * probably be some type of hash based on the contents of the node. I was not able to
 * quickly figure out how to do this so I left it for later.
 *
 * I think this will be fine for our use case so far.
 *
 * @param {object} node A node.
 * @returns {string} key A generated key for the given node.
 */
function generateNodeKey() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/**
 * All the magic happens here.
 *
 * Transforms plain html in to React elements
 *
 * @param {Element} node
 * @param {options.convert} convert - config file that handles conversions. See /conveters folder.
 * @param {options.whitelist} whitelist - html elements to accept.
 */
function transform(node, options) {
  const { convert, whitelist } = options;
  const processChildren = children => processNodes(children, _node => transform(_node, options));

  /**
   * Return null if we're ignoring this element
   *
   * This will fail if ALL of these conditions are present:
   * a) Invalid/undefined node name
   * b) Node/element name is not whitelisted explicitly
   * c) There is no converter defined for the node
   *
   */
  if (node.name !== undefined && (!whitelist.includes(node.name) || !convert[node.name])) {
    return null;
  }

  // Generate a key that will be used for this node
  const key = generateNodeKey(node);

  // If the convert object has a transform defined for this type of element
  if (Object.keys(convert).includes(node.name)) {
    const Element = convert[node.name];
    // If the object has a "transform" property that is a function, use that.
    if (typeof Element === 'function') {
      return Element(node, key, {
        props: Element.props,
        transform: children => transform(children, options),
        ...options
      });
    }

    if (typeof Element === 'object') {
      // Fallback on the "default" convention of { element: ReactComponent, props: { disabled: true } }
      return (
        <>
          {/* eslint-disable-next-line */}
          <Element.element {...Element.props} key={key}>
            {processChildren(node.children)}
          </Element.element>
        </>
      );
    }

    // No transform defined just return the element exatly as it was
    return <Element key={key}>{processChildren(node.children)}</Element>;
  }

  // Undefined will convert to plain html
  return undefined;
}

export default transform;
