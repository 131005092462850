import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Massage extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m22.79 21.09h-21.93v-1h6.27v-5a.5.5 0 0 1 .17-.38.55.55 0 0 1 .39-.12l15.16 1.75a.5.5 0 0 1 .44.49v3.75a.5.5 0 0 1 -.5.51zm-14.66-1h14.16v-2.8l-14.16-1.63z"
        />
        <path
          fill={iconColor}
          d="m3.54 19.22a2.84 2.84 0 1 1 2.84-2.84 2.85 2.85 0 0 1 -2.84 2.84zm0-4.68a1.84 1.84 0 1 0 1.84 1.84 1.85 1.85 0 0 0 -1.84-1.84z"
        />
        <path
          fill={iconColor}
          d="m13.79 8.58a2.84 2.84 0 1 1 2.84-2.84 2.84 2.84 0 0 1 -2.84 2.84zm0-4.67a1.84 1.84 0 1 0 1.84 1.83 1.83 1.83 0 0 0 -1.84-1.83z"
        />
        <path fill={iconColor} d="m19.07 14.73h-1a4.28 4.28 0 0 0 -8.49-.73l-1-.18a5.28 5.28 0 0 1 10.47 1z" />
      </svg>
    );
  }
}
