import React, { Fragment } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line
const spreadDiv = ({ height, ...rest }) => <div {...rest} />;
export const SearchContainer = styled.div`
  position: relative;
  z-index: 2;
  height: ${({ height }) => height}px;
`;

export const StateOutput = styled(({ state, ...props }) => (
  <pre {...props}>
    {Object.keys(state).map(key => (
      <Fragment key={key}>
        <strong>{key}: </strong>
        {state[key]}
        <br />
      </Fragment>
    ))}
  </pre>
))`
  padding: 25px;
`;
