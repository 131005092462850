// exclude-from-index
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import SVGIcon from '../../../SVGIcon';
import {
  MobileWrapper,
  Underlay,
  MenuHamburger,
  HomeLinkContainer,
  SearchBarContainer,
  ContactContainer,
  NotificationsContainer,
  AuthenticatedContainer,
  SearchMenu,
  FlyoutBar,
  CloseBack,
  FlyoutHeader,
  ContactIcon,
  FlyoutContainer,
  MenuFlyout,
  PaddedUserCard,
  UserFlyout,
  StyledSignOut,
  StyledCarrot,
  NotificationsFlyout,
  NotificationsLabel
} from './MobileNav.styles';
import LinkList from '../common/LinkList';
import NavLink from '../common/NavLink';
import Logo from '../common/Logo';
import { colors } from '../../../css';
// import SVGIcon from '../../../../BuildingBlocks/SVGIcon';
import UserCard from '../common/UserCard';
import OrgSelect from '../common/OrgSelect';

const MENU_CONSTS = {
  search: 'searchMenu',
  forcedSearch: 'forcedSearch',
  main: 'mainMenu',
  user: 'userMenu',
  notifications: 'notificationsMenu'
};

class MobileNav extends Component {
  static propTypes = {
    /**
     * Passed Component of the link that takes a user to the homepage.
     */
    logo: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * The Passed component housing the search feature.
     */
    search: PropTypes.node,

    /**
     * An array of objects used to create functional nav links.
     */
    mainLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string,
        label: PropTypes.string,
        className: PropTypes.string,
        target: PropTypes.string,
        onClick: PropTypes.func
      })
    ),

    /**
     * Flagging whether user is logged in or not to determine wether to render user in nav with submenu or the passed joinLink.
     */
    authenticated: PropTypes.bool,

    /**
     * Callback function to tell parent whether or not the search bar is open.
     */
    mobileSearchCB: PropTypes.func,

    /**
     * Array of notification components provided by the navigation.
     */
    notifications: PropTypes.arrayOf(PropTypes.node),

    /**
     * String used on mobile only to indicate what the icon is.
     */
    notificationsLabel: PropTypes.string,

    /**
     * String message telling the user they do not have notifications.
     */
    noNotificationsMsg: PropTypes.string,

    /**
     * JSON object representing a link
     */
    joinLink: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Authenticated Users First Name.
     */

    userFirstName: PropTypes.string,

    /**
     * Authenticated Users Last Name.
     */

    userLastName: PropTypes.string,

    /**
     * Authenticated Users Currently active organization ID.
     */

    userCurrentOrganization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Authenticated Users Profile Pic (optional), defaults to users initials from First/Last Name.
     */

    userProfilePicture: PropTypes.string,

    /**
     * List of Organizations the Authenticated user is associated to that when clicked sets current active organization
     */
    // eslint-disable-next-line
    organizations: PropTypes.arrayOf(PropTypes.object),

    /**
     * JSON object representing a link
     */

    organizationSettings: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Change handler to send back selected org so all places can be updated by parent/ancestor
     */
    organizationChange: PropTypes.func,

    /**
     * JSON object representing a link to the Join Organization page.
     */
    joinOrganization: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * string of the telephone number for contacting lodgelink. only for mobile.
     */
    contactNumber: PropTypes.string,

    /**
     * function fired when clicking the contact icon on mobile menu.
     */
    onContactClick: PropTypes.func,

    /**
     * Array of JSON representing links available to the authenticated user.
     */
    subMenuLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string,
        label: PropTypes.string,
        className: PropTypes.string,
        target: PropTypes.string,
        onClick: PropTypes.func
      })
    ),

    /**
     * Ref of the search input for interaction needs.
     */
    searchFieldRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

    /**
     * JSON object representing a link to sign out the current authenticated user.
     */
    signOut: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * JSON object representing a link to sign in a user.
     */
    signIn: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     *  bool forcing open the navigation.
     */
    stickSearchOpen: PropTypes.bool,
    /**
     *  callback to enable/disable scrolling down or up to trigger nav show/hide
     */
    forceScrollCB: PropTypes.func,
    /**
     * Boolean to tell the navs to not render notifications.
     */
    suppressNotifications: PropTypes.bool,
    logoSelectedIcon: PropTypes.string
  };

  static defaultProps = {
    mainLinks: [],
    authenticated: false,
    forceScrollCB: () => {},
    userFirstName: '',
    userLastName: '',
    userCurrentOrganization: '',
    userProfilePicture: null,
    mobileSearchCB: () => {},
    notifications: [],
    notificationsLabel: '',
    noNotificationsMsg: 'No New Notifications',
    subMenuLinks: [],
    organizations: [],
    organizationSettings: null,
    organizationChange: () => {},
    joinOrganization: null,
    contactNumber: null,
    onContactClick: () => {},
    searchFieldRef: null,
    stickSearchOpen: false,
    suppressNotifications: false,
    joinLink: null,
    search: null,
    logo: null,
    signOut: null,
    signIn: null,
    logoSelectedIcon: ''
  };

  static getCurrentOrgName(userCurrentOrganization, organizations) {
    const currentOrg =
      organizations.length > 0
        ? organizations.find(organization => organization.id === userCurrentOrganization)
        : undefined;
    const orgName = currentOrg ? currentOrg.name : undefined;

    return orgName;
  }

  static hasMounted = false;

  static createNavLinks(links, cbFunc) {
    // if triggered again with no links, return null right away.
    if (links === null || links === undefined) {
      return null;
    }
    const extraCallback = typeof cbFunc === 'function' ? cbFunc : () => {};

    // handles if there is an array of links to make.
    if (Array.isArray(links)) {
      const reactLinks = [];

      links.forEach(linkObj => {
        const interceptedOnClick =
          linkObj.onClick && typeof linkObj.onClick === 'function'
            ? e => {
                linkObj.onClick(e, extraCallback);
              }
            : extraCallback;

        reactLinks.push(
          <NavLink
            key={linkObj.id}
            href={linkObj.href || '#'}
            onClick={interceptedOnClick}
            className={linkObj.className || null}
            target={linkObj.target || null}
          >
            {linkObj.label}
          </NavLink>
        );
      });

      return reactLinks;
    }

    // handles single link
    if (!Array.isArray(links) && typeof links === 'object') {
      const interceptedOnClick =
        links.onClick && typeof links.onClick === 'function'
          ? e => {
              links.onClick(e, extraCallback);
            }
          : extraCallback;
      return (
        <NavLink
          href={links.href || '#'}
          onClick={interceptedOnClick}
          target={links.target || null}
          className={links.className || null}
        >
          {links.label}
        </NavLink>
      );
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { userCurrentOrganization, organizations } = this.props;

    this.state = {
      // keeps track of the open menus
      menusOpen: [],
      // passed to let other components know nav is visible or not and to react to that
      menuVisible: false,
      // grabs the current organization name based on id passed and the organizations passed. used in displaying to user.
      currentOrgName: MobileNav.getCurrentOrgName(userCurrentOrganization, organizations),
      // state of the searchBar being open or closed
      searchBarOpen: false
    };

    // track y poistion when flyouts open
    this.flyoutOpenY = null;

    this.userLoggedInOut = this.userLoggedInOut.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleBodyLock = this.toggleBodyLock.bind(this);
    this.toggleMobileSearch = this.toggleMobileSearch.bind(this);
    this.focusSearch = this.focusSearch.bind(this);
    this.showLogo = this.showLogo.bind(this);
    this.showSearch = this.showSearch.bind(this);

    this.closeFlyouts = this.closeFlyouts.bind(this);
  }

  componentWillUnmount() {
    /* eslint-disable no-undef */

    // Enable page scrolling when nav is unmounted
    document.body.classList.remove('no-scrolling');

    /* eslint-disable no-undef */
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentOrgName, menusOpen } = prevState;
    const { userCurrentOrganization, organizations, stickSearchOpen } = nextProps;
    const newOrgName = MobileNav.getCurrentOrgName(userCurrentOrganization, organizations);

    const newState = {};

    // force state of the menu being open to close when nav is hidden
    // also call focus on something else to close search flyout
    if (currentOrgName !== newOrgName && newOrgName !== undefined) {
      newState.currentOrgName = newOrgName;
    }

    // know whether to add or remove the forced search piece. and we add it to the beggining of the menus open array so we don't break any current open menus
    const newMenusOpen = menusOpen;
    if (stickSearchOpen && !newMenusOpen.includes(MENU_CONSTS.forcedSearch)) {
      newMenusOpen.unshift(MENU_CONSTS.forcedSearch);
      newState.menusOpen = newMenusOpen;
    } else if (!stickSearchOpen) {
      if (menusOpen.includes(MENU_CONSTS.forcedSearch))
        newMenusOpen.splice(menusOpen.indexOf(MENU_CONSTS.forcedSearch), 1);
      newState.menusOpen = newMenusOpen;
    }

    return newState;
  }

  openMenu(e, menu) {
    e.preventDefault();
    const { mobileSearchCB } = this.props;
    const { menusOpen } = this.state;
    // flag to set menuVisible in setstate.
    let flyoutVisible = false;

    // add the opened menu to state
    const newArray = menu === MENU_CONSTS.search ? [menu, ...menusOpen] : [...menusOpen, menu];
    if (
      newArray[newArray.length - 1] !== MENU_CONSTS.search &&
      newArray[newArray.length - 1] !== MENU_CONSTS.forcedSearch
    ) {
      flyoutVisible = true;
    }
    if (newArray[0] === MENU_CONSTS.search) mobileSearchCB(true);

    // track current window scorll position to restore when flyouts close.
    if (
      newArray[newArray.length - 1] !== MENU_CONSTS.search &&
      newArray[newArray.length - 1] !== MENU_CONSTS.forcedSearch &&
      this.flyoutOpenY === null
    ) {
      this.flyoutOpenY = window.pageYOffset || document.documentElement.scrollTop;
    }

    this.setState({
      menusOpen: newArray,
      menuVisible: flyoutVisible
    });
  }

  closeMenu(e, menu) {
    e.preventDefault();
    const { mobileSearchCB } = this.props;
    const { menusOpen } = this.state;

    // remove the last item from the array of menu's open.
    const newArray = [...menusOpen];
    menu === MENU_CONSTS.search ? newArray.splice(0, 1) : newArray.splice(-1, 1);
    const newState = {
      menusOpen: newArray
    };

    // fire callback to let controller know we are closing the menu
    if (!newArray.includes(MENU_CONSTS.search)) {
      mobileSearchCB(false);
    }

    // ensure overlay is shown/hidden properly
    newState.menuVisible =
      (newArray[newArray.length - 1] !== MENU_CONSTS.search &&
        newArray[newArray.length - 1] !== MENU_CONSTS.forcedSearch &&
        newArray.length > 0) ||
      false;

    if (!newState.menuVisible) {
      this.toggleBodyLock(false);
    }

    this.setState(newState);
  }

  // force all flyouts closed, ignores search
  closeFlyouts() {
    const { menusOpen } = this.state;

    const filteredMenus = menusOpen.filter(menu => menu === MENU_CONSTS.search || menu === MENU_CONSTS.forcedSearch);

    this.setState({ menusOpen: filteredMenus, menuVisible: false });
    this.toggleBodyLock(false);
  }

  // sets the state to lock the body from scrolling
  toggleBodyLock(state) {
    if (state) {
      document.body.classList.add('no-scrolling');
    } else {
      document.body.classList.remove('no-scrolling');
      if (this.flyoutOpenY !== null) {
        // scroll down
        document.documentElement.scrollTop = this.flyoutOpenY;
        document.body.parentNode.scrollTop = this.flyoutOpenY;
        document.body.scrollTop = this.flyoutOpenY;
        this.flyoutOpenY = null;
      }
    }
  }

  // logic to figure out if the logo needs to be shown or not based on state
  showLogo() {
    const { stickSearchOpen } = this.props;
    const { menusOpen, searchBarOpen } = this.state;

    let shouldShow = true;

    // regular toggle of search via click on icon.
    if (stickSearchOpen && menusOpen.includes(MENU_CONSTS.search) && searchBarOpen) {
      shouldShow = false;
    } else if (
      (menusOpen.includes(MENU_CONSTS.search) && searchBarOpen) ||
      searchBarOpen ||
      menusOpen.includes(MENU_CONSTS.search)
    ) {
      shouldShow = false;
    }

    // becuase we went through the previous if we need to set it to true or false again.
    if (stickSearchOpen && menusOpen.includes(MENU_CONSTS.forcedSearch) && searchBarOpen) {
      shouldShow = false;
    } else if (
      (menusOpen.includes(MENU_CONSTS.forcedSearch) && searchBarOpen) ||
      searchBarOpen ||
      menusOpen.includes(MENU_CONSTS.forcedSearch)
    ) {
      shouldShow = false;
    }

    return shouldShow;
  }

  // logic to figure out if the search menu needs to be shown or not based on state
  showSearch() {
    const { stickSearchOpen } = this.props;
    const { menusOpen, searchBarOpen } = this.state;

    let shouldShow = true;

    // regular toggle of search via click on icon.
    if (!stickSearchOpen && !menusOpen.includes(MENU_CONSTS.search) && !searchBarOpen) {
      shouldShow = false;
    } else if (
      (!menusOpen.includes(MENU_CONSTS.search) && !searchBarOpen) ||
      !searchBarOpen ||
      !menusOpen.includes(MENU_CONSTS.search)
    ) {
      shouldShow = false;
    }

    // becuase we went through the previous if we need to set it to true or false again.
    if (stickSearchOpen && menusOpen.includes(MENU_CONSTS.forcedSearch) && searchBarOpen) {
      shouldShow = true;
    } else if (!this.showLogo() && stickSearchOpen && menusOpen.indexOf('forcedSearch') > -1 && !searchBarOpen) {
      shouldShow = true;
    }

    return shouldShow;
  }

  // toggle to show and hide the search bar and logo timely so they don't push each other around.
  toggleMobileSearch() {
    const { stickSearchOpen } = this.props;
    const { menusOpen } = this.state;
    let searchBarState = false;

    // figure out how to set the searchbar open close state.
    if (
      (stickSearchOpen && menusOpen.includes(MENU_CONSTS.forcedSearch)) ||
      (menusOpen.includes(MENU_CONSTS.search) && !stickSearchOpen) ||
      menusOpen.includes(MENU_CONSTS.forcedSearch)
    ) {
      searchBarState = true;
    } else if (!menusOpen.includes(MENU_CONSTS.forcedSearch) && menusOpen.includes(MENU_CONSTS.search)) {
      searchBarState = false;
    }
    this.setState({ searchBarOpen: searchBarState });
  }

  // we use the reference passed to us to focus the search input.
  focusSearch() {
    const { searchFieldRef } = this.props;
    searchFieldRef.current ? searchFieldRef.current.focus() : null;
  }

  // based on authentication state return proper components to render for the user
  userLoggedInOut() {
    const {
      authenticated,
      joinLink,
      userFirstName,
      userLastName,
      userProfilePicture,
      notificationsLabel,
      suppressNotifications
    } = this.props;
    const { currentOrgName } = this.state;

    return authenticated ? (
      <AuthenticatedContainer>
        {!suppressNotifications && (
          <NotificationsContainer
            onClick={e => {
              this.openMenu(e, MENU_CONSTS.notifications);
            }}
          >
            <SVGIcon selectedIcon="notifications" height="24px" width="24px" backgroundColor={colors.colorCobalt} />
            <NotificationsLabel>{notificationsLabel}</NotificationsLabel>
            <StyledCarrot selectedIcon="arrow" height="16px" width="16px" rotate={0} />
          </NotificationsContainer>
        )}

        <PaddedUserCard
          onClick={e => {
            this.openMenu(e, MENU_CONSTS.user);
          }}
          className="user-card"
        >
          <UserCard
            userFirstName={userFirstName}
            userLastName={userLastName}
            userCurrentOrganization={currentOrgName}
            userProfilePicture={userProfilePicture}
            cursor="pointer"
          />
        </PaddedUserCard>
      </AuthenticatedContainer>
    ) : (
      <AuthenticatedContainer>{MobileNav.createNavLinks(joinLink, this.closeFlyouts)}</AuthenticatedContainer>
    );
  }

  render() {
    const {
      logo,
      authenticated,
      forceScrollCB,
      search,
      contactNumber,
      onContactClick,
      userFirstName,
      userLastName,
      userCurrentOrganization,
      userProfilePicture,
      mainLinks,
      notifications,
      noNotificationsMsg,
      notificationsLabel,
      subMenuLinks,
      organizations,
      joinOrganization,
      organizationSettings,
      signOut,
      signIn,
      stickSearchOpen,
      organizationChange,
      suppressNotifications,
      logoSelectedIcon
    } = this.props;
    const { menusOpen, menuVisible, currentOrgName, searchBarOpen } = this.state;

    return (
      <>
        <MobileWrapper>
          <MenuHamburger>
            <NavLink
              className="menu"
              href="#"
              onClick={e => {
                this.openMenu(e, MENU_CONSTS.main);
              }}
            >
              <SVGIcon selectedIcon="hamburger" width="20px" height="20px" />
            </NavLink>
          </MenuHamburger>
          <CSSTransition
            in={this.showLogo()}
            timeout={400}
            classNames="logo"
            exit={stickSearchOpen ? !stickSearchOpen : true}
            unmountOnExit
            onExited={() => {
              this.toggleMobileSearch();
            }}
          >
            <HomeLinkContainer>
              <Logo
                href={(logo && logo.href) || '#'}
                onClick={(logo && logo.onClick) || null}
                className="lodgelink-logo"
                selectedIcon={logoSelectedIcon}
              />
            </HomeLinkContainer>
          </CSSTransition>
          <CSSTransition
            in={this.showSearch()}
            timeout={400}
            classNames="search"
            unmountOnExit
            exit={!stickSearchOpen && !searchBarOpen ? !(!stickSearchOpen && !searchBarOpen) : true}
            onEntered={() => {
              if (!stickSearchOpen) this.focusSearch();
            }}
            onExited={() => {
              this.toggleMobileSearch();
            }}
          >
            <SearchBarContainer>{search}</SearchBarContainer>
          </CSSTransition>
          {search && !stickSearchOpen && (
            <SearchMenu>
              <NavLink
                className="search"
                href="#"
                onClick={e => {
                  if (menusOpen.includes(MENU_CONSTS.search)) {
                    this.closeMenu(e, MENU_CONSTS.search);
                  } else {
                    this.openMenu(e, MENU_CONSTS.search);
                  }
                }}
              >
                <SVGIcon
                  selectedIcon={menusOpen.includes(MENU_CONSTS.search) ? 'close' : 'search'}
                  width="20px"
                  height="20px"
                  iconColor={colors.colorGrey}
                />
              </NavLink>
            </SearchMenu>
          )}

          {/* Main Menu Flyout */}
          <CSSTransition
            in={menusOpen.includes(MENU_CONSTS.main)}
            timeout={400}
            classNames="flyout"
            onEntering={() => {
              forceScrollCB(true);
            }}
            onEntered={() => {
              this.toggleBodyLock(true);
            }}
            onExited={() => {
              forceScrollCB(false);
            }}
          >
            <MenuFlyout>
              <FlyoutBar>
                <CloseBack
                  width="20px"
                  height="20px"
                  className="close"
                  href="#"
                  onClick={e => {
                    this.closeMenu(e);
                  }}
                >
                  <SVGIcon selectedIcon="close" width="20px" height="20px" iconColor={colors.colorGrey} />
                </CloseBack>
                {contactNumber && (
                  <ContactContainer>
                    <ContactIcon className="contact-phone" href={`tel:+${contactNumber}`} onClick={onContactClick}>
                      <SVGIcon selectedIcon="phone" width="20px" height="20px" iconColor={colors.colorBlue} />
                    </ContactIcon>
                  </ContactContainer>
                )}
              </FlyoutBar>
              <FlyoutContainer>
                <LinkList links={MobileNav.createNavLinks(mainLinks, this.closeFlyouts)} type="stacked" />
                {!authenticated && MobileNav.createNavLinks(signIn, this.closeFlyouts)}
                {this.userLoggedInOut()}
              </FlyoutContainer>
            </MenuFlyout>
          </CSSTransition>

          {/* User Menu Flyout */}
          <CSSTransition in={menusOpen.includes(MENU_CONSTS.user) && authenticated} timeout={400} classNames="flyout">
            <UserFlyout>
              <FlyoutBar>
                <CloseBack
                  className="back"
                  href="#"
                  onClick={e => {
                    this.closeMenu(e);
                  }}
                >
                  <SVGIcon selectedIcon="arrow" rotate={90} width="20px" height="20px" iconColor={colors.colorGrey} />
                </CloseBack>
                <FlyoutHeader>
                  <UserCard
                    userFirstName={userFirstName}
                    userLastName={userLastName}
                    userCurrentOrganization={currentOrgName}
                    userProfilePicture={userProfilePicture}
                    showOrg={false}
                    showCarrot={false}
                  />
                </FlyoutHeader>
              </FlyoutBar>
              <FlyoutContainer>
                <LinkList links={MobileNav.createNavLinks(subMenuLinks, this.closeFlyouts)} type="stacked" />
                <OrgSelect
                  organizations={organizations}
                  userCurrentOrganization={userCurrentOrganization}
                  organizationChange={organizationChange}
                />
                {MobileNav.createNavLinks(joinOrganization, this.closeFlyouts)}
                {userCurrentOrganization && organizations.length > 0
                  ? MobileNav.createNavLinks(organizationSettings, this.closeFlyouts)
                  : null}
                <StyledSignOut>{MobileNav.createNavLinks(signOut, this.closeFlyouts)}</StyledSignOut>
              </FlyoutContainer>
            </UserFlyout>
          </CSSTransition>

          {/* Notifications Menu Flyout */}
          <CSSTransition
            in={menusOpen.includes(MENU_CONSTS.notifications) && authenticated && !suppressNotifications}
            timeout={400}
            classNames="flyout"
          >
            <NotificationsFlyout>
              <FlyoutBar>
                <CloseBack
                  className="back"
                  href="#"
                  onClick={e => {
                    this.closeMenu(e);
                  }}
                >
                  <SVGIcon selectedIcon="arrow" rotate={90} width="20px" height="20px" iconColor={colors.colorGrey} />
                </CloseBack>
                <FlyoutHeader>{notificationsLabel}</FlyoutHeader>
              </FlyoutBar>
              <FlyoutContainer>
                {notifications !== null && notifications.length > 0 ? notifications : noNotificationsMsg}
              </FlyoutContainer>
            </NotificationsFlyout>
          </CSSTransition>
        </MobileWrapper>
        <CSSTransition in={menuVisible} timeout={400} classNames="underlay" unmountOnExit>
          <Underlay />
        </CSSTransition>
      </>
    );
  }
}

export default MobileNav;
