import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StyledBackgroundWrapper } from './PageBackgroundWrapper.styles';

class PageBackgroundWrapper extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
  };

  render() {
    const { children } = this.props;

    return <StyledBackgroundWrapper id="pageBackgroundWrapper">{children}</StyledBackgroundWrapper>;
  }
}

export default PageBackgroundWrapper;
