// Enums for feature flag start with "FLAG"

const FeatureFlagEnum = {
  FLAG_DATE_SNAKE: 'FLAG_DATE_SNAKE',
  FLAG_BOOK_MGMT: 'FLAG_BOOK_MGMT',
  FLAG_PAG_BOOKINGS: 'FLAG_PAG_BOOKINGS',
  FLAG_CREW_SWAP: 'FLAG_CREW_SWAP',
  FLAG_TIME_ZONE_MGMT: 'FLAG_TIME_ZONE_MGMT',
  FLAG_CREW_MGMT: 'FLAG_CREW_MGMT',
  FLAG_CREW_ROLE_MGMT: 'FLAG_CREW_ROLE_MGMT'
};

export default FeatureFlagEnum;
