import styled from 'styled-components';
import { fonts } from '../../components/css';

/**
 * The "flex" styles in here are used to make sure the layout always fills the
 * entire screen. The bottom of the footer should never appear above the bottom of
 * the window. The contents of the page should be stretched to to fill the remaining
 * space.
 *
 *
 * ----------- WINDOW TOP -----------
 * ----------------------------------
 * NAV
 * ----------------------------------
 * PAGE CONTENT
 *
 * (STRETCH THIS AREA TO FILL WINDOW)
 *
 * ----------------------------------
 * FOOTER
 * ----------------------------------
 * ---------- WINDOW BOTTOM ----------
 *
 *
 * Some of the flex box stuff looks a little weird because I had to do dirty
 * things to support IE11. I used this article to help me through it:
 * https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/
 *
 * At time of dev tested in Chrome (Latest), Firefox (Latest), Safari (Latest),
 * Edge (Latest), iOS 12
 */

export const Layout = styled.div`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  display: flex;
  flex-direction: column;
  height: 100vh; /* intuitively this should be "min-height: 100vh" but its not because IE11 sucks */
  background: #fff;
`;

export const LayoutNav = styled.div`
  flex-shrink: 0; /* intuitively this should not even exist but its here because IE11 sucks */
  z-index: 99;
`;

export const LayoutPageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* intuitively this should be "flex-grow: 1" but its not because IE11 sucks */

  > *:only-child {
    height: auto;
    flex: 1 0 auto; /* intuitively this should be "flex-grow: 1" but its not because IE11 sucks */
  }
`;

export const LayoutFooter = styled.div`
  flex-shrink: 0; /* intuitively this should not even exist but its here because IE11 sucks */
`;

export const StyledLink = styled.span`
  display: inline-block;
  padding-right: 2rem;
  font-family: ${fonts.fontRegular};

  &:before {
    margin-right: 1.5rem;
  }
  font-size: 14px;
  & > a {
    text-decoration: none;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
`;
