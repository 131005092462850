import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Parser from 'react-html-parser';
import { transform, defaultConverters } from './lib';

export default class RichText extends Component {
  static propTypes = {
    content: PropTypes.string,
    converters: PropTypes.object, // eslint-disable-line
    whitelist: PropTypes.arrayOf(PropTypes.string),
    allowedObjects: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    content: '',
    whitelist: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'strong', 'br', 'a', 'ul', 'li', 'table', 'tbody', 'tr', 'td'],
    allowedObjects: [],
    converters: {}
  };

  render() {
    const { content, converters, whitelist, allowedObjects, complexText } = this.props;
    const mergedConverters = {
      ...defaultConverters,
      ...converters
    };

    if (!complexText) {
      // eslint-disable-next-line
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }

    return (
      <>
        {Parser(content, {
          transform: node => transform(node, { allowedObjects, convert: mergedConverters, whitelist })
        })}
      </>
    );
  }
}
