// exclude-from-index
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledLink } from './NavLink.styles';
import { IsTouchEnabledConsumer } from '../Context';

export default class NavLink extends Component {
  static propTypes = {
    /**
     * Content of the styled anchor component, usually text based but can also accept SVGs
     */
    children: PropTypes.node,
    /**
     * regular anchor tag href attribute to pass to styled anchor component
     */
    href: PropTypes.string,
    /**
     * regular anchor tag target attribute to pass to styled anchor component
     */
    target: PropTypes.string,
    /**
     * Class's to apply to the anchor tag
     */
    className: PropTypes.string,
    /**
     * function passed to control any required behavior that is not regular routing.
     */
    onClick: PropTypes.func
  };

  static defaultProps = {
    children: null,
    href: '',
    target: '',
    className: '',
    onClick: () => {}
  };

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <IsTouchEnabledConsumer>
          {({ isTouchDevice }) => (
            <StyledLink {...this.props} isTouchDevice={isTouchDevice}>
              {children}
            </StyledLink>
          )}
        </IsTouchEnabledConsumer>
      </Fragment>
    );
  }
}
