import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class GamesRoom extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <circle fill={iconColor} cx="5.25" cy="7.71" r="1.11" />
        <circle fill={iconColor} cx="8.93" cy="11.39" r="1.11" />
        <path
          fill={iconColor}
          d="m12.5 9.05v-4.91h-10.81v10.86h9.81v4.91h10.81v-10.86zm-9.81 4.95v-8.86h8.81v8.86zm18.62 4.91h-8.81v-8.86h8.81z"
        />
        <circle fill={iconColor} cx="15.06" cy="12.61" r="1.11" />
        <circle fill={iconColor} cx="18.74" cy="12.61" r="1.11" />
        <circle fill={iconColor} cx="15.06" cy="16.29" r="1.11" />
        <circle fill={iconColor} cx="18.74" cy="16.29" r="1.11" />
      </svg>
    );
  }
}
