import cookie from 'cookie';
import get from 'lodash.get';
import getConfig from 'next/config';
import uuidv4 from 'uuid/v4';

import gtm from './providers/gtm';

const {
  publicRuntimeConfig: { DEBUG_ENABLE_ANALYTICS_LOGGING }
} = getConfig();

export const ANALYTICS_ID = {
  CLIENT: 'LLV2_CLIENT_ID',
  SESSION: 'LLV2_SESSION_ID'
};

// You can add multiple providers if you want analytics to be dumped to multiple services
const providers = [gtm];

// Returns client and session IDs found in cookie
const getAnalyticsIds = () => {
  const cookies = cookie.parse(document.cookie || '');

  return {
    [ANALYTICS_ID.CLIENT]: cookies[ANALYTICS_ID.CLIENT],
    [ANALYTICS_ID.SESSION]: cookies[ANALYTICS_ID.SESSION]
  };
};

// Logs an event with all analytics providers
export const trackEvent = (eventName, payload) => {
  if (process.browser) {
    const analyticsIds = getAnalyticsIds();

    // Optional debug logging
    if (DEBUG_ENABLE_ANALYTICS_LOGGING) {
      /* eslint-disable no-console  */
      console.group('Analytics (trackEvent)');
      console.log(`Event Name: ${eventName}`);
      console.log('Payload:', payload);
      console.log(`Client ID: ${analyticsIds[ANALYTICS_ID.CLIENT]}`);
      console.log(`Session ID: ${analyticsIds[ANALYTICS_ID.SESSION]}`);
      console.groupEnd();
      /* eslint-enable no-console  */
    }

    // Track the event with each of the analytics providers
    providers.forEach(provider => {
      provider.trackEvent(eventName, payload, analyticsIds);
    });
  }
};

export const trackGA4 = (event = 'track-event', payload) => {
  if (process.browser) {
    const analyticsIds = getAnalyticsIds();

    // Optional debug logging
    if (DEBUG_ENABLE_ANALYTICS_LOGGING) {
      /* eslint-disable no-console  */
      console.group('Analytics (trackGA4)');
      console.log(`Event Name: ${event}`);
      console.log('Payload:', payload);
      console.log(`Client ID: ${analyticsIds[ANALYTICS_ID.CLIENT]}`);
      console.log(`Session ID: ${analyticsIds[ANALYTICS_ID.SESSION]}`);
      console.groupEnd();
      /* eslint-enable no-console  */
    }

    // Track the event with each of the analytics providers
    providers.forEach(provider => {
      provider.trackGA4(event, payload, analyticsIds);
    });
  }
};

// Log a route or page load with all analytics providers
export const trackRoute = routeName => {
  if (process.browser) {
    const analyticsIds = getAnalyticsIds();

    // Optional debug logging
    if (DEBUG_ENABLE_ANALYTICS_LOGGING) {
      /* eslint-disable no-console  */
      console.group('Analytics (trackRoute)');
      console.log(`Route Name: ${routeName}`);
      console.log(`Client ID: ${analyticsIds[ANALYTICS_ID.CLIENT]}`);
      console.log(`Session ID: ${analyticsIds[ANALYTICS_ID.SESSION]}`);
      console.groupEnd();
      /* eslint-enable no-console  */
    }

    // This timeout gives the PageSEO (uses react-helmet) component time to update title and meta tags
    // before the analytics providers are called. This makes sure the title tag is set before the providers
    // try to access it.
    setTimeout(() => {
      // Track the route change with each of the analytics providers
      providers.forEach(provider => {
        provider.trackRoute(routeName, analyticsIds);
      });
    }, 5);
  }
};

/**
 * Checks if we have cookie info for analytics, if not creates the info and cookie.
 *
 * TODO:
 * - Understand what is going on here and see if there is anything that can be done to tidy it up
 * - Make use of ANALYTICS_ID constant everywhere not just a couple places
 *
 * @static
 * @param {object} ctx
 * @returns {object} A locale object containing LLV2_CLIENT_ID and LLV2_SESSION_ID.
 * @memberof LodgeLinkApp
 */
export const getAnalyticCookiesFromContext = ctx => {
  const res = get(ctx, 'ctx.res');
  const cookiesToSet = [];
  const cookieValues = {};
  // determine how to get the cookie info.
  let cookieRef = '';

  if (!process.browser) {
    cookieRef = get(ctx, 'ctx.req.headers.cookie', '');
  } else {
    cookieRef = get(document, 'cookie', '');
  }

  const sessionUUID = uuidv4();
  // check the headers and see if we have any cookies already
  const { LLV2_CLIENT_ID, LLV2_SESSION_ID } = cookie.parse(cookieRef);
  const sessionExpireTime = new Date();
  sessionExpireTime.setTime(sessionExpireTime.getTime() + 30 * 60 * 1000);

  // check if we have a client cookie, and if we don't set it. should always exist unless user clears cookies.
  if (!LLV2_CLIENT_ID) {
    const clientUUID = uuidv4();
    const clientExpireTime = new Date();
    clientExpireTime.setFullYear(3050, 1);
    cookiesToSet.push(cookie.serialize(ANALYTICS_ID.CLIENT, clientUUID, { expires: clientExpireTime, path: '/' }));

    cookieValues.LLV2_CLIENT_ID = clientUUID;
  } else {
    cookieValues.LLV2_CLIENT_ID = LLV2_CLIENT_ID;
  }

  // check for session cookie. if is there take value and update expiry, if not set it.
  if (!LLV2_SESSION_ID) {
    cookiesToSet.push(cookie.serialize(ANALYTICS_ID.SESSION, sessionUUID, { expires: sessionExpireTime, path: '/' }));
    cookieValues.LLV2_SESSION_ID = sessionUUID;
  } else {
    cookiesToSet.push(
      cookie.serialize(ANALYTICS_ID.SESSION, LLV2_SESSION_ID, { expires: sessionExpireTime, path: '/' })
    );
    cookieValues.LLV2_SESSION_ID = LLV2_SESSION_ID;
  }

  // if we need to update/set cookies do it. have to do it all at once or only last set works...
  if (res && cookiesToSet.length > 0) {
    res.setHeader('Set-Cookie', cookiesToSet);
  } else if (cookiesToSet.length > 0 && process.browser) {
    cookiesToSet.forEach(newCookie => {
      document.cookie = newCookie;
    });
  }

  return cookieValues;
};
