import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class OpenPanel extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 18,
    backgroundColor: 'none',
    iconColor: colors.colorBlue
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g fill={backgroundColor} fillRule="evenodd">
          <g transform="translate(-22 -2669)" fill={iconColor}>
            <g transform="translate(22 2301)">
              <g transform="translate(0 365)">
                <g>
                  <g transform="translate(0 3)">
                    <path d="M9,16.1997 C5.03025,16.1997 1.8,12.96945 1.8,8.9997 C1.8,5.02995 5.03025,1.7997 9,1.7997 C12.96975,1.7997 16.2,5.02995 16.2,8.9997 C16.2,12.96945 12.96975,16.1997 9,16.1997 M9,0.7497 C4.44375,0.7497 0.75,4.44345 0.75,8.9997 C0.75,13.5567 4.44375,17.2497 9,17.2497 C13.55625,17.2497 17.25,13.5567 17.25,8.9997 C17.25,4.44345 13.55625,0.7497 9,0.7497" />
                    <polygon
                      transform="rotate(45 9 9)"
                      points="11.628675 5.628675 8.999925 8.257425 6.371175 5.628675 5.628675 6.371175 8.257425 8.999925 5.628675 11.628675 6.371175 12.371175 8.999925 9.742425 11.628675 12.371175 12.371175 11.628675 9.742425 8.999925 12.371175 6.371175"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
