import PropTypes from 'prop-types';
import { Component } from 'react';
import { flowRight as compose } from 'lodash';

import withRouting, { withRoutingPropType } from '../../hocs/withRouting';
import withActiveLocale, { withActiveLocalePropType } from '../../hocs/withActiveLocale';

/**
 * When this componnet is rendered on a page it will redirect the user to the
 * SignIn page.
 *
 * The "redirect" parameter will be automatically set so that after logging in
 * the user can be redirected back to the page they started on.
 */
class RedirectSignIn extends Component {
  static propTypes = {
    activeLocale: withActiveLocalePropType.isRequired,
    method: PropTypes.oneOf(['push', 'replace']),
    router: withRoutingPropType.isRequired
  };

  static defaultProps = {
    method: 'push'
  };

  constructor(props) {
    super(props);

    if (process.browser) {
      const {
        activeLocale,
        method,
        router: { asPath, pushRouteAndScroll, replaceRouteAndScroll }
      } = props;

      const noRedirectPaths = ['/en/onboarding/onboarding-options', '/en/onboarding/onboarding-status'];

      // Assemble route information
      const routeName = 'SignIn';
      const routeParams = {
        locale: activeLocale.id,
        // The magic that makes this component different than Redirect
        // Populates the redirect paramater automatically
        // redirect: asPath
        ...(noRedirectPaths.includes(asPath) ? {} : { redirect: asPath })
      };

      // Use one of the two routing methods
      if (method === 'push') {
        pushRouteAndScroll(routeName, routeParams).then(RedirectSignIn.onRouteChanged);
      } else if (method === 'replace') {
        replaceRouteAndScroll(routeName, routeParams).then(RedirectSignIn.onRouteChanged);
      }
    }
  }

  render() {
    return null;
  }
}

export default compose(
  withRouting,
  withActiveLocale
)(RedirectSignIn);
