import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Breakfast extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <g fill="none">
          <path d="m14 14-.56 5h7.63l.86-12h-10.86l.27 3.78c1.66.73 2.66 1.9 2.66 3.22z" />
          <path d="m2.9 22h1.1v-7h-1.88z" />
          <path d="m5 15h2v7h-2z" />
          <path d="m8 15h2v7h-2z" />
        </g>
        <path
          fill={iconColor}
          d="m10 6 .31 4.4a10 10 0 0 0 -2.81-.4c-3.59 0-6.5 1.79-6.5 4l1 9h11l.33-3h8.67l1-14zm-6 16h-1.1l-.78-7h1.88zm3 0h-2v-7h2zm3 0h-2v-7h2zm0-8h-8c0-1.42 2.26-3 5.5-3a8.4 8.4 0 0 1 2.89.49 5.83 5.83 0 0 1 1 .46 2.65 2.65 0 0 1 1.61 2.05zm2.1 8h-1.1v-7h1.88l-.44 4-.11 1zm9-3h-7.66l.56-5c0-1.32-1.05-2.49-2.66-3.22l-.27-3.78h10.86z"
        />
        <path
          fill={iconColor}
          d="m12.9 4.4.6.6a1.59 1.59 0 0 0 0-2.3.74.74 0 0 1 0-1.1l-.3-.3-.3-.3a1.59 1.59 0 0 0 0 2.3.74.74 0 0 1 0 1.1z"
        />
        <path
          fill={iconColor}
          d="m15.9 4.4.6.6a1.59 1.59 0 0 0 0-2.3.74.74 0 0 1 0-1.1l-.3-.3-.3-.3a1.59 1.59 0 0 0 0 2.3.74.74 0 0 1 0 1.1z"
        />
        <path
          fill={iconColor}
          d="m18.9 4.4.6.6a1.59 1.59 0 0 0 0-2.3.74.74 0 0 1 0-1.1l-.3-.3-.3-.3a1.59 1.59 0 0 0 0 2.3.74.74 0 0 1 0 1.1z"
        />
      </svg>
    );
  }
}
