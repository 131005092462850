import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Instagram extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 37,
    width: 37,
    iconColor: colors.colorWhite
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 37 37">
        <g fill={iconColor}>
          <path d="M23.992 6.648a6.36 6.36 0 016.36 6.36v10.984a6.36 6.36 0 01-6.36 6.36H13.008a6.36 6.36 0 01-6.36-6.36V13.008a6.36 6.36 0 016.36-6.36h10.984m0-2.312H13.008a8.697 8.697 0 00-8.672 8.672v10.984a8.697 8.697 0 008.672 8.672h10.984a8.697 8.697 0 008.672-8.672V13.008a8.697 8.697 0 00-8.672-8.672zm0 0" />
          <path d="M26.055 9.25a1.657 1.657 0 10.001 3.314 1.657 1.657 0 00-.001-3.314zm0 0M18.578 13.434a5.107 5.107 0 015.113 5.109 5.112 5.112 0 01-10.223-.004 5.118 5.118 0 015.11-5.105m0-2.313a7.426 7.426 0 00-7.41 7.434 7.424 7.424 0 1012.668-5.266 7.415 7.415 0 00-5.258-2.168zm0 0" />
        </g>
      </svg>
    );
  }
}
