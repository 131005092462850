import React from 'react';
import PropTypes from 'prop-types';
import { StyledImageContainer, StyledImage } from './Image.styles';

export const IMAGE_RATIO_NAMES = ['6:4', '16:9', '1:1', '2:1', '14:5', '5:2', '5:4'];

export const IMAGE_RATIOS = {
  '6:4': [6, 4],
  '16:9': [16, 9],
  '1:1': [1, 1],
  '2:1': [2, 1],
  '14:5': [14, 5],
  '5:2': [5, 2],
  '5:4': [5, 4]
};

/**
 *
 * Regular Image component that takes ratio as a prop.
 */

function Image({ src, alt, title, ratio, className, displayImage }) {
  const ratioValue = IMAGE_RATIOS[ratio];
  return (
    <StyledImageContainer
      className={className}
      height={ratioValue[1]}
      width={ratioValue[0]}
      src={src}
      displayImage={displayImage}
    >
      <StyledImage alt={alt} src={src} title={!title ? alt : title} displayImage={displayImage} />
    </StyledImageContainer>
  );
}

Image.propTypes = {
  /** Ratio the image will maintain */
  ratio: PropTypes.oneOf(IMAGE_RATIO_NAMES),
  /** Location of the asset */
  src: PropTypes.string.isRequired,
  /** Alt copy provided by SEO */
  alt: PropTypes.string.isRequired,
  /** Title copy provided by SEO - defaults to alt copy if no title is provided */
  title: PropTypes.string,
  className: PropTypes.string,
  /** How to render an image that does not fit the ratio: resize (stretches image to fit) or crop (crops the sides and centers the image) */
  displayImage: PropTypes.oneOf(['resize', 'crop'])
};

Image.defaultProps = {
  ratio: '6:4',
  title: '',
  className: undefined,
  displayImage: 'resize'
};

export default Image;
