import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class CrewSupervisorIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.colorBlack
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={height} width={width}>
        <path
          fill={iconColor}
          d="M16.62,3.09V.91H7.38V3.09H2.92v20H21.08v-20ZM8.38,1.91h7.24V4.57H8.38Zm11.7,20.17H3.92v-18H7.38V5.57h9.24V4.09h3.46Z"
        />
        <rect fill={iconColor} x="6.7" y="8.68" width="10.6" height="1" />
        <rect fill={iconColor} x="6.7" y="13.14" width="10.6" height="1" />
        <rect fill={iconColor} x="6.7" y="17.59" width="10.6" height="1" />
      </svg>
    );
  }
}
