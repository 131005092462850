import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Facebook extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 37,
    backgroundColor: colors.facebookBlue,
    iconColor: colors.colorWhite
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="Icons/Social/Facebook">
            <rect id="Rectangle-5-Copy" fill={backgroundColor} x="0" y="0" width="37" height="37" />
            <path
              fill={iconColor}
              d="M20.3145197,31 L20.3145197,18.9985949 L23.5687449,18.9985949 L24,14.8628442 L20.3145197,14.8628442 L20.3200486,12.7928612 C20.3200486,11.7141937 20.4207209,11.136219 21.9425538,11.136219 L23.9769629,11.136219 L23.9769629,7 L20.7222769,7 C16.8128757,7 15.4368678,9.00628403 15.4368678,12.3802229 L15.4368678,14.8633126 L13,14.8633126 L13,18.9990633 L15.4368678,18.9990633 L15.4368678,31 L20.3145197,31 Z"
              id="Shape-Copy"
            />
          </g>
        </g>
      </svg>
    );
  }
}
