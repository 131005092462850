import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class OilGas extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 120,
    iconColor: colors.colorGrey02,
    backgroundColor: colors.iconGreen
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;
    return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
        <polygon
          fill={backgroundColor}
          opacity={0.4}
          points="86.15 80.14 95.01 80.14 98.28 92.91 99.87 92.91 99.87 93.67 51.09 93.67 57.98 67.5 67.24 67.5 73.84 92.91 81.71 92.91 86.15 80.14"
        />
        <path
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          d="M26.64,93.26V68.79L39.77,47.62l16.64,3c0,.1-.08,5.82-.08,5.82l-1.92.74L45,93.26Z"
        />
        <path
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          d="M16.89,93.26H98.28L95,80.49H86.15L82.44,93.26h-8.6L64.47,57.19l-1.71-.66.12-4.84,27.78,4.85.1,23.95-.1-23.95,6,1.12,1.24-4.48L40.73,40.61,35.87,26.33s-11.35,9-13.39,20.78c-1.43,8.23,1.08,15.75,4.16,21.68"
        />
      </svg>
    );
  }
}
