import React, { Component } from 'react';
import Helmet from 'react-helmet';

export default class Favicon extends Component {
  static PATH = '/static/assets/favicon'; // Corrected path

  static getLink() {
    const link = [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: `${Favicon.PATH}/apple-touch-icon.png`
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: `${Favicon.PATH}/favicon-32x32.png`
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: `${Favicon.PATH}/favicon-16x16.png`
      },
      {
        rel: 'manifest',
        href: `${Favicon.PATH}/site.webmanifest`
      },
      {
        rel: 'mask-icon',
        href: `${Favicon.PATH}/safari-pinned-tab.svg`,
        color: '#5bbad5'
      },
      {
        rel: 'shortcut icon',
        href: `${Favicon.PATH}/favicon.ico`
      }
    ];

    return link;
  }

  static getMeta() {
    const meta = [
      {
        name: 'msapplication-TileColor',
        content: '#ffffff'
      },
      {
        name: 'msapplication-config',
        content: `${Favicon.PATH}/browserconfig.xml`
      },
      {
        name: 'theme-color',
        content: '#ffffff'
      }
    ];

    return meta;
  }

  render() {
    return <Helmet meta={Favicon.getMeta()} link={Favicon.getLink()} />;
  }
}
