import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import SVGIcon from '../../../SVGIcon/SVGIcon';
import { mixins, colors, zIndex, fonts } from '../../../css';
import NavLink from '../common/NavLink';

const spreadStickSearch = ({ stickSearchOpen, ...rest }) => <div {...rest} />;

export const MobileWrapper = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: ${mixins.pxToRem(55)};
  min-width: ${mixins.pxToRem(320)};
  margin: 0 auto;
  background-color: ${colors.colorWhite};
`;

export const Underlay = styled.div`
  width: 100%;
  height: 100vh;
  z-index: ${zIndex.navigation.underlay.over};
  background-color: #000000;
  opacity: 0.7;
  transition: opacity 0.5s, transform 0.5s;
  position: fixed;
  top: 0;
  left: 0;

  &.underlay-enter {
    opacity: 0;
  }
  &.underlay-enter-active {
    opacity: 0.7;
  }
  &.underlay-exit {
    opacity: 0.7;
  }
  &.underlay-exit-active {
    opacity: 0;
  }
`;

export const MenuHamburger = styled.div`
  max-width: ${mixins.pxToRem(60)};
  height: 100%;
  a.menu {
    margin: ${mixins.pxToRem(17.5)} ${mixins.pxToRem(20)};
  }
`;

export const HomeLinkContainer = styled(spreadStickSearch)`
  height: 100%;
  flex-grow: 1;
  padding: 0 ${mixins.pxToRem(20)};
  justify-content: center;
  display: flex;
  opacity: 1;
  transition: opacity 0.4s, transform 0.4s;

  ${({ stickSearchOpen }) => (stickSearchOpen ? `display: none` : ``)} > a {
    width: ${mixins.pxToRem(100)};
    align-items: center;
  }

  &.logo-enter {
    opacity: 0;
  }
  &.logo-enter-active {
    opacity: 1;
  }
  &.logo-exit {
    opacity: 1;
  }
  &.logo-exit-active {
    opacity: 0;
  }
`;

export const SearchBarContainer = styled(spreadStickSearch)`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  padding: 0 ${mixins.pxToRem(20)};
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: ${zIndex.navigation.main};
  opacity: 1;
  transition: opacity 0.4s, transform 0.4s;

  > div:first-of-type {
    width: 100%;
  }

  &.search-enter {
    opacity: 0;
  }
  &.search-enter-active {
    opacity: 1;
  }
  &.search-exit {
    opacity: 1;
  }
  &.search-exit-active {
    opacity: 0;
  }
`;

export const SearchMenu = styled.div`
  max-width: ${mixins.pxToRem(60)};
  height: 100%;
  a.search {
    margin: ${mixins.pxToRem(17.5)} ${mixins.pxToRem(20)};
  }
`;

/* Flyout Styles */

const flyIn = keyframes`
0% {
  transform: translateX(-100%);
} 
100% {
  transform: translateX(0);
}  
`;

const flyOut = keyframes`
0% {
  transform: translateX(0);
}  
100% {
  transform: translateX(-100%);
} 
`;
const Flyout = styled.div`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 20px;
  min-width: ${mixins.pxToRem(320)};
  height: 100vh;
  z-index: ${zIndex.navigation.flyout};
  transform: translateX(-100%);
  font-family: ${fonts.fontRegular};

  &.flyout-enter {
    transform: translateX(-100%);
  }
  &.flyout-enter-active {
    right: 20px;
    animation: ${css`
      ${flyIn} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    `};
  }
  &.flyout-enter-done {
    right: 20px;
    transform: translateX(0);
  }
  &.flyout-exit {
    right: 20px;
    transform: translateX(0);
  }
  &.flyout-exit-active {
    animation: ${css`
      ${flyOut} 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    `};
  }
  &.flyout-exit-done {
    transform: translateX(-100%);
  }
`;

export const FlyoutBar = styled.div`
  width: 100%;
  position: relative;
  height: ${mixins.pxToRem(70)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const FlyoutHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: calc(100% - 50px);
  padding-right: ${mixins.pxToRem(30)};
  transform: translateX(50px);

  > div:first-of-type {
    max-height: 55%;
    width: auto;
  }
`;

export const FlyoutContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${mixins.pxToRem(70)});
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  a {
    padding: ${mixins.pxToRem(15)} ${mixins.pxToRem(25)};

    &:hover {
      background-color: ${colors.colorGrey05};
    }
  }
`;

export const CloseBack = styled(NavLink)`
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: ${mixins.pxToRem(25)};
  transform: translateY(-50%);
`;

/* End Flyout Styles */

export const ContactContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const ContactIcon = styled(NavLink)`
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: ${mixins.pxToRem(25)};
  transform: translateY(-50%);
`;

export const MenuFlyout = styled(Flyout)``;

export const AuthenticatedContainer = styled.div`
  width: 100%;
  padding: ${mixins.pxToRem(40)} 0 ${mixins.pxToRem(20)};
  position: relative;
  display: flex;
  flex-flow: row wrap;

  > a {
    width: 100%;
  }

  &:after {
    content: '';
    display: block;
    width: calc(100% - ${mixins.pxToRem(45)});
    height: ${mixins.pxToRem(1)};
    position: absolute;
    top: ${mixins.pxToRem(20)};
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.colorGrey05};
  }
`;

export const PaddedUserCard = styled.div`
  padding: ${mixins.pxToRem(25)};
  width: 100%;
`;

export const UserFlyout = styled(Flyout)``;

export const StyledOrg = styled.div`
  position: relative;
  cursor: pointer;
  padding: ${`${mixins.pxToRem(15)} ${mixins.pxToRem(45)} ${mixins.pxToRem(15)} ${mixins.pxToRem(25)}`};
`;

export const StyledSignOut = styled.div`
  background-color: ${colors.colorGrey05};
`;

export const OrgFlyout = styled(Flyout)``;

export const NotificationsContainer = styled.div`
  margin: ${mixins.pxToRem(25)} ${mixins.pxToRem(25)} ${mixins.pxToRem(10)};
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const StyledCarrot = styled(SVGIcon)`
  position: absolute;
  right: ${mixins.pxToRem(40)};
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
`;

export const NotificationsFlyout = styled(Flyout)`
  > ${FlyoutContainer} {
    padding: 25px;
  }
`;

export const NotificationsLabel = styled.span`
  display: inline-block;
  padding-left: 20px;
`;
