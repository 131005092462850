import getConfig from 'next/config';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import Helmet from 'react-helmet';
import { intlShape, injectIntl } from 'react-intl';

import withRouting, { withRoutingPropType } from '../../hocs/withRouting';

import messages from './messages';

// Pull related configuration variables from the runtime config
const {
  publicRuntimeConfig: { DOMAIN }
} = getConfig();

class PageSEO extends Component {
  static propTypes = {
    canonicalUrl: PropTypes.string,
    description: PropTypes.string,
    facebookDescription: PropTypes.string,
    facebookImage: PropTypes.string,
    facebookTitle: PropTypes.string,
    intl: intlShape.isRequired,
    router: withRoutingPropType.isRequired,
    title: PropTypes.string,
    twitterDescription: PropTypes.string,
    twitterImage: PropTypes.string,
    twitterTitle: PropTypes.string
  };

  static defaultProps = {
    canonicalUrl: null,
    description: null,
    facebookDescription: null,
    facebookImage: null,
    facebookTitle: null,
    title: null,
    twitterDescription: null,
    twitterImage: null,
    twitterTitle: null
  };

  getCanonicalUrl() {
    const { canonicalUrl, router } = this.props;

    if (canonicalUrl !== null) {
      return `${DOMAIN}${canonicalUrl}`;
    }

    return `${DOMAIN}${router.asPath}`;
  }

  getTitle() {
    const { intl, title } = this.props;

    const defaultTitle = intl.formatMessage(messages.defaultTitle);

    return title || defaultTitle;
  }

  getLink() {
    const link = [];

    // Canonical Url
    link.push({
      rel: 'canonical',
      href: this.getCanonicalUrl()
    });

    return link;
  }

  getMeta() {
    const {
      description,
      facebookDescription,
      facebookImage,
      facebookTitle,
      intl,
      twitterDescription,
      twitterImage,
      twitterTitle
    } = this.props;

    const meta = [];

    // Default localized values
    const defaultDescription = intl.formatMessage(messages.defaultDescription);
    const defaultTitle = intl.formatMessage(messages.defaultTitle);

    // Description
    meta.push({
      name: 'description',
      content: description || defaultDescription
    });

    // Static Facebook Values
    meta.push(
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:site_name',
        content: 'LodgeLink'
      }
    );

    // Facebook Description
    meta.push({
      property: 'og:description',
      content: facebookDescription || defaultDescription
    });

    // Facebook Image
    meta.push({
      property: 'og:image',
      content: facebookImage || `${DOMAIN}/static/assets/seo/default-facebook.jpg`
    });

    // Facebook Title
    meta.push({
      property: 'og:title',
      content: facebookTitle || defaultTitle
    });

    // Facebook Url
    meta.push({
      property: 'og:url',
      content: this.getCanonicalUrl()
    });

    // Static Twitter Values
    meta.push(
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:site',
        content: '@LodgeLink'
      },
      {
        name: 'twitter:creator',
        content: '@LodgeLink'
      }
    );

    // Twitter Description
    meta.push({
      name: 'twitter:description',
      content: twitterDescription || defaultDescription
    });

    // Twitter Image
    meta.push({
      name: 'twitter:image',
      content: twitterImage || `${DOMAIN}/static/assets/default-twitter.jpg`
    });

    // Twitter Title
    meta.push({
      name: 'twitter:title',
      content: twitterTitle || defaultTitle
    });

    return meta;
  }

  render() {
    return <Helmet title={this.getTitle()} meta={this.getMeta()} link={this.getLink()} />;
  }
}

export default compose(
  injectIntl,
  withRouting
)(PageSEO);
