import { defineMessages } from 'react-intl';

const messages = defineMessages({
  placeholder: 'Search Accommodations...',
  searchLabelText: 'Find Accommodations Near...',
  roomsLabelText: 'Rooms',
  startDateLabelText: 'Start Date',
  endDateLabelText: 'End Date'
});

export default messages;
