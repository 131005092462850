// exclude-from-index
import React from 'react';
import PropTypes from 'prop-types';
import { TAG_NAMES, FONT_WEIGHT, FONT_COLOR, TEXT_ALIGN, HEADING_COLORS, TEXT_TRANSFORM } from '../Base/Base';
import { StyledCopyBody } from './CopyBody.styles';

function CopyBody({ tagName, weight, color, children, align, textTransform, noMargins, className, fontSize }) {
  return (
    <StyledCopyBody
      tagName={tagName}
      fontSize={fontSize}
      color={HEADING_COLORS[color]}
      align={align}
      weight={weight}
      noMargins={noMargins}
      className={className}
      textTransform={textTransform}
    >
      {children}
    </StyledCopyBody>
  );
}

CopyBody.propTypes = {
  /** Font Color: primary, secondary, tertiary, quaternary */
  color: PropTypes.oneOf(FONT_COLOR),
  /** Font Weight: normal, bold */
  weight: PropTypes.oneOf(FONT_WEIGHT),
  /** Element tag name: h1, h2, h3, h4, h5, h6, div, span, label, p, strong, li */
  tagName: PropTypes.oneOf(TAG_NAMES),
  /** Copy alignment: inherit, left, center, right, justify */
  align: PropTypes.oneOf(TEXT_ALIGN),
  /** Text Transform: none, capitalize, uppercase, lowercase, initial, inherit */
  textTransform: PropTypes.oneOf(TEXT_TRANSFORM),
  /** Copy/elements you want inside */
  children: PropTypes.node,
  /** Remove default margins */
  noMargins: PropTypes.bool,
  /** Allows component to be overwritten by StyledComponents */
  className: PropTypes.string,
  /** Allows fontsize to be overwritten by StyledComponents */
  fontSize: PropTypes.number
};

CopyBody.defaultProps = {
  color: 'primary',
  weight: 'normal',
  tagName: 'p',
  align: 'inherit',
  children: null,
  noMargins: false,
  className: '',
  textTransform: 'none',
  fontSize: null
};

export default CopyBody;
