// exclude-from-index
import React from 'react';
import PropTypes from 'prop-types';
import { TAG_NAMES, FONT_WEIGHT, FONT_COLOR, TEXT_ALIGN, HEADING_COLORS, TEXT_TRANSFORM } from '../Base/Base';
import { StyledCopyMini } from './CopyMini.styles';

function CopyMini({
  tagName,
  weight,
  color,
  children,
  align,
  textTransform,
  noMargins,
  className,
  fontSize,
  noLineHeight
}) {
  return (
    <StyledCopyMini
      fontSize={fontSize}
      tagName={tagName}
      color={HEADING_COLORS[color]}
      align={align}
      weight={weight}
      noMargins={noMargins}
      className={className}
      textTransform={textTransform}
      noLineHeight={noLineHeight}
    >
      {children}
    </StyledCopyMini>
  );
}

CopyMini.propTypes = {
  /** Font Color: primary, secondary, tertiary, quaternary */
  color: PropTypes.oneOf(FONT_COLOR),
  /** Font Weight: normal, bold */
  weight: PropTypes.oneOf(FONT_WEIGHT),
  /** Element tag name: h1, h2, h3, h4, h5, h6, div, span, label, p, strong, li */
  tagName: PropTypes.oneOf(TAG_NAMES),
  /** Copy alignment: inherit, left, center, right, justify */
  align: PropTypes.oneOf(TEXT_ALIGN),
  /** Text Transform: none, capitalize, uppercase, lowercase, initial, inherit */
  textTransform: PropTypes.oneOf(TEXT_TRANSFORM),
  /** Copy/elements you want inside */
  children: PropTypes.node,
  /** Remove default margins */
  noMargins: PropTypes.bool,
  /** Allows component to be overwritten by StyledComponents */
  className: PropTypes.string,

  /** Allows fontsize to be overwritten by StyledComponents */
  fontSize: PropTypes.number,
  noLineHeight: PropTypes.bool
};

CopyMini.defaultProps = {
  color: 'primary',
  weight: 'normal',
  tagName: 'p',
  align: 'inherit',
  textTransform: 'none',
  children: null,
  noMargins: false,
  className: '',
  fontSize: null,
  noLineHeight: false
};

export default CopyMini;
