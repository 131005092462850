// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Adaptive from '../../../../Adaptive';
import { locationsShape, patternsShape } from '../../../../Search/Search.shapes';

import NavSearch from '../../../../NavSearch';

export default class SearchBar extends Component {
  static propTypes = {
    /**
     * Boolean prop to turn on autocomplete
     * Defaults to `false`
     */
    autocomplete: PropTypes.bool,
    /** Override default focused border width */
    borderFocusedWidth: PropTypes.string,
    /** Override default border width */
    borderWidth: PropTypes.string,
    /**
     * Accepts JSX/Components to display a Button to the right of the SearchField inside
     * of the FormControl component. Attach click handlers to this component directly.
     */
    button: PropTypes.node,
    /**
     * Method to clear the value controlled by parent container.
     * This must be sent down from the parent container.
     * Used by the clear search button
     */
    clearValue: PropTypes.func,
    /**
     * Height override for Search component
     */
    height: PropTypes.number,
    /**
     * Represents the id HTML attribute to be passed to the SearchField.
     */
    id: PropTypes.string,
    /**
     * Method to hook into closeFlyout method for extra functionality
     */
    onFlyoutCloseHook: PropTypes.func,
    /**
     * Override for max-height of the Flyout component
     */
    flyoutMaxHeight: PropTypes.string,
    /** position left setting for CSS */
    flyoutOffsetLeft: PropTypes.string,
    /** position top setting for CSS */
    flyoutOffsetTop: PropTypes.string,
    /**
     * Accepts JSX/Components to display on the SearchField. Styling is the responsibility
     * of the prop-passer.
     */
    icon: PropTypes.node,
    /**
     * Label that displays when the input is blurred & empty and shrinks/animates
     * on focus.
     */
    labelText: PropTypes.string,
    /**
     * Represents the name HTML attribute to be passed to SearchField.
     * Additionally passed to the label as a for attribute.
     */
    name: PropTypes.string,
    /**
     * Callback for the onBlur handlers inside the SearchField component.
     */
    onBlurHook: PropTypes.func,
    /**
     * onChangeHook handler passed down to the input itself to callback to the parent
     * when a user changes the input. Use this to ensure your parent container's value
     * is kept in sync and passed down the tree to the input.
     */
    onChangeHook: PropTypes.func,
    /**
     * onFlyoutItemClickHook handler for clicking on a FlyoutItem, or, locations item.
     */
    onFlyoutItemClickHook: PropTypes.func,
    /**
     * Callback for the onFocus handlers inside the SearchField component.
     */
    onFocusHook: PropTypes.func,

    /** JSX Button to render at bottom of screen */
    onSubmitHook: PropTypes.func,

    /**
     * Array of objects representing patterns or pattern suggets returned from the API.
     * These are passed down directly to the Flyout component.
     */
    patterns: patternsShape,
    /**
     * Placeholder string that displays while the input is focused AND
     * has no value.
     */
    placeholder: PropTypes.string,
    /**
     * Array of objects representing locations or result suggests returned from the API.
     * These are passed down directly to the Flyout component.
     */
    locations: locationsShape,
    /**
     * Localization label to be passed to Flyout component
     */
    searchFormatsLabel: PropTypes.string,
    /**
     * Ref to be passed down to SearchField -> StyledInput -> Input (@mui/core)
     */
    searchFieldRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     * String passed down the tree to the input itself to control the value of
     * the input. Ensure that you provide an onChangeHook handler to update the value
     * in your parent container.
     */
    searchValue: PropTypes.string
  };

  static defaultProps = {
    autocomplete: false,
    borderFocusedWidth: '0',
    borderWidth: '0',
    button: null,
    clearValue: () => {},
    height: 20,
    icon: null,
    id: 'globalnav-mobile-search-input',
    flyoutMaxHeight: '100vh',
    flyoutOffsetLeft: undefined,
    flyoutOffsetTop: undefined,
    labelText: 'Enter a location',
    name: 'globalnav-mobile-search-input',
    onBlurHook: () => {},
    onChangeHook: () => {},
    onFlyoutItemClickHook: () => {},
    onFlyoutCloseHook: () => {},
    onFocusHook: () => {},
    onSubmitHook: () => {},
    patterns: [],
    placeholder: 'Enter a location ',
    locations: [],
    searchFormatsLabel: 'Search Formats',
    searchFieldRef: null,
    searchValue: ''
  };

  render() {
    return (
      <Adaptive
        sm={
          <NavSearch.Mobile
            {...this.props}
            flyoutMaxHeight="calc(100vh - 55px)"
            flyoutOffsetLeft="0"
            flyoutOffsetTop="55px"
          />
        }
        lg={<NavSearch.Desktop {...this.props} />}
      />
    );
  }
}
