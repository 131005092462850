import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ClearSearch extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 22,
    height: undefined,
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 22 22" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/UI/Clear/Grey" transform="translate(-1.000000, -1.000000)" fill={iconColor}>
            <path
              d="M12,21.5996 C6.707,21.5996 2.4,17.2926 2.4,11.9996 C2.4,6.7066 6.707,2.3996 12,2.3996 C17.293,2.3996 21.6,6.7066 21.6,11.9996 C21.6,17.2926 17.293,21.5996 12,21.5996 M12,0.9996 C5.925,0.9996 1,5.9246 1,11.9996 C1,18.0756 5.925,22.9996 12,22.9996 C18.075,22.9996 23,18.0756 23,11.9996 C23,5.9246 18.075,0.9996 12,0.9996"
              id="Fill-1"
            />
            <polygon
              id="Fill-4"
              points="15.5049 7.5049 11.9999 11.0099 8.4949 7.5049 7.5049 8.4949 11.0099 11.9999 7.5049 15.5049 8.4949 16.4949 11.9999 12.9899 15.5049 16.4949 16.4949 15.5049 12.9899 11.9999 16.4949 8.4949"
            />
          </g>
        </g>
      </svg>
    );
  }
}
