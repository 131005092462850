// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../../SVGIcon';
import NavLink from '../NavLink';

export default class Logo extends Component {
  static propTypes = {
    /**
     * Value passed that would be acceptable for a regular anchor tag href attribute.
     */
    href: PropTypes.string,
    /**
     * Value passed that would be acceptable for a regular anchor tag target attribute.
     */
    target: PropTypes.string,
    /**
     * string of class(es) to add to the anchor.
     */
    className: PropTypes.string,
    /**
     * Function passed to handle any extra functionality than a regular page route.
     */
    onClick: PropTypes.func
  };

  static defaultProps = {
    href: '',
    target: '',
    className: '',
    onClick: () => {}
  };

  render() {
    const { href, target, className, onClick } = this.props;
    return (
      <NavLink onClick={onClick} href={href} target={target} className={className}>
        <SVGIcon selectedIcon="lodgelinkcolor" width="120px" height="40px" />
      </NavLink>
    );
  }
}
