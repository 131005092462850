import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class RoomService extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m10 15.16v-2h-2.5v-11.45h-1v11.45h-2.5v2h-1.52l-1.17 7h11.37l-1.16-7zm-5-1h4v1h-4zm4 7v-2.45h-1v2.45h-2.7v-2.45h-1v2.45h-1.81l.84-5h7.34l.83 5z"
        />
        <path
          fill={iconColor}
          d="m21.84 12.32h-5.38-4.39l1.42 9.87h6.07l.7-4.84h1.58zm-3.14 8.84h-4.35l-1.12-7.87h3.48l1 4h1.54zm2.14-4.84h-2.35l-.75-3h3.1z"
        />
      </svg>
    );
  }
}
