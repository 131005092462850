import { Container } from 'react-grid-system';
import styled from 'styled-components';

import { grid, media } from '../../../css';

/**
 * Get padding styles to be applied to Container.
 *
 * @param {*} screenClass One of xs, sm, md, lg or xl.
 * @param {*} props Props that are passed to Container.
 * @returns {string} Padding styles.
 */
function getContainerPadding(screenClass, { nogutter }) {
  const [xs, sm, md, lg, xl] = grid.containerGutters;

  let padding;

  if (nogutter) {
    padding = 0;
  } else {
    switch (screenClass) {
      case 'xs':
        padding = xs;
        break;
      case 'sm':
        padding = sm;
        break;
      case 'md':
        padding = md;
        break;
      case 'lg':
        padding = lg;
        break;
      case 'xl':
        padding = xl;
        break;
      default:
        padding = xs;
    }
  }

  // Ugly but I need to use !important to override the inline styles
  const paddingStyles = `
    padding-left: ${padding}px !important;
    padding-right: ${padding}px !important;
  `;

  return paddingStyles;
}

/**
 * Wraps the `react-grid-system` Container component with our own styles which
 * add additional padding to both sides of the Container.
 */
export const StyledContainer = styled(Container)`
  ${props => getContainerPadding('xs', props)}

  ${media.greaterThan('sm')`
    ${props => getContainerPadding('sm', props)}
  `}

  ${media.greaterThan('md')`
    ${props => getContainerPadding('md', props)}
  `}

  ${media.greaterThan('lg')`
    ${props => getContainerPadding('lg', props)}
  `}

  ${media.greaterThan('xl')`
    ${props => getContainerPadding('xl', props)}
  `}
`;
