import gql from 'graphql-tag';

/**
 * Me Query
 */
const FRAGMENT_COMMON_ME_FIELDS = gql`
  fragment FRAGMENT_COMMON_ME_FIELDS on Me {
    userId
    emailAddress
    firstName
    lastName
    active
    accountCreated
    receiveMarketingEmails
    phoneNumber
    position
    company
    admin
    adminToken {
      action
      organization {
        name
      }
      identityOnBehalf {
        emailAddress
      }
    }
    organizations {
      id
      domainSuffix
      name
      stub
      uniqueCrewIdentifier
      slug
      emailAddress
      membershipType
      permissions
      status
    }
    activeOrganization {
      id
      creditApplicationStatus
      masterServiceAgreementNeedsUpdate
      domainSuffix
      name
      uniqueCrewIdentifier
      stub
      slug
      featureFlagMapping {
        featureFlag {
          active
          id
          featureName
          description
          featureIdentifier
        }
        active
      }
      primaryBusinessAddress {
        address
        city
        postalCode
        region
        country
      }
      emailAddress
      membershipType
      permissions
      trackingCodesRequired
    }
    userJoinRequests {
      requestStatus
      userRequestedOrganizationName
    }
  }
`;

export const MUTATION_LOGIN = gql`
  mutation MUTATION_LOGIN($emailAddress: EmailAddress!, $password: String!) {
    login(emailAddress: $emailAddress, password: $password) {
      tokenExpiry
      accountToken
      errorMessage
      me {
        ...FRAGMENT_COMMON_ME_FIELDS
      }
    }
  }
  ${FRAGMENT_COMMON_ME_FIELDS}
`;

export const MUTATION_LOGOUT = gql`
  mutation MUTATION_LOGOUT {
    logout @client
  }
`;

export const QUERY_ME = gql`
  query QUERY_ME {
    me {
      ...FRAGMENT_COMMON_ME_FIELDS
    }
  }
  ${FRAGMENT_COMMON_ME_FIELDS}
`;

export const MUTATION_CHANGE_ACTIVE_ORGANIZATION = gql`
  mutation MUTATION_CHANGE_ACTIVE_ORGANIZATION($organizationId: Int!) {
    changeActiveOrganization(organizationId: $organizationId) {
      me {
        ...FRAGMENT_COMMON_ME_FIELDS
      }
    }
  }
  ${FRAGMENT_COMMON_ME_FIELDS}
`;
