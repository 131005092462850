import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Government extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 120,
    iconColor: colors.colorGrey02,
    backgroundColor: colors.iconGreen
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
        <circle
          opacity={0.4}
          fill={backgroundColor}
          cx="66.56"
          cy="44.81"
          r="13.73"
          transform="translate(-11.73 27.25) rotate(-21.31)"
        />
        <rect
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          stroke={iconColor}
          x="29.86"
          y="24.12"
          width="58.82"
          height="73.71"
        />
        <line
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          stroke={iconColor}
          x1="39.21"
          y1="40.28"
          x2="63.21"
          y2="40.28"
        />
        <line
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          stroke={iconColor}
          x1="39.21"
          y1="71.5"
          x2="79.45"
          y2="71.5"
        />
        <line
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          stroke={iconColor}
          x1="39.21"
          y1="84.46"
          x2="79.45"
          y2="84.46"
        />
      </svg>
    );
  }
}
