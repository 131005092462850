import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class AirportIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string,
    backgroundColor: PropTypes.string
  };

  static defaultProps = {
    width: 22,
    height: 22,
    iconColor: colors.colorBlue,
    backgroundColor: 'none'
  };

  render() {
    const { height, width, iconColor, backgroundColor } = this.props;

    return (
      <svg
        height={height}
        width={width}
        viewBox="0 0 22 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Batch-5-–-Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="XL_Checkout_05_Payment_02" transform="translate(-150.000000, -539.000000)" fillRule="nonzero">
            <g id="Group-4" transform="translate(120.000000, 511.000000)">
              <g id="Radio-Buttons/Radio-Button-Copy" transform="translate(31.000000, 24.000000)">
                <g id="State">
                  <circle id="Oval-Copy-2" stroke={iconColor} strokeWidth="1.5" fill="none" cx="10" cy="15" r="10" />
                  <circle id="Oval-Copy-3" fill={backgroundColor} cx="10" cy="15" r="6" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
