import get from 'lodash.get';
import set from 'lodash.set';
import defaultsDeep from 'lodash.defaultsdeep';

export default class LocalStorage {
  constructor(key, initialValues = {}) {
    if (!key) {
      throw new Error('Missing key for cache storage.');
    }
    this.storageKey = key;
    this.initialValues = initialValues;
    this.ensureValues(this.initialValues);
  }

  ensureValues(values = {}) {
    const currentValues = this.read();
    const completeValues = defaultsDeep(currentValues, values);
    this.write(completeValues);
  }

  read() {
    const storageEntry = localStorage.getItem(this.storageKey);
    return JSON.parse(storageEntry);
  }

  write(data) {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(this.storageKey, serializedData);
  }

  get(key) {
    const storeData = this.read();
    return get(storeData, key);
  }

  set(key, value) {
    const storeData = this.read();
    const newData = set(storeData, key, value);

    this.write(newData);
    return newData;
  }
}
