import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Kitchenette extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width={width} height={height}>
        <g>
          <g>
            <path
              fill={iconColor}
              d="M77.1,9.9h-4.9V7h2.3c0,0,0,0,0,0c0.6,0,1.1-0.5,1.1-1.1c0-0.1,0-0.3-0.1-0.4l-0.8-4.7C74.5,0.4,74,0,73.5,0H42.6
                c-0.5,0-1,0.4-1,0.9l-0.9,4.9c-0.1,0.3,0,0.6,0.2,0.9C41.1,6.8,41.4,7,41.8,7h2.1v2.9H1.1C0.5,9.9,0,10.4,0,11v48
                c0,0.6,0.5,1.1,1.1,1.1h76c0.6,0,1.1-0.5,1.1-1.1V11C78.1,10.4,77.6,9.9,77.1,9.9z M43.5,2.1h29.1l0.5,2.8H43L43.5,2.1z M45.9,7
                H70v2.9H45.9V7z M76,57.9H2.1V12H76V57.9z"
            />
            <path
              fill={iconColor}
              d="M26,18.1H5.5c-0.6,0-1.1,0.5-1.1,1.1V54c0,0.6,0.5,1.1,1.1,1.1h20.5c0.6,0,1.1-0.5,1.1-1.1V19.1
                C27.1,18.5,26.6,18.1,26,18.1z M25,20.2v9.9H6.6v-9.9H25z M6.6,53V32.2H25V53C25,53,6.6,53,6.6,53z"
            />
            <path
              fill={iconColor}
              d="M13.2,25.1h5.1c0.6,0,1.1-0.5,1.1-1.1S18.9,23,18.3,23h-5.1c-0.6,0-1.1,0.5-1.1,1.1S12.6,25.1,13.2,25.1z"
            />
            <path
              fill={iconColor}
              d="M9.4,39.7c-0.6,0-1.1,0.5-1.1,1.1v5.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1v-5.1C10.4,40.2,10,39.7,9.4,39.7z"
            />
            <path
              fill={iconColor}
              d="M32.4,27.1c-0.6,0-1.1,0.5-1.1,1.1V54c0,0.6,0.5,1.1,1.1,1.1h38.7c0.6,0,1.1-0.5,1.1-1.1V28.2c0-0.6-0.5-1.1-1.1-1.1
                H32.4z M70,53H33.4V29.2H70V53z"
            />
            <path
              fill={iconColor}
              d="M70.1,23H33.3c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h36.8c0.6,0,1.1-0.5,1.1-1.1S70.7,23,70.1,23z"
            />
            <path
              fill={iconColor}
              d="M39.8,21c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4s-3.4,1.5-3.4,3.4S37.9,21,39.8,21z M39.8,16.4c0.7,0,1.3,0.6,1.3,1.3
                c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3C38.6,17,39.1,16.4,39.8,16.4z"
            />
            <path
              fill={iconColor}
              d="M52.1,21c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4s-3.4,1.5-3.4,3.4S50.2,21,52.1,21z M52.1,16.4c0.7,0,1.3,0.6,1.3,1.3
                c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3C50.8,17,51.4,16.4,52.1,16.4z"
            />
            <path
              fill={iconColor}
              d="M63.6,21c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4S61.8,21,63.6,21z M63.6,16.4
                c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3C62.4,17,63,16.4,63.6,16.4z"
            />
            <path
              fill={iconColor}
              d="M37.3,41c0.3,0,0.5-0.1,0.7-0.3l6.7-6.7c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0l-6.7,6.7c-0.4,0.4-0.4,1.1,0,1.5
                C36.8,40.9,37.1,41,37.3,41z"
            />
            <path
              fill={iconColor}
              d="M41.4,45.5c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l9.8-9.8c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0
                l-9.8,9.8C41,44.5,41,45.1,41.4,45.5z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
