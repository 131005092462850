import PropTypes from 'prop-types';
import PropertyPropType from './Property';
import { CommonIDPropType } from './common';

const SupplierPropType = PropTypes.shape({
  id: CommonIDPropType,
  name: PropTypes.string,
  brand: PropTypes.string,
  property: PropTypes.arrayOf(PropertyPropType)
});

export default SupplierPropType;
