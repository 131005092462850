import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Medic extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m15.37 22.5h-6.75v-7.13h-7.12v-6.75h7.12v-7.12h6.75v7.12h7.13v6.75h-7.13zm-5.75-1h4.75v-7.13h7.13v-4.75h-7.13v-7.12h-4.75v7.12h-7.12v4.75h7.12z"
        />
      </svg>
    );
  }
}
