import styled from 'styled-components';
import { media, fonts, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledHeadingSecond = styled(Base)`
  font-size: ${mixins.pxToRem(18)};
  font-family: ${props => (props.weight === 'bold' ? fonts.fontBold : fonts.fontMedium)};
  line-height: ${mixins.pxToRem(32)};
  letter-spacing: 0.5px;
  text-align: ${props => props.align};
  text-transform: ${props => props.textTransform};
  margin: ${props => (props.noMargins === false ? `0 0 ${mixins.pxToRem(8)} 0` : 0)};
  color: ${props => props.color};

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  ${media.greaterThan('lg')`
    font-size: ${mixins.pxToRem(28)};
    line-height: ${mixins.pxToRem(48)};
    letter-spacing: 1px;
  `};
`;
