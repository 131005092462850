import styled from 'styled-components';
import SVGIcon from '../../SVGIcon/SVGIcon';
import Flyout from '../../Search/Flyout';
import { colors, mixins } from '../../css';

export const StyledSearchSVG = styled(SVGIcon)`
  top: calc(50% - 10px);
  left: 23px;
  position: absolute;
  pointer-events: none;
  z-index: 3;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  height: ${mixins.pxToRem(95)};
  left: 0;
  bottom: 0;

  background-color: ${colors.colorWhite};
  padding: 20px;
  text-align: center;
  z-index: 89;
`;

export const FlyoutContainer = styled.div`
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: calc(100vh - ${mixins.pxToRem(55)});
  z-index: 2;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  overflow: hidden;
`;

export const StyledFlyout = styled(Flyout)`
  position: relative;
  top: auto;
  left: auto;
  max-height: calc(100% - ${mixins.pxToRem(150)});
`;
