import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class RotateRight extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={backgroundColor}
        viewBox="0 0 138.934 78.738"
      >
        <path
          id="Path_3"
          data-name="Path 3"
          d="M164.234,130.144,147.2,147.178l-17.033-17.034,6.184-6.184,6.434,6.436a58.588,58.588,0,0,0-109.8-22.626L25.3,103.592a67.305,67.305,0,0,1,126.253,26.863l6.5-6.5Z"
          transform="translate(-25.3 -68.44)"
          fill={iconColor}
        />
      </svg>
    );
  }
}
