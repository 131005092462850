import toastMessages from '../constants/messages/toasts';
import { BOOKING_PAGE_ROUTES } from '../constants/enums/BookingRoutes';

// if we are in the middle of editing a booking block navigation until confirmation
export const checkForEditedBooking = (goAhead, intl) => {
  if (sessionStorage.getItem('bookingObject')) {
    if (confirm(intl.formatMessage(toastMessages.leavingEditedBookingToast))) {
      sessionStorage.removeItem('bookingObject');
      goAhead();
    }
  } else {
    goAhead();
  }
};

export const checkBookingRoute = pathName => Object.values(BOOKING_PAGE_ROUTES).includes(pathName);
