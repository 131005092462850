import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class SharedRoomIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.colorBlack
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={height} width={width}>
        <path
          fill={iconColor}
          d="M5.86,12.28A3.79,3.79,0,0,0,6,14.43a3.86,3.86,0,0,0,3.11,2.46l2,5.28,2.24,1L14.49,21l-.6-1.58-.61-.32.37-.92-1-.48.3-.77-.48-1.28.09-.12a3.82,3.82,0,0,0,2.06-.77L19.81,17l2.3-.87-.77-2.37-1.54-.7-.64.21-.4-.91-1,.33L17.42,12l-1.25-.57a3.83,3.83,0,0,0-2.26-3.25A2.46,2.46,0,0,0,13.53,8,5.85,5.85,0,0,0,13,3.85a5.9,5.9,0,1,0-7.09,8.43Zm6.7-4.47A3.86,3.86,0,0,0,9.34,9.24a4.15,4.15,0,0,0-1.08.22,3.79,3.79,0,0,0-2.07,1.88A4.91,4.91,0,0,1,3.51,9.1a4.91,4.91,0,0,1,1.9-6.67,4.89,4.89,0,0,1,7.15,5.38Zm.69,6.54a3.81,3.81,0,0,0,0-2.64,3.86,3.86,0,0,0-2.59-2.35,2.82,2.82,0,0,1,2.86-.3,2.86,2.86,0,0,1,1.68,2.66v.33l1.48.67L17.19,14l1-.33.39.9,1.14-.36.79.36.34,1-1,.39-5.35-2.42-.24.22A2.82,2.82,0,0,1,13.25,14.35ZM6.83,12.53A5.86,5.86,0,0,0,8,12.61a1.35,1.35,0,1,0,.74-1.83,1.38,1.38,0,0,0-.8.84,6.34,6.34,0,0,1-.76,0,2.85,2.85,0,1,1,4.4,3.55l-.24.23.57,1.52-.5,1.26,1,.48-.36.92,1.07.55.3.81-.5,1-1-.45-2.07-5.5H9.5A2.85,2.85,0,0,1,7,14.07,2.81,2.81,0,0,1,6.83,12.53Zm2.28-.81a.36.36,0,0,1,.46.2.37.37,0,0,1-.21.46.36.36,0,0,1-.45-.21A.35.35,0,0,1,9.11,11.72Z"
        />
      </svg>
    );
  }
}
