import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getMaterialContext, STYLE_ELEMENT_ID } from './index';

export default class MaterialSSR extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: null
  };

  constructor(props) {
    super(props);

    this.materialContext = getMaterialContext();
  }

  componentDidMount() {
    // Remove stylesheet as suggested by:
    // http://cssinjs.org/server-side-rendering?v=v9.8.7
    const jssStyles = document.querySelector(`#${STYLE_ELEMENT_ID}`);
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <StylesProvider
        generateClassName={this.materialContext.generateClassName}
      >
        <MuiThemeProvider theme={this.materialContext.theme}>
          {children}
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}
