import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Pencil extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = { height: 16, width: 16, iconColor: colors.colorBlue };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 16 16" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h16v16H0z" />
          <path
            d="M14.635 4.192l-1.061 1.06-2.827-2.826 1.06-1.061a1.253 1.253 0 0 1 1.768 0l1.06 1.061a1.248 1.248 0 0 1 0 1.766zm-9.45 9.45l-3.96 1.13 1.132-3.956 7.683-7.684 2.828 2.827-7.683 7.683zM15.341 1.718L14.28.658a2.25 2.25 0 0 0-3.18 0L1.56 10.2a.49.49 0 0 0-.128.215L.02 15.362a.5.5 0 0 0 .618.62l4.947-1.415a.501.501 0 0 0 .216-.127l9.54-9.54c.427-.426.66-.99.66-1.591s-.233-1.166-.66-1.591z"
            fill={iconColor}
          />
        </g>
      </svg>
    );
  }
}
