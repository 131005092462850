import { generateMedia } from 'styled-media-query';

import breakpoints from './breakpoints';

/**
 * Convert breakpoints to the format 'styled-media-query' expects.
 */
const mediaBreakpoints = {};
Object.keys(breakpoints).forEach(breakpointName => {
  const value = breakpoints[breakpointName];

  if (value !== Infinity) {
    mediaBreakpoints[breakpointName] = `${value}px`;
  }
});

/**
 * Generate breakpoints.
 */
const breakpointMediaGenerators = generateMedia(mediaBreakpoints);

/**
 * Export for cssKit
 */
export default breakpointMediaGenerators;
