import PropTypes from 'prop-types';
import { CommonIDPropType } from './common';
import OrganizationPropType from './Organization';
import OrganizationFeatureFlagPropType from './OrganizationFeatureFlags';
import PropertyPropType from './Property';

const MePropType = PropTypes.shape({
  userId: CommonIDPropType,
  emailAddress: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  dateOfBirth: PropTypes.string,
  phoneNumber: PropTypes.string,
  profilePicture: PropTypes.string,
  organizations: PropTypes.arrayOf(OrganizationPropType),
  activeOrganization: OrganizationPropType,
  favourites: PropTypes.arrayOf(PropertyPropType),
  receiveMarketingEmails: PropTypes.bool,
  organizationFeatureFlags: PropTypes.arrayOf(OrganizationFeatureFlagPropType)
});

export default MePropType;
