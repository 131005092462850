const Industry = {
  DEFENSE_NAVAL_BASES_AIR_FORCE_BASES_ARMY_BASES: 'DEFENSE_NAVAL_BASES_AIR_FORCE_BASES_ARMY_BASES',
  INDIGENOUS: 'INDIGENOUS',
  OTHER_GOVERNMENT_EXCL_EDUCATION_HEALTHCARE_INDIGENOUS: 'OTHER_GOVERNMENT_EXCL_EDUCATION_HEALTHCARE_INDIGENOUS',
  EDUCATION_PRIVATE: 'EDUCATION_PRIVATE',
  EDUCATION_PUBLIC: 'EDUCATION_PUBLIC',
  HEALTHCARE_EMERGENCY_RESPONSE_AND_SOCIAL_SERVICES: 'HEALTHCARE_EMERGENCY_RESPONSE_AND_SOCIAL_SERVICES',
  CONSTRUCTION_COMMERCIAL_INDUSTRIAL: 'CONSTRUCTION_COMMERCIAL_INDUSTRIAL',
  CONSTRUCTION_RESIDENTIAL: 'CONSTRUCTION_RESIDENTIAL',
  INFORMATION_AND_PROFESSIONAL_SERVICES_EXCL_TRAVEL_MGMT: 'INFORMATION_AND_PROFESSIONAL_SERVICES_EXCL_TRAVEL_MGMT',
  UTILITIES: 'UTILITIES',
  CONSUMER_SERVICES_DISCRETIONARY_EXCL_TRAVEL_MGMT: 'CONSUMER_SERVICES_DISCRETIONARY_EXCL_TRAVEL_MGMT',
  CONSUMER_SERVICES_STAPLES: 'CONSUMER_SERVICES_STAPLES',
  OIL_AND_GAS_UPSTREAM_DRILLING_AND_COMPLETIONS: 'OIL_AND_GAS_UPSTREAM_DRILLING_AND_COMPLETIONS',
  OIL_AND_GAS_MIDSTREAM_INFRASTRUCTURE_PIPELINES_FACILITIES:
    'OIL_AND_GAS_MIDSTREAM_INFRASTRUCTURE_PIPELINES_FACILITIES',
  OIL_AND_GAS_DOWNSTREAM_REFINERIES_PETROCHEMICALS: 'OIL_AND_GAS_DOWNSTREAM_REFINERIES_PETROCHEMICALS',
  ENERGY_CLEAN_AND_RENEWABLE: 'ENERGY_CLEAN_AND_RENEWABLE',
  MINING_NON_METALS_EXCL_COAL: 'MINING_NON_METALS_EXCL_COAL',
  MINING_METALS_GOLD_COPPER_SILVER_IRON: 'MINING_METALS_GOLD_COPPER_SILVER_IRON',
  MINING_COAL: 'MINING_COAL',
  OTHER_MINING: 'OTHER_MINING',
  AGRICULTURE: 'AGRICULTURE',
  TRANSPORTATION_AND_WAREHOUSING: 'TRANSPORTATION_AND_WAREHOUSING',
  MANUFACTURING: 'MANUFACTURING',
  TRAVEL_MANAGEMENT: 'TRAVEL_MANAGEMENT',
  OTHER: 'OTHER',
  INTERNAL_PROJECT: 'INTERNAL_PROJECT'
};

export const IndustryOrder = [
  Industry.AGRICULTURE,
  Industry.CONSTRUCTION_COMMERCIAL_INDUSTRIAL,
  Industry.CONSTRUCTION_RESIDENTIAL,
  Industry.CONSUMER_SERVICES_DISCRETIONARY_EXCL_TRAVEL_MGMT,
  Industry.CONSUMER_SERVICES_STAPLES,
  Industry.DEFENSE_NAVAL_BASES_AIR_FORCE_BASES_ARMY_BASES,
  Industry.EDUCATION_PRIVATE,
  Industry.EDUCATION_PUBLIC,
  Industry.ENERGY_CLEAN_AND_RENEWABLE,
  Industry.HEALTHCARE_EMERGENCY_RESPONSE_AND_SOCIAL_SERVICES,
  Industry.INDIGENOUS,
  Industry.INFORMATION_AND_PROFESSIONAL_SERVICES_EXCL_TRAVEL_MGMT,
  Industry.INTERNAL_PROJECT,
  Industry.MANUFACTURING,
  Industry.MINING_COAL,
  Industry.MINING_METALS_GOLD_COPPER_SILVER_IRON,
  Industry.MINING_NON_METALS_EXCL_COAL,
  Industry.OIL_AND_GAS_DOWNSTREAM_REFINERIES_PETROCHEMICALS,
  Industry.OIL_AND_GAS_MIDSTREAM_INFRASTRUCTURE_PIPELINES_FACILITIES,
  Industry.OIL_AND_GAS_UPSTREAM_DRILLING_AND_COMPLETIONS,
  Industry.OTHER_GOVERNMENT_EXCL_EDUCATION_HEALTHCARE_INDIGENOUS,
  Industry.OTHER_MINING,
  Industry.TRANSPORTATION_AND_WAREHOUSING,
  Industry.TRAVEL_MANAGEMENT,
  Industry.UTILITIES,
  Industry.OTHER
];

export default Industry;
