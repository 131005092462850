import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Padlock extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 12,
    height: undefined,
    iconColor: colors.colorGrey04
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Atoms" fill="none" fillRule="evenodd">
          <g id="Icons/UI/Padlock" transform="translate(-4 -3)" fill={iconColor}>
            <g id="Group-3" transform="translate(4.604 2.632)">
              <g id="Group-2">
                <g id="Group" transform="translate(2.75)" fillRule="nonzero">
                  <path
                    d="M4.71461631,0.489402639 C-0.134822447,0.489402639 0.00471354769,3.67071958 0.00471354769,11.693683 L0.860766086,11.693683 C1.41350843,4.62373988 0.413508425,1.43785806 4.70447285,1.43785806 L4.71461631,0.489402639 Z"
                    id="Path-4"
                  />
                </g>
                <g id="Group" transform="matrix(-1 0 0 1 12.115 0)" fillRule="nonzero">
                  <path
                    d="M4.71461631,0.489402639 C-0.134822447,0.489402639 0.00471354769,3.67071958 0.00471354769,11.693683 L0.860766086,11.693683 C1.41350843,4.62373988 0.413508425,1.43785806 4.70447285,1.43785806 L4.71461631,0.489402639 Z"
                    id="Path-4"
                  />
                </g>
                <rect id="Rectangle" stroke={iconColor} x="0.5" y="8.858" width="13.792" height="9.378" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
