const nextRoutes = require('next-routes')();

// Routes are defined in routes.js
const routes = require('./routes');

// All supported locales are defined here
const { getLocaleIds } = require('../locales');

/**
 * Helper to assist in adding localized routes.
 *
 * The patterns passed in to this function be prefixed with a locale.
 *
 * Passed in pattern:
 * /my-route
 *
 * Results in the following pattern:
 * /:locale/my-route
 *
 * @param {string} name Route name
 * @param {string} pattern Route pattern (See: https://github.com/pillarjs/path-to-regexp)
 * @param {string} page Page inside ./pages to be rendered
 */
const addLocalizedRoute = ({ name, pattern, page, bypassLocalization = false }) => {
  // Build a pattern that matches the list of supported locales.
  const localePattern = `:locale(${getLocaleIds().join('|')})`;

  // Build a route object for next routes
  const route = {
    name,
    pattern: bypassLocalization ? pattern : `/${localePattern}${pattern}`,
    page
  };

  // Pass it along to next-routes
  nextRoutes.add(route);
};

/**
 * Loop through all routes and add them to next-routes
 */
routes.forEach(route => {
  addLocalizedRoute(route);
});

module.exports = nextRoutes;
