import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './ToastComponent.styles';
import RichText from '../RichText';
import SVGIcon from '../SVGIcon/SVGIcon';
import { colors } from '../css';
import Text from '../BuildingBlocks/Text';

const TOAST_TRANSITION_STATE_ENUM = ['entering', 'entered', 'exiting', 'exited'];
export const TOAST_PLACEMENT_ENUM = [
  'bottom-left',
  'bottom-center',
  'bottom-right',
  'top-left',
  'top-center',
  'top-right'
];

class ToastComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    onDismiss: PropTypes.func,
    placement: PropTypes.oneOf(TOAST_PLACEMENT_ENUM),
    transitionDuration: PropTypes.number,
    transitionState: PropTypes.oneOf(TOAST_TRANSITION_STATE_ENUM)
  };

  static defaultProps = {
    children: null,
    onDismiss: () => {},
    placement: TOAST_PLACEMENT_ENUM[0],
    transitionState: TOAST_TRANSITION_STATE_ENUM[1],
    transitionDuration: 500
  };

  render() {
    const { transitionState, placement, children, transitionDuration, onDismiss } = this.props;
    return (
      <Styled.Container transitionDuration={transitionDuration} transitionState={transitionState} placement={placement}>
        <div>
          {typeof children === 'string' ? (
            <Text.CopyBody noMargins>
              <RichText content={children} complexText />
            </Text.CopyBody>
          ) : (
            children
          )}
        </div>
        {onDismiss ? (
          <Styled.Button onClick={onDismiss} tabIndex="0" type="button">
            <SVGIcon selectedIcon="close" iconColor={colors.colorBlue} />
          </Styled.Button>
        ) : null}
      </Styled.Container>
    );
  }
}

export default ToastComponent;
