import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // Toasts
  errorMessageToast: '<p><strong>An error has occurred:</strong> {message}</p>',
  defaultErrorToast: '<p>Your action was not successful.</p>',
  successMessageToast: '<p><strong>Success!</strong> {message}</p>',
  defaultSuccessToast: '<p>Your action was successful.</p>',
  notificationMessageToast: '<p><strong>Notification: </strong> {message}</p>',
  leavingEditedBookingToast: 'You are leaving your edited booking. Your edits will be lost. Do you want to continue?',
  maxGuestExceededToast:
    '<p>Your request has exceeded our maximum booking limits. Maximum number of guests allowed is {maxGuest}.<p>'
});

export default messages;
