import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class BagStorage extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m17.06 7.25h-3.06v-5h1.5v-1h-7v1h1.5v5h-3.06a1.5 1.5 0 0 0 -1.5 1.5v12a1.5 1.5 0 0 0 1.5 1.5h1.06v.5h1v-.5h6v.5h1v-.5h1.06a1.5 1.5 0 0 0 1.5-1.5v-12a1.5 1.5 0 0 0 -1.5-1.5zm-6-5h2v5h-2.06zm6.52 18.5a.5.5 0 0 1 -.5.5h-10.14a.5.5 0 0 1 -.5-.5v-12a.5.5 0 0 1 .5-.5h10.12a.5.5 0 0 1 .5.5z"
        />
        <path fill={iconColor} d="m11.5 10.75h1v8h-1z" />
        <path fill={iconColor} d="m14.5 10.75h1v8h-1z" />
        <path fill={iconColor} d="m8.5 10.75h1v8h-1z" />
      </svg>
    );
  }
}
