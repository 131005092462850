import styled from 'styled-components';
import { mixins, colors } from '../css';

const resolveTranslate = placement => {
  const position = placement.split('-');
  const relevantPlacement = position[1] === 'center' ? position[0] : position[1];
  const translateMap = {
    right: 'translate3d(120%, 0, 0)',
    left: 'translate3d(-120%, 0, 0)',
    bottom: 'translate3d(0, 120%, 0)',
    top: 'translate3d(0, -120%, 0)'
  };
  return translateMap[relevantPlacement];
};

const toastStates = placement => ({
  entering: `transform: ${resolveTranslate(placement)}`,
  exiting: `transform: ${resolveTranslate(placement)}`,
  exited: `transform: ${resolveTranslate(placement)}`,
  entered: `transform: translate3d(0, 0, 0);`
});

export const Container = styled.div`
  background-color: ${colors.colorWhite};
  border-radius: 37px;
  color: ${colors.colorBlack};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  transition: transform ${({ transitionDuration }) => transitionDuration}ms cubic-bezier(0.2, 0, 0, 1);
  ${({ placement, transitionState }) => toastStates(placement)[transitionState]};
  width: 510px;
  max-width: 90vw;
  margin: 45px 25px;
  word-break: break-word;
  border: 2px solid ${colors.colorBlue}; /* Added border */
`;

export const Button = styled.button`
  margin-left: ${mixins.pxToRem(20)};

  &:hover {
    cursor: pointer;
  }

  & > figure {
    cursor: pointer;
  }
`;
