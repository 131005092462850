import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Utilities extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 120,
    iconColor: colors.colorGrey02,
    backgroundColor: colors.iconGreen
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
        <polygon
          fill={backgroundColor}
          opacity={0.4}
          points="70.11 46.94 69.57 45.92 42.53 73.89 66.19 73.89 50.8 109.03 92.28 62.11 65.81 62.11 71.34 49.37 70.11 46.94"
        />
        <polygon
          stroke={iconColor}
          fill="none"
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          points="59.31 28.99 25.72 87.03 92.28 87.03 59.31 28.99"
        />
      </svg>
    );
  }
}
