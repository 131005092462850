import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class CardViewToggle extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: 16,
    width: 16,
    iconColor: colors.colorGrey
  };

  render() {
    const { height, width, iconColor } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Batch-6---Desktop" fill="none" fillRule="evenodd">
          <g id="XL_Dashboard_04_Bookings_01_Cards_01" transform="translate(-1248 -560)" fill={iconColor}>
            <g id="Group-6" transform="translate(1238 550)">
              <g id="Group-5" transform="translate(10 10)">
                <path d="M0,7 L7,7 L7,0 L0,0 L0,7 Z M1,6 L6,6 L6,1 L1,1 L1,6 Z" id="Fill-1" />
                <path d="M9,7 L16,7 L16,0 L9,0 L9,7 Z M10,6 L15,6 L15,1 L10,1 L10,6 Z" id="Fill-2" />
                <path d="M0,16 L7,16 L7,9 L0,9 L0,16 Z M1,15 L6,15 L6,10 L1,10 L1,15 Z" id="Fill-3" />
                <path d="M9,16 L16,16 L16,9 L9,9 L9,16 Z M10,15 L15,15 L15,10 L10,10 L10,15 Z" id="Fill-4" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
