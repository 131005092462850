import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class ColoredWarning extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string,
    backgroundColor: PropTypes.string
  };

  static defaultProps = {
    height: 22,
    width: 22,
    iconColor: colors.colorBlack,
    backgroundColor: colors.colorWhite
  };

  render() {
    const { height, width, iconColor, backgroundColor } = this.props;
    return (
      <svg width={width} height={height} id="Set_of_64_UI_Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <defs>
          <style />
        </defs>
        <path
          fill={iconColor}
          d="M15.03,1.24L.69,24.74c-.26,.42,.05,.96,.54,.96H30.95c.5,0,.8-.56,.53-.97L16.09,1.22c-.25-.39-.82-.38-1.06,.02Z"
        />
        <path
          fill={backgroundColor}
          d="M17.11,11.08c.02,.54-.38,.97-1.01,.97-.56,0-.95-.43-.95-.97s.41-.99,.99-.99,.97,.43,.97,.99Zm-1.76,11.16V13.53h1.58v8.71h-1.58Z"
        />
      </svg>
    );
  }
}
