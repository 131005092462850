import React from 'react';
import get from 'lodash.get';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withRouting from '../withRouting';
import NoPermission from '../../components/NoPermission';
import RedirectSignIn from '../../components/RedirectSignIn';
import Redirect from '../../components/Redirect';
import { checkBookingRoute } from '../../helpers/navigationHelpers';
import { QUERY_ME } from './gql';
import { getAuthStore, setAuthTokens, GQLLogin, GQLLogout } from './service';
import MePropType from '../../constants/types/Me';
import ORGANIZATION_STATUS from '../../constants/enums/OrganizationStatus';
import ONBOARDING_ROUTES from '../../constants/enums/OnboardingRoutes';

export { default as LODGELINK_ROLES } from '../../constants/enums/RoleName';
export { default as ORG_PERMISSIONS } from '../../constants/enums/OrganizationPermission';

export const userHasPermission = (me, permission) => {
  const userPermissions = get(me, 'activeOrganization.permissions', []);
  return userPermissions.includes(permission);
};

export const withAuthPropType = PropTypes.shape({
  loginAuthToken: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  changeOrganization: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  authError: PropTypes.string,
  me: MePropType
});

const withAuth = options => BaseComponent => {
  class ComponentWithAuth extends React.Component {
    static displayName = `withAuth(${BaseComponent.displayName || BaseComponent.name})`;

    constructor(props) {
      super(props);

      this.buildProps = this.buildProps.bind(this);
      this.login = this.login.bind(this);
      this.addFeatureFlagsToLocalStorage = this.addFeatureFlagsToLocalStorage.bind(this);
      this.loginAuthToken = this.loginAuthToken.bind(this);
      this.logout = this.logout.bind(this);
      this.changeOrganization = this.changeOrganization.bind(this);
      this.state = { authError: null, changingOrg: false };
    }

    async componentDidMount() {
      this.store = getAuthStore();
      this.addFeatureFlagsToLocalStorage();
    }

    async componentDidUpdate(prevProps) {
      const { me } = this.props;
      if (prevProps.me !== me && me) {
        this.addFeatureFlagsToLocalStorage();
      }
    }

    async loginAuthToken(variables) {
      const { client, reload } = this.props;
      setAuthTokens(client, this.store, variables);
      await reload();
    }

    async login(variables) {
      const { client } = this.props;
      this.setState({ authError: null });
      const authError = await GQLLogin(client, this.store, variables);
      this.setState({ authError });
      if (authError) {
        throw authError;
      }
    }

    async logout() {
      const { client } = this.props;
      await GQLLogout(client, this.store);
    }

    changeOrganization({ organization }) {
      const {
        router: { replaceRouteAndScroll, asPath }
      } = this.props;

      const routingArgs = ['ResolveActiveOrganization', { organizationSlug: organization.slug, nextRoute: asPath }];
      replaceRouteAndScroll(...routingArgs);
    }

    buildProps() {
      const { me, authenticated, loading } = this.props;
      const hocProps = {
        ...this.state,
        me,
        authenticated,
        loading,
        loginAuthToken: this.loginAuthToken,
        login: this.login,
        logout: this.logout,
        changeOrganization: this.changeOrganization
      };
      return hocProps;
    }

    addFeatureFlagsToLocalStorage() {
      const { me } = this.props;
      const permissions = get(me, 'activeOrganization.permissions', []);
      const featureFlagMapping = get(me, 'activeOrganization.featureFlagMapping', []);
      let featureFlagInLocalStorage = [permissions];
      if (featureFlagMapping && featureFlagMapping.length) {
        const buildFeatureFlagMapping = featureFlagMapping.map(featureFlag => {
          const {
            featureFlag: { featureIdentifier = '', active: featureFlagActive = false },
            active = false
          } = featureFlag;

          return { featureIdentifier, featureFlagActiveOrg: active, featureFlagActive };
        });
        featureFlagInLocalStorage = [...featureFlagInLocalStorage, ...buildFeatureFlagMapping];
      }
      const checkLocalStorage = JSON.parse(localStorage.getItem('organizationFeatureFlag')) || [];
      if (JSON.stringify(featureFlagInLocalStorage) !== JSON.stringify(checkLocalStorage)) {
        localStorage.setItem('organizationFeatureFlag', JSON.stringify(featureFlagInLocalStorage));
      }
    }

    /**
     * Check if user is onboarding and needs to redirect to an onboarding route
     * return false is user not required to redirect to onboarding route
     */
    getUserOnboardingRoute() {
      const { me, router } = this.props;

      const isLodgeLinkAdmin = me.admin || false;
      const organizations = get(me, 'organizations', []) || [];
      const isUserBelongsToAnActiveOrganization = organizations.some(
        organization => organization.status === ORGANIZATION_STATUS.Active
      );
      const isOnboardingRoute = Object.keys(ONBOARDING_ROUTES).some(routeName => router.pathname.includes(routeName));

      if (!isUserBelongsToAnActiveOrganization && !isLodgeLinkAdmin && !isOnboardingRoute) {
        const associatedWithAnOrganization = organizations.length > 0;
        const userJoinRequests = get(me, 'userJoinRequests', []) || [];
        const userJoinRequestsPending = userJoinRequests.some(
          userJoinRequest => !['Approved', 'AutoApproved'].includes(userJoinRequest.requestStatus)
        );

        const redirectRoute =
          associatedWithAnOrganization || userJoinRequestsPending
            ? ONBOARDING_ROUTES.OnboardingStatus
            : ONBOARDING_ROUTES.OnboardingOptions;

        return redirectRoute;
      }

      // if user not required to redirect to an onboarding route or
      // user belongs to an active organization
      // and user in an onboarding route already then redirect user to Dashboard
      return isUserBelongsToAnActiveOrganization && isOnboardingRoute ? 'Dashboard' : false;
    }

    render() {
      const { authenticated, loading, me, router } = this.props;

      const requireAuthentication = get(options, 'requireAuthentication', false);

      // The "requireAuthentication" option sets up a gate so that the component will
      // not render unless the user is authenticated
      if (requireAuthentication) {
        // While we figure out if the user is authenticated render nothing
        if (loading) {
          return null;
        }

        // If we are done loading and the user is not authenticated
        if (!authenticated) {
          return <RedirectSignIn method="replace" />;
        }

        // Find if user is onboarding and redirect to respective onboarding route
        const onboardingRoute = this.getUserOnboardingRoute();
        if (onboardingRoute) return <Redirect route={onboardingRoute} method="replace" />;
      }

      // CHECK FOR ORGANIZATION MEMBERSHIP
      // check to see if the current route has an organizationSlug value.
      // if it does then we need to check to see if its the same as the activeOrg slug
      // if its not we can try and change it,
      // if that doesn't work we 404
      const currentRoute = get(router, 'route', 'Dashboard').replace('/', '');

      // make sure that we aren't already on the resole org screen to prevent a redirect loop
      const IGNORED_ROUTES = ['ResolveOrganization'];

      if (IGNORED_ROUTES.includes[currentRoute]) {
        const routeOrganizationSlug = get(router, 'query.organizationSlug', null);
        const activeOrganizationSlug = get(me, 'activeOrganization.slug', null);
        const path = get(router, 'asPath', '');
        if (routeOrganizationSlug && activeOrganizationSlug && routeOrganizationSlug !== activeOrganizationSlug) {
          return (
            <Redirect
              route="ResolveActiveOrganization"
              params={{ organizationSlug: routeOrganizationSlug, nextRoute: path }}
            />
          );
        }
      }

      // CHECK FOR PERMISSIONS
      const requiredPermissions = get(options, 'requireAllPermissions', []);
      if (requiredPermissions && requiredPermissions.length) {
        if (!authenticated) {
          return <RedirectSignIn method="replace" />;
        }
        const assignedPermissions = get(me, 'activeOrganization.permissions', []);
        const missingPermissions = requiredPermissions.reduce((allMissingPerms, permission) => {
          if (assignedPermissions.includes(permission)) {
            return allMissingPerms;
          }
          return [...allMissingPerms, permission];
        }, []);

        if (missingPermissions && missingPermissions.length) {
          const permissionSummary =
            missingPermissions.length > 1
              ? missingPermissions.join(', ').replace(/, ([^,]*)$/, ' and $1')
              : missingPermissions[0];
          const organizationName = get(me, 'activeOrganization.name');
          return <NoPermission missingPermissions={permissionSummary} organizationName={organizationName} />;
        }
      }

      // This is to ensure user is redirected to the dashboard when the booking object is not in session and causes an error.
      const bookingNumberInURL = get(router, 'query.bookingNumber', null);
      const isBookingRoute = checkBookingRoute(router.pathname);
      const isWindowLoadedWithSessionStorage = typeof window !== 'undefined' && !window?.sessionStorage?.bookingObject;
      if (bookingNumberInURL && isBookingRoute && isWindowLoadedWithSessionStorage) {
        return <Redirect route="Dashboard" method="replace" />;
      }

      // These props are added from the graphql query query but don't need to be passed down
      const blackListProps = ['client', 'me', 'authenticated', 'loading', 'reload', 'error'];
      const passThroughProps = omit(this.props, blackListProps);

      return <BaseComponent auth={this.buildProps()} {...passThroughProps} />;
    }
  }

  return compose(
    withApollo,
    withRouting,
    graphql(QUERY_ME, {
      options: () => ({
        errorPolicy: 'all',
        ssr: false
      }),
      props: ({ data }) => {
        const me = get(data, 'me', null);
        const error = get(data, 'error', null);
        const userId = get(me, 'userId', null);
        const authenticated = userId !== null;

        return {
          me,
          authenticated,
          error,
          loading: data.loading,
          reload: data.refetch
        };
      }
    })
  )(ComponentWithAuth);
};

export default withAuth;
