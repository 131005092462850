import React from 'react';

export default function Information({ height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'scaleY(-1)' }}
    >
      <path
        d="M14.0001 7.33332C14.7334 7.33332 15.3334 7.93332 15.3334 8.66666V14C15.3334 14.7333 14.7334 15.3333 14.0001 15.3333C13.2667 15.3333 12.6667 14.7333 12.6667 14V8.66666C12.6667 7.93332 13.2667 7.33332 14.0001 7.33332ZM13.9867 0.666656C6.62675 0.666656 0.666748 6.63999 0.666748 14C0.666748 21.36 6.62675 27.3333 13.9867 27.3333C21.3601 27.3333 27.3334 21.36 27.3334 14C27.3334 6.63999 21.3601 0.666656 13.9867 0.666656ZM14.0001 24.6667C8.10675 24.6667 3.33341 19.8933 3.33341 14C3.33341 8.10666 8.10675 3.33332 14.0001 3.33332C19.8934 3.33332 24.6667 8.10666 24.6667 14C24.6667 19.8933 19.8934 24.6667 14.0001 24.6667ZM15.3334 20.6667H12.6667V18H15.3334V20.6667Z"
        fill="#53B7B0"
      />
    </svg>
  );
}
