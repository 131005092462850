import React, { Component } from 'react';

import { localePropType, LocaleConsumer } from '../../locales';

/**
 * Export a shape for use with this HOC.
 */
export const withActiveLocalePropType = localePropType;

/**
 * A HOC component that applies an "activeLocale" prop containing the
 * locale the user is currently using.
 *
 * @param {Component} WrappedComponent React component to wrap.
 */
export default function withActiveLocale(WrappedComponent) {
  class ComponentWithActiveLocale extends Component {
    static displayName = `withActiveLocale(${WrappedComponent.displayName || WrappedComponent.name})`;

    render() {
      return (
        <LocaleConsumer>
          {activeLocale => <WrappedComponent {...this.props} activeLocale={activeLocale} />}
        </LocaleConsumer>
      );
    }
  }

  return ComponentWithActiveLocale;
}
