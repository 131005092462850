import styled from 'styled-components';
import { zIndex } from '../../css';

const placements = {
  'top-left': `top: 0px; left: 0 `,
  'top-center': `top: 0px; left: 50%; transform: translateX(-50%);`,
  'top-right': ` top: 0px; right: 0; `,
  'bottom-left': ` bottom: 0px; left: 0;`,
  'bottom-center': ` bottom: 0px; left: 50%; transform: translateX(-50%);`,
  'bottom-right': ` bottom: 0px; right: 0;`
};

export const ToastContainer = styled.div`
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: ${zIndex.toasts};

  ${({ placement }) => placements[placement]};
`;
