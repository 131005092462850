import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class SearchIcon extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg viewBox="0 0 20 20" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Atoms" stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
          <g id="Icons/Search" transform="translate(-1.000000, 0.000000)" fill={iconColor} fillRule="nonzero">
            <g id="Icon_LL_Search" transform="translate(1.000000, 0.000000)">
              <path
                d="M8.60909091,16.3 C5.49308012,16.3036767 2.68201739,14.4289808 1.48787264,11.5508643 C0.293727896,8.67274767 0.951929252,5.35863704 3.15528315,3.15528315 C5.35863704,0.951929252 8.67274767,0.293727896 11.5508643,1.48787264 C14.4289808,2.68201739 16.3036767,5.49308012 16.3,8.60909091 C16.2949906,12.854586 12.854586,16.2949906 8.60909091,16.3 Z M8.60909091,2.21818182 C5.09455159,2.21818182 2.24545455,5.06727886 2.24545455,8.58181818 C2.24545455,12.0963575 5.09455159,14.9454545 8.60909091,14.9454545 C12.1236302,14.9454545 14.9727273,12.0963575 14.9727273,8.58181818 C14.9727273,5.06727886 12.1236302,2.21818182 8.60909091,2.21818182 Z"
                id="Shape"
              />
              <rect
                id="Rectangle-path"
                transform="translate(16.523257, 16.521396) rotate(-45.000000) translate(-16.523257, -16.521396) "
                x="15.8687113"
                y="12.3441232"
                width="1.30909091"
                height="8.35454545"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
