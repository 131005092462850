import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Transportation extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 120,
    iconColor: colors.colorGrey02,
    backgroundColor: colors.iconGreen
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" stroke={iconColor} strokeMiterlimit={10} strokeWidth="2.33px" cx="85.37" cy="80" r="7.79" />
        <circle fill="none" stroke={iconColor} strokeMiterlimit={10} strokeWidth="2.33px" cx="35.11" cy="80" r="7.79" />
        <rect fill={backgroundColor} opacity={0.4} x="26.05" y="41.53" width="48.51" height="22.25" />
        <line
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="42.9"
          x2="77.58"
          y1="80"
          y2="80"
        />
        <polyline
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          points="92.89 80 100.73 80 100.73 58.48 91.94 45.91 74.56 45.91 74.56 80"
        />
        <line
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="94.2"
          x2="100.73"
          y1="70.44"
          y2="70.44"
        />
        <polyline
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          points="100.73 63.77 85.25 63.77 85.25 51.79 96.05 51.79"
        />
        <polyline
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          points="27.32 80 20.07 80 20.07 36.97 74.56 36.97 74.56 48.45"
        />
        <line
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="74.56"
          x2="20.07"
          y1="63.77"
          y2="63.77"
        />
        <line
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="20.07"
          x2="49.31"
          y1="54.84"
          y2="54.84"
        />
        <line
          fill="none"
          stroke={iconColor}
          strokeMiterlimit={10}
          strokeWidth="2.33px"
          x1="20.07"
          x2="49.31"
          y1="45.91"
          y2="45.91"
        />
      </svg>
    );
  }
}
