import { ApolloClient, ApolloLink, defaultDataIdFromObject, InMemoryCache } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-unfetch';
import get from 'lodash.get';
import getConfig from 'next/config';

import { getAuthStore } from '../hocs/withAuth/service';

import { getDefaultLocale } from '../locales';

import createStateLink from './state';

let apolloClient = null;

// Pull related configuration variables from the runtime config
const {
  publicRuntimeConfig: { DEBUG_ENABLE_GRAPHQL_LOGGING, LODGELINK_API_URI, PLATFORM_KEY }
} = getConfig();

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

function create(initialState, { locale = getDefaultLocale(), analyticIDs = {} }) {
  const httpLink = createHttpLink({
    uri: LODGELINK_API_URI
  });

  /**
   * Middleware for the HTTPLink to append headers to all requests.
   */
  const augmentedHeaders = setContext((_, { headers }) => {
    /**
     * Auth Headers.
     *
     * Used by the API for access control and identification.
     */
    const authHeaders = {};

    const store = getAuthStore();
    const accessToken = store.get('token', null);
    if (accessToken) {
      authHeaders.authorization = `Bearer ${accessToken}`;
    }

    /**
     * Analytics Headers.
     *
     * Used to relate/connect analytics captured on the front end with the analytics captured
     * on the back end.
     *
     * Mainly helps with tracking of unauthenticated users.
     */
    const analyticsHeaders = {
      clientId: analyticIDs.LLV2_CLIENT_ID,
      sessionId: analyticIDs.LLV2_SESSION_ID
    };

    /**
     * Locale Headers.
     *
     * Tells the API how it should localizejs it's responses.
     * Setting a default value now because it allows us to test things easier, and might prevent some bad stuff if we make a local setting issue.
     */

    const localeHeaders = {
      locale: locale.locale
    };

    /**
     * Some extra mks headers that ITGroove can use on
     * server side logging. One is for platform (web/ios/android/etc),
     * the other is for the environment (Browser, Node)
     */
    const environmentHeaders = {
      platform: PLATFORM_KEY,
      runtime: process.browser ? 'BROWSER' : 'NODEJS'
    };

    const customHeaders = {
      'Platform-Type': 'Web'
    };

    return {
      headers: {
        ...headers,
        ...authHeaders,
        ...analyticsHeaders,
        ...localeHeaders,
        ...environmentHeaders,
        ...customHeaders
      }
    };
  });

  /**
   * Create a cache.
   *
   * We can also define what the unique identifier for each type is
   * this should only be used when the default "id" is not found on a type.
   */
  const apolloCache = new InMemoryCache({
    dataIdFromObject: object => {
      // eslint-disable-next-line
      switch (object.__typename) {
        case 'Me':
          return `Me:${object.userId}`;
        case 'CrewMember':
          return `CrewMember:${object.memberId}`; // Needed to make this change because the way ITG was handling patron and crew member IDs
        case 'CreditApplication':
          return `CreditApplication:${object.creditApplicationId}`;
        case 'Booking':
          return `Booking:${object.bookingNumber}`;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    }
  }).restore(initialState || {});

  const stateLink = createStateLink({ cache: apolloCache });

  // Added some basic error link handling to make GQL errors more obvious.
  /* eslint-disable no-console */
  const errorLink = onError(error => {
    // Only log in the browser and if the configuration says logging is enabled
    if (!process.browser || !DEBUG_ENABLE_GRAPHQL_LOGGING) {
      return;
    }

    const { networkError, graphQLErrors, operation } = error;
    const { operationName, variables } = operation;

    console.group('GraphQL Error');

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`Message: ${message}`);
        console.log(`Operation Name: ${operationName}`);
        console.log(`Query Path: ${path ? path.map(step => step) : '–'}`);
        console.log(`Line: ${get(locations, '[0].line', '–')}`);
        console.log(`Column: ${get(locations, '[0].column', '–')}`);

        // IE does not have console.table and generates an error if used
        if (typeof console.table === 'function') {
          if (Object.keys(variables).length > 0) {
            console.table({ ...variables });
          }
        }
      });
    }

    if (networkError) {
      console.log(`Network error: ${networkError}`);
    }

    console.groupEnd();
  });
  /* eslint-enable no-console */

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([stateLink, augmentedHeaders, errorLink, httpLink]),
    cache: apolloCache
  });
}

export default function initApollo(initialState = {}, options = {}, recreate = false) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections
  if (!process.browser) {
    return create(initialState, { ...options });
  }

  // Reuse client on the client-side, so we aren't creating extra overhead.
  if (!apolloClient || recreate) {
    apolloClient = create(initialState, { ...options });
  }

  return apolloClient;
}

export const getClient = () => apolloClient;
