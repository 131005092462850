import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { VerticalSpacingWrapper } from './VerticalSpacing.styles';

const SIZES = ['lg', 'sm', 'xs'];

class VerticalSpacing extends Component {
  static propTypes = {
    /** This can be anything, a string, component etc. */
    children: PropTypes.node,
    /** Pick size of spacing you want to use. Current options are: lg, sm */
    size: PropTypes.oneOf(SIZES),
    /** Type of spacing you want to use */
    type: PropTypes.oneOf(['padding', 'margin'])
  };

  static defaultProps = {
    size: 'lg',
    type: 'padding',
    children: null
  };

  render() {
    const { children, size, type } = this.props;

    return (
      <VerticalSpacingWrapper size={size} type={type}>
        {children}
      </VerticalSpacingWrapper>
    );
  }
}

export default VerticalSpacing;
