import { colors } from '../css';

export const HEADING_COLORS = {
  primary: colors.colorGrey,
  secondary: colors.colorWhite,
  tertiary: colors.colorGrey03,
  quaternary: colors.colorBlue,
  error: colors.colorRed,
  alert: colors.colorCobalt,
  pending: colors.colorOrange
};
