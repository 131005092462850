import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class RotateLeft extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string
  };

  static defaultProps = {
    height: undefined,
    width: 20,
    backgroundColor: 'transparent',
    iconColor: colors.colorGrey03
  };

  render() {
    const { height, width, backgroundColor, iconColor } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={backgroundColor}
        viewBox="0 0 139.331 78.547"
      >
        <path
          id="Path_2"
          data-name="Path 2"
          d="M133.787,37.125A67.293,67.293,0,0,0,19.1,79.615l-6.88-6.882L6.029,78.919,23.063,95.952,40.1,78.919l-6.184-6.185-5.96,5.961a58.576,58.576,0,0,1,109.72-21.922L145.36,52.6a67.352,67.352,0,0,0-11.573-15.475Z"
          transform="translate(-6.029 -17.405)"
          fill={iconColor}
        />
      </svg>
    );
  }
}
