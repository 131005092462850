import styled from 'styled-components';
import { colors, mixins, fonts, zIndex } from '../../../css';

export const DesktopWrapper = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: ${mixins.pxToRem(80)};
  margin: 0 auto;
`;
export const HomeLinkContainer = styled.div`
  width: ${mixins.pxToRem(165)};
  height: 100%;
  padding: 0 ${mixins.pxToRem(20)};
`;

export const SearchContainer = styled.div`
  flex: 1 0 auto;
  padding: 0 ${mixins.pxToRem(10)} 0 ${mixins.pxToRem(20)};
  max-width: ${mixins.pxToRem(550)};
`;

export const LinkListContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  text-align: right;
  height: 100%;
  padding: 0 ${mixins.pxToRem(25)} 0 ${mixins.pxToRem(25)};
`;

export const SignInContainer = styled.div`
  margin-left: ${mixins.pxToRem(30)};
`;

export const AuthenticatedContainer = styled.div`
  width: ${({ authenticated }) => (authenticated ? mixins.pxToRem(160) : mixins.pxToRem(130))};
  height: 100%;
  position: relative;
  ${({ authenticated }) =>
    !authenticated &&
    `
    > a:only-child {
      background: ${colors.colorBlue};
      color: ${colors.colorWhite};
      text-decoration: none;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      text-transform: uppercase;

      padding: 10px 20px;
      font-family: ${fonts.fontBold};

      &:hover {
        background-color: ${colors.colorBlue02};
        text-decoration: none;
        color: ${colors.colorWhite};
      } 
    }
  `};
`;

export const NotificationsContainer = styled.div`
  height: 100%;
  width: auto;
  padding: 0 ${mixins.pxToRem(25)} 0 ${mixins.pxToRem(15)};
  /* offsetting the padding on the link list with margin left */
  margin-left: -${mixins.pxToRem(15)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: center;
  position: relative;
  > a {
    cursor: default;
  }
`;

export const NotificationsFlyout = styled.div`
  position: absolute;
  top: ${mixins.pxToRem(79)};
  left: 0;
  z-index: ${zIndex.navigation.notifications};
  transform: translateX(-75%);
  background-color: ${colors.colorWhite};
  padding: ${mixins.pxToRem(25)} ${mixins.pxToRem(20)};
  min-height: 100px;
  max-height: calc(100vh - ${mixins.pxToRem(120)});
  overflow-y: auto;
  width: ${mixins.pxToRem(300)};
  border: 1px solid ${colors.colorGrey05};
  font-family: ${fonts.fontRegular};
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;
