import styled from 'styled-components';
import SVGIcon from '../../SVGIcon/SVGIcon';

export const StyledSearchSVG = styled(SVGIcon)`
  top: calc(50% - 10px);
  left: 23px;
  position: absolute;
  pointer-events: none;
  z-index: 3;
`;
