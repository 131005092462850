import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css';

export default class Cafe extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    width: 24,
    height: undefined,
    iconColor: colors.iconGrey
  };

  render() {
    const { height, width, iconColor } = this.props;

    return (
      <svg viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
          fill={iconColor}
          d="m6.9 6.27.6.6a1.58 1.58 0 0 0 0-2.3.73.73 0 0 1 0-1.1l-.3-.3-.3-.3a1.58 1.58 0 0 0 0 2.3.74.74 0 0 1 0 1.1z"
        />
        <path
          fill={iconColor}
          d="m9.9 6.27.6.6a1.58 1.58 0 0 0 0-2.3.73.73 0 0 1 0-1.1l-.3-.3-.3-.3a1.58 1.58 0 0 0 0 2.3.74.74 0 0 1 0 1.1z"
        />
        <path
          fill={iconColor}
          d="m12.9 6.27.6.6a1.58 1.58 0 0 0 0-2.3.73.73 0 0 1 0-1.1l-.3-.3-.3-.3a1.58 1.58 0 0 0 0 2.3.74.74 0 0 1 0 1.1z"
        />
        <path
          fill={iconColor}
          d="m19.06 11.18h-1.39v-3.29h-14.17v10.73a2.5 2.5 0 0 0 2.5 2.5h9.17a2.5 2.5 0 0 0 2.5-2.5v-.79h1.39a1.39 1.39 0 0 0 1.44-1.31v-4a1.39 1.39 0 0 0 -1.44-1.34zm-2.39 7.44a1.5 1.5 0 0 1 -1.5 1.5h-9.17a1.5 1.5 0 0 1 -1.5-1.5v-9.73h12.17zm2.83-2.1c0 .15-.18.31-.44.31h-1.39v-4.65h1.39c.26 0 .44.16.44.31z"
        />
      </svg>
    );
  }
}
