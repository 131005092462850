import React from 'react';
import styled from 'styled-components';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import SVGIcon from '../../SVGIcon/SVGIcon';
import { colors, mixins, fonts, media } from '../../css';

/**
 * SearchField Styles
 *
 * Styled*Classes - These objects are used to enforce consistency with the classnames that are passed
 * in as props and subsequently passed down to the @material-ui components themselves
 * Styled* - represents the `styled`-tagged version of the @material-ui component
 *
 * Note that the props used inside the `styled`-tagged functions are passed into the component in the
 * SearchField component file.
 */

export const StyledInputClasses = {
  root: 'SearchField-root',
  focused: 'SearchField-input-focused'
};

const spreadInput = ({
  hasButton,
  hasIcon,
  borderWidth,
  borderFocusedWidth,
  height,
  fontSizeOverride,
  noLeftPadding,
  ...rest
}) => <Input {...rest} />;
export const StyledInput = styled(spreadInput)`
  &&.${({ classes }) => classes.root} {
    /* Element */
    background-color: #ffffff;
    caret-color: ${colors.colorBlue};
    box-sizing: border-box;
    border: 1px solid ${colors.colorGrey04};
    ${({ borderWidth }) => !!borderWidth && `border-width: ${borderWidth}`};
    border-color: ${colors.colorGrey04};
    border-style: solid;
    min-height: 100%;
    width: 100%;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    margin: 0;
    padding: ${({ height, hasIcon }) =>
      `0 ${mixins.pxToRem(height / 2)} 0 ${hasIcon ? mixins.pxToRem(44) : mixins.pxToRem(20)};`};
    ${media.greaterThan('370px')`
      padding:${({ height, hasIcon }) =>
        `0 ${mixins.pxToRem(height / 2)} 0 ${hasIcon ? mixins.pxToRem(59) : mixins.pxToRem(35)};`};
      ${({ noLeftPadding }) => noLeftPadding && `padding-left: 0;`};
    `};
    ${({ noLeftPadding }) => noLeftPadding && `padding-left: 0;`};
    align-items: center;

    &&.${({ classes }) => classes.focused} {
      outline: none;
      position: relative;
      ${({ hasIcon, hasButton }) => `
      border-width: 0 1px 0 ${hasIcon ? '1px' : '0'};
      border-color: transparent;
      border-right-color: ${hasButton ? colors.colorBlue : 'transparent'};
      `};
      ${({ borderFocusedWidth }) =>
        `box-shadow: inset ${borderFocusedWidth || '2px'} ${borderFocusedWidth || '2px'}  0 0 ${colors.colorBlue},
                     inset -${borderFocusedWidth || '2px'}  -${borderFocusedWidth || '2px'}  0 0 ${colors.colorBlue};`};
    }
  }
  && .${({ classes }) => classes.input} {
    /* Typopgraphy */
    color: ${colors.colorGrey};
    font-family: ${fonts.fontMedium};
    ${({ fontSizeOverride }) =>
      fontSizeOverride
        ? `font-size: ${mixins.pxToRem(fontSizeOverride)};`
        : `font-size: ${mixins.pxToRem(15)};
    `};
    ${media.greaterThan('md')`font-size: ${mixins.pxToRem(18)};`};
    caret-color: ${colors.colorBlue};

    /* Element */
    width: 100%;
    min-height: 100%;
    height: ${({ height }) => height || '80'}px;
    padding: ${({ height }) => `${mixins.pxToRem(height * 0.2)} 0 ${mixins.pxToRem(7)};`};
    margin: 0;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    /** Go away, IE11 */
    ::-ms-clear,
    ::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /** Go away, clear search button **/
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }

    /* some hacky stuff here to disable autofill styles. */
    ::-webkit-autofill,
    ::-webkit-autofill:hover,
    ::-webkit-autofill:focus {
      display: none;
    }
  }

  padding-top: 1rem !important;
  font-size: 1.125rem !important;
  font-family: AvenirNextLTPro-Medium, arial, helvetica, verdana, sans-serif !important;
`;

export const StyledLabelClasses = {
  root: 'SearchField-label',
  shrink: 'SearchField-label-shrink'
};

const spreadLabel = ({ hasIcon, height, ...rest }) => <InputLabel {...rest} />;
export const StyledLabel = styled(spreadLabel)`
  &&.${({ classes }) => classes.root} {
    /* Typopgraphy */
    color: ${colors.colorGrey03};
    font-family: ${fonts.fontRegular};
    font-size: ${mixins.pxToRem(16)};
    ${media.greaterThan('370px')`font-size: ${mixins.pxToRem(18)}`};
    top: 50%;
    left: 0;
    transform: translate(0, -50%) scale(1);
    transform-origin: top left;
    pointer-events: none;

    /* Element */
    margin-left: ${({ hasIcon }) => (hasIcon ? mixins.pxToRem(44) : mixins.pxToRem(20))};
    ${media.greaterThan('370px')`margin-left: ${({ hasIcon }) => (hasIcon ? mixins.pxToRem(60) : mixins.pxToRem(36))}`};
    z-index: 1;
  }

  &&.${({ classes }) => classes.shrink} {
    font-size: ${mixins.pxToRem(16)};
    color: ${colors.colorBlue};
    margin: ${({ height, hasIcon }) => `${mixins.pxToRem(height * 0.25)} 0 0 ${mixins.pxToRem(hasIcon ? 44 : 20)};`};
    ${media.greaterThan('370px')`margin: ${({ height, hasIcon }) =>
      `${mixins.pxToRem(height * 0.225)} 0 0 ${mixins.pxToRem(hasIcon ? 60 : 36)};`}`};
    left: 0;
    transform-origin: top left;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition-property: transform, top;

    ${({ height }) =>
      height < 70
        ? `
      top: 0px;
      transform: translate(0, 0) scale(0.65);`
        : `
      top: 5%;
      transform: translate(0, -5%) scale(0.75);`};
  }
`;

export const StyledHelperClasses = {
  root: 'SearchField-helper'
};

const spreadHelper = ({ hasIcon, height, ...rest }) => <FormHelperText {...rest} />;
export const StyledHelper = styled(spreadHelper)`
  &&.${({ classes }) => classes.root} {
    /* Typopgraphy */
    color: ${colors.colorRed};
    font-family: ${fonts.fontRegular};
    font-size: ${mixins.pxToRem(16)};
    ${media.greaterThan('370px')`font-size: ${mixins.pxToRem(18)}`};
    top: 0;
    left: 0;
    transform: translate(0, -50%) scale(1);
    transform-origin: top left;
    pointer-events: none;

    margin: ${({ height, hasIcon }) => `-${mixins.pxToRem(height * 0.27)} 0 5px ${mixins.pxToRem(hasIcon ? 44 : 20)};`};
    ${media.greaterThan('370px')`margin: ${({ height, hasIcon }) =>
      `-${mixins.pxToRem(height * 0.25)} 0 5px ${mixins.pxToRem(hasIcon ? 60 : 36)};`}`};
    z-index: 1;

    ${({ height }) =>
      height < 70
        ? `
      top: 0px;
      transform: translate(0, 0) scale(0.65);`
        : `
      top: 5%;
      transform: translate(0, -5%) scale(0.75);`};
  }
`;

export const StyledFormControlClasses = {
  root: 'SearchField-formcontrol-root'
};

const spreadFormControl = ({ flexRatio, ...rest }) => <FormControl {...rest} />;
export const StyledFormControl = styled(spreadFormControl)`
  &&.${({ classes }) => classes.root} {
    ${({ flexRatio }) => flexRatio && `flex: 1 0 ${flexRatio.split('/')[0]}%;`};
    width: 100%;
    min-height: 100%;
  }
`;

export const StyledClearSearch = styled.a`
  padding: 0 15px;
  position: absolute;
  top: calc(50% - 11px);
  right: 2px;
  cursor: pointer;
  background: #fff;
`;

const spreadDivProps = ({ flexRatio, ...rest }) => <div {...rest} />;

export const StyledContainer = styled(spreadDivProps)`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;

  ${({ flexRatio }) => flexRatio && `> button {flex: 0 1 ${flexRatio.split('/')[1]}%;}`};
`;

export const StyledCloseSVG = styled(SVGIcon)``;
