// exclude-from-index
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledLinkList, StyledLinkListItem } from './FooterLinkList.styles';

export default class FooterLinkList extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: []
  };

  /* eslint-disable react/no-array-index-key */
  renderChildren() {
    const { children } = this.props;
    return React.Children.toArray(children).map((child, index) => (
      <StyledLinkListItem key={index}>{child}</StyledLinkListItem>
    ));
  }

  render() {
    return <StyledLinkList {...this.props}>{this.renderChildren()}</StyledLinkList>;
  }
}
