import React from 'react';

export default function DndIcon() {
  return (
    <svg width={60} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_824_13274)">
        <path
          d="M9.16659 14.9997C9.16659 15.9163 8.41659 16.6663 7.49992 16.6663C6.58325 16.6663 5.83325 15.9163 5.83325 14.9997C5.83325 14.083 6.58325 13.333 7.49992 13.333C8.41659 13.333 9.16659 14.083 9.16659 14.9997ZM7.49992 8.33301C6.58325 8.33301 5.83325 9.08301 5.83325 9.99967C5.83325 10.9163 6.58325 11.6663 7.49992 11.6663C8.41659 11.6663 9.16659 10.9163 9.16659 9.99967C9.16659 9.08301 8.41659 8.33301 7.49992 8.33301ZM7.49992 3.33301C6.58325 3.33301 5.83325 4.08301 5.83325 4.99967C5.83325 5.91634 6.58325 6.66634 7.49992 6.66634C8.41659 6.66634 9.16659 5.91634 9.16659 4.99967C9.16659 4.08301 8.41659 3.33301 7.49992 3.33301ZM12.4999 6.66634C13.4166 6.66634 14.1666 5.91634 14.1666 4.99967C14.1666 4.08301 13.4166 3.33301 12.4999 3.33301C11.5833 3.33301 10.8333 4.08301 10.8333 4.99967C10.8333 5.91634 11.5833 6.66634 12.4999 6.66634ZM12.4999 8.33301C11.5833 8.33301 10.8333 9.08301 10.8333 9.99967C10.8333 10.9163 11.5833 11.6663 12.4999 11.6663C13.4166 11.6663 14.1666 10.9163 14.1666 9.99967C14.1666 9.08301 13.4166 8.33301 12.4999 8.33301ZM12.4999 13.333C11.5833 13.333 10.8333 14.083 10.8333 14.9997C10.8333 15.9163 11.5833 16.6663 12.4999 16.6663C13.4166 16.6663 14.1666 15.9163 14.1666 14.9997C14.1666 14.083 13.4166 13.333 12.4999 13.333Z"
          fill="#828282"
        />
      </g>
      <defs>
        <clipPath id="clip0_824_13274">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
